import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from '../app.service';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-plts-reports',
  templateUrl: './plts-reports.component.html',
  styleUrls: ['./plts-reports.component.css']
})
export class PltsReportsComponent implements OnInit {
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';


  // dashboard_property_developer='Client';
  // dashboard_main_contractor='Main Contractor';
  // dashboard_precinct_project='Precincts/Projects/Condos';
  // dashboard_street='Street';
  // dashboard_house_number='House Number';




  dashboard_date_from='Date From';
  dashboard_date_to='Date To';
  deal="Deal";
  dashboard_Next_Action="Next Action";
  dashboard_Outlet_Work_Title="Outlet Work Title";
  dashboard_Outlet_Number="Outlet Number";
  dashboard_Outlet_Status="Outlet Status";
  dashboard_Outlet_Sub_Status="Outlet Sub-Status";
  dashboard_City="City";
  dashboard_Tenancy_Expiry_From="Tenancy Expiry From";
  dashboard_Tenancy_Expiry_To="Tenancy Expiry To";




  dashboard_Number_of_Listed_Clients='Open Outlets';
  open_outlets;
  dashboard_Total_Number_of_Outlets='New Outlets';
  new_outlets;
  dashboard_Average_Number_Of_Outlets='Open Defects';
  open_defects;
  dashboard_Total_Deals_Value='Defects % Open';
  open_defects_percent;
  dashboard_Average_Deal_Value_Client='Nego Stage';
  nego_stage;
  dashboard_Average_Deal_Value_Outlet='Under Renovation';
  under_renovation;
  dashboard_New_Clients_Accepted='Closed Defects';
  closed_defects;
  dashboard_Deals_KIV='Defects % Closed';
  closed_defects_percent;
  dashboard_age_open_work_orders='Renewals';
  renewals;
  dashboard_age_closed_work_orders='Rent Pending'
  rent_pending;
  dashboard_frontdesk_work_orders='Outlets With Defects';
  outlets_with_defects;
  dashboard_supervisor_work_orders='Closed Outlets';
  closed_outlets;
  dashboard_created_closed_work_orders_Month='Deals Closed - Deal Value  (Compared to Previous Month)';
  dashboard_created_closed_work_orders_quarter='Deals Closed  - Previous Quarter';
  public userLevel: string;
  showPD = false;
  searchT: any = [];
  show_search_boxes=false;
  totalList: any;

  totalListDefects: any;

  total_open_defects;


  completeList: any = [];
  apiName = 'tenancymanagment'; // replace this with your api name.
  apiPath = '/tenancymanagment'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  apiNameDefects = 'pltsdefect'; // replace this with your api name.
  pathDefects = '/pltsdefect'; // replace this with the path you have configured on your API
  getMyInitDefects = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  
  constructor(private loader: LoaderService,private projectService: AppService) { }

  ngOnInit() {
    this.showPD=true;
    this.show_search_boxes = true;
    this.getAll();
    this.getAllDefects();
  }

  getAll(){
    this.loader.show();
    this.completeList.splice(0,this.completeList.length);
    var pd = localStorage.getItem('property_developer');
    let srchFiltersValues = [];
    let srchFiltersNames = [];
     var condition = [];
     condition.push(pd);
      srchFiltersNames.splice(0, srchFiltersNames.length);
      srchFiltersValues.splice(0, srchFiltersValues.length);
   
      for (let i = 0; i <= condition.length; i++){
        srchFiltersNames.push('client');
        srchFiltersValues.push(condition[i]);
      }      
    API.get(this.apiName, this.apiPath, this.getMyInit).then(response => {
      this.completeList.push(...response.data);
      this.totalList = Object.keys(this.completeList).length;
      const open_outlets=this.completeList.filter(obj => obj.next_action === 'Open');
      this.open_outlets=open_outlets.length;
      const new_outlets=this.completeList.filter(obj => obj.next_action === 'New Store' || obj.next_action === 'Owner Contected');
      this.new_outlets=new_outlets.length;
      const nego_stage=this.completeList.filter(obj => obj.next_action === 'Proposal Viable' || obj.next_action === 'T&C Negotiation' || obj.next_action === 'Contract Stage' || obj.next_action === 'Contract Signed');
      this.nego_stage=nego_stage.length;
      const under_renovation=this.completeList.filter(obj => obj.next_action === 'Under Renovation');
      this.under_renovation=under_renovation.length;
      const renewals=this.completeList.filter(obj => obj.site_information_store_sub_status === 'Request For Renewal' || obj.site_information_store_sub_status === 'Renewal In Progress');
      this.renewals=renewals.length;
      const rent_pending=this.completeList.filter(obj => obj.site_information_store_sub_status === 'Behind On Rent');
      this.rent_pending=rent_pending.length;
      const closed_outlets=this.completeList.filter(obj => obj.next_action === 'Closed');
      this.closed_outlets=closed_outlets.length;
      this.outlets_with_defects=1;
      console.log( this.open_outlets);
      this.loader.hide();
      console.log('\'project\' component-> All plts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All plts from server!');
      console.log(error.response);
    });
  }


  getAllDefects() {
    API.get(this.apiNameDefects, this.pathDefects, this.getMyInitDefects).then(response => {
      this.totalListDefects=response;
      const open_defects=this.totalListDefects.filter(obj => obj.next_action != 'Closed');
      this.open_defects=open_defects.length;
      this.open_defects_percent=(this.totalListDefects.length)/(this.open_defects)*100;
      const closed_defects=this.totalListDefects.filter(obj => obj.next_action === 'Closed');
      this.closed_defects=closed_defects.length;
      this.closed_defects_percent=(this.totalListDefects.length)/(this.closed_defects)*100;
      if(this.closed_defects_percent===Infinity){
        this.closed_defects_percent=0;
      }
      console.log('\'\' component-> All  Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      console.log('\'\' component-> Error in Retreiving All from server!');
      console.log(error);
    });
  }

  instantSearch(val, name) {
    this.completeList.push(...this.projectService.instantSearch(val, this.completeList, name, ''));
    this.refreshList();
  }

  instantSearchDropDown(val, name) {
    this.completeList.push(...this.projectService.instantSearch(val, this.completeList, name, 'All'));
    this.refreshList();
  }


  refreshList(){
    this.totalList = Object.keys(this.completeList).length;
      const open_outlets=this.completeList.filter(obj => obj.next_action === 'Open');
      this.open_outlets=open_outlets.length;
      const new_outlets=this.completeList.filter(obj => obj.next_action === 'New Store' || obj.next_action === 'Owner Contected');
      this.new_outlets=new_outlets.length;
      const nego_stage=this.completeList.filter(obj => obj.next_action === 'Proposal Viable' || obj.next_action === 'T&C Negotiation' || obj.next_action === 'Contract Stage' || obj.next_action === 'Contract Signed');
      this.nego_stage=nego_stage.length;
      const under_renovation=this.completeList.filter(obj => obj.next_action === 'Under Renovation');
      this.under_renovation=under_renovation.length;
      const renewals=this.completeList.filter(obj => obj.site_information_store_sub_status === 'Request For Renewal' || obj.site_information_store_sub_status === 'Renewal In Progress');
      this.renewals=renewals.length;
      const rent_pending=this.completeList.filter(obj => obj.site_information_store_sub_status === 'Behind On Rent');
      this.rent_pending=rent_pending.length;
      const closed_outlets=this.completeList.filter(obj => obj.next_action === 'Closed');
      this.closed_outlets=closed_outlets.length;
      this.outlets_with_defects=1;
  }

  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
        this.getAll()
  }

}
