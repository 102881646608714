import { Component, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { error } from 'util';
import { resolve } from 'url';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

declare const $: any;
type AOA = any[][];
@Component({
  selector: 'app-settings-services',
  templateUrl: './settings-services.component.html',
  styleUrls: ['./settings-services.component.css'],
  providers: [DatePipe]

})
export class SettingsServicesComponent implements OnInit {
  public rows = [];
  public srch = [];

  public countryAreaCodes = [];

  currentDate: string;
  currentTime: string;

  public addLT: any = {};
  public uptLT: any = {};
  addFormValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public typeOfUnitAdd;
  totalList: any;
  public categorys = [];
  public subCategorys = [];
  subCatLoad: Boolean = false;
  unsortedrows = [];

  SelectedLogoPic: FileList;
  eventLogoPic;
  haveLogo: boolean = false;
  viewLogo;

  selectedCompanyPic: FileList;
  eventComapnyPic;
  haveComapnyPic: boolean = false;
  viewComapnyPic;

  selectedPdfBrochure: FileList;
  eventPdfBrocheure;
  havePdfBrochure: boolean = false;
  viewPdfBrochure;

  selectedAttachments: FileList;
  eventAttachments;
  haveAttachments: boolean = false;
  viewAttachments;

  public updateItem: any = {};
  public editFlag: boolean = false;

  uploadFile = false;
  Results = '';
  excelData: AOA = [[1, 2], [3, 4]];
  RecordAdded = '';

  searchT: any = [];
  public MSG;

  logopic;
  companypic;
  pdfBrochure;
  attachment;

  notificationsUrl = "https://fcm.googleapis.com/fcm/send";

  scan: boolean = false;
  scanResults = '';

  public myDatePickerOptions1: IMyDpOptions = {
    todayBtnTxt: 'Today',
    dateFormat: 'dd/mm/yyyy',
    firstDayOfWeek: 'su',
    sunHighlight: true,
    inline: false,
    height: '48px'
  };

  uploadPicsFolder = '/General/Settings/Services';

  apiNameCat = 'servicesCategory';
  apiPathCat = '/servicesCategory';

  getCatInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
    }
  };

  deleteID;
  delete_results;
  apiNameDelete = 'settingsServices'; // replace this with your api name.
  pathDelete = '/settingsServices/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };


  apiNameSubCat = 'servicesSubCategory';
  apiPathSubCat = '/servicesSubCategory';

  getSubCatInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  
  apiName = 'settingsServices'; // replace this with your api name.
  path = '/settingsServices'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameNotifications = 'notifications'; // replace this with your api name.
  pathNotifications = '/notifications'; // replace this with the path you have configured on your API
  putMyInitNotifications = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  services_category='Category';
  services_sub_category='Sub Category';
  services_stars_rating='Stars Rating';
  services_company_name='Company Name';
  services_short_description='Short Description';
  services_address='Address';
  services_telephone_number='Telephone Number';
  services_country_code='Country Code';
  services_mobile='Mobile (Only Numbers without zero,dash or space)';
  services_email='Email';
  services_fb_messenger='FB Messenger';
  services_website='Website';
  services_facebook_page='Facebook Page';
  services_extended_description='Extended Description';
  services_special_offer='Special Offer';
  services_top_of_the_list_value='Top of The List Value';
  services_date_last_advertisement='Date Last Advertisement';
  services_advertising_from='Advertising From';
  services_advertising_to='Advertising To';
  services_paid='Paid';
  services_status='Status';
  services_logo='Logo';
  services_company_picture='Company Picture';
  services_pdf_brochure='PDF brochure';
  services_attachments='Attachments (PDF only)';
  services_upload_picture='Upload Pitcture';

  title = 'Services';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';
  generals_creation_date = 'Creation Date';

  permissions_userlevel;
  permissions_view=false;
  permissions_create=false;
  permissions_edit=false;
  permissions_delete=false;

  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  constructor(private loader: LoaderService, public http: HttpClient, private uploadService:
    UploadFileService, private appService: AppService, private datePipe: DatePipe) {

    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString('en-GB');
    this.countryAreaCodes = appService.countryAreaCodes;

  }

  
  ngOnInit() {
    this.getCategories();
    this.getAllServices();
    this.getPermissions();

    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }
  }

  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');
    this.showSuccessMsg = false;
    this.showErrorMsg = false;
  }

  onEdit(item) {
    console.log(item);
    if (item.attachment) {
      this.attachment = item.attachments;
    } else {
      this.attachment = "";
    }
    if (item.pdfBrochure) {
      this.pdfBrochure = item.pdfBrochure;
    } else {
      this.pdfBrochure = "";
    }
    if (item.logo) {
      this.logopic = item.logo;
    } else {
      this.logopic = "";
    }
    if (item.company_pic) {
      this.companypic = item.company_pic;
    } else {
      this.companypic = "";
    }
    this.editFlag = true;
    this.uptLT = {};
    this.updateItem = item;
    this.addFormValidation = false;
    this.onChangeCat(item.category);
    $('#Edit_modal').modal('show');
  }

  updatePicsAndFiles(f) {
    console.log(f.form.value);
    if (f.invalid) {
      this.addFormValidation = true;
    } else {
      this.loader.show();
      this.douploadLogo().then(Response => {
        this.douploadComapnyPic().then(Response => {
          this.douploadPdfBrochure().then(Response => {
            this.douploadAttachment().then(Response => {
              this.onUpdate(f);
            })
          })
        })
      })
    }
  }

  onUpdate(f) {
    const createBody = {
      id: f.form.value.id,
      category: f.form.value.category,
      sub_category: f.form.value.sub_category,
      stars_rating: f.form.value.stars_rating,
      company_name: f.form.value.company_name,
      short_desc: f.form.value.short_desc,
      address: f.form.value.address,
      telephone_number: f.form.value.telephone_number,
      dial_code: f.form.value.dial_code,
      mobile: f.form.value.mobile,
      email: f.form.value.email,
      fb_messenger: f.form.value.fb_messenger,
      web_site: f.form.value.web_site,
      fb_page: f.form.value.fb_page,
      ext_description: f.form.value.ext_description,
      special_offer: f.form.value.special_offer,
      top_of_list: f.form.value.top_of_list,
      date_last_advertisement: f.form.value.date_last_advertisement,
      advertisement_from: f.form.value.advertisement_from,
      advertisement_to: f.form.value.advertisement_to,
      paid: f.form.value.paid,
      status: f.form.value.status,
      logo: this.logopic,
      company_pic: this.companypic,
      pdfBrochure: this.pdfBrochure,
      attachments: this.attachment,
    };
    this.putMyInit.body = createBody;
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      var id = f.form.value.id;
      var arr = this.rows.find(function (item, i) {
        return item.id === id
      });

      arr.id = f.form.value.id;
      arr.category = f.form.value.category;
      arr.sub_category = f.form.value.sub_category,
        arr.stars_rating = f.form.value.stars_rating,
        arr.company_name = f.form.value.company_name,
        arr.short_desc = f.form.value.short_desc,
        arr.address = f.form.value.address,
        arr.telephone_number = f.form.value.telephone_number,
        arr.dial_code = f.form.value.dial_code,
        arr.mobile = f.form.value.mobile,
        arr.email = f.form.value.email,
        arr.fb_messenger = f.form.value.fb_messenger,
        arr.web_site = f.form.value.web_site,
        arr.fb_page = f.form.value.fb_page,
        arr.ext_description = f.form.value.ext_description,
        arr.special_offer = f.form.value.special_offer,
        arr.top_of_list = f.form.value.top_of_list,
        arr.date_last_advertisement = f.form.value.date_last_advertisement,
        arr.advertisement_from = f.form.value.advertisement_from,
        arr.advertisement_to = f.form.value.advertisement_to,
        arr.paid = f.form.value.paid,
        arr.status = f.form.value.status,
        arr.logo = this.logopic,
        arr.company_pic = this.companypic,
        arr.pdfBrochure = this.pdfBrochure,
        arr.attachments = this.attachment



      var index = this.rows.findIndex(function (item, i) {
        return item.id === id
      });
      if (index > -1) {
        this.rows.splice(index, 1);
      }

      this.rows.unshift(arr);
      this.srch.unshift(arr);
      this.rows = this.rows;
      this.showSuccessMsg = true;
      this.MSG = 'Service Updated Successfully!';
      $('#Edit_modal').modal('hide');
      console.log('\'services\' component-> Updated Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.MSG = 'Service Update Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'services\' component-> Error in Updating!');
      console.log(error.response);
    });

  }


  getCategories() {
    this.categorys.push('');
    this.loader.show();
    API.get(this.apiNameCat, this.apiPathCat, this.getCatInit).then(response => {
      this.categorys.push(...response.data);
      console.log('\'categorys\' component-> All categorys Retrieved Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'categorys\' component-> Error in Retreiving All categorys from server!');
      console.log(error.response);
    });
  }


  onChangeCat(category) {
    this.subCategorys.splice(0, this.subCategorys.length);

    if (category) {
      console.log(category);
      this.subCatLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (category != undefined) {
        srchFiltersNames.push('category');
        srchFiltersValues.push(category);
      }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {

      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.subCategorys.splice(0, this.subCategorys.length);
        this.getSubCatInit.body.FilterExpression = FilterExpression;
        this.getSubCatInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getSubCatInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanSubCat();
      }
    }
  }

  scanSubCat() {
    this.subCategorys.push('');
    this.getSubCat(this.apiNameSubCat, this.apiPathSubCat, this.getSubCatInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.subCategorys.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getSubCatInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanSubCat();
      } else {
        this.subCatLoad = false;
        if (this.editFlag == true) {
          this.uptLT = this.updateItem;
          this.editFlag = false;
        }
        delete this.getSubCatInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      if (this.totalList != 0) {
      } else {
      }
    });
  }

  getSubCat(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'Location\' component->  For Services Sub Cat Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'Location\' component-> For Services Error in Retreiving Sub Cat from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  addFormService(f, id) {
    console.log(f.form.value);
    if (f.invalid) {
      this.addFormValidation = true;
    } else {

      const createBody = {
        id: id,
        category: f.form.get('category').value,
        sub_category: f.form.get('sub_category').value,
        stars_rating: f.form.get('stars_rating').value,
        company_name: f.form.get('company_name').value,
        short_desc: f.form.get('short_desc').value,
        address: f.form.get('address').value,
        telephone_number: f.form.get('telephone_number').value,
        dial_code: f.form.get('dial_code').value,
        mobile: f.form.get('mobile').value,
        email: f.form.get('email').value,
        fb_messenger: f.form.get('fb_messenger').value,
        web_site: f.form.get('web_site').value,
        fb_page: f.form.get('fb_page').value,
        ext_description: f.form.get('ext_description').value,
        special_offer: f.form.get('special_offer').value,
        top_of_list: f.form.get('top_of_list').value,
        date_last_advertisement: f.form.get('date_last_advertisement').value,
        advertisement_from: f.form.get('advertisement_from').value,
        advertisement_to: f.form.get('advertisement_to').value,
        paid: f.form.get('paid').value,
        status: f.form.get('status').value,
        logo: this.viewLogo,
        company_pic: this.viewComapnyPic,
        pdfBrochure: this.viewPdfBrochure,
        attachments: this.viewAttachments,
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.MSG = 'Service Added Successfully!';
        this.rows.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_modal').modal('hide');
        console.log('\'services\' component-> New services added Successfully!');
        console.log(response);
        this.loader.hide();
        this.saveNotifications(f.form.get('company_name').value, f.form.get('short_desc').value, 'services', id);

      }).catch(error => {
        this.showErrorMsg = true;
        this.MSG = 'Add Service Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'services\' component-> Error in creating new services!');
        console.log(error.response);
      });
    }

  }

  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const category = f.form.get('category').value;
    const sub_category = f.form.get('sub_category').value;
    const short_desc = f.form.get('short_desc').value;
    const company_name = f.form.get('company_name').value;
    const status = f.form.get('status').value;

    if (category != undefined) {
      srchFiltersNames.push('category');
      srchFiltersValues.push(category);
    }

    if (sub_category != undefined) {
      srchFiltersNames.push('sub_category');
      srchFiltersValues.push(sub_category);
    }

    if (short_desc != undefined) {
      srchFiltersNames.push('short_desc');
      srchFiltersValues.push(short_desc);
    }

    if (company_name != undefined) {
      srchFiltersNames.push('company_name');
      srchFiltersValues.push(company_name);
    }

    if (status != undefined) {
      srchFiltersNames.push('status');
      srchFiltersValues.push(status);
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);

    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);

    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getAllServices();
    } else {
      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanRows();
    }
  }

  scanRows() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    this.getRows(this.apiName, this.path, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);

      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanRows();
      } else {
        this.scanResults = 'Completed';
        this.scan = false;
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No Results Found for this Search Please Try Different Search parameters!';
        }
        this.srch.push(...this.rows);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      if (this.totalList != 0) {
        this.scanResults = 'Incomplete, Records List Reached to its maximum limit, Please Narrow Down your Search by Adding More Filters ';
      } else {
        this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
      }
    });
  }

  getRows(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'category\' component->  Rows Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'category\' component-> Error in Retreiving Rows from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  searchCategory(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { category: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }
  searchSubCategory(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { sub_category: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }
  searchServiceDescription(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { short_desc: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchCompanyName(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { company_name: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchStatus(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { status: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.rows.splice(0, this.rows.length);
    this.srch.splice(0, this.srch.length);
  }

  uploadLogo(event) {
    this.SelectedLogoPic = event.target.files;
    this.eventLogoPic = event;
    if (this.SelectedLogoPic.length > 0) {
      this.haveLogo = true;
    }
  }

  uploadCompanyPic(event) {
    this.selectedCompanyPic = event.target.files;
    this.eventComapnyPic = event;
    if (this.selectedCompanyPic.length > 0) {
      this.haveComapnyPic = true;
    }
  }


  uploadPdfBroche(event) {
    this.selectedPdfBrochure = event.target.files;
    this.eventPdfBrocheure = event;
    if (this.selectedPdfBrochure.length > 0) {
      this.havePdfBrochure = true;
    }
  }


  uploadAttachments(event) {
    this.selectedAttachments = event.target.files;
    this.eventAttachments = event;
    if (this.selectedAttachments.length > 0) {
      this.haveAttachments = true;
    }
  }


  uploadPicsAndFiles(f) {
    const randomnumber = Math.floor(Math.random() * 999999999);
    if (f.invalid) {
      this.addFormValidation = true;
    } else {
      this.loader.show();
      this.douploadLogo().then(Response => {
        this.douploadComapnyPic().then(Response => {
          this.douploadPdfBrochure().then(Response => {
            this.douploadAttachment().then(Response => {
              this.addFormService(f, randomnumber.toString());
            })
          })
        })
      })
    }
  }

  douploadLogo() {
    return new Promise<string>((resolve, reject) => {
      if (this.haveLogo) {
        for (let i = 0; i < this.SelectedLogoPic.length; i++) {
          const file = this.SelectedLogoPic.item(i);
          this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
            console.log("Logo Uploaded Successfully " + JSON.stringify(Response));
            this.eventLogoPic.srcElement.value = null;
            this.viewLogo = Response.Location;
            this.logopic = Response.Location;
            this.haveLogo = false;
            resolve("All Logo uploaded");
          }).catch(error => {
            console.log("Error in Logo Uploaded" + error);
            reject(error);
          });
        }
      } else {
        resolve("All Logo uploaded");
      }
    });
  }

  douploadComapnyPic() {
    return new Promise<string>((resolve, reject) => {
      if (this.haveComapnyPic) {
        for (let i = 0; i < this.selectedCompanyPic.length; i++) {
          const file = this.selectedCompanyPic.item(i);
          this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
            console.log("Company PIC Uploaded Successfully " + JSON.stringify(Response));
            this.eventComapnyPic.srcElement.value = null;
            this.viewComapnyPic = Response.Location;
            this.companypic = Response.Location;
            this.haveComapnyPic = false;
            resolve("All Company PIC uploaded");
          }).catch(error => {
            console.log("Error in Company PIC Uploaded" + error);
            reject(error);
          });
        }
      } else {
        resolve("All Company PIC uploaded");
      }
    });
  }

  douploadPdfBrochure() {
    return new Promise<string>((resolve, reject) => {
      if (this.havePdfBrochure) {
        for (let i = 0; i < this.selectedPdfBrochure.length; i++) {
          const file = this.selectedPdfBrochure.item(i);
          this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
            console.log("Pdf Brochure Uploaded Successfully " + JSON.stringify(Response));
            this.eventPdfBrocheure.srcElement.value = null;
            this.viewPdfBrochure = Response.Location;
            this.pdfBrochure = Response.Location;
            this.havePdfBrochure = false;
            resolve("All Pdf Brochure uploaded");
          }).catch(error => {
            console.log("Error in Pdf Brochure Uploaded " + error);
            reject(error);
          });
        }
      } else {
        resolve("All Pdf Brochure uploaded");
      }
    });
  }

  douploadAttachment() {
    return new Promise<string>((resolve, reject) => {
      if (this.haveAttachments) {
        for (let i = 0; i < this.selectedAttachments.length; i++) {
          const file = this.selectedAttachments.item(i);
          this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
            console.log("Attachments Uploaded Successfully " + JSON.stringify(Response));
            this.eventAttachments.srcElement.value = null;
            this.viewAttachments = Response.Location;
            this.attachment = Response.Location;
            this.haveAttachments = false;
            resolve("All Attachments uploaded");
          }).catch(error => {
            console.log("Error in Attachments Uploaded" + error);
            reject(error);
          });
        }
      } else {
        resolve("All Attachments uploaded");
      }
    });
  }


  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toString().toLowerCase().includes(v.toString().toLowerCase()))));
  }


  getAllServices() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    this.unsortedrows.splice(0, this.unsortedrows.length);
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.scan = false;
      this.unsortedrows.push(...response);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.category;
        const name2 = t2.category;
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);


      console.log('\'project\' component-> All  getAllServices Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  getAllServices from server!');
      console.log(error.response);
    });
  }

  saveNotifications(title, body, moduleName, id) {
    const randomnumber = Math.floor(Math.random() * 999999999);
    const createBody = {
      id: randomnumber.toString(),
      title: title,
      body: body,
      moduleName: moduleName,
      deepLinkArticleId: id,
      creation_date:this.currentDate,
      creation_time:this.currentTime
        };
    this.putMyInitNotifications.body = createBody;
    API.put(this.apiNameNotifications, this.pathNotifications, this.putMyInitNotifications).then(response => {
      console.log('\'notifications\' component-> New notification added Successfully!');
      console.log(response);
      this.sendNotifications(title, body, moduleName, id);

      this.loader.hide();
    }).catch(error => {
      console.log('\'notifications\' component-> Error in creating new notifications!');
      console.log(error.response);
    });
  }

  sendNotifications(title, body, moduleName, id) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=' + "AAAA5mIllzA:APA91bH-wa6UVq3IvGUIE4oliUiVO1t3tWu5DqocJG8y9p1O9grxBC05dwLCVIxkftFsoXKcpqsK3MHNZaaNM0Wo9xs0vfndkUGGJRqJ9sEk91JJNhtTkNXnQ3RJBxQVETXiZBeuReKL"
    });

    var callibayaqPlzObj = {
      "to": "/topics/houseResidents",
      "notification": {
        "body": body,
        "content_available": true,
        "priority": "high",
        "title": title
      },
      "data": {
        "sound": true,
        "deepLinkArticleId": id,
        "module": moduleName,
        "content_available": true,
        "priority": "high",
        "title": "Testing Deep Linking"
      }
    }

    this.http.post(this.notificationsUrl, callibayaqPlzObj, { headers }).subscribe(res => {
      console.log(res);
    }, error => {
      console.log(error);
    });
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.services_category = this.rowslanguages[0].services_category;
        this.services_sub_category = this.rowslanguages[0].services_sub_category;
        this.services_stars_rating =this.rowslanguages[0].services_stars_rating;
        this.services_company_name = this.rowslanguages[0].services_company_name;
        this.services_short_description = this.rowslanguages[0].services_short_description;
        this.services_address = this.rowslanguages[0].services_address;
        this.services_telephone_number = this.rowslanguages[0].services_telephone_number;
        this.services_country_code = this.rowslanguages[0].services_country_code;
        this.services_mobile = this.rowslanguages[0].services_mobile;
        this.services_email = this.rowslanguages[0].services_email;
        this.services_fb_messenger = this.rowslanguages[0].services_fb_messenger;
        this.services_website = this.rowslanguages[0].services_website;
        this.services_facebook_page = this.rowslanguages[0].services_facebook_page;
        this.services_extended_description = this.rowslanguages[0].services_extended_description;
        this.services_special_offer = this.rowslanguages[0].services_special_offer;
        this.services_top_of_the_list_value = this.rowslanguages[0].services_top_of_the_list_value;
        this.services_date_last_advertisement = this.rowslanguages[0].services_date_last_advertisement;
        this.services_advertising_from = this.rowslanguages[0].services_advertising_from;
        this.services_advertising_to = this.rowslanguages[0].services_advertising_to;
        this.services_paid = this.rowslanguages[0].services_paid;
        this.services_status = this.rowslanguages[0].services_status;
        this.services_logo = this.rowslanguages[0].services_logo;
        this.services_company_picture = this.rowslanguages[0].services_company_picture;
        this.services_pdf_brochure = this.rowslanguages[0].services_pdf_brochure;
        this.services_attachments = this.rowslanguages[0].services_attachments;
        this.services_upload_picture =this.rowslanguages[0].services_upload_picture;

        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_actions = this.rowslanguages[0].generals_actions;
        this.generals_creation_date = this.rowslanguages[0].generals_creation_date;
        
        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getAllServices();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }

  onFileUpload(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { 
    this.uploadFile = true;
    this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
    throw new Error('Cannot use multiple files');
   }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.excelData);
      console.log(this.excelData[1].length);

      if(this.excelData.length>=2){
      if (this.excelData[0].length == 24) {
        this.uploadFile = true;
        this.Results = "Under Progress...";
        this.asynccalltoupload(this.excelData);
      }else {
        this.uploadFile = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    } else {
        this.uploadFile = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  async asynccalltoupload(excelData) {
    for (var i = 2; i < excelData.length; i++) {
      await new Promise<void>(resolve => {
        this.addFromFile(excelData[i][0], excelData[i][1], excelData[i][2], excelData[i][3], excelData[i][4], excelData[i][5],excelData[i][6],excelData[i][7],excelData[i][8],excelData[i][9],excelData[i][10],excelData[i][11],excelData[i][12],excelData[i][13],excelData[i][14],excelData[i][15],excelData[i][16],excelData[i][17],excelData[i][18],excelData[i][19],excelData[i][20],excelData[i][21],excelData[i][22],excelData[i][23],i
        ).then(response => {
          console.log('This is iteration ' + i);
          resolve();
        }).catch(error => {
          console.log(error);
        });
      });
    }
    this.Results = "Completed. Please Go ";
  }

  addFromFile(top_of_list,	short_desc,	web_site,	sub_category,	ext_description,	status,	dial_code,	address,	email,	fb_messenger,	paid,	advertisement_to,	telephone_number,	mobile,	company_name,	advertisement_from,	stars_rating,	fb_page,	category,	id,	pdfBrochure,	attachments,	logo,	company_pic,
    i): any {
    
    const createBody = {
      top_of_list: top_of_list,
      short_desc: short_desc,
      web_site: web_site,
      sub_category:sub_category,
      ext_description:ext_description,
      status:status,
      dial_code:dial_code,
      address:address,
      email:email,
      fb_messenger:fb_messenger,
      paid:paid,
      advertisement_to:advertisement_to,
      telephone_number:telephone_number,
      mobile:mobile,
      company_name:company_name,
      advertisement_from:advertisement_from,
      stars_rating:stars_rating,
      fb_page:fb_page,
      category:category,
      id:id.toString(),
      pdfBrochure:pdfBrochure,
      attachments:attachments,
      logo:logo,
      company_pic:company_pic,
  
    };

    this.putMyInit.body = createBody;
    //console.log(createBody);
    return new Promise<string>((resolve, reject) => {
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.RecordAdded = i+": "+id + '  Added Successfully!';
      console.log('\'all-residents\' component-> New Created Successfully!');
      console.log(response);
      resolve(response);
    }).catch(error => {
      this.showErrorMsg = true;
      this.RecordAdded = id + 'Failed, Please Retry or Contact App Support';
      console.log('\'all-\' component-> Error in creating new !');
      console.log(error.response);
      reject(error.response);
    });
  });

  }

  getPermissions() {
    API.get(this.apiNamePermissions, this.apiPathPermissions, this.getPermissionsInit).then(response => {
      this.loader.hide();
      this.permissions_userlevel = localStorage.getItem('user_level');
      const filters = { user_level: [this.permissions_userlevel] },
      results = response.data,
      Filtered = this.multiFilter(results, filters);

      this.permissions_view=Filtered[0].services_view;
      this.permissions_create=Filtered[0].services_create;
      this.permissions_edit=Filtered[0].services_update;
      this.permissions_delete=Filtered[0].services_delete;
     
    
      console.log(Filtered);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Sidebar\' component-> Error in Retreiving All permissions from server!'+error);
      console.log(error.response);
    });
  }
}
