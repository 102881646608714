import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare const $:any;

@Component({
  selector: 'app-settingsmain',
  templateUrl: './settingsmain.component.html',
  styleUrls: ['./settingsmain.component.css']
})
export class SettingsmainComponent implements OnInit {

  flag: boolean=false;
  constructor(private router: Router) { 
    
    router.events.subscribe((val) => {
      this.flag=false;
});

}

  ngOnInit() {
    this.flag=true;
  }
}