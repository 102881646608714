import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { AuthService } from 'src/app/auth/auth.service';
import * as bootstrap from 'bootstrap';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

declare const $: any;
type AOA = any[][];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [DatePipe]

})
export class UsersComponent implements OnInit {

  //////////////title varaible////////////////
  users_user_title = "Users";
  totalList: any;


  //////////////////alert varaible////////
  showSuccessMsg = false;
  userAdded = '';
  scan: boolean = false;
  scanResults = '';
  passwordsMismatch = false;
  showErrorMsg = false;
  addUserValidation = false;
  showMsgUpdate = false;
  userUpdated = '';
  /////////////////search variable///////////////

  generals_search = 'Search';
  generals_clear_search = 'Clear Search';

  ///////////////////permission//////////////////
  permissions_userlevel;
  permissions_view = false;
  permissions_create = false;
  permissions_edit = false;
  permissions_delete = false;



  ///////////////////get contractor variables//////////
  users_full_name = "Full Name";
  users_nickname = "Nickname";
  users_company_name = "Contractor Name";
  public AllContractors = [];
  public AllContractorsList = [];
  users_user_level = "User Level";
  users_property_developer = "Client";
  public AllPd = [];
  public AllPdList = [];
  users_prefered_language = "Prefered Language";
  generals_actions = 'Actions';
  searchT: any = [];
  public rows = [];

  totalUsersList = [];
  users_username = "Username";
  users_email = "Email";

  users_telephone = "Telephone";
  users_designation = "Designation";
  users_status = "Status";
  public selectedUserLevel: string;
  ////////////////////////view variable//////////////////

  public viewU: any = [];
  profile_pic;

  //////////////////////Add contractor///////////////
  public addU: any = {};
  typeofunitload: Boolean = false;
  public genderOptions = [];
  public user_level_list = [];
  public currentDate: string;
  selectedPics: FileList;
  eventPics;
  havePictures: boolean = false;
  uploadPicsFolder = '/General/UsersProfile';
  users_add_user = "Add User";
  public userlevels = [];
  public userLevel: string;
  public superAdminLoggedIn: boolean;

  ////////////////////////edit contractor////////////////
  generals_edit = 'Edit'
  public uptU: any = [];
  public editU: any = [];
  ////////////////////////delete variables//////////////


  generals_delete = 'Delete';
  delete_results;
  /////////////languages Variables//////////////////////
  public languages = [];

  users_user_id = "User ID";

  users_password = "Password";
  users_confirm_password = "Confirm Password";
  users_gender = "Gender";
  users_upload_picture = "Upload Picture";

  users_create_user = "Create User";
  users_update_user = "Update User";
  users_edit_user = "Edit User";
  users_company_type = "Company Type";
  users_date_of_birth = "Date of Birth";
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'

  generals_creation_date = 'Creation Date';
  ///////////////////////API////////////////////////////
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';

  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiName = 'users'; // replace this with your api name.
  path = '/users'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  myInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameLanguages = 'languages'; // replace this with your api name.
  pathLanguages = '/languages'; // replace this with the path you have configured on your API
  putMyInitLanguages = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameTou = 'userlevelandtask'; // replace this with your api name.
  apiPathTou = '/userlevelandtask'; // replace this with the path you have configured on your API
  getTouInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameDelete = 'users'; // replace this with your api name.
  pathDelete = '/users/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  constructor(private authService: AuthService, private appService: AppService, private router: Router, private projectService: AppService, private loader: LoaderService, private datePipe: DatePipe, private uploadService: UploadFileService) {
    this.user_level_list = appService.userLevels;
    this.genderOptions = appService.genderOptions;

  }
  ngOnInit() {
    this.getUsers();
    this.getPropertyDevelopers();
    this.getAllLanguages();
    this.getContractors();
    this.getPermissions();

    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      this.setLanguage(language);
    }

    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.userLevel = localStorage.getItem('user_level');
    if (this.userLevel == 'Super_admin') {
      this.superAdminLoggedIn = true;
    }


    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }


  /////////////////get contractors///////////////

  getContractors() {
    var p = [];

    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {
      this.AllContractorsList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.con_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllContractors.splice(0, this.AllContractors.length);
      this.AllContractors.push(...results);
      console.log('\'Contractor\' component-> All con Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All con from server!');
      console.log(error.response);
    });
  }
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)

      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
        
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead" && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);

      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  getUsers() {
    this.totalUsersList.splice(0, this.totalUsersList.length);
    this.scan = true;
    this.totalList = 0;
    this.scanResults = "Getting Users, Please Wait."
    this.rows.splice(0, this.rows.length);
    API.get(this.apiName, this.path, this.myInit2).then(response => {
      this.rows = response.data;
      this.totalUsersList.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.scan = false;

      console.log('\'users\' component-> All Users Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'users\' component-> Error in Retreiving All Users from server!');
      console.log(error.response);
    });
  }
  userLevelType(userlevel: string) {
    this.selectedUserLevel = userlevel;
  }






  /////////////////////view User////////////////
  viewUser(item) {

    let temp;
     console.log(item);
    temp = item;
    this.viewU = temp;
    if (temp.profile_pic === undefined || temp.profile_pic === '') {
      this.profile_pic = 'https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png'
    } else {
      this.profile_pic = temp.profile_pic;
    }

    // console.log(this.profile_pic);

  }


  //////////////////add user//////////////////
  addReset() {
    const randomnumber = Math.floor(Math.random() * 999999999);
    this.addU = {
      id: 'PA-' + randomnumber,
      status: 'Unconfirmed'
    };
    $('#add_user').modal('show');
  }

  addUser(f) {
    if (f.form.get('password').value != f.form.get('confirm_password').value) {
      this.passwordsMismatch = true;
    } else {
      f.form.value.profile_pic = this.profile_pic,
      f.form.value.date_created = this.currentDate;
      if(f.form.get('date_of_birth').value!=undefined){
      f.form.value.date_of_birth = this.projectService.reformatDate(f.form.get('date_of_birth').value);
    }
      this.myInit.body = f.form.value;
      console.log(f.form.value)
      Object.keys(this.myInit.body).forEach(key => this.myInit.body[key] === undefined ? delete this.myInit.body[key] : {});
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showSuccessMsg = true;
        this.userAdded = 'User Added Successfully! Please contact App Support for the confirmation of User.';
        $('#add_user').modal('hide');
        console.log('\'users\' component-> New user Created Successfully!');
        console.log(response);
        this.loader.hide();
        this.getUsers();
      }).catch(error => {
        this.showErrorMsg = true;
        this.userAdded = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'users\' component-> Error in creating new User!');
        console.log(error.response);
      });
    }
  }


  signUp(f) {
    console.log(f.form.value)
    this.addUserValidation = false;
    if (f.invalid == true) {
      this.addUserValidation = true;
    } else {
      this.loader.show();
      this.authService.signUp({
        'email': f.form.get('email').value.toLowerCase(),
        'password': f.form.get('password').value,
        'username': f.form.get('user_name').value,
        'userLevel': f.form.get('user_level').value,
        'nickname': f.form.get('nickname').value,
        'property_developer': f.form.get('property_developer').value,
      })
        .then((data) => {
          this.addUserWithPics(f);
        })
        .catch((error) => {
          console.log(error);
          this.showErrorMsg = true;
          this.userAdded = 'Failed, ' + error.message + ' Please Retry or Contact App Support';
          this.loader.hide();
          console.log('\'users\' component-> Error in creating new User!');
          console.log(error.response);
        });
    }
  }
  addUserWithPics(f) {
    if (f.invalid == true) {
      this.addUserValidation = true;
    } else {
      if (this.havePictures) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.addUser(f);
          this.havePictures = false;
        }).catch(error => {
          console.log(error);
          this.havePictures = false;
        });
      } else {
        this.addUser(f);
      }
    }
  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          console.log(Response);
          resolve("Add  Now!")
          this.eventPics.srcElement.value = null;
          resolve(Response);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else {
        resolve("Add  Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.profile_pic = Response.Location;
          resolve("All pics uploaded");
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }

  onChangePD(pd) {
    // console.log(pd);
    // console.log(this.AllContractorsList);
    this.getUserLevel(pd);
    var p = [];
    if (pd == '') {
      this.AllContractorsList.forEach(function (arrayItem) {
        var x = arrayItem.con_name;
        p.push(x);
      });
      this.AllContractors.splice(0, this.AllContractors.length);
      this.AllContractors.push(...p);
      this.AllContractors = this.AllContractors.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.AllContractors.sort();
    } else {
      const filters = { property_developer: [pd] },
        results = this.AllContractorsList,
        Filtered = this.projectService.multiFilter(results, filters);
      console.log(Filtered);
      Filtered.forEach(function (arrayItem) {
        var x = arrayItem.con_name;
        p.push(x);
      });
      this.AllContractors.splice(0, this.AllContractors.length);
      this.AllContractors.push(...p);
      this.AllContractors = this.AllContractors.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.AllContractors.sort();

    }
  }

  //////////////////edit////////////////
  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }
  getUserLevel(property_developer) {
    this.userlevels.splice(0, this.userlevels.length);
    const unsortedrows = [];

    if (property_developer) {
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (property_developer != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(property_developer);
      }
      this.projectService.scanContainsAnd(this.apiNameTou, this.apiPathTou, srchFiltersNames, srchFiltersValues).then(response => {
        unsortedrows.push(...response);
        const sorted = unsortedrows.sort((t1, t2) => {
          const name1 = t1.id;
          const name2 = t2.id;
          if (name1 < name2) { return 1; }
          if (name1 > name2) { return -1; }
          return 0;
        });
        this.loader.hide();
        this.userlevels.push(...sorted);
        this.uptU = this.editU;
      });
    }
  }
  UpdateUserWithPics(f) {
    this.addUserValidation = false;
    this.passwordsMismatch = false;

    if (f.invalid == true) {
      this.addUserValidation = true;
    } else {
      this.loader.show();
      if (this.havePictures) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.updateUser(f);
          this.havePictures = false;
        }).catch(error => {
          console.log(error);
          this.havePictures = false;
        });
      } else {
        this.updateUser(f);
      }
    }
  }
  updateUser(f) {
    console.log(f.form.value);
    if (f.form.get('password').value != f.form.get('confirm_password').value) {
      this.passwordsMismatch = true;
    } else {
      f.form.value.profile_pic = this.profile_pic,
      f.form.value.date_created = this.currentDate,
        f.form.value.date_of_birth = this.projectService.reformatDate(f.form.get('date_of_birth').value),
        this.myInit.body = f.form.value;
      Object.keys(this.myInit.body).forEach(key => this.myInit.body[key] === undefined ? delete this.myInit.body[key] : {});
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showMsgUpdate = true;
        this.userUpdated = 'User Updated Successfully!';
        console.log('\'users\' component->  user Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showMsgUpdate = true;
        this.userUpdated = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'users\' component-> Error in Updating User!');
        console.log(error.response);
      });
    }

  }
  onEdit(item) {
    this.editU = Object.assign({}, item);
    this.getUserLevel(item.property_developer);
    this.profile_pic = item.profile_pic;
    if (this.uptU.date_of_birth != undefined) {
      var date_of_birthParts = this.uptU.date_of_birth.split("/");
      this.uptU.date_of_birth = date_of_birthParts[2] + "-" + date_of_birthParts[1] + "-" + date_of_birthParts[0];
    }
    $('#edit_user').modal('show');
    console.log(this.uptU)
  }




  /////////////////////////delete /////////////////////////////


  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getUsers();
  }

  ////////////////////////////////////////////permissions variable////////////////////

  getPermissions() {
    this.projectService.getPermissions().then(response => {
      this.permissions_view = response[0].users_view;
      this.permissions_create = response[0].users_create;
      this.permissions_edit = response[0].users_update;
      this.permissions_delete = response[0].users_delete;
    }).catch(error => {
      console.log(error);
    })
  }


  ///////////////language conversion/////////

  ////////////////////////check languages///////////////////////////////////////////////////////////////////////

  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.users_user_title = response.Items[0].users;
      this.users_user_id = response.Items[0].users_user_id;
      this.users_username = response.Items[0].users_username;
      this.users_full_name = response.Items[0].users_full_name;
      this.users_nickname = response.Items[0].users_nickname;
      this.users_password = response.Items[0].users_password;
      this.users_confirm_password = response.Items[0].users_confirm_password;
      this.users_gender = response.Items[0].users_gender;
      this.users_email = response.Items[0].users_email;
      this.users_date_of_birth = response.Items[0].users_date_of_birth;
      this.users_telephone = response.Items[0].users_telephone;
      this.users_company_type = response.Items[0].users_company_type;
      this.users_user_level = response.Items[0].users_user_level;
      this.users_property_developer = response.Items[0].users_property_developer;
      this.users_designation = response.Items[0].users_designation;
      this.users_company_name = response.Items[0].users_company_name;
      this.users_status = response.Items[0].users_status;
      this.users_prefered_language = response.Items[0].users_prefered_language;
      this.users_upload_picture = response.Items[0].users_upload_picture;
      this.users_add_user = response.Items[0].users_add_user;
      this.users_create_user = response.Items[0].users_create_user;
      this.users_update_user = response.Items[0].users_update_user;
      this.users_edit_user = response.Items[0].users_edit_user;


      this.generals_search = response.Items[0].generals_search;
      this.generals_clear_search = response.Items[0].generals_clear_search;
      this.generals_view_details = response.Items[0].generals_view_details;
      this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
      this.generals_edit = response.Items[0].generals_edit;
      this.generals_delete = response.Items[0].generals_delete;
      this.generals_creation_date = response.Items[0].generals_creation_date;
      this.generals_actions = response.Items[0].generals_actions;

    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }

  ///////////////languages///////////
  getAllLanguages() {

    var status = 'e';
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const sorted = [];
    const unsortedrows = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNameLanguages, this.pathLanguages, srchFiltersNames, srchFiltersValues).then(response => {
      unsortedrows.push(...response);
      for (var i = 0; i < unsortedrows.length; i++) {
        sorted.push(unsortedrows[i].language);

      }
      this.languages.push(...sorted);
      this.languages.sort();
    });
  }

  //////////////////////////////////SEARCH Functions///////////////////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.totalUsersList);
    console.log(Filtered);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getUsers();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.totalUsersList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.totalUsersList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }


  ///////////////////////////////////////////Destroy functions///////////////////////////////////
  ngOnDestroy() {
    $('#add_user').modal('hide');
    $('#edit_user').modal('hide');
    $('#view_user').modal('hide');
  }

}
