import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as uuid from 'uuid';

@Injectable()
export class UploadFileService {


  constructor() { }

  uploadfile(file,uploadPicsFolder): any {
    var today = new Date();
    var year = today.getFullYear();
    var mes = today.getMonth() + 1;
    var dia = today.getDate();
    var folderName = year + "/" + mes + "/" + dia+"/";

    return new Promise<string>((resolve, reject) => {

      const myId = uuid.v4();

      const bucket = new S3(
        {
          accessKeyId: 'AKIA5PB6YNY25EDCAF7N',
          secretAccessKey: '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',
          region: 'us-east-1'
        }
      );

      const params = {
        Bucket: 'workordersfiles920'+uploadPicsFolder,
        Key: folderName + myId + file.name,
        Body: file,
        ContentType: file.type
      };

      bucket.upload(params, function (err, data) {
        if (err) {
          reject(err);
        }

        else {
          resolve(data);
        }

      });
    });

  }

}
