import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { LoaderService } from './loader/loader.service';
import { API } from 'aws-amplify';

declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public url;
  public url2;
  loginPage: Boolean = false;
  registerPage: Boolean = false;
  forgotPage: Boolean = false; 

  @HostListener('window:beforeunload')
  unloadHandler(event) {
    localStorage.clear();
  }
  

  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  
    getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  constructor(private router: Router, private loader: LoaderService,private activatedRoute: ActivatedRoute) {
    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')[1];
        this.url2 = event.url.split('/')[2];
       // console.log(this.url);
      }
    });
  }

  ngOnInit() {
    if ($('.main-wrapper').length > 0) {
      const $wrapper = $('.main-wrapper');
      $(document).on('click', '#mobile_btn', function (e) {
        $('.dropdown.open > .dropdown-toggle').dropdown('toggle');
        return false;
      });
      $(document).on('click', '#mobile_btn', function (e) {
        $wrapper.toggleClass('slide-nav-toggle');
        $('#chat_sidebar').removeClass('opened');
        return false;
      });
      $(document).on('click', '#open_msg_box', function (e) {
        $wrapper.toggleClass('open-msg-box').removeClass('');
        $('.dropdown').removeClass('open');
        return false;
      });
    }

    const $this = this;
    $(document).on('click', '#sidebar-menu a', function (e) {
      // console.log('remains')
      if ($(this).parent().hasClass('submenu')) {
        e.preventDefault();
      }
      if (!$(this).hasClass('subdrop')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('subdrop');
        $(this).next('ul').slideDown(350);
        $(this).addClass('subdrop');
        $('.active').parent().parent().css('display', 'block');
      } else if ($(this).hasClass('subdrop')) {
        $(this).removeClass('subdrop');
        $(this).next('ul').slideUp(350);
      }
    });

    const $sidebarOverlay = $('.sidebar-overlay');
    $(document).on('click', '#mobile_btn, .task-chat', function(e) {
      const $target = $($(this).attr('href'));
      if ($target.length) {
        $target.toggleClass('opened');
        $sidebarOverlay.toggleClass('opened');
        $('html').toggleClass('menu-opened');
        $sidebarOverlay.attr('data-reff', $(this).attr('href'));
      }
      e.preventDefault();
    });

    $sidebarOverlay.on('click', function(e) {
      const $target = $($(this).attr('data-reff'));
      if ($target.length) {
        $target.removeClass('opened');
        $('html').removeClass('menu-opened');
        $(this).removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav-toggle');
      }
      e.preventDefault();
    });
    const height = $(window).height();
    $('.page-wrapper').css('min-height', height);


    $(window).resize(function() {
      if ($('.page-wrapper').length > 0 ) {
        const height = $(window).height();
        $('.page-wrapper').css('min-height', height);
      }
    });

  }

  ngOnDestroy() {
  }
}
