import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.css']
})
export class VenuesComponent implements OnInit {
  public rows = [];
  public srch = [];

  public addLT: any = {};
  public uptLT: any = {};
  searchT: any = [];


  public numberOfHours = [];

  public vanuList = [];

  deleteID;
  delete_results;

  addVenueValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  totalList: any;
  public userLevel;


  public venueadded;
  scan: Boolean = false;
  public scanResults;


  unsortedrows = [];


  apiName = 'bookingvenues'; // replace this with your api name.
  path = '/bookingvenues'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'bookingvenues'; // replace this with your api name.
  pathDelete = '/bookingvenues/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  getMyInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  bookings_title='Title';
  bookings_location='Location';
  bookings_booking_start_time='Booking Start Time';
  bookings_booking_end_time='Booking End Time';
  bookings_minimum_booking_time_hours='Minimum Booking Time (Hours)';
  bookings_maximum_booking_time_hours='Maximum Booking Time (Hours)';
  bookings_add_venue='Add Venue';
  bookings_edit_venue='Edit Venue';
  bookings_update_venue='Update Venue';

  head_title = 'Venues';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';

  constructor(private appService: AppService, private loader: LoaderService) {
    this.numberOfHours = appService.numberOfHours;
    this.userLevel = localStorage.getItem('user_level');

  }

  ngOnInit() {
    this.getVenues();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }
  }


  addReset() {
    this.addVenueValidation = false;
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');

  }

  onEdit(updateItem) {
    this.addVenueValidation = false;
    this.uptLT = updateItem;
    $('#Edit_modal').modal('show');

  }

  addVenue(f) {
    if (f.invalid) {
      this.addVenueValidation = true;
    } else {
      this.loader.show();
      const randomnumber = Math.floor(Math.random() * 999999999);

      const createBody = {
        id: randomnumber.toString(),
        title: f.form.get('title').value,
        location: f.form.get('location').value,
        booking_start_time: f.form.get('booking_start_time').value,
        booking_end_time: f.form.get('booking_end_time').value,
        minimum_booking_time: f.form.get('minimum_booking_time').value,
        maximum_booking_time: f.form.get('maximum_booking_time').value,
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.venueadded = 'venue Added Successfully!';

        this.rows.unshift(f.form.value);
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_modal').modal('hide');
        console.log('\'venue\' component-> New venue added Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.venueadded = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'venue\' component-> Error in creating new venue!');
        console.log(error.response);
      });
    }
  }

  onUpdate(f) {
    if (f.invalid) {
      this.addVenueValidation = true;
    } else {
      this.loader.show();
      const createBody = {
        id: f.form.value.id,
        title: f.form.value.title,
        location: f.form.value.location,
        booking_start_time: f.form.value.booking_start_time,
        booking_end_time: f.form.value.booking_end_time,
        minimum_booking_time: f.form.value.minimum_booking_time,
        maximum_booking_time: f.form.value.maximum_booking_time,
        
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        var id = f.form.value.id;
        var arr = this.rows.find(function (item, i) {
          return item.id === id
        });

        arr.id = f.form.value.id;
        arr.title = f.form.value.title;
        arr.location = f.form.value.location;
        arr.booking_start_time = f.form.value.booking_start_time;
        arr.booking_end_time = f.form.value.booking_end_time;
        arr.minimum_booking_time = f.form.value.minimum_booking_time;
        arr.maximum_booking_time = f.form.value.maximum_booking_time;

        var index = this.rows.findIndex(function (item, i) {
          return item.id === id
        });
        if (index > -1) {
          this.rows.splice(index, 1);
        }

        this.rows.unshift(arr);
        this.srch.unshift(arr);
        this.rows = this.rows;
        $('#Edit_modal').modal('hide');
        console.log('\'cause\' component-> Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.venueadded = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'cause\' component-> Error in Updating!');
        console.log(error.response);
      });
    }
  }

  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    const title = f.form.get('title').value;
    const location = f.form.get('location').value;


    if (title != undefined) {
      srchFiltersNames.push('title');
      srchFiltersValues.push(title);
    }
    if (location != undefined) {
      srchFiltersNames.push('location');
      srchFiltersValues.push(location);
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      
    } else {
      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanRows();
    }

  }


  scanRows() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    this.getRows(this.apiName, this.path, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);

      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanRows();
      } else {
        this.scanResults = 'Completed';
        this.scan = false;
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No Results Found for this Search Please Try Different Search parameters!';
        }
        this.srch.push(...this.rows);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      if (this.totalList != 0) {
        this.scanResults = 'Incomplete, Records List Reached to its maximum limit, Please Narrow Down your Search by Adding More Filters ';
      } else {
        this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
      }
    });
  }

  getRows(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'venue\' component->  Rows Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'venue\' component-> Error in Retreiving Rows from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  getVenues() {
    this.loader.show();
    API.get(this.apiName, this.path, this.getMyInit2).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);
      this.vanuList = response.data;

      this.loader.hide();
      console.log('\'Contractor\' component-> All venues Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All venues from server!');
      console.log(error);
    });
  }

  searchTitle(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { title: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchLocation(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { location: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }
  
  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);

      this.rows.push(...this.vanuList);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);

  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
       
        this.head_title = this.rowslanguages[0].vbookings;

        this.bookings_title = this.rowslanguages[0].bookings_title;
        this.bookings_location = this.rowslanguages[0].bookings_location;
        this.bookings_booking_start_time =this.rowslanguages[0].bookings_booking_start_time;
        this.bookings_booking_end_time = this.rowslanguages[0].bookings_booking_end_time;
        this.bookings_minimum_booking_time_hours = this.rowslanguages[0].bookings_minimum_booking_time_hours;
        this.bookings_maximum_booking_time_hours = this.rowslanguages[0].bookings_maximum_booking_time_hours;
        this.bookings_add_venue = this.rowslanguages[0].bookings_add_venue;
        this.bookings_edit_venue = this.rowslanguages[0].bookings_edit_venue;
        this.bookings_update_venue = this.rowslanguages[0].bookings_update_venue;


        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_actions = this.rowslanguages[0].generals_actions;


        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getVenues();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }
}
