import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-sub-precincts',
  templateUrl: './sub-precincts.component.html',
  styleUrls: ['./sub-precincts.component.css']
})
export class SubPrecinctsComponent implements OnInit {
/////////////total list variable//////////
totalList: any;

//////////////alert variables////////////
scan: boolean = false;
scanResults = '';
showErrorMsg: Boolean = false;
showSuccessMsg: Boolean = false;
public AddedMsg;
addSubPrecinctValidation: Boolean = false;
///////////search variables//////////
precincts_property_developer = "Client";
precincts_projects_precinct = "Precincts/Projects/Condos";
precincts_projects_sub_precinct = "Sub-Precincts/Sub-Projects/Sub-Condos";
generals_delete = "Delete";
public AllPdList = [];
precinctsList = [];
public AllPd = [];
public addLT: any = {};
searchT: any = [];
public rows = [];
public precincts = [];
sub_precinctsList = [];
///////////////deletion/////////
delete_results;
//////////////add//////////
public precinctAdd;
/////////////update///////
public uptLT: any = {};
///////////languages/////
public languages = [];
public userLevel;
/////////////API///////////
apiNamePR = 'settingsPrecincts';
apiPathPR = '/settingsPrecincts';

getPRInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {  // OPTIONAL
    name: 'param'
  }
};
apiNameDelete = 'settingsSubPrecincts'; // replace this with your api name.
pathDelete = '/settingsSubPrecincts/delete'; // replace this with the path you have configured on your API
myInitDelete = {
  body: {}, // replace this with attributes you need
};
getMyInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
apiName = 'settingsSubPrecincts'; // replace this with your api name.
path = '/settingsSubPrecincts'; // replace this with the path you have configured on your API
putMyInit = {
  body: {}, // replace this with attributes you need
  headers: {} // OPTIONAL
};

apiNamelanguages = 'languages'; // replace this with your api name.
pathlanguages = '/languages'; // replace this with the path you have configured on your API
getMyInitlanguages = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
constructor(private loader: LoaderService, private projectService: AppService) {
  this.userLevel = localStorage.getItem('user_level');
}


ngOnInit() {
  this.getPrecincts();
  this.getAllSubPrecincts();
  $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
  }).trigger('blur');

  var language = localStorage.getItem('language');
  if (language != 'undefined' && language != 'English') {
    console.log(language);
    this.setLanguage(language);
  } else {
    //console.log("null5");
  }

}


//////////////get percint//////////
getPrecincts() {
  this.precincts.push('');
  API.get(this.apiNamePR, this.apiPathPR, this.getPRInit).then(response => {
    this.precincts.push(...response.data);
    console.log('\'add_sub_precinct\' component-> All Precincts Retrieved Successfully!');
    console.log(response);
    this.loader.hide();
  }).catch(error => {
    this.loader.hide();
    console.log('\'add_sub_precinct\' component-> Error in Retreiving All Precincts from server!');
    console.log(error.response);
  });
}
getAllSubPrecincts() {
  this.scan = true;
  this.scanResults = 'In Progress...';
  API.get(this.apiName, this.path, this.getMyInit).then(response => {
    this.sub_precinctsList = response;
    this.scan = false;
    this.rows.splice(0, this.rows.length);
    this.rows.push(...response);
    this.totalList = Object.keys(this.rows).length;
    console.log('\'project\' component-> All  Sub precincts Retrieved Successfully!');
    console.log(response);
  }).catch(error => {
    this.loader.hide();
    console.log('\'project\' component-> Error in Retreiving All Sub precincts from server!');
    console.log(error.response);
  });
}
/////////////searching///////////////////////////
searchRecordLocally(f) {
  let Filtered = this.projectService.searchRecordLocally(f, this.sub_precinctsList);
  this.rows.splice(0, this.rows.length);
  this.rows.push(...Filtered);
  console.log(Filtered);
  this.totalList = Object.keys(this.rows).length;
}
instantSearch(val, name) {
  this.rows.splice(0, this.rows.length);
  this.rows.push(...this.projectService.instantSearch(val, this.sub_precinctsList, name, ''))
  this.totalList = Object.keys(this.rows).length;
}
clearSearch() {
  this.rows.splice(0, this.rows.length);
  this.searchT = Object.keys(this.searchT).reduce(
    (accumulator, current) => {
      accumulator[current] = null;
      return accumulator
    }, {});
  this.getAllSubPrecincts();
}


//////////////add///////////////////
addReset() {
  let randomnumber = Math.floor(Math.random() * 500);
  this.addLT = { 'id': randomnumber };
  $('#add_sub_precinct').modal('show');
}
addSubPrecincts(f) {
  //console.log(f.form.value);
  if (f.invalid) {
    this.addSubPrecinctValidation = true;
  } else {
    this.loader.show();
    const randomnumber = Math.floor(Math.random() * 999999999);
     f.form.value.id= randomnumber.toString(),
     this.putMyInit.body = f.form.value;
     console.log(f.form.value)
     Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.AddedMsg = 'Sub-Precinct/Sub-Project Added Successfully!';

      this.rows.unshift(f.form.value);
      // this.srch.unshift(f.form.value);
      this.rows = this.rows;
      $('#add_sub_precinct').modal('hide');
      console.log('\'sub_precinct\' component-> sub_precinct added Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showSuccessMsg = true;
      this.AddedMsg = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'sub_precinct\' component-> Error in creating new sub_precinct!');
      console.log(error.response);
    });
  }

}
////////////Edit//////////////
onEdit(item) {
  this.uptLT = item;
  $('#Edit_modal').modal('show');
}

onUpdate(f) {
  if (f.invalid) {
    this.addSubPrecinctValidation = true;
  } else {
    this.loader.show();
    this.putMyInit.body = f.form.value;
    console.log(f.form.value)
    Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      var id = f.form.value.id;
      var arr = this.rows.find(function (item, i) {
        return item.id === id
      });

      arr.id = f.form.value.id;
      arr.precinct = f.form.value.precinct;

      var index = this.rows.findIndex(function (item, i) {
        return item.id === id
      });
      if (index > -1) {
        this.rows.splice(index, 1);
      }
      this.AddedMsg = 'Sub-Precinct/Sub-Project Updated Successfully!';

      this.rows.unshift(arr);
      this.rows = this.rows;
      $('#Edit_modal').modal('hide');
      console.log('\'subprecinct\' component-> Updated Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showSuccessMsg = true;
      this.AddedMsg = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'subprecinct\' component-> Error in Updating!');
      console.log(error.response);
    });
  }
}

///////////////deletion///////////

onDelete(c) {
  this.projectService.onDelete(c);
}

deleteConfirmation() {
  this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
    this.delete_results = response;
  }).catch(error => {
    this.delete_results = error;
  });
}

deleteResult() {
  $('#delete_result').modal('hide');
  this.getAllSubPrecincts();
}

////////////////languages//////////////
setLanguage(language) {
  this.loader.show();
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('language');
  srchFiltersValues.push(language);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.precincts_property_developer = response.Items[0].precincts_property_developer;
    this.precincts_projects_precinct = response.Items[0].precincts_projects_precinct;
    this.precincts_projects_sub_precinct = response.Items[0].precincts_projects_sub_precinct;

  }).catch(error => {
    this.loader.hide();
    console.log(error);
  });
}
retriveLanguages() {
  var status = 'Completed';
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('status');
  srchFiltersValues.push(status);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.languages = response.Items;
  }).catch(error => {
    console.log(error);
  });
}

}