import { Component, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { CookieService } from 'ngx-cookie-service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import * as XLSX from 'xlsx';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { DatePipe } from '@angular/common';

declare const $: any;
type AOA = any[][];


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [DatePipe]

})
export class PaymentsComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: Router,
    private cookieService: CookieService,
    private loader: LoaderService,
    private uploadService: UploadFileService,
    private datePipe: DatePipe,


  ) {
    // this.rows = appService.employees;
    // this.srch = [...this.rows];
    //this.modules = appService.employee_modules;
    this.countryList = appService.countryList;
    this.genderOptions = appService.genderOptions;
    this.currencyCodes = appService.currencyCodes;

  }

  excelData: AOA = [[1, 2], [3, 4]];

  public myDatePickerOptions: IMyDpOptions = {
    todayBtnTxt: 'Today',
    dateFormat: 'dd/mm/yyyy',
    firstDayOfWeek: 'su',
    sunHighlight: true,
    inline: false,
    height: '38px'
  };

  DeleteFeedback = '';

  searchT: any = [];

  public updateEmp = [];
  public createEmp: any = {};
  public viewEmp: any = [];

  Results = '';
  uploadFile = false;

  showDeletionResponse = false;

  public srch = [];
  public rows = [];
  passwordsMismatch = false;

  totalList: any;
  paymentList = [];

  itemObj: any;

  addEmployeeValidation = false;
  uptEmployeeValidation = false;


  public allEmployees = true;
  public userLevel: string;
  public superAdminLoggedIn: boolean;
  public modules = [];
  public countryList = [];
  public currencyCodes = [];

  public genderOptions = [];

  selectedPics: FileList;
  eventPics;
  havePictures: boolean = false;
  pictures;
  profile_pic;
  uploadPicsFolder = '/General/ResidentProfile';
  public residents = [];

  showSuccessMsg = false;
  showErrorMsg = false;
  ResidentAdded = '';
  ResidentUpdated = '';
  currentDate: string;


  public addInv: any = {};
  public uptEmp: any = {};

  propertiesHouseOwnerList = [];
  propertiesHouseNumberList = [];
  propertiesPrecinctList = [];
  p = [];
  propertiesStreetsList = [];
  propertiesList = [];
  deleteID;
  delete_results;

  amount_decimal;

  apiName = 'invoices'; // replace this with your api name.
  path = '/invoices'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'invoices'; // replace this with your api name.
  pathDelete = '/invoices/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  myInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameResidents = 'residents'; // replace this with your api name.
  pathResidents = '/residents'; // replace this with the path you have configured on your API
  myInitResidents = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  myInit2Residents = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
    }
  };

  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API
  getPropertiessInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  public AllPd = [];
  public AllPdList = [];
  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  payments_order_no='Order No';
  payments_invoice_title='Invoice Title';
  payments_invoice_date='Invoice Date';
  payments_due_date='Due Date';
  payments_currency='Currency';
  payments_amount='Amount';
  payments_precinct='Precincts/Projects/Condos';
  payments_street='Street';
  payments_house_number='House Number';
  payments_customer_name='Customer Name';
  payments_customer_contact_number='Customer Contact Number';
  payments_email='Email';
  payments_address='Adress';
  payments_postal_code='Postal Code';
  payments_township='Township';
  payments_city='City';
  payments_state='State';
  payments_country='Country';
  payments_property_developer='Client';
  payments_order_description='Order Desciption';
  user_status='Status';

  payments_add_invoice='Add Invoice';
  payments_save_invoice='Save Invoice';
  payments_send_invoice='Send Invoice';
  payments_invoice_template='Download Invoice Template Master';


  head_title = 'Invoices';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';


  public date: Date = new Date();
  public model: any = { date: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() } };
  pdCodeList = [];

  public empUpt = {};
  public vals = [];

  ngOnInit() {
    this.getPropertyDevelopers();
    this.getResidents();
    this.getInvoices();
    this.getProperties();
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');

    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }

    this.userLevel = localStorage.getItem('user_level');
    if (this.userLevel == 'Super_admin') {
      this.superAdminLoggedIn = true;
    }

    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    // this.getResidents();

  }

  addReset() {
    this.pictures = '';
    this.addEmployeeValidation = false;
    this.addInv = { 'id': 'Auto'};
    // console.log(randomnumber)
    $('#add_invoice').modal('show');
  }



  onEdit(item) {
    this.pictures = '';
    this.uptEmp = Object.assign({}, item);
    if (this.uptEmp.registration_date != undefined) {
      var registration_dateParts = item.registration_date.split("/");
      this.uptEmp.registration_date = registration_dateParts[2] + "-" + registration_dateParts[1] + "-" + registration_dateParts[0];
    }
  }

  onView(item) {

    if (item.pictures === undefined) {
      this.pictures = 'https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png';
      this.profile_pic = 'https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png';
    } else {
      this.pictures = item.pictures;
      this.profile_pic = item.pictures;
    }
    let temp;
    console.log(item);
    temp = item;
    this.viewEmp = temp;
    this.amount_decimal=item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element!=="" && element!=="New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      this.setPdCode();
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!'+error);
      console.log(error.response);
    });
  }

  setPdCode() {
    var pd=localStorage.getItem("property_developer");
    // console.log(pd);
    // console.log(this.propertyDevelopers);
    var p = [];
    const filters = { pd_name: [pd] },
      results = this.AllPdList,
      Filtered = this.multiFilter(results, filters);
   // console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.company_code;
      p.push(x);
    });

    this.pdCodeList.splice(0, this.pdCodeList.length);
    this.pdCodeList.push(...p);
    this.pdCodeList = this.pdCodeList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.pdCodeList.sort();
    console.log(this.pdCodeList[0]+'-'+new Date().getFullYear().toString().substr(-2));

  }

  getResidents() {
    API.get(this.apiNameResidents, this.pathResidents, this.myInit2Residents).then(response => {
      this.residents.push(...response.data);
      const sorted = this.residents.sort((t1, t2) => {
        const name1 = t1.name;
        const name2 = t2.name;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.residents.push(...sorted);

      console.log('\'all-residents\' component-> All Residents Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      console.log('\'all-residents\' component-> Error in Retreiving All Residents from server!');
      console.log(error);
    });
  }

  getProperties() {
    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesPrecinctList.push('');

    API.get(this.apiNameProperties, this.apiPathProperties, this.getPropertiessInit).then(response => {
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]);
        this.propertiesList = response.data;
        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();
        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();
      }
      console.log('\'tickets\' component-> All properties Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      console.log('\'tickets\' component-> Error in Retreiving All properties from server!');
      console.log(error.response);
    });
  }


  addInvoice(f, flag) {
    console.log(flag);
    console.log(f);
    const randomnumber = Math.floor(Math.random() * 99999999999);
    var status;
    if (f.valid == true) {
      this.loader.show();
      if (flag == 1) {
        status = 'Sent';
      } else {
        status = 'Draft';
      }
      const createBody = {
        creation_date: this.currentDate,
        id:this.pdCodeList[0]+'-'+new Date().getFullYear().toString().substr(-2)+'-'+randomnumber,
        title: f.form.get('title').value,
        invdate: this.reformatDate(f.form.get('invdate').value),
        duedate: this.reformatDate(f.form.get('duedate').value),
        currency: f.form.get('currency').value,
        amount: f.form.get('amount').value,
        precinct:f.form.get('precinct').value,
        street:f.form.get('street').value,
        house_number:f.form.get('house_number').value,
        customer_name: f.form.get('customer_name').value,
        customer_contact_no: f.form.get('customer_contact_no').value,
        email: f.form.get('email').value,
        address: f.form.get('address').value,
        postal_code: f.form.get('postal_code').value,
        township: f.form.get('township').value,
        city: f.form.get('city').value,
        state: f.form.get('state').value,
        country: f.form.get('country').value,
        property_developer: f.form.get('property_developer').value,
        order_desc: f.form.get('order_desc').value,
        status: status
      };

      this.myInit.body = createBody;
      console.log(createBody);
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showSuccessMsg = true;
        this.ResidentAdded = 'Invoice Added Successfully!';
        this.getInvoices();
        f.form.value.status = status;
        this.rows.unshift(f.form.value);
        const index = this.rows.findIndex(function (item, i) {
          return item.id === f.form.get('id').value.toString();
        });

        if (index > -1) {
          this.rows[index] = f.form.value;
        }
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_invoice').modal('hide');
        console.log('\'all-add_invoice\' component-> New add_invoice Created Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.ResidentAdded = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'all-add_invoice\' component-> Error in creating new add_invoice!');
        console.log(error.response);
      });

    } else {
      this.addEmployeeValidation = true;
    }

  }

  sendInvoice(f) {
    console.log(f);
    if (f.status == "Sent") {
      this.showSuccessMsg = true;
      this.ResidentAdded = 'Invoice Sent Already!';
    } else {
      this.loader.show();
      const createBody = {
        creation_date: f.currentDate,
        id: f.id,
        title: f.title,
        invdate: f.invdate,
        duedate: f.duedate,
        currency: f.currency,
        amount: f.amount,
        customer_name: f.customer_name,
        customer_contact_no: f.customer_contact_no,
        email: f.email,
        address: f.address,
        postal_code: f.postal_code,
        township: f.township,
        city: f.city,
        state: f.state,
        country: f.country,
        property_developer: f.property_developer,
        order_desc: f.order_desc,
        status: "Sent"
      };

      this.myInit.body = createBody;
      console.log(createBody);
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showSuccessMsg = true;
        this.ResidentAdded = 'Invoice Sent Successfully!';
        f.status = "Sent";
        this.getInvoices();
        this.rows.unshift(f);
        const index = this.rows.findIndex(function (item, i) {
          return item.id === f.id;
        });

        if (index > -1) {
          this.rows[index] = f;
        }
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_invoice').modal('hide');
        console.log('\'all-add_invoice\' component-> New add_invoice Created Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.ResidentAdded = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'all-add_invoice\' component-> Error in creating new add_invoice!');
        console.log(error);
      });

    }
  }

  getInvoices() {
    this.loader.show();
    API.get(this.apiName, this.path, this.myInit2).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);
      this.loader.hide();
      this.paymentList = response.data;
      console.log('\'all-Invoices\' component-> All Invoices Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'all-Invoices\' component-> Error in Retreiving All Invoices from server!');
      console.log(error);
    });
  }


  onFileUpload(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      this.uploadFile = true;
      this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.excelData);

      if (this.excelData[1].length == 22) {
        this.uploadFile = true;
        this.Results = "Under Progress...";
        this.asynccalltoupload(this.excelData);
      } else {
        this.uploadFile = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  async asynccalltoupload(excelData) {
    for (var i = 2; i < excelData.length; i++) {
      await new Promise<void>(resolve => {
        this.addInvoicesFromFile(excelData[i][0], excelData[i][1], excelData[i][2],
          excelData[i][3], excelData[i][4], excelData[i][5],
          excelData[i][6], excelData[i][7], excelData[i][8],
          excelData[i][9], excelData[i][10], excelData[i][11],
          excelData[i][12], excelData[i][13], excelData[i][14], excelData[i][15], excelData[i][16], excelData[i][17], 
          excelData[i][18],excelData[i][19],excelData[i][20],excelData[i][21], i
        ).then(response => {
          console.log('This is iteration ' + i);
          resolve();
        }).catch(error => {
          console.log(error);
        });
      });
    }
    this.Results = "Completed. Please Refresh Page to view your Uploaded Invoices.";
  }

  addInvoicesFromFile(ID, title, invdate, duedate, currency, Email, amount,precinct,street,house_number,customer_name, customer_contact_no,
     address, postal_code, township, city, state, country, property_developer, order_desc, status, creation_date, i): any {

    const createBody = {
      creation_date: creation_date,
      id: ID,
      title: title,
      duedate: duedate,
      invdate: invdate,
      currency: currency,
      amount: amount,
      precinct:precinct,
      street:street,
      house_number:house_number,
      customer_name: customer_name,
      customer_contact_no: customer_contact_no,
      email: Email,
      address: address,
      postal_code: postal_code,
      township: township,
      city: city,
      state: state,
      country: country,
      property_developer: property_developer,
      order_desc: order_desc,
      status: status
    };

    this.myInit.body = createBody;
    console.log(createBody);
    return new Promise<string>((resolve, reject) => {
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showSuccessMsg = true;
        this.ResidentAdded = i + ": " + ID + ' Invoice Created Successfully!';
        console.log('\'all-Invoice\' component-> New Invoice Created Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.showErrorMsg = true;
        this.ResidentAdded = ID + 'Failed, Please Retry or Contact App Support';
        console.log('\'all-Invoice\' component-> Error in creating new Invoice!');
        console.log(error.response);
        reject(error.response);
      });
    });

  }


  DownloadTemplate() {
    window.open("https://workordersfiles920.s3.amazonaws.com/General/TemplateUploads/invoicesTemplate.xlsx");
  }



  onChangePrecinct(precinct, street, house_number) {
    // console.log(precinct);
    // console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    // console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();

    this.searchHouseOwner(precinct,street,house_number);

  }

  onChangeStreet(precinct, street, house_number) {
    // console.log(street);
    // console.log(h);

    // console.log(this.propertiesList);
    var p = [];
    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    // console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      p.push(x);
    });

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push('');
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();

    this.searchHouseOwner(precinct,street,house_number);


  }

  onChangeHouseNo(precinct, street, house_number) {
   this.searchHouseOwner(precinct,street,house_number);
  }

  searchHouseOwner(precinct, street, house_number) {
    console.log(precinct);
    console.log(street);
    console.log(house_number);

    console.log(this.propertiesList);
    var p = [];
    const filters = {precinct: [precinct], street: [street],house_number:[parseInt(house_number)] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    // console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_owner;
      p.push(x);
    });

    console.log(p);

    this.propertiesHouseOwnerList.splice(0, this.propertiesHouseOwnerList.length);
    this.propertiesHouseOwnerList.push(...p);
    this.propertiesHouseOwnerList = this.propertiesHouseOwnerList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseOwnerList.sort();

  }

  searchPD(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { property_developer: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchCustomerName(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { customer_name: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchOrderNumber(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { id: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchCustomerEmail(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { email: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchStatus(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { status: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const property_developer = f.form.get('property_developer').value;
    const customer_name = f.form.get('customer_name').value;
    const id = f.form.get('id').value;
    const email = f.form.get('email').value;
    const status = f.form.get('status').value;


    if (property_developer != undefined && property_developer != "") {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(property_developer);
    }
    if (customer_name != undefined && customer_name != "") {
      srchFiltersNames.push('customer_name');
      srchFiltersValues.push(customer_name);
    }
    if (id != undefined && id != "") {
      srchFiltersNames.push('id');
      srchFiltersValues.push(id);
    }
    if (email != undefined && email != "") {
      srchFiltersNames.push('email');
      srchFiltersValues.push(email);
    } 
    if (status != undefined && status != "") {
      srchFiltersNames.push('status');
      srchFiltersValues.push(status);
    } 

    if (srchFiltersNames.length == 0) {
      // this.scan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Results';
    } else {

      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[srchFiltersNames[i]] = srchFiltersValues[i];
      }
      console.log(ExpressionAttributeValues);

      var Filtered = this.paymentList.filter(function (item) {
        for (var key in ExpressionAttributeValues) {
          if (item[key] === undefined || item[key] != ExpressionAttributeValues[key])
            return false;
        }
        return true;
      });
      console.log(Filtered);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);

    }
  }

  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});

    this.rows.splice(0, this.rows.length);
    this.srch.splice(0,this.srch.length);

    this.rows.push(...this.paymentList);
    this.totalList = Object.keys(this.rows).length;
    this.srch.push(...this.rows);
  }

  reformatDate(dateStr) {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => (o[k].toString()).toLowerCase().includes((v.toString()).toLowerCase()))));
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.head_title = this.rowslanguages[0].apayments;
        this.payments_order_no = this.rowslanguages[0].payments_order_no;
        this.payments_invoice_title = this.rowslanguages[0].payments_invoice_title;
        this.payments_invoice_date =this.rowslanguages[0].payments_invoice_date;
        this.payments_due_date = this.rowslanguages[0].payments_due_date;
        this.payments_currency = this.rowslanguages[0].payments_currency;
        this.payments_amount = this.rowslanguages[0].payments_amount;
        this.payments_precinct = this.rowslanguages[0].payments_precinct;
        this.payments_street = this.rowslanguages[0].payments_street;
        this.payments_house_number = this.rowslanguages[0].payments_house_number;
        this.payments_customer_name = this.rowslanguages[0].payments_customer_name;
        this.payments_customer_contact_number = this.rowslanguages[0].payments_customer_contact_number;
        this.payments_email = this.rowslanguages[0].payments_email;
        this.payments_address = this.rowslanguages[0].payments_address;
        this.payments_postal_code = this.rowslanguages[0].payments_postal_code;
        this.payments_township = this.rowslanguages[0].payments_township;
        this.payments_city = this.rowslanguages[0].payments_city;
        this.payments_state = this.rowslanguages[0].payments_state;
        this.payments_country = this.rowslanguages[0].payments_country;
        this.payments_property_developer = this.rowslanguages[0].payments_property_developer;
        this.payments_order_description = this.rowslanguages[0].payments_order_description;
        this.user_status = this.rowslanguages[0].users_status;

        this.payments_add_invoice = this.rowslanguages[0].payments_add_invoice;
        this.payments_save_invoice = this.rowslanguages[0].payments_save_invoice;
        this.payments_send_invoice = this.rowslanguages[0].payments_send_invoice;
        this.payments_invoice_template = this.rowslanguages[0].payments_invoice_template;

        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_actions = this.rowslanguages[0].generals_actions;


        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getInvoices();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }

  ngOnDestroy() {
    $('#add_resident').modal('hide');

  }

}
