import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { API } from 'aws-amplify';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../loader/loader.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-visitors-managment',
  templateUrl: './visitors-managment.component.html',
  styleUrls: ['./visitors-managment.component.css'],
  providers: [DatePipe]

})
export class VisitorsManagmentComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://www.properly.asia/';

  public addEmp: any = {};

  currentDate: string;
  public resident_id;
  showSuccessMsg = false;
  Added = '';
  showErrorMsg = false;
  addValidation = false;
  qrCode = false;
  showBtn = true;

  apiName = 'visitors'; // replace this with your api name.
  path = '/visitors'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  myInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  constructor( private activatedRoute: ActivatedRoute,   private datePipe: DatePipe,    private loader: LoaderService,

    ) { 

      this.activatedRoute.queryParams.subscribe(params => {
        this.resident_id = params['resident_id'];
    });
    }


  
  ngOnInit() {
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }

  
  addVisitor(f) {  
    if (f.invalid == false) {

    this.loader.show();
    const createBody = {
      id: "VI"+Math.floor(Math.random() * 999999999),
      email: f.form.get('email').value,
      name: f.form.get('name').value,
      IC_number: f.form.get('IC_number').value,
      vaccination_status: f.form.get('vaccination_status').value,
      telephone_number: f.form.get('telephone_number').value,
      postal_code: f.form.get('postal_code').value,
      city: f.form.get('city').value,
      address: f.form.get('address').value,
      creation_date: this.currentDate,
      vehicle_type: f.form.get('vehicle_type').value,
      license_plate_no: f.form.get('license_plate_no').value,
      relationship_to_resident: f.form.get('relationship_to_resident').value,
      reason_of_visit: f.form.get('reason_of_visit').value,
      expected_visiting_time: f.form.get('expected_visiting_time').value,
      status:"Expected",
      resident_id: this.resident_id

    };

    this.myInit.body = createBody;
    console.log(createBody);
    API.put(this.apiName, this.path, this.myInit).then(response => {
      this.value=createBody.id;
      this.qrCode=true;
      this.showBtn=false;
      this.showSuccessMsg = true;
      this.Added = 'Registration Successfull!';    
      console.log('\'all-residents\' component-> New Visitor Created Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.Added = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'all-residents\' component-> Error in creating new Visitor!');
      console.log(error.response);
    });
  
}else{
  this.addValidation = true;

}
}

}
