import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe } from '@angular/common';

declare const $: any;
type AOA = any[][];


@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None


})
export class LanguagesComponent implements OnInit {

  ///////////total list/////////

  totalList: any;
  //////////alert variable//////
  addTValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public AddedMsg;
  op: boolean = false;
  scan: boolean = false;
  scanResults = '';
  //////////search variable//////
  public rows = [];
  searchT: any = [];
  addT: any = [];
  public addLT: any = {};
  public uptLT: any = {};
  public PropertyDevelopers = [];
  public languages = [];
  public userLevel;
  languagesList = [];
  /////////delete//
  delete_results;
  /////////////API////////////
  apiName = 'languages'; // replace this with your api name.
  path = '/languages'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'languages'; // replace this with your api name.
  pathDelete = '/languages/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  constructor(private loader: LoaderService, private app_service: AppService, private projectService: AppService) {

    this.languages = app_service.languages;
    this.userLevel = localStorage.getItem('user_level');

  }

  ngOnInit() {
    this.getAllLanguages();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }

  ///////////get functions//////
  getAllLanguages() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.languagesList = response;
      this.scan = false;
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response);
      this.totalList = Object.keys(this.rows).length;
      console.log('\'project\' component-> All languages Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All languages from server!');
      console.log(error.response);
    });
  }
  ///////////search functions/////////
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.languagesList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAllLanguages();
  }
  ////////////add languauge////
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_language').modal('show');
    this.addTValidation = false;
  }
  addlanguageForm(f) {
    var staus = "In Progress";
    console.log(f.form.value);
    if (f.invalid && this.op == false) {
      this.addTValidation = true;
    } else {
      if (!f.invalid) {
        staus = 'Completed';
      }
      this.loader.show();
      const randomnumber = Math.floor(Math.random() * 999999999);
      f.form.value.id = randomnumber.toString();
      f.form.value.status = staus;
      this.putMyInit.body = f.form.value;
      console.log(f.form.value);
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Language Added Successfully.';
        f.form.value.status = staus;

        this.rows.unshift(f.form.value);
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        this.op = false;
        $('#add_language').modal('hide');
        console.log('\'add_language\' component-> New language added Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Failed, Please Retry or Contact App Support.';
        this.loader.hide();
        console.log('\'add_language\' component-> Error in creating new language!');
        console.log(error.response);
      });
    }

  }
  saveLanguage() {
    this.op = true;
  }
  ////////////update langauge///

  onEdit(item) {
    this.uptLT = item;
    $('#Edit_modal').modal('show');
    this.addTValidation = false;
  }
  updatelanguageForm(f) {
    var staus = "In Progress";
    console.log(f.form.value);
    if (f.invalid && this.op == false) {
      this.addTValidation = true;
    } else {

      if (!f.invalid) {
        staus = 'Completed';
      }
      this.loader.show();
      f.form.value.status = staus;
      this.putMyInit.body = f.form.value;
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Language Updated Successfully.';
        this.getAllLanguages();
        f.form.value.status = staus;
        this.rows = this.rows;
        this.op = false;
        $('#Edit_modal').modal('hide');
        console.log('\'add_language\' component-> New language Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Failed, Please Retry or Contact App Support.';
        this.loader.hide();
        console.log('\'add_language\' component-> Error in Updated new language!');
        console.log(error.response);
      });
    }
  }
  ///////////////deletion///////////

  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAllLanguages();
  }



}
