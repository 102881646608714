import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-add-lead-pd',
  templateUrl: './add-lead-pd.component.html',
  styleUrls: ['./add-lead-pd.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None


})
export class AddLeadPdComponent implements OnInit {
  public addEmp: any = {};
  apiName = 'propertydevelopers'; // replace this with your api name.
  path = '/propertydevelopers   '; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  pd;
  userLevel;
  showSuccessMsg = false;
  Added = '';
  showErrorMsg = false;
  addValidation = false;
  public countryList = [];
  public malaysiaStatesList = [];
  productInterestselectedItemsList = [];
  preferredContactMethodselectedItemsList = [];
  userNickname = 'Lead Form';
  public timeList = [];



  productInterestDataList = []
  preferredContactMethodDataList = []
  currentDate: string;
  constructor(private datePipe: DatePipe, private loader: LoaderService, private projectService: AppService,
  ) {
    this.countryList = projectService.countryList;
    this.malaysiaStatesList = projectService.malaysiaStates;
    this.timeList = projectService.timeList;
    this.productInterestDataList = this.projectService.productInterestDataList;
    this.preferredContactMethodDataList = this.projectService.preferredContactMethodDataList;
  }

  ngOnInit() {
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    this.userNickname = localStorage.getItem('nick_name');
    this.pd = localStorage.getItem('property_developer');
    this.userLevel = localStorage.getItem('user_level');

    if (this.userNickname == undefined) {
      this.userNickname = 'Lead Form';
    }

    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.addEmp = {
      'reg_created_by': this.userNickname,
      'reg_date': this.currentDate
    };
    this.fetchSelectedItemsProductInterest();
    this.fetchSelectedItemspreferredContactMethod();
  }



  changeSelectionProductInterest() {
    this.fetchSelectedItemsProductInterest();
    console.log(this.productInterestselectedItemsList);
  }

  fetchSelectedItemsProductInterest() {
    this.productInterestselectedItemsList = this.productInterestDataList.filter((value, index) => {
      return value.isChecked
    });
  }


  changeSelectionpreferredContactMethod() {
    this.fetchSelectedItemspreferredContactMethod();
    console.log(this.preferredContactMethodselectedItemsList);
  }

  fetchSelectedItemspreferredContactMethod() {
    this.preferredContactMethodselectedItemsList = this.preferredContactMethodDataList.filter((value, index) => {
      return value.isChecked
    });
  }

  onAdd(f) {
    console.log(f);
    var status;
    if (f.invalid == true || this.productInterestselectedItemsList.length == 0 || this.preferredContactMethodselectedItemsList.length == 0) {
      this.addValidation = true;
    } else {
      this.addValidation = false;

      if (this.userNickname == 'Lead Form') {
        status = 'New Lead';
      } else {
        status = this.userLevel + ' New Lead';
      }
      this.loader.show();
      f.form.value.id = "LPD-" + Math.floor(Math.random() * 999999999);
      f.form.value.prefered_contact_method = this.preferredContactMethodselectedItemsList;
      f.form.value.product_interest = this.productInterestselectedItemsList;
      f.form.value.status = status;
      f.form.value.creation_date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      this.myInit.body = f.form.value;
      console.log(f.form.value);
      Object.keys(this.myInit.body).forEach(key => this.myInit.body[key] === undefined ? delete this.myInit.body[key] : {});
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showSuccessMsg = true;
        this.Added = 'Registration Successfull!';
        console.log('\'all-residents\' component-> New Visitor Created Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.Added = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'all-residents\' component-> Error in creating new Visitor!');
        console.log(error.response);
      });


    }
  }



  DownloadP() {
    window.open("https://workordersfiles920.s3.amazonaws.com/General/CRM/20220407-PLA-residential-one-pager-Malaysia-updated.pdf");
  }

  DownloadD() {
    window.open("https://workordersfiles920.s3.amazonaws.com/General/CRM/20220321-PLA-floorplan-one-pager-Malaysia.pdf");
  }

  DownloadT() {
    window.open("https://workordersfiles920.s3.amazonaws.com/General/CRM/20220216-PLTS-commercial-one-pager-Leasing-Tenancy-Malaysia.pdf  ");
  }


}
