import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import * as XLSX from 'xlsx';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare const $: any;
type AOA = any[][];

@Component({
  selector: 'app-sub-tab',
  templateUrl: './sub-tab.component.html',
  styleUrls: ['./sub-tab.component.css']
})
export class SubTabComponent implements OnInit {

  public rows = [];
  public srch = [];
  public userLevel;

  selectedPics: FileList;
  eventPics;
  havePictures: boolean = false;
  viewPictures = [];
  pictures = [];

  public addLT: any = {};
  public uptLT: any = {};
  addFormValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public MSG;
  totalList: any;

  uploadPicsFolder = '/General/Settings/contentManagment/SubTab';

  searchT: any = [];
  scan: boolean = false;
  scanResults = '';
  unsortedrows = [];
  public categorys = [];
  logoPic;

  deleteID;
  delete_results;
  apiNameDelete = 'contentManagmentSubTab'; // replace this with your api name.
  pathDelete = '/contentManagmentSubTab/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  apiName = 'contentManagmentSubTab'; // replace this with your api name.
  path = '/contentManagmentSubTab'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };



  apiNameCat = 'contentManagmentTab';
  apiPathCat = '/contentManagmentTab';

  getCatInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  services_category='Tab';
  services_sub_category='Sub Tab';
  services_upload_picture='Upload Pitcture';


  constructor(private leaveService: AppService, private loader: LoaderService, private uploadService: UploadFileService) {
    this.userLevel = localStorage.getItem('user_level');

  }


  ngOnInit() {
    this.getCategories();
    this.getAllSubCategories();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }
  }

  addReset() {
    this.addFormValidation = false;
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');
  }


  onEdit(item) {
    this.uptLT = item;
    this.addFormValidation = false;
    if(this.uptLT.pictures){
      this.viewPictures.splice(0, this.viewPictures.length);
      this.viewPictures.push(this.uptLT.pictures);
      this.logoPic = this.uptLT.pictures;
    }
  
    $('#Edit_modal').modal('show');
  }

  updateRecordAndPic(f){
    this.loader.show();
    if (this.havePictures) {
      this.uploadPicsAndFiles().then(Response => {
        console.log(Response);
        this.onUpdate(f);
        this.havePictures = false;
      }).catch(error => {
        console.log(error);
        this.havePictures = false;
      });
    } else {
      this.onUpdate(f);
    }
  }


  onUpdate(f) {
    if (f.invalid) {
      this.addFormValidation = true;
    } else {
      const createBody = {
        id: f.form.value.id,
        tab: f.form.value.tab,
        sub_tab: f.form.value.sub_tab,
        pictures: this.logoPic,
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        var id = f.form.value.id;
        var arr = this.rows.find(function (item, i) {
          return item.id === id
        });

        arr.id = f.form.value.id;
        arr.tab = f.form.value.tab;
        arr.sub_tab = f.form.value.sub_tab;
        arr.pictures = this.logoPic;

        var index = this.rows.findIndex(function (item, i) {
          return item.id === id
        });
        if (index > -1) {
          this.rows.splice(index, 1);
        }

        this.rows.unshift(arr);
        this.srch.unshift(arr);
        this.rows = this.rows;
        $('#Edit_modal').modal('hide');
        console.log('\'type of unit\' component-> Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.MSG = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'type of unit\' component-> Error in Updating!');
        console.log(error.response);
      });
    }
  }

  getCategories() {
    this.categorys.push('');
    this.loader.show();
    API.get(this.apiNameCat, this.apiPathCat, this.getCatInit).then(response => {

      this.categorys.push(...response.data);
      console.log('\'categorys\' component-> All categorys Retrieved Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'categorys\' component-> Error in Retreiving All categorys from server!');
      console.log(error.response);
    });
  }


  addFormSubCategory(f) {
    if (f.invalid) {
      this.addFormValidation = true;
    } else {
      const randomnumber = Math.floor(Math.random() * 999999999);

      const createBody = {
        id: randomnumber.toString(),
        tab: f.form.get('tab').value,
        sub_tab: f.form.get('sub_tab').value,
        pictures: this.pictures.join(";"),
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.MSG = 'sub_Tab  Added Successfully!';
        this.viewPictures.splice(0, this.viewPictures.length);
        this.viewPictures.push(this.pictures.join(";"));
        this.rows.unshift(f.form.value);
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_modal').modal('hide');
        console.log('\'sub_Tab\' component-> New sub_Tab added Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.MSG = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'sub_Tab\' component-> Error in creating new sub_Tab!');
        console.log(error.response);
      });
    }

  }


  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    const tab = f.form.get('tab').value;
    const sub_tab = f.form.get('sub_tab').value;



    if (tab != undefined) {
      srchFiltersNames.push('tab');
      srchFiltersValues.push(tab);
    }
    if (sub_tab != undefined) {
      srchFiltersNames.push('sub_tab');
      srchFiltersValues.push(sub_tab);
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      this.getAllSubCategories();
    } else {
      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanRows();
    }

  }

  scanRows() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    this.getRows(this.apiName, this.path, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);

      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanRows();
      } else {
        this.scanResults = 'Completed';
        this.scan = false;
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No Results Found for this Search Please Try Different Search parameters!';
        }
        this.srch.push(...this.rows);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      if (this.totalList != 0) {
        this.scanResults = 'Incomplete, Records List Reached to its maximum limit, Please Narrow Down your Search by Adding More Filters ';
      } else {
        this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
      }
    });
  }

  getRows(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'sub_tab\' component->  Rows Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'sub_tab\' component-> Error in Retreiving Rows from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  searchCategory(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { tab: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }
  searchSubCategory(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { sub_tab: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }


  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }

  addSubCatWithPics(f) {
    this.loader.show();
    if (this.havePictures) {
      this.uploadPicsAndFiles().then(Response => {
        console.log(Response);
        this.addFormSubCategory(f);
        this.havePictures = false;
      }).catch(error => {
        console.log(error);
        this.havePictures = false;
      });
    } else {
      this.addFormSubCategory(f);
    }
  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          console.log(Response);
          resolve("Add  Now!")
          this.eventPics.srcElement.value = null;
          resolve(Response);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else {
        resolve("Add  Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.logoPic=Response.Location;
          this.pictures.push(Response.Location);
          resolve("All pics uploaded");
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }


  getAllSubCategories() {
    this.unsortedrows.splice(0, this.unsortedrows.length);

    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.scan = false;
      this.unsortedrows.push(...response);
      var sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.tab;
        const name2 = t2.tab;
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);


      console.log('\'project\' component-> All Sub categories Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All Sub categories from server!');
      console.log(error);
    });
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.services_category = this.rowslanguages[0].services_category;      
        this.services_upload_picture = this.rowslanguages[0].services_upload_picture;        
        this.services_sub_category = this.rowslanguages[0].services_sub_category;        

        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getAllSubCategories();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }

}
