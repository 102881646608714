import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ISlimScrollOptions } from 'ngx-slimscroll';
import { LoaderService } from 'src/app/loader/loader.service';
import { AuthService } from 'src/app/auth/auth.service';
import {LoginCredentialService} from 'src/app/services/login-credentials.service';
import { CookieService } from 'ngx-cookie-service';


declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  opts1: ISlimScrollOptions;
  menuSidebar = false;

  public url;
  public userLevel: string;
  public userNickname;
  public profile_pic;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _loader: LoaderService ,
    private loginService: LoginCredentialService,
    private cookieService: CookieService
    ) {

      this.userNickname = localStorage.getItem('nick_name');
      this.profile_pic = localStorage.getItem('profile_pic');
      if (this.profile_pic==='undefined'){
        this.profile_pic='https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png';
      }
    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')[1];
        // console.log(this.url);
        if (this.url === 'inbox' || this.url === 'chats' || this.url === 'settings' || this.url === 'calls' ) {
          if ($('body').hasClass('mini-sidebar')) {
            $('body').removeClass('mini-sidebar');
            $('.subdrop + ul').slideDown();
          }
        }

      }
    });
  }

  ngAfterViewInit() {
    const h = $(window).height() - 124;
    $('.msg-list-scroll').height(h);
    $('.msg-sidebar .slimscroll-wrapper').height(h);

  }

  ngOnInit() {

    // this.loginService.currentMessage.subscribe(temp => this.userLevel = temp);
    this.userLevel = localStorage.getItem('user_level');


    this.opts1 = {
      barBackground: '#878787',
      gridBackground: 'transparent',
      barOpacity: '0.6',
      barBorderRadius: '6',
      barWidth: '7',
      gridWidth: '0',
      alwaysVisible: false
    };

    // console.log($(window).height());

    const h = $(window).height() - 124;
    $('.msg-list-scroll').height(h);
    $('.msg-sidebar .slimscroll-wrapper').height(h);

    $(window).resize(function() {
        const h = $(window).height() - 124;
        $('.msg-list-scroll').height(h);
        $('.msg-sidebar .slimscroll-wrapper').height(h);
    });

      // $(document).on('click','#toggle_btn', function() {
      //   if($('body').hasClass('mini-sidebar')) {
      //     $('body').removeClass('mini-sidebar');
      //     $('.subdrop + ul').slideDown();

      //   } else {
      //     $('body').addClass('mini-sidebar');
      //     $('.subdrop + ul').slideUp();
      //   }
      //   return false;
      // });

      $(document).on('mouseover', function(e) {
        e.stopPropagation();
        if ($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
          const targ = $(e.target).closest('.sidebar').length;
          if (targ) {
            $('body').addClass('expand-menu');
            $('.subdrop + ul').slideDown();
          } else {
            $('body').removeClass('expand-menu');
            $('.subdrop + ul').slideUp();
          }
        }
      });
    if ($(window).width() > 991) {
    }

  }

  logOut() {
    console.log('Logout done');
    this.cookieService.deleteAll;
    localStorage.clear();
    this._authService.signOut()
      .then(() => this.router.navigate(['pages/login']));
  }

}
