import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { AppService } from '../app.service';
import { API } from 'aws-amplify';
import { IMyOptions } from 'mydatepicker';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { LoaderService } from '../loader/loader.service';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
const SCRIPT_PATH = 'https://app.smplrspace.com/lib/smplr.js';
declare let smplr: any;
declare const $: any;
type AOA = any[][];
@Component({
  selector: 'app-add-defect',
  templateUrl: './add-defect.component.html',
  styleUrls: ['./add-defect.component.css'],
  providers: [DatePipe]
})
export class AddDefectComponent implements OnInit {
  addT: any = [];
  workorderCounterId;
  add_new_work_orders_add_work_order='Add New Defect';
  add_new_work_orders_created_by='Created By';
  add_new_work_orders_work_order_id='Defect ID';
  add_new_work_orders_store_work_title='Outlet Work Title'
  add_new_work_orders_location='Location';
  add_new_work_orders_property_developer='Client';
  add_new_work_orders_element='Element';
  add_new_work_orders_floor='Floor';
  add_new_work_orders_defect='Defect';
  add_new_work_orders_costs='Costs';
  add_new_work_orders_competency='Competency';
  add_new_work_orders_invoice='Invoice';
  add_new_work_orders_days_to_execute='Days To Execute';
  add_new_work_orders_charge_to='Charge To';
  add_new_work_orders_priority='Priority';
  add_new_work_orders_main_contractor='Main Contractor';
  add_new_work_orders_next_action='Next Action';
  add_new_work_orders_date_scheduled='Date Scheduled';
  add_new_work_orders_cause='Cause';
  add_new_work_orders_time_scheduled='Time Scheduled';
  add_new_work_orders_am_pm='AM/PM';
  add_new_work_orders_work_order_description='Defect Description';
  add_new_work_orders_upload_pictures='Upload Pictures';
  add_new_work_orders_upload_documents_pdf_only='Upload Documents (PDF Only)';
  add_new_work_orders_create_new_work_order='Submit Defect';
  all_work_orders_all_work_orders='All Defects';
  all_work_orders_print_work_order='Print Defect';
  my_pending_work_orders_my_pending_work_orders='My Pending Defects';
  my_pending_work_orders_refresh_list='Refresh List';
  my_pending_work_orders_add_work_order='Add Defect';
  work_order_details_my_pending_work_orders='My Pending Defects';
  work_order_details_print='Print';
  work_order_details_edit_work_order='Edit Defect';
  work_order_details_remarks_and_approval_log='Remarks And Approval Log';
  work_order_details_created_date='Created Date';
  work_order_details_created_time='Created Time';
  work_order_details_created_by='Created By';
  work_order_details_action='Action';
  work_order_details_remarks='Remarks';
  work_order_details_next_action='Next Action';

  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';

  public defects = [];

  public elements = [];
  elementload: Boolean = false;
  daysToExecuteLoad: Boolean = false;
  defectLoad: Boolean = false;
  competencyLoad: Boolean = false;
  priorityLoad: Boolean = false;
  causeLoad: Boolean = false;

  currentDate: string;
  currentTime: string;
  public timeList = [];
  public propertyDevelopers = [];
  pdCodeList =[];
  loadsmplrflag:boolean=false;
  progress = 0;
  goteditFlag:boolean=false;
  edititem;
  public contractorsList = [];
  public allContractorsList = [];
  propertiesHouseNumberList = [];
  propertiesPrecinctList = [];
  propertiesStreetsList = [];
  propertiesList = [];
  public nextActions = [];
  nextActionLoad:Boolean=false;
  unsortedrows2 = [];
  public rowslanguages = [];
  unsortedrows = [];
  rows = [];
  public locations = [];
  locationload: Boolean = false;
  scan: Boolean = false;
  public scanResults;
  sorted = [];
  public selectedLocation;
  public selectedElement;
  public selectDefect;
  public selectedCompetency;
  public selectedDaysToExecute;
  public selectedPriority;
  public SelectednextAction;
  public days_to_executes = [];
  public prioritys = [];
  public causes = [];
  public next_actions = [];

  public competencys = [];

  public utilities = [];
  public submitterActions = [];
  public workOrderPriorities = [];
  public workOrderDaysToExecute = [];
  public workOrderCompetencies = [];
  showSuccessMsg = false;
  TicketSubmitted = '';
  public userNickName;
  showErrorMsg = false;
  public userLevel;
  srch = [];
 addWorkOrderValidation = false;
 selectedPics: FileList;
 selectedFiles: FileList;
 haveFiles: boolean = false;
 eventFiles;
 havePictures: boolean = false;
 eventPics;
 
 notificationsUrl = "https://fcm.googleapis.com/fcm/send";

 pictures = [];
 files = [];
  /////////////API//////////
  

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiName = 'pltsdefect'; // replace this with your api name.
  path = '/pltsdefect'; // replace this with the path you have configured on your API
  apiPathUpdate = '/pltsdefect/update'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';
  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API
  getPropertiessInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamepltstask = 'pltstask'; // replace this with your api name.
  apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API
  getpltstask = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameLocation = 'LEDlocation'; // replace this with your api name.
  apipathLocation = '/ledLocation'; // replace this with the path you have configured on your API
  getLocationInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameDefect = 'ledDefect'; // replace this with your api name.
  apipathDefect = '/ledDefect'; // replace this with the path you have configured on your API
  getDefectInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };



  apiNameUtilities = 'utilities';
  apiPathUtilities = '/utilities';
  getUtilitiesInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  putUtilitiesInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL                        
  };

  apiNameElement = 'ledElement'; // replace this with your api name.
  apipathElement = '/ledElement'; // replace this with the path you have configured on your API
  getElementInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameNotifications = 'notifications'; // replace this with your api name.
  pathNotifications = '/notifications'; // replace this with the path you have configured on your API
  putMyInitNotifications = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameCompetency = 'ledCompetencies'; // replace this with your api name.
  apipathCompetency = '/ledCompetencies'; // replace this with the path you have configured on your API
  getCompetencyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameDaysToExecute = 'ledDaysToExecute'; // replace this with your api name.
  apipathDaysToExecute = '/ledDaysToExecute'; // replace this with the path you have configured on your API
  getDaysToExecuteInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNamePriority = 'ledPriority'; // replace this with your api name.
  apipathPriority = '/ledPriority'; // replace this with the path you have configured on your API
  getPriorityInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNamePltsProperties = 'tenancymanagment';
  apiPathPltsProperties = '/tenancymanagment';
  getPltsPropertiesInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  apiNameNextAction = 'ledCause'; // replace this with your api name.
  apipathNextAction = '/ledCause'; // replace this with the path you have configured on your API
  getNextActionInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  constructor(    private uploadService: UploadFileService,public http: HttpClient ,@Inject(DOCUMENT) private document: Document,private renderer: Renderer2,private loader: LoaderService,private datePipe: DatePipe,    private appService: AppService,) {  
    
  

     this.userNickName = localStorage.getItem('nick_name');
    this.submitterActions = this.appService.pdAdminActions;
    this.workOrderPriorities = this.appService.workOrderPriorities;
    this.workOrderDaysToExecute = this.appService.workOrderDaysToExecute;
    this.workOrderCompetencies = this.appService.workOrderCompetencies;
    this.userLevel = localStorage.getItem('user_level');
     this.timeList = appService.timeList;
    }
    private myDatePickerOptions1: IMyOptions = {
      dateFormat: 'dd/mm/yyyy',
    };

  ngOnInit() {
    this.getPLTSProperties();
    const itemm=localStorage.getItem('itemm');
    localStorage.removeItem('itemm');
    if(itemm!=undefined && itemm!=null){
      this.gotedit(JSON.parse(itemm));
    }else{
      this.gotNew();
    }
  }

  gotedit(item){
    //console.log(item);
    this.add_new_work_orders_add_work_order='Edit Defect';
    this.loader.show();
    this.loadsmplrflag=true;
    this.progress=10;
    this.edititem=item;
    this.goteditFlag=true;
    this.getContractors();
    this.onChangetou();
    this.getDynamicNextActions();
    this.getPropertyDevelopers();
  }

  gotNew(){
    this.getContractors();
    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      //console.log(language);
      this.searchlanguage(language);
    }
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString('en-GB');
    const randomnumber = Math.floor(Math.random() * 99999999);
    // //console.log(this.currentDate);
    this.addT = {
      'id': 'Auto',
      'days_open': '0',
      'created_by': localStorage.getItem('nick_name'),
      'property_developer':localStorage.getItem('property_developer')
    };

    this.onChangetou();
    this.getDynamicNextActions();
    this.getPropertyDevelopers();
  }
  getPropertyDevelopers() {
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      const results = response.data.filter(element => {
        return element.pd_name !== undefined && element.pd_name!=="" && element.pd_name!=="New Lead" && element.pd_name!=="" && element.pd_name!=="New Lead";
      });
      this.propertyDevelopers.splice(0, this.propertyDevelopers.length);
      this.propertyDevelopers.push(...results);
      //console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      //console.log(this.propertyDevelopers);
      this.setPdCode();
    }).catch(error => {
      //console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      //console.log(error);
    });
  }
  setPdCode() {
    var pd=localStorage.getItem("property_developer");
    // //console.log(pd);
    // //console.log(this.propertyDevelopers);
    var p = [];
    const filters = { pd_name: [pd] },
      results = this.propertyDevelopers,
      Filtered = this.multiFilter(results, filters);
   //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.company_code;
      p.push(x);
    });

    this.pdCodeList.splice(0, this.pdCodeList.length);
    this.pdCodeList.push(...p);
    this.pdCodeList = this.pdCodeList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.pdCodeList.sort();
   // //console.log(this.pdCodeList[0]+'-'+new Date().getFullYear().toString().substr(-2));

  }
  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }
  getContractors() {
    this.contractorsList.push('');
    var t = [];
    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {

      response.data.forEach(function (arrayItem) {
        var y = arrayItem.con_name;
        t.push(y);
      });
      this.contractorsList.push(...t);
      this.allContractorsList.push(...t);
      //console.log('\'add-new-work-order\' component-> All Contractors Retrieved Successfully!');
      //console.log(response);
    }).catch(error => {
      //console.log('\'add-new-work-order\' component-> Error in Retreiving All Contractors from server!');
      //console.log(error.response);
    });
  }
  getDynamicNextActions() {
    this.nextActions.splice(0,this.nextActions.length);
    var pd=localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if(user_level){
   // //console.log(user_level);
    this.nextActionLoad = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];    


    if (pd != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(pd);
    }

    if (user_level != undefined) {
      srchFiltersNames.push('from_user_level');
      srchFiltersValues.push(user_level);
    } 

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    //console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    //console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    //console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      
    } else {
      this.unsortedrows2.splice(0, this.unsortedrows2.length);
      this.nextActions.splice(0,this.nextActions.length);
      this.getpltstask.body.FilterExpression = FilterExpression;
      this.getpltstask.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getpltstask.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanTask();
    }
  }
  }

  scanTask() {
    this.nextActions.push('');
    let unsortedrows2=[];
    this.getTask(this.apiNamepltstask, this.apiPathpltstask, this.getpltstask).then(response => {
      unsortedrows2.push(...response.Items);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getpltstask.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanTask();
      } else {
        this.nextActions.push(...this.appService.sortByKey(unsortedrows2,'order'));
        this.nextActionLoad = false;
        delete this.getpltstask.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getTask(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'Location\' component->  For Location Next Actions (tasks) Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'Location\' component-> For Location Error in Retreiving Next Actions (tasks) from server!');
        //console.log(error.response);
        reject(error.response);
      });

    });
  }
 
  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    //console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    //console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    //console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Defects';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      //console.log(response.Items);

      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rows.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        this.add_new_work_orders_add_work_order = this.rowslanguages[0].add_new_work_orders_add_work_order;
        this.add_new_work_orders_created_by =this.rowslanguages[0].add_new_work_orders_created_by;
        this.add_new_work_orders_work_order_id = this.rowslanguages[0].add_new_work_orders_work_order_id;
        this.add_new_work_orders_location = this.rowslanguages[0].add_new_work_orders_location;
        this.add_new_work_orders_element = this.rowslanguages[0].add_new_work_orders_element;
        this.add_new_work_orders_floor = this.rowslanguages[0].add_new_work_orders_floor;
        this.add_new_work_orders_defect = this.rowslanguages[0].add_new_work_orders_defect;
        this.add_new_work_orders_costs = this.rowslanguages[0].add_new_work_orders_costs;
        this.add_new_work_orders_competency = this.rowslanguages[0].add_new_work_orders_competency;
        this.add_new_work_orders_invoice = this.rowslanguages[0].add_new_work_orders_invoice;
        this.add_new_work_orders_days_to_execute = this.rowslanguages[0].add_new_work_orders_days_to_execute;
        this.add_new_work_orders_charge_to = this.rowslanguages[0].add_new_work_orders_charge_to;
        this.add_new_work_orders_priority = this.rowslanguages[0].add_new_work_orders_priority;
        this.add_new_work_orders_main_contractor = this.rowslanguages[0].add_new_work_orders_main_contractor;
        this.add_new_work_orders_next_action = this.rowslanguages[0].add_new_work_orders_next_action;
        this.add_new_work_orders_store_work_title=this.rowslanguages[0].add_new_work_orders_store_work_title;
        this.add_new_work_orders_date_scheduled = this.rowslanguages[0].add_new_work_orders_date_scheduled;
        this.add_new_work_orders_cause = this.rowslanguages[0].add_new_work_orders_cause;
        this.add_new_work_orders_time_scheduled = this.rowslanguages[0].add_new_work_orders_time_scheduled;
        this.add_new_work_orders_am_pm = this.rowslanguages[0].add_new_work_orders_am_pm;
        this.add_new_work_orders_work_order_description = this.rowslanguages[0].add_new_work_orders_work_order_description;
        this.add_new_work_orders_upload_pictures = this.rowslanguages[0].add_new_work_orders_upload_pictures;
        this.add_new_work_orders_upload_documents_pdf_only = this.rowslanguages[0].add_new_work_orders_upload_documents_pdf_only;
        this.add_new_work_orders_create_new_work_order = this.rowslanguages[0].add_new_work_orders_create_new_work_order;
        this.all_work_orders_all_work_orders = this.rowslanguages[0].all_work_orders_all_work_orders;
        this.all_work_orders_print_work_order = this.rowslanguages[0].all_work_orders_print_work_order;
        this.my_pending_work_orders_my_pending_work_orders = this.rowslanguages[0].my_pending_work_orders_my_pending_work_orders;
        this.my_pending_work_orders_refresh_list = this.rowslanguages[0].my_pending_work_orders_refresh_list;
        this.my_pending_work_orders_add_work_order = this.rowslanguages[0].my_pending_work_orders_add_work_order;
        this.work_order_details_my_pending_work_orders = this.rowslanguages[0].work_order_details_my_pending_work_orders;
        this.work_order_details_print = this.rowslanguages[0].work_order_details_print;
        this.work_order_details_edit_work_order = this.rowslanguages[0].work_order_details_edit_work_order;
        this.work_order_details_remarks_and_approval_log = this.rowslanguages[0].work_order_details_remarks_and_approval_log;
        this.work_order_details_created_date = this.rowslanguages[0].work_order_details_created_date;
        this.work_order_details_created_time = this.rowslanguages[0].work_order_details_created_time;
        this.work_order_details_created_by = this.rowslanguages[0].work_order_details_created_by;
        this.work_order_details_action = this.rowslanguages[0].work_order_details_action;
        this.work_order_details_remarks = this.rowslanguages[0].work_order_details_remarks;
        this.work_order_details_next_action = this.rowslanguages[0].work_order_details_next_action;

        this.add_new_work_orders_property_developer = this.rowslanguages[0].properties_property_developer;

        
        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_creation_date = this.rowslanguages[0].generals_creation_date;
        this.generals_actions = this.rowslanguages[0].generals_actions;

        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      //console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'login\' component->  languages Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'login\' component-> Error in Retreiving languages from server!');
        //console.log(error);
        reject(error.response);
      });

    });
  }
 
  onChangetou() {
    this.locations.splice(0, this.locations.length);
    var tou = 'TreeHouse City Sdn Bhd';

    if (tou) {
      this.locationload = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (tou != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(tou);
      }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.locations.splice(0, this.locations.length);
        this.getLocationInit.body.FilterExpression = FilterExpression;
        this.getLocationInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getLocationInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanLocations();
      }
    }
  }

  scanLocations() {
    this.getLocations(this.apiNameLocation, this.apipathLocation, this.getLocationInit).then(response => {
      this.unsortedrows.push(...response.Items);
      for (var i = 0; i < this.unsortedrows.length; i++) {
        this.sorted.push(this.unsortedrows[i].location);
      }

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getLocationInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLocations();
      } else {
        this.locations.push('');
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });

        this.locations.push(...this.sorted);
        this.locations.sort();
        this.locationload = false;
        delete this.getLocationInit.body['ExclusiveStartKey'];
        if(this.goteditFlag){
          this.progress=20;
          this.onChangeLocation(this.edititem.location)
        }
        return;
      }
    }).catch(error => {
      //console.log(error);

    });
  }

  getLocations(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'element\' component->  For Element Locations Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'element\' component-> For Element Error in Retreiving Locations from server!');
        //console.log(error.response);
        reject(error.response);
      });

    });
  }



  onChangeLocation(location) {
    this.sorted.splice(0, this.sorted.length);
    this.elements.splice(0, this.elements.length);
    this.selectedLocation = location;

    if (location) {
      this.elementload = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (location != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(location);
      }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.elements.splice(0, this.elements.length);
        this.getElementInit.body.FilterExpression = FilterExpression;
        this.getElementInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getElementInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanElements();
      }
    }
  }

  scanElements() {
    this.getElement(this.apiNameElement, this.apipathElement, this.getElementInit).then(response => {
      this.unsortedrows.push(...response.Items);
      for (var i = 0; i < this.unsortedrows.length; i++) {
        this.sorted.push(this.unsortedrows[i].element);
      }

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getElementInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanElements();
      } else {
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });

        this.elements.push(...this.sorted);
        this.elements.sort();
        this.elementload = false;
        delete this.getElementInit.body['ExclusiveStartKey'];
        if(this.goteditFlag){
          this.progress=30;
          this.onChangeElement(this.edititem.element)
        }
        return;
      }
    }).catch(error => {
      //console.log(error);
    });
  }

  getElement(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'defect\' component->  For defects elements Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'element\' component-> For Defect Error in Retreiving elemts from server!');
        //console.log(error.response);
        reject(error.response);
      });
    });
  }



  onChangeElement(element) {
    //console.log(element);
    this.sorted.splice(0, this.sorted.length);
    this.defects.splice(0, this.defects.length);
    this.selectedElement = element;
    if (element) {
      this.defectLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (element != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(element);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.defects.splice(0, this.defects.length);
        this.getDefectInit.body.FilterExpression = FilterExpression;
        this.getDefectInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getDefectInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanDefect();
      }
    }
  }

  scanDefect() {
    this.getDefect(this.apiNameDefect, this.apipathDefect, this.getDefectInit).then(response => {
      for (var i = 0; i < response.Items.length; i++) {
        this.sorted.push(response.Items[i].defect);
      }

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getDefectInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanDefect();
      } else {
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.defects.push(...this.sorted);
        this.defects.sort();
        this.defectLoad = false;
        delete this.getDefectInit.body['ExclusiveStartKey'];
        if(this.goteditFlag){
          this.progress=50;
          this.onChangeDefect(this.edititem.defect)
        }
        return;
      }
    }).catch(error => {
      //console.log(error);
    });
  }

  getDefect(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'Competency\' component->  For Competency defects Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'Competency\' component-> For Competency Error in Retreiving defects from server!');
        //console.log(error.response);
        reject(error.response);
      });

    });
  }



  onChangeDefect(defect) {
    this.sorted.splice(0, this.sorted.length);
    this.selectDefect = defect;
    this.competencys.splice(0, this.competencys.length);
    if (defect) {
      //console.log(defect);
      this.competencyLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (defect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(defect);
      }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.competencys.splice(0, this.competencys.length);
        this.getCompetencyInit.body.FilterExpression = FilterExpression;
        this.getCompetencyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getCompetencyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanCompetency();
      }
    }
  }

  scanCompetency() {
    this.getCompetency(this.apiNameCompetency, this.apipathCompetency, this.getCompetencyInit).then(response => {
      for (var i = 0; i < response.Items.length; i++) {
        this.sorted.push(response.Items[i].competency);
      }

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getCompetencyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanCompetency();
      } else {
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });

        this.competencys.push(...this.sorted);
        this.competencys.push(...this.workOrderCompetencies);
        this.competencyLoad = false;
        delete this.getCompetencyInit.body['ExclusiveStartKey'];

        if(this.goteditFlag){
          this.onChangeCompetency(this.edititem.subject)
          this.progress=60;
        }else{
          this.onChangeCompetency(this.competencys[0]);
          this.addT.subject=this.competencys[0];
        }
        return;
      }
    }).catch(error => {
      //console.log(error);

    });
  }

  getCompetency(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'days_to_execute\' component->  For days_to_execute competency Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'days_to_execute\' component-> For days_to_execute Error in Retreiving competency from server!');
        //console.log(error);
        reject(error.response);
      });

    });
  }


  onChangeCompetency(competency) {
    this.sorted.splice(0, this.sorted.length);
    this.selectedCompetency = competency;
    this.days_to_executes.splice(0, this.days_to_executes.length);
    this.prioritys.splice(0, this.prioritys.length);
    this.next_actions.splice(0, this.next_actions.length);
    if (competency) {
      this.daysToExecuteLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (this.selectDefect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(this.selectDefect);
      }
      if (competency != undefined) {
        srchFiltersNames.push('competency');
        srchFiltersValues.push(competency);
      }


      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.days_to_executes.splice(0, this.days_to_executes.length);
        this.getDaysToExecuteInit.body.FilterExpression = FilterExpression;
        this.getDaysToExecuteInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getDaysToExecuteInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scandaysToExecute();
      }
    }
  }

  scandaysToExecute() {
    this.getdaysToExecute(this.apiNameDaysToExecute, this.apipathDaysToExecute, this.getDaysToExecuteInit).then(response => {
      for (var i = 0; i < response.Items.length; i++) {
        this.sorted.push(response.Items[i].days_to_execute);
      }

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getDaysToExecuteInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scandaysToExecute();
      } else {
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });

        this.days_to_executes.push(...this.sorted);
        this.days_to_executes.push(...this.workOrderDaysToExecute);

        this.daysToExecuteLoad = false;
        delete this.getDaysToExecuteInit.body['ExclusiveStartKey'];

        if(this.goteditFlag){
          this.progress=70;
          this.onChangeDaysToExecute(this.edititem.days_to_execute)
        }else{
          this.onChangeDaysToExecute(this.days_to_executes[0]);
          this.addT.days_to_execute=this.days_to_executes[0];
        }
        return;
      }
    }).catch(error => {
      //console.log(error);
    });
  }

  getdaysToExecute(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'priority\' component->  For priority days to execute Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'priority\' component-> For priority Error in Retreiving days to execute from server!');
        //console.log(error);
        reject(error.response);
      });

    });
  }


  onChangeDaysToExecute(days_to_execute) {
    this.sorted.splice(0, this.sorted.length);
    this.prioritys.splice(0, this.prioritys.length);
    this.next_actions.splice(0, this.next_actions.length);
    if (days_to_execute) {
      this.priorityLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];
      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (this.selectDefect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(this.selectDefect);
      }
      if (this.selectedCompetency != undefined) {
        srchFiltersNames.push('competency');
        srchFiltersValues.push(this.selectedCompetency);
      }
      // if (days_to_execute != undefined) {
      //   srchFiltersNames.push('days_to_execute');
      //   srchFiltersValues.push(days_to_execute);
      // }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.prioritys.splice(0, this.prioritys.length);
        this.getPriorityInit.body.FilterExpression = FilterExpression;
        this.getPriorityInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getPriorityInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanPriority();
      }
    }
  }

  scanPriority() {
    this.getPriority(this.apiNamePriority, this.apipathPriority, this.getPriorityInit).then(response => {
      for (var i = 0; i < response.Items.length; i++) {
        this.sorted.push(response.Items[i].priority);
      }


      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getPriorityInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanPriority();
      } else {
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });

        this.prioritys.push(...this.sorted);
        this.prioritys.push(...this.workOrderPriorities);

        this.priorityLoad = false;
        delete this.getPriorityInit.body['ExclusiveStartKey'];

        if(this.goteditFlag){
          this.progress=80;
          this.onChangePriority(this.edititem.priority)
        }else{
          this.onChangePriority(this.prioritys[0]);
          this.addT.priority=this.prioritys[0];
        }
        return;
      }
    }).catch(error => {
      //console.log(error);
    });
  }

  getPriority(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'nextAction\' component->  For nextAction priority Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'nextAction\' component-> For nextAction Error in Retreiving priority from server!');
        //console.log(error);
        reject(error.response);
      });

    });
  }


  onChangePriority(priority) {
    this.sorted.splice(0, this.sorted.length);
    this.next_actions.splice(0, this.next_actions.length);

    if (priority) {
      //console.log(priority);
      this.causeLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (this.selectDefect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(this.selectDefect);
      }
      if (this.selectedCompetency != undefined) {
        srchFiltersNames.push('competency');
        srchFiltersValues.push(this.selectedCompetency);
      }
      if (priority != undefined) {
        srchFiltersNames.push('priority');
        srchFiltersValues.push(priority);
      }

      let FilterExpression = '';
      for (let i = 0; i < srchFiltersNames.length; i++) {
        FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
        if (i != srchFiltersNames.length - 1) {
          FilterExpression += ' and ';
        }
      }
      //console.log(FilterExpression);


      const ExpressionAttributeNames = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
      }
      //console.log(ExpressionAttributeNames);


      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
      }
      //console.log(ExpressionAttributeValues);

      if (srchFiltersNames.length == 0) {
        this.scan = true;
        this.scanResults = 'Please Enter Atleast One Field ';
      } else {
        this.unsortedrows.splice(0, this.unsortedrows.length);
        this.causes.splice(0, this.causes.length);
        this.getNextActionInit.body.FilterExpression = FilterExpression;
        this.getNextActionInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
        this.getNextActionInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
        this.scanCause();
      }
    }
  }

  scanCause() {
    this.getCause(this.apiNameNextAction, this.apipathNextAction, this.getNextActionInit).then(response => {
      for (var i = 0; i < response.Items.length; i++) {
        this.sorted.push(response.Items[i].cause);
      }

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getNextActionInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanCause();
      } else {
        this.sorted = this.sorted.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });

        this.causes.push(...this.sorted);
        this.causes.sort();

        this.causeLoad = false;
        delete this.getNextActionInit.body['ExclusiveStartKey'];
        if(this.goteditFlag && this.loadsmplrflag){
          this.addT=this.edititem;
          this.progress=100;
          this.loader.hide();          
        }else{
          this.addT.cause=this.causes[0];
        }
        return;
      }
    }).catch(error => {
      //console.log(error);

    });
  }

  getCause(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'cause\' component->  For priority cause Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'cause\' component-> For priority Error in Retreiving cause from server!');
        //console.log(error);
        reject(error.response);
      });

    });
  }



  getWorkOrderCounter(f) {
    //console.log(f.form.value);
    if (f.invalid === true) {
      this.addWorkOrderValidation = true;
    } else {
this.loader.show();
      if(this.goteditFlag){
        if (this.haveFiles || this.havePictures) {
          this.uploadPicsAndFiles().then(Response => {
            //console.log(Response);
            this.addTicket(f, f.form.value.id);
          }).catch(error => {
            //console.log(error);
          });
        } else {
          this.addTicket(f, f.form.value.id);
        }
      }else{
      API.get(this.apiNameUtilities, this.apiPathUtilities, this.putUtilitiesInit).then(response => {
        //console.log('\'add-new-work-order\' component-> All Utilities Retrieved Successfully!');
        //console.log(response);
        this.workorderCounterId = response[0].workordercounter;
        //console.log(this.workorderCounterId);
        this.addWorkOrderCounter(this.workorderCounterId);

        if (this.haveFiles || this.havePictures) {
          this.uploadPicsAndFiles().then(Response => {
            //console.log(Response);
            this.addTicket(f, this.workorderCounterId);
          }).catch(error => {
            //console.log(error);
          });
        } else {
          this.addTicket(f, this.workorderCounterId);
        }

      }).catch(error => {
        this.showErrorMsg = true;
        this.TicketSubmitted = 'Defect Submission Failed, Please Retry!';
        //console.log('\'add-new-work-order\' component-> Error in Retreiving All Utilities from server!');
        //console.log(error);
      });
    }
  }

  }

  addTicket(f, id) {
    var workorderid;
    if(this.goteditFlag){
      workorderid=id
    }else{
      workorderid =this.pdCodeList[0]+'-'+new Date().getFullYear().toString().substr(-2)+'-'+ id;
    }
    var dateScheduled = '';
    if (f.form.get('date_scheduled').value == undefined || f.form.get('date_scheduled').value===null) {
    } else {
      dateScheduled = this.reformatDate(f.form.get('date_scheduled').value);
    }

    if (f.invalid === true) {
      this.addWorkOrderValidation = true;
    } else {

      if (this.goteditFlag) {
        this.currentDate = this.edititem.created_date;;
      }

      const createTicketBody = {
        created_by: f.form.get('created_by').value,
        created_date: this.currentDate,
        id: workorderid,
        subject: f.form.get('subject').value,
        days_to_execute: f.form.get('days_to_execute').value,
        priority: f.form.get('priority').value,
        floor: f.form.get('floor').value,
        location: f.form.get('location').value,
        element: f.form.get('element').value,
        defect: f.form.get('defect').value,
        cause: f.form.get('cause').value,
        costs: f.form.get('costs').value,
        ampm: f.form.get('ampm').value,
        days_open: f.form.get('days_open').value,
        next_action: f.form.get('next_action').value,
        main_contractor: f.form.get('main_contractor').value,
        date_scheduled: dateScheduled,
        time_scheduled: f.form.get('time_scheduled').value,
        invoice: f.form.get('invoice').value,
        charge_to: f.form.get('charge_to').value,
        description: f.form.get('description').value,
        status: f.form.get('next_action').value,
        property_developer:f.form.get('property_developer').value,
        store_work_title:f.form.get('store_work_title').value,
        closed_by: this.userNickName,
        date_closed: this.currentDate,
        time_closed: this.currentTime,
        images: this.pictures,
        documents: this.files,
      };


      if (this.pictures.length > 0) {
      } else {
        delete createTicketBody.images;
      }
      if (this.files.length > 0) {
      } else {
        delete createTicketBody.documents;
      }
      if (f.form.get('costs').value === '') {
        delete createTicketBody.costs;
      }
      if (f.form.get('invoice').value === '') {
        delete createTicketBody.invoice;
      }
      if (f.form.get('charge_to').value === '') {
        delete createTicketBody.charge_to;
      }
      if (f.form.get('date_scheduled').value === '') {
        delete createTicketBody.date_scheduled;
      }
      if (f.form.get('time_scheduled').value === '') {
        delete createTicketBody.time_scheduled;
      }
      if (f.form.get('ampm').value === '') {
        delete createTicketBody.ampm;
      }
      if (f.form.get('next_action').value != 'Closed') {
        delete createTicketBody.closed_by;
        delete createTicketBody.date_closed;
        delete createTicketBody.time_closed;
      }


      this.putMyInit.body = createTicketBody;
      console.log(createTicketBody);
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.addWorkOrderValidation = false;
        this.TicketSubmitted = 'Defect Submitted Successfully. Defect ID:' + workorderid;
       
        this.havePictures = false;
        this.haveFiles = false;
        this.loader.hide();

           if(this.goteditFlag){
          this.goteditFlag=false;
           }else{
            this.addT = {
              'id': 'Auto',
              'days_open': '0',
              'created_by': localStorage.getItem('nick_name'),
              'date_scheduled': ''
            };
           }
      }).catch(error => {
        this.showErrorMsg = true;
        this.TicketSubmitted = 'Defect Submission Failed, Please Retry!';
        console.log("by");
        console.log('\'tickets\' component-> Error in creating new Ticket!');
        console.log(error);
           this.loader.hide();

      });
    }
  }

  addWorkOrderCounter(counter) {
    const id = 318;
    const utilitiesBody = {
      'id': id.toString(),
      'workordercounter': counter + 1
    };
    this.putUtilitiesInit.body = utilitiesBody;
    API.put(this.apiNameUtilities, this.apiPathUtilities, this.putUtilitiesInit).then(response => {
      //console.log('\'tickets\' component-> Utilities workorder counter added Successfully!');
      //console.log(response);
    }).catch(error => {
      this.showErrorMsg = true;
      this.TicketSubmitted = 'Defect Submission Failed, Please Retry!';
      console.log("heloo");
      //console.log('\'tickets\' component-> Error in saving utilities work order counter!');
      //console.log(error);
         this.loader.hide();


    });
  }
  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }


  uploadAllFiles(event) {
    this.selectedFiles = event.target.files;
    this.eventFiles = event;
    this.haveFiles = true;
  }

  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          //console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              //console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          //console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              //console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Ticket Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          //console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          //console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }

  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          //console.log("File Uploaded Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All Files uploaded");
          }
        }).catch(error => {
          //console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }
  reformatDate(dateStr) {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
  }

  public store_work_titleList = [];

  getPLTSProperties() {
    this.loader.show();
    API.get(this.apiNamePltsProperties, this.apiPathPltsProperties, this.getPltsPropertiesInit).then(response => {
      console.log(response);
      this.store_work_titleList.splice(0, this.store_work_titleList.length);
      this.store_work_titleList.push(...response.data);
      console.log('\'store_work_title\' component-> All store_work_title Retrieved Successfully!');
      console.log(this.store_work_titleList);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'store_work_title\' component-> Error in Retreiving All store_work_title from server!');
      console.log(error);
    });
  }


}
