import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
import { LoaderService } from 'src/app/loader/loader.service';
@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
  ///////////total list//////////////
  totalList: any;
  ///////////////alert variables/////////
  scan: boolean = false;
  editFlag: boolean = false;
  addTypeOfUnitValidation: Boolean = false;
  nextActionLoad: Boolean = false;
  scanResults = '';
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public addMsg;
  userLevelLoad: Boolean = false;
  ///////////search variable////////
  searchT: any = [];
  residentsList = [];
  public AllPd = [];
  public AllPdList = [];
  public pd;
  public userLevels = [];
  public rows = [];
  public euptLT: any = {};
  public uptLT: any = {};
  public typeOfUnit2 = [];
  public next_action = [];
  public addLT: any = {};
  ///////////delete variable//////
  delete_results;
  ////////////////////APi//////////
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';



  apiName = 'pltstask'; // replace this with your api name.
  path = '/pltstask'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'pltstask'; // replace this with your api name.
  pathDelete = '/pltstask/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameTou = 'userlevelandtask'; // replace this with your api name.
  apiPathTou = '/userlevelandtask'; // replace this with the path you have configured on your API
  getTouInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameTou2 = 'taskanduserlevel'; // replace this with your api name.
  apiPathTou2 = '/taskanduserlevel'; // replace this with the path you have configured on your API
  getTouInit2 = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };


  constructor(private loader: LoaderService, private projectService: AppService) { }

  ngOnInit() {
    this.getPropertyDevelopers();
    this.getAllRecords();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  //////////////////get functions///////////
  getAllRecords() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.scan = false;
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response);
      this.residentsList = response;
      this.totalList = Object.keys(this.rows).length;
      console.log('\'project\' component-> All  precincts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  getPropertyDevelopers() {
    var p = [];
    this.AllPd.splice(0, this.AllPd.length);
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }

  onChangePD(property_developer) {
    this.userLevels.splice(0, this.userLevels.length);

    this.pd = property_developer;

    if (property_developer) {
      console.log(property_developer);
      this.userLevelLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (property_developer != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(property_developer);
      }
      this.projectService.scanContainsAnd(this.apiNameTou, this.apiPathTou, srchFiltersNames, srchFiltersValues).then(response => {
        this.userLevels.push(...response);
        this.userLevelLoad = false;
        this.getNextActions();
      });
    }
  }

  getNextActions() {
    this.next_action.splice(0, this.next_action.length);
    this.nextActionLoad = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    if (this.pd != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(this.pd);
    }
    this.projectService.scanContainsAnd(this.apiNameTou2, this.apiPathTou2, srchFiltersNames, srchFiltersValues).then(response => {
      this.next_action.push(...response);
      this.nextActionLoad = false;
      if (this.editFlag == true) {
        this.loader.hide();
        this.uptLT = this.euptLT;
        console.log(this.uptLT);
        $('#Edit_modal').modal('show');
      }
    });
  }

  /////////////edit/////////////
  onEdit(item) {
    console.log(item);
    this.euptLT = item;

    this.editFlag = true;
    this.loader.show();
    this.onChangePD(item.property_developer);

  }

  onUpdate(f) {
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      this.addform(f.form.value.id, f);
    }
  }

  ///////////add /////////
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');
    this.editFlag = false;
  }
  onAdd(f) {
    console.log(f);
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      const randomnumber = Math.floor(Math.random() * 999999999);
      this.addform(randomnumber.toString(), f)
    }
  }
  addform(id, f) {
    console.log(f.form.value);
    this.loader.show();
    f.form.value.id = id,
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    this.putMyInit.body = f.form.value;
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      if (this.editFlag == false) {
        this.rows.unshift(f.form.value);
        this.rows = this.rows;
        this.addMsg = 'Workflow Added Successfully!';
        $('#add_modal').modal('hide');
      } else {
        this.addMsg = "Updated Successfully."
        $('#Edit_modal').modal('hide');
        this.editFlag = false;
      }
      console.log('\'type of unit\' component-> Action added Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.addMsg = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'type of unit\' component-> Error in creating new Action!');
      console.log(error.response);
    });


  }
  /////////////////////////////////////////////SEARCH Functions//////////////////////////////////////////////
  searchRecordLocally(f) {
    this.scanResults = "in progress";
    let Filtered = this.projectService.searchRecordLocally(f, this.residentsList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    this.totalList = Object.keys(this.rows).length;

  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAllRecords();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.residentsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.residentsList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }

  ///////////////deletion///////////

  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAllRecords();
  }
}
