import { Component, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { AppService } from 'src/app/app.service';
import { CookieService } from 'ngx-cookie-service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { Router, ActivatedRoute, UrlSerializer } from '@angular/router';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { UploadFileService } from 'src/app/services/upload-file.service';


declare const $: any;
type AOA = any[][];

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  providers: [DatePipe]

})
export class ProjectComponent implements OnInit {

 
  /////////////QR varaibles////////
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://www.properly.asia/';

  /////////////Alert varaible////////
  PropertyAdded = '';
  PropertyUpdated = '';
  Results = '';
  scan: boolean = false;
  scanResults = '';
  ///////////////title////////////
  title = 'Properties';
  /////////permission variable//////
  permissions_edit = false;
  permissions_delete = false;
  permissions_view = false;
  permissions_create = false;
  ///////////////total list varaiblle///////////
  totalList: any;

  ///////////////////add property button varaibles//
  properties_add_property = 'Add Property';

  ///////////search varaibles////////

  searchT: any = [];
  properties_property_id = 'Property ID';
  properties_house_owner = 'House Owner (Resident)';
  properties_house_number = 'House Number';
  properties_street = 'Street';
  properties_type_of_unit = 'Type of Unit';
  properties_precinct = 'Precincts/Projects';
  properties_main_contractor = 'Main Contractor';
  precinctsList = [];
  propertiesStreetsList = [];
  propertiesHouseNumberList = [];
  public MainContractors = [];
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  //////////////////////get residents variables///////////////
  public residents = [];
  propertiesPrecinctList = [];
  public rows = [];
  public srch = [];
  propertiesList = [];
  ////////////get property variabales//////
  public AllPd = [];
  public AllPdList = [];

  ///////////////////create project variables/////////////////////

  properties_create_property = 'Create Property';
  properties_construction_no = 'Construction No';
  public addP: any = [];
  sub_precinctsList = [];
  sub_precinctsListOrignal = [];
  properties_sub_precinct = 'Sub-Precincts/Projects';
  properties_township = 'Township';
  properties_city = 'City';
  properties_postal_code = 'Postal Code';
  properties_state = 'State';
  properties_country = 'Country';
  public countryList = [];
  public malaysiaStatesList = [];

  properties_property_developer = 'Client';
  properties_latitude = 'Latitude';
  properties_longitude = 'Longitude';
  properties_type_of_contract = 'Type of Contract';
  properties_contract_start_date = 'Contract Start Date';
  properties_contract_end_date = 'Contract End Date';
  properties_remarks = 'Remarks';
  properties_upload_floor_plans = 'Upload Floor Plans (Pdf Only)';
  addPropertyValidation = false;
  selectedPics: FileList;
  eventPics;
  havePictures: boolean = false;
  showSuccessMsg = false;
  showErrorMsg = false;
  uploadPicsFolder = '/General/Properties';
  logoPic;
  pictures = [];
  currentDate: string;
  properties_address = 'Address';
  ////////////////view variables/////////
  totalListDocuments: any = 'loading...';
  public viewP: any = [];

  public rowsDocuments = [];
  properties_print_qr_code = 'Print QR Code';
  ////////////delete///////////////
  delete_results;

  generals_delete = 'Delete';

  ///////////edit variable///////////
  generals_edit = 'Edit'


  /////////////////////////////////languages//////////
  properties_edit_property = 'Edit Property';
  properties_update_property = 'Update Property';

  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';
  public languages = [];


  ////////////////////////////////edit variables////////////////////////
  public uptP: any = [];
  showSuccessMsgUpdate = false;

  ////////////////////API//////////////////
  apiNameResidents = 'residents'; // replace this with your api name.
  apiPathResidents = '/residents'; // replace this with the path you have configured on your API

  getResidentsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiName = 'properties'; // replace this with your api name.
  apiPath = '/properties'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';

  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamePrecincts = 'settingsPrecincts'; // replace this with your api name.
  pathPrecincts = '/settingsPrecincts'; // replace this with the path you have configured on your API
  putMyInitPrecincts = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInitPrecincts = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameDocuments = 'residentsDocuments';
  apiPathDocuments = '/residentsDocuments';
  getMyInitDocuments = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameSubPrecincts = 'settingsSubPrecincts'; // replace this with your api name.
  pathSubPrecincts = '/settingsSubPrecincts'; // replace this with the path you have configured on your API
  putMyInitSubPrecincts = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInitSubPrecincts = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';

  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameDelete = 'properties'; // replace this with your api name.
  pathDelete = '/properties/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  constructor(private router: Router, private projectService: AppService, private loader: LoaderService, private datePipe: DatePipe, private uploadService: UploadFileService) {
    this.countryList = projectService.countryList;
    this.malaysiaStatesList = projectService.malaysiaStates;
  }

  ngOnInit() {
    this.getResidents();
    this.getProperties();
    this.getContractors();
    this.getAllPrecincts();
    this.getAllSubPrecincts();
    this.getPropertyDevelopers();
    this.getPermissions();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    const randomnumber = Math.floor(Math.random() * 999999999);
    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      this.setLanguage(language);
    }
    this.addP = {
      'id': 'KOS-' + randomnumber,
      'address': 'Auto Generated'
    };

  }
  /////////get residents///////////
  getResidents() {
    this.residents.push('');
    var t = [];

    API.get(this.apiNameResidents, this.apiPathResidents, this.getResidentsInit).then(response => {
      response.data.forEach(function (arrayItem) {
        var y = arrayItem.name;
        t.push(y);
      });
      this.residents.push(...t);
      this.residents = this.residents.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.residents.sort();
      console.log('\'project\' component-> All Residents Retrieved Successfully!');
      console.log(response);
      // this.viewProfileFromTickets();
    }).catch(error => {
      console.log('\'project\' component-> Error in Retreiving All Residents from server!');
      console.log(error);
    });
  }
  /////////////////get properties///////////////
  getProperties() {
    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesPrecinctList.push('');

    this.scan = true;
    this.scanResults = "In Progress...";
    API.get(this.apiName, this.apiPath, this.getMyInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.scanResults = "Completed.";
      this.scan = false;
      this.srch.push(...this.rows);

      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]);
        this.propertiesList = response.data;
        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();
        localStorage.setItem('propertiesPrecinctList', JSON.stringify(this.propertiesPrecinctList));
        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();
        localStorage.setItem('propertiesHouseNumberList', JSON.stringify(this.propertiesHouseNumberList));

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();
        localStorage.setItem('propertiesStreetsList', JSON.stringify(this.propertiesStreetsList));
      }

      console.log('\'project\' component-> All properties Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All properties from server!');
      console.log(error.response);
    });
  }
  ///////////////////get contractors///////////////////
  getContractors() {
    this.MainContractors.push('');
    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {
      this.MainContractors.push(...response.data);
      console.log('\'Properties\' component-> All Contractors Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      console.log('\'Properties\' component-> Error in Retreiving All Contractors from server!');
      console.log(error.response);
    });
  }
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  /////////////get percenlit/////////////////////
  getAllPrecincts() {
    API.get(this.apiNamePrecincts, this.pathPrecincts, this.getMyInitPrecincts).then(response => {
      this.precinctsList = response;
      this.precinctsList = this.precinctsList.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.precinctsList.sort();
      console.log('\'project\' component-> All  precincts Retrieved Successfully!');
      console.log(this.precinctsList);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  /////////////////search record////////.///////////////////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.propertiesList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.propertiesList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.propertiesList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }

  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getProperties();
  }
  ////////////////onchange methods/////////////////
  onChangePrecinct(precinct) {
    //console.log(precinct);
    //console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.projectService.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();
  }

  onChangeStreet(street) {
    //console.log(street);
    //console.log(this.propertiesList);
    var p = [];
    var t = [];
    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.projectService.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      var y = arrayItem.main_contractor;
      p.push(x);
      t.push(y);
    });

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push('');
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();
  }
  onChangePrecinctGetSub(precinct) {
    if (precinct == "" || precinct == undefined) {
      this.sub_precinctsList.push(...this.sub_precinctsListOrignal);
    } else {
      console.log(precinct);
      console.log(this.sub_precinctsList);
      var p = [];
      const filters = { precinct: [precinct] },
        results = this.sub_precinctsListOrignal,
        Filtered = this.projectService.multiFilter(results, filters);
      console.log(Filtered);
      this.sub_precinctsList.splice(0, this.sub_precinctsList.length);
      this.sub_precinctsList.push(...Filtered);
      this.sub_precinctsList = this.sub_precinctsList.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.sub_precinctsList.sort();
      console.log(this.sub_precinctsList);
    }
  }
  getAllSubPrecincts() {
    API.get(this.apiNameSubPrecincts, this.pathSubPrecincts, this.getMyInitSubPrecincts).then(response => {
      this.sub_precinctsList = response;
      this.sub_precinctsListOrignal = response;
      console.log('\'project\' component-> All  Sub precincts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All Sub precincts from server!');
      console.log(error.response);
    });
  }

  //////////////////add property function//////////
  uploadAttachments(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }
  addCatWithPics(f) {
    this.addPropertyValidation = false;
    if (f.invalid === true) {
      console.log(f.form.value);
      this.addPropertyValidation = true;
    } else {
      this.loader.show();
      if (this.havePictures) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.addProperties(f);
          this.havePictures = false;
        }).catch(error => {
          console.log(error);
          this.havePictures = false;
        });
      } else {
        this.addProperties(f);
      }
    }
  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          console.log(Response);
          resolve("Add  Now!")
          this.eventPics.srcElement.value = null;
          resolve(Response);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else {
        resolve("Add  Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.logoPic = Response.Location;
          this.pictures.push(Response.Location);
          resolve("All pics uploaded");
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }

  addProperties(f) {
    var contract_start_date = '';
    var contract_end_date = '';

    if (f.form.get('contract_start_date').value == undefined) {
    } else {
      contract_start_date = this.projectService.reformatDate(f.form.get('contract_start_date').value);
    }
    if (f.form.get('contract_end_date').value == undefined) {
    } else {
      contract_end_date = this.projectService.reformatDate(f.form.get('contract_end_date').value);
    }


    if (f.invalid === true) {
      console.log(f.form.value);
      this.addPropertyValidation = true;
    } else {
      f.form.value.remarks = f.form.value.remarks.split(/\s+/).slice(0, 25).join(' ');
      f.form.value.address = f.form.get('house_number').value + '-' + f.form.get('street').value + '-' + f.form.get('precinct').value,
        f.form.value.contract_start_date = contract_start_date,
        f.form.value.contract_end_date = contract_end_date,
        f.form.value.floor_plans = this.pictures.join(";")
      this.putMyInit.body = f.form.value;
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.apiPath, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.PropertyAdded = 'Property Added Successfully! ' + f.form.get('id').value;
        this.rows.unshift(f.form.value);
        this.rows = this.rows;
        $('#create_project').modal('hide');
        console.log('\'project\' component-> New Property Created Successfully!');
        console.log(response);
        this.loader.hide();
        this.getProperties();
      }).catch(error => {
        $('#create_project').modal('hide');
        this.showErrorMsg = true;
        this.PropertyAdded = 'Property Creation Failed, Please Retry!';
        this.loader.hide();
        console.log('\'project\' component-> Error in creating new Property!');
        console.log(error.response);
      });
    }
  }
  //////////////////view profile////////////////////////////////////////////

  viewProfile(item) {
    this.totalListDocuments = 'loading...';
    this.searchDocuments(item.id);

    let temp;
    // console.log(item);
    temp = item;
    this.viewP = temp;
  }

  searchDocuments(property_id) {
    let srchFiltersValues = [];
    let srchFiltersNames = [];

    if (property_id != undefined) {
      srchFiltersNames.push('property_id');
      srchFiltersValues.push(property_id);
    }
    this.projectService.scanContainsAnd(this.apiNameDocuments, this.apiPathDocuments, srchFiltersNames, srchFiltersValues).then(response => {
      this.rowsDocuments.push(...response.Items);
      this.totalListDocuments = Object.keys(this.rowsDocuments).length;
    }).catch(error => {
      console.log(error);
    });
  }
  //////////////////documwnts upload///////////////////////////////////////
  uploadDocuments(view) {
    console.log(view);
    localStorage.setItem('temp_precinct', view.precinct);
    localStorage.setItem('temp_street', view.street);
    localStorage.setItem('temp_house_number', view.house_number);
    localStorage.setItem('temp_house_owner', view.house_owner);
    localStorage.setItem('temp_property_id', view.id);

    this.router.navigate(['properties/upload-documents']);
  }
  viewFloorPlan(view) {
    localStorage.setItem('floor_plan_id', view.floor_plan_id);
    this.router.navigate(['properties/floorplans']);
  }
  viewFloorPlanP(view) {
    localStorage.setItem('floor_plan_id', view.floor_plan_id_p);
    this.router.navigate(['properties/floorplans']);
  }
  viewAdditionalDocuments(view) {
    console.log(view);
    localStorage.setItem('temp_property_id', view.id);
    this.router.navigate(['properties/uploaded-documents']);
  }
  /////////////////////delete result/////////////////////////////////////////////
  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getProperties();
  }
  /////////////////////////set languages////////////
  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.title = response.Items[0].properties;
      this.properties_property_id = response.Items[0].properties_property_id;
      this.properties_address = response.Items[0].properties_address;
      this.properties_house_owner = response.Items[0].properties_house_owner;
      this.properties_construction_no = response.Items[0].properties_construction_no;
      this.properties_house_number = response.Items[0].properties_house_number;
      this.properties_street = response.Items[0].properties_street;
      this.properties_type_of_unit = response.Items[0].properties_type_of_unit;
      this.properties_precinct = response.Items[0].properties_precinct;
      this.properties_sub_precinct = response.Items[0].properties_sub_precinct;
      this.properties_township = response.Items[0].properties_township;
      this.properties_city = response.Items[0].properties_city;
      this.properties_postal_code = response.Items[0].properties_postal_code;
      this.properties_state = response.Items[0].properties_state;
      this.properties_country = response.Items[0].properties_country;
      this.properties_property_developer = response.Items[0].properties_property_developer;
      this.properties_main_contractor = response.Items[0].properties_main_contractor;
      this.properties_latitude = response.Items[0].properties_latitude;
      this.properties_longitude = response.Items[0].properties_longitude;
      this.properties_type_of_contract = response.Items[0].properties_type_of_contract;
      this.properties_contract_start_date = response.Items[0].properties_contract_start_date;
      this.properties_contract_end_date = response.Items[0].properties_contract_end_date;
      this.properties_remarks = response.Items[0].properties_remarks;
      this.properties_upload_floor_plans = response.Items[0].properties_upload_floor_plans;

      this.properties_create_property = response.Items[0].properties_create_property;
      this.properties_add_property = response.Items[0].properties_add_property;
      this.properties_edit_property = response.Items[0].properties_edit_property;
      this.properties_update_property = response.Items[0].properties_update_property;
      this.properties_print_qr_code = response.Items[0].properties_print_qr_code;


      this.generals_search = response.Items[0].generals_search;
      this.generals_clear_search = response.Items[0].generals_clear_search;
      this.generals_view_details = response.Items[0].generals_view_details;
      this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
      this.generals_edit = response.Items[0].generals_edit;
      this.generals_delete = response.Items[0].generals_delete;
      this.generals_uploaded_files = response.Items[0].generals_uploaded_files;
      this.generals_actions = response.Items[0].generals_actions;

    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }
  ///////////////////edit///////////////////////////////
  onEdit(item) {
    console.log(item);
    this.uptP = Object.assign({}, item);
    if (this.uptP.contract_start_date != undefined) {
      var contract_start_dateParts = item.contract_start_date.split("/");
      this.uptP.contract_start_date = contract_start_dateParts[2] + "-" + contract_start_dateParts[1] + "-" + contract_start_dateParts[0];

    } if (this.uptP.contract_end_date != undefined) {
      var contract_end_dateParts = item.contract_end_date.split("/");
      this.uptP.contract_end_date = contract_end_dateParts[2] + "-" + contract_end_dateParts[1] + "-" + contract_end_dateParts[0];
    }

  }

  editView(id) {
    let temp;
    // console.log(id);
    temp = this.rows.find(function (item, i) {
      return item.projectID === id;
    });
    this.uptP = temp;
    $('#view_project').modal('hide');
    $('#edit_project').modal('show');
  }
  updateProperties(f) {
    var contract_start_date = '';
    var contract_end_date = '';

    if (f.form.get('contract_start_date').value == undefined) {
    } else {
      contract_start_date = this.projectService.reformatDate(f.form.get('contract_start_date').value);
    }
    if (f.form.get('contract_end_date').value == undefined) {
    } else {
      contract_end_date = this.projectService.reformatDate(f.form.get('contract_end_date').value);
    }


    if (f.invalid === true) {
      console.log(f.form.value);
      this.addPropertyValidation = true;
    } else {
      f.form.value.remarks = f.form.value.remarks.split(/\s+/).slice(0, 25).join(' ');

      f.form.value.remarks = f.form.value.remarks.split(/\s+/).slice(0, 25).join(' ');
      f.form.value.address = f.form.get('house_number').value + '-' + f.form.get('street').value + '-' + f.form.get('precinct').value,
        f.form.value.contract_start_date = contract_start_date,
        f.form.value.contract_end_date = contract_end_date,
        f.form.value.floor_plans = this.pictures.join(";")
      this.putMyInit.body = f.form.value;
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.apiPath, this.putMyInit).then(response => {
        this.showSuccessMsgUpdate = true;
        this.PropertyUpdated = 'Property Updated Successfully!';
        this.rows = this.rows;
        console.log('\'project\' component-> New Property Updated Successfully!');
        console.log(response);
        this.loader.hide();
        this.getProperties();
      }).catch(error => {
        this.showSuccessMsgUpdate = true;
        this.PropertyUpdated = 'Property Update Failed, Please Retry!';
        this.loader.hide();
        console.log('\'project\' component-> Error in updating Property!');
        console.log(error.response);
      });
    }

  }

  updateRecordAndPic(f) {
    this.addPropertyValidation = false;
    if (f.invalid === true) {
      console.log(f.form.value);
      this.addPropertyValidation = true;
    } else {
      this.loader.show();
      if (this.havePictures) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.updateProperties(f);
          this.havePictures = false;
        }).catch(error => {
          console.log(error);
          this.havePictures = false;
        });
      } else {
        this.updateProperties(f);
      }
    }
  }
  /////////////////////////////////////Permissions//////////////////////////////////////////////

  getPermissions() {
    this.projectService.getPermissions().then(response => {
      this.permissions_view = response[0].properties_view;
      this.permissions_create = response[0].properties_create;
      this.permissions_edit = response[0].properties_update;
      this.permissions_delete = response[0].properties_delete;
    }).catch(error => {
      console.log(error);
    });
  }

  ngOnDestroy() {
    $('#create_project').modal('hide');
    $('#view_project').modal('hide');
    $('#edit_project').modal('hide');

  }

}
