import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';

@Component({
  selector: 'app-resident-documents',
  templateUrl: './resident-documents.component.html',
  styleUrls: ['./resident-documents.component.css'],
  providers: [DatePipe]

})
export class ResidentDocumentsComponent implements OnInit {
   ///////////////alert variable//////
   showErrorMsg = false;
   Submitted = '';
   addValidation = false;
   showSuccessMsg = false;
   havePictures: boolean = false;
   haveFiles: boolean = false;
   ////////////object/////////////
   addT: any = [];
   ///////////////uploading variables///////
   eventPics;
   eventFiles;
   selectedPics: FileList;
   selectedFiles: FileList;
   pictures = [];
   files = [];
   currentDate: string;
   currentTime: string;
   /////////////api//////////////
   putMyInit = {
     body: {}, // replace this with attributes you need
     headers: {} // OPTIONAL
   };
   apiName = 'residentsDocuments'; // replace this with your api name.
   path = '/residentsDocuments'; // replace this with the path you have configured on your API
 
   constructor(private datePipe: DatePipe,private loader: LoaderService, private uploadService: UploadFileService) { }
 
 
   ngOnInit() {
     console.log(localStorage.getItem('temp_precinct')+"   "+localStorage.getItem('temp_street')+"   "+localStorage.getItem('temp_house_number')+"             "+localStorage.getItem('temp_house_owner')+"     "+localStorage.getItem('temp_property_id')
     )
     this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
     this.addT = {
       'id': 'Auto',
       'creation_date': this.currentDate,
       'created_by': localStorage.getItem('nick_name'),
       'property_developer':localStorage.getItem('property_developer'),
       'house_owner':localStorage.getItem('temp_house_owner'),
       'precinct':localStorage.getItem('temp_precinct'),
       'street':localStorage.getItem('temp_street'),
       'house_number':localStorage.getItem('temp_house_number'),
       'property_id':localStorage.getItem('temp_property_id'),
 
     };
 
     
     localStorage.removeItem("temp_precinct");
     localStorage.removeItem("temp_street");
     localStorage.removeItem("temp_house_number");
     localStorage.removeItem("temp_house_owner");
     localStorage.removeItem("temp_property_id");
 
    
 
   }
   ////////////upload files////////////////
   onClickSubmit(f) {
     if (f.invalid === true) {
       this.addValidation = true;
     } else {
       this.loader.show();
       const randomnumber = Math.floor(Math.random() * 999999999);
       if (this.haveFiles || this.havePictures) {
         this.uploadPicsAndFiles().then(Response => {
           console.log(Response);
           this.add(f, randomnumber);
         }).catch(error => {
           console.log(error);
         });
       } else {
         this.add(f, randomnumber);
       }
     }
   }
   uploadPicsAndFiles() {
     return new Promise<string>((resolve, reject) => {
 
       if (this.havePictures) {
         this.uploadPics().then(Response => {
           console.log(Response);
           this.eventPics.srcElement.value = null;
           if (this.haveFiles) {
             this.uploadFiles().then(Response => {
               this.eventFiles.srcElement.value = null;
               console.log(Response);
               resolve("Add Now!")
             }).catch(error => {
               console.log(error);
               reject(error);
             });
           } else {
             resolve("Add Now!")
           }
         }).catch(error => {
           console.log(error);
           reject(error);
         });
       } else if (this.haveFiles) {
         this.uploadFiles().then(Response => {
           console.log(Response);
           this.eventFiles.srcElement.value = null;
           if (this.havePictures) {
             this.uploadPics().then(Response => {
               this.eventPics.srcElement.value = null;
               console.log(Response);
               resolve("Add Ticket Now!")
             }).catch(error => {
               console.log(error);
               reject(error);
             });
           } else {
             resolve("Add Now!")
           }
         }).catch(error => {
           console.log(error);
           reject(error);
         });
       } else {
         resolve("Add Now!")
       }
     });
   }
 
   uploadPics() {
     return new Promise<string>((resolve, reject) => {
       for (let i = 0; i < this.selectedPics.length; i++) {
         const file = this.selectedPics.item(i);
         this.uploadService.uploadfile(file, "").then(Response => {
           console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
           this.pictures.push(Response.Location);
           if (this.pictures.length === this.selectedPics.length) {
             resolve("All pics uploaded");
           }
         }).catch(error => {
           console.log("Error in uploading pic" + error);
           reject(error);
         });
       }
     });
   }
 
   uploadFiles() {
     return new Promise<string>((resolve, reject) => {
       for (let i = 0; i < this.selectedFiles.length; i++) {
         const file = this.selectedFiles.item(i);
         this.uploadService.uploadfile(file, "").then(Response => {
           console.log("File Uploaded Successfully " + JSON.stringify(Response));
           this.files.push(Response.Location);
           if (this.files.length === this.selectedFiles.length) {
             resolve("All Files uploaded");
           }
         }).catch(error => {
           console.log("Error in uploading file" + error);
           reject(error);
         });
       }
     });
   }
   add(f, randomnumber) {   
       f.form.value.id= randomnumber.toString(),
       f.form.value. creation_date= this.currentDate,
       f.form.value.images= this.pictures,
       f.form.value.documents= this.files
     this.putMyInit.body = f.form.value;
     console.log(f.form.value);
     Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
     API.put(this.apiName, this.path, this.putMyInit).then(response => {
       this.showSuccessMsg = true;
       this.addValidation = false;
       this.Submitted = 'Documents Submitted Successfully. ID: ' + randomnumber;
       console.log('\'Documents\' component-> New Documents Created Successfully!');
       console.log(response);
       this.havePictures = false;
       this.haveFiles = false;
       this.loader.hide();
     }).catch(error => {
       this.showErrorMsg = true;
       this.Submitted = 'Documents Submission Failed, Please Retry!';
       console.log('\'tickets\' component-> Error in creating new Documents!');
       console.log(error);
       this.loader.hide();
     });
   
 }
 uploadAllPictures(event) {
   this.selectedPics = event.target.files;
   this.eventPics = event;
   this.havePictures = true;
 }
 uploadAllFiles(event) {
   this.selectedFiles = event.target.files;
   this.eventFiles = event;
   this.haveFiles = true;
 }

}
