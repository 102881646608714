import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { API } from 'aws-amplify';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare const $: any;
type AOA = any[][];

@Component({
  selector: 'app-all-residents',
  templateUrl: './all-residents.component.html',
  styleUrls: ['./all-residents.component.css'],
  providers: [DatePipe]

})
export class AllEmployeesComponent implements OnInit {

 ////////////////////////////get call variables////////////////////////////////////////////////////////
 public viewEmp: any = [];
 profile_pic;
 scanResults = '';
 scan: boolean = false;
 public rows = [];
 searchT: any = [];
 residentsList = [];
 public countryList = []
 public timeList = [];
 totalList: any;
 selectedPics: FileList;
 havePictures: boolean = false;
 pictures;
 apiName = 'residents'; // replace this with your api name.
 path = '/residents'; // replace this with the path you have configured on your API
 pathDelete = '/residents/delete'; // replace this with the path you have configured on your API
 myInit = {
   body: {}, // replace this with attributes you need
   headers: {} // OPTIONAL
 };

 myInit2 = {
   headers: {}, // OPTIONAL
   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
   queryStringParameters: {  // OPTIONAL
     name: 'param'
   }
 };

 apiNamelanguages = 'languages'; // replace this with your api name.
 pathlanguages = '/languages'; // replace this with the path you have configured on your API


 ////////////////////////////get call variables End////////////////////////////////////////////////////////
 residents_resident_status = 'Resident Status';
 residents_name = 'Name';
 residents_email = 'Email';
 residents_telephone_1 = 'Telephone 1';
 generals_search = 'Search';
 generals_clear_search = 'Clear Search';
 residents_telephone_2 = 'Telephone 2';
 residents_ic_no = 'IC No';
 generals_actions = 'Actions';
 generals_edit = 'Edit'
 generals_delete = 'Delete';
 permissions_edit = false;
 permissions_delete = false;
 title = 'All Residents';
 residents_resident_id = 'Resident ID';
 residents_password = 'Password';
 residents_confirm_password = 'Confirm Password';
 residents_nationality = 'Nationality';
 residents_registration_date = 'Registration Date';
 residents_gender = 'Gender';
 residents_resident_remarks = 'Resident Remarks';
 residents_upload_profile_picture = 'Upload Profile Picture';
 users_prefered_language = "Prefered Language";
 residents_create_resident = 'Create Resident';
 residents_add_resident = 'Add Resident';
 residents_edit_resident = 'Edit Resident';
 residents_update_resident = 'Update Resident';
 generals_view_details = 'View Details';
 generals_uploaded_images = 'Uploaded Images'
 generals_uploaded_files = 'Uploaded Files';
 generals_creation_date = 'Creation Date';
 public genderOptions = [];
 unsortedrows = [];
 public languages = [];
 showSuccessMsg = false;
 showErrorMsg = false;
 ResidentAdded = '';
 addEmployeeValidation = false;
 passwordsMismatch = false;
 eventPics;
 uploadPicsFolder = '/General/ResidentProfile';
 currentDate: string;
 public addEmp: any = {};
 Results = '';
 public uptEmp: any = {};
 permissions_userlevel;
 permissions_view = false;
 permissions_create = false;
 uptEmployeeValidation = false;
 ProjectServices: any;
 delete_results;
 deleteID;



 constructor(private loader: LoaderService, private datePipe: DatePipe, private projectService: AppService, private appService: AppService, private uploadService: UploadFileService
 ) {
   this.countryList = projectService.countryList;
   this.timeList = projectService.timeList;
   this.genderOptions = appService.genderOptions;
 }
 ngOnInit() {
   this.getPermissions();
   this.retriveLanguages();
   this.getAllRecords();
   this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
   var language = localStorage.getItem('language');
   if (language != 'undefined' && language != 'English') {
     this.setLanguage(language);
   }

 }

 /////////////////////////////////////////////Get Call//////////////////////////////////////////////


 getAllRecords() {
   this.scan = true;
   this.scanResults = "Getting Residents, Please Wait."
   this.residentsList.splice(0,this.residentsList.length);
   API.get(this.apiName, this.path, this.myInit2).then(response => {
     this.rows.splice(0, this.rows.length);
     this.residentsList = response.data;
     this.rows.push(...response.data);
     this.totalList = Object.keys(this.rows).length;
     this.loader.hide();
     this.scan = false;
     console.log('\'all-residents\' component-> All Residents Retrieved Successfully!');
     console.log(response);
   }).catch(error => {
     this.loader.hide();
     console.log('\'all-residents\' component-> Error in Retreiving All Residents from server!');
     console.log(error);
   });
 }


 /////////////////////////////////////////////SEARCH Functions//////////////////////////////////////////////
 searchRecordLocally(f) {
   let Filtered = this.projectService.searchRecordLocally(f, this.residentsList);
   this.rows.splice(0, this.rows.length);
   this.rows.push(...Filtered);
   this.totalList = Object.keys(this.rows).length;
 }
 clearSearch() {
  this.rows.splice(0,this.rows.length);
   this.searchT = Object.keys(this.searchT).reduce(
     (accumulator, current) => {
       accumulator[current] = null;
       return accumulator
     }, {});
   this.getAllRecords();
 }
 instantSearch(val, name) {
   this.rows.splice(0, this.rows.length);
   this.rows.push(...this.projectService.instantSearch(val, this.residentsList, name, ''))
   this.totalList = Object.keys(this.rows).length;
 }
 instantSearchDropDown(val, name) {
   this.rows.splice(0, this.rows.length);
   this.rows.push(...this.projectService.instantSearch(val, this.residentsList, name, 'All'))
   this.totalList = Object.keys(this.rows).length;
 }


 ////////////////////////check languages///////////////////////////////////////////////////////////////////////

 setLanguage(language) {
   this.loader.show();
   const srchFiltersValues = [];
   const srchFiltersNames = [];

   srchFiltersNames.push('language');
   srchFiltersValues.push(language);

   this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
     this.loader.hide();
     this.title = response.Items[0].residents;
     this.residents_resident_id = response.Items[0].residents_resident_id;
     this.residents_ic_no = response.Items[0].residents_ic_no;
     this.residents_name = response.Items[0].residents_name;
     this.residents_email = response.Items[0].residents_email;
     this.residents_password = response.Items[0].residents_password;
     this.residents_confirm_password = response.Items[0].residents_confirm_password;
     this.residents_nationality = response.Items[0].residents_nationality;
     this.residents_registration_date = response.Items[0].residents_registration_date;
     this.residents_gender = response.Items[0].residents_gender;
     this.residents_resident_status = response.Items[0].residents_resident_status;
     this.residents_telephone_1 = response.Items[0].residents_telephone_1;
     this.residents_telephone_2 = response.Items[0].residents_telephone_2;
     this.residents_resident_remarks = response.Items[0].residents_resident_remarks;
     this.residents_upload_profile_picture = response.Items[0].residents_upload_profile_picture;
     this.users_prefered_language = response.Items[0].users_prefered_language;

     this.residents_create_resident = response.Items[0].residents_create_resident;
     this.residents_add_resident = response.Items[0].residents_add_resident;
     this.residents_edit_resident = response.Items[0].residents_edit_resident;
     this.residents_update_resident = response.Items[0].residents_update_resident;


     this.generals_search = response.Items[0].generals_search;
     this.generals_clear_search = response.Items[0].generals_clear_search;
     this.generals_view_details = response.Items[0].generals_view_details;
     this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
     this.generals_edit = response.Items[0].generals_edit;
     this.generals_delete = response.Items[0].generals_delete;
     this.generals_uploaded_files = response.Items[0].generals_uploaded_files;
     this.generals_actions = response.Items[0].generals_actions;
     this.generals_creation_date = response.Items[0].generals_creation_date;
   }).catch(error => {
     this.loader.hide();
     console.log(error);
   });
 }
 retriveLanguages() {
   var status = 'Completed';
   const srchFiltersValues = [];
   const srchFiltersNames = [];

   srchFiltersNames.push('status');
   srchFiltersValues.push(status);

   this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
     this.loader.hide();
     this.languages = response.Items;
   }).catch(error => {
     console.log(error);
   });
 }

 ////////////////////////Upload Files///////////////////////////////////////////////////////////


 uploadAllPictures(event) {
   this.selectedPics = event.target.files;
   this.eventPics = event;
   this.havePictures = true;
 }

 addUserWithPics(f) {
   this.addEmployeeValidation = false;
   this.passwordsMismatch = false;
   if (f.invalid == true) {
     this.addEmployeeValidation = true;
   } else if (f.form.get('password').value != f.form.get('confirm_password').value) {
     this.passwordsMismatch = true;
   } else {
     this.loader.show();
     if (this.havePictures) {
       this.uploadPicsAndFiles().then(Response => {
         console.log(Response);
         this.addResident(f);
         this.havePictures = false;
       }).catch(error => {
         console.log(error);
         this.havePictures = false;
       });
     } else {
       this.addResident(f);
     }
   }
 }
 uploadPicsAndFiles() {
   return new Promise<string>((resolve, reject) => {

     if (this.havePictures) {
       this.uploadPics().then(Response => {
         console.log(Response);
         resolve("Add  Now!")
         this.eventPics.srcElement.value = null;
         resolve(Response);
       }).catch(error => {
         console.log(error);
         reject(error);
       });
     } else {
       resolve("Add  Now!")
     }
   });
 }
 uploadPics() {
   return new Promise<string>((resolve, reject) => {
     for (let i = 0; i < this.selectedPics.length; i++) {
       const file = this.selectedPics.item(i);
       this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
         console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
         this.pictures = Response.Location;
         resolve("All pics uploaded");
       }).catch(error => {
         console.log("Error in uploading pic" + error);
         reject(error);
       });
     }
   });
 }

 ////////////////////////ADD Resident///////////////////////////////////////////////////////////


 addReset() {
   this.showSuccessMsg = false;
   this.pictures = '';
   const randomnumber = Math.floor(Math.random() * 999999999);
   this.addEmployeeValidation = false;
   this.addEmp = {
     id: 'KOS-00' + randomnumber,
   };
   $('#add_resident').modal('show');
 }
 addResident(f) {
   f.form.value.registration_date = this.projectService.reformatDate(f.form.get('registration_date').value);
   f.form.value.creation_date = this.currentDate;
   f.form.value.pictures = this.pictures;
   this.myInit.body = f.form.value;
   console.log(this.pictures);
   Object.keys(this.myInit.body).forEach(key => this.myInit.body[key] === undefined ? delete this.myInit.body[key] : {});
   API.put(this.apiName, this.path, this.myInit).then(response => {
     this.showSuccessMsg = true;
     this.ResidentAdded = 'Resident Added Successfully!';
     this.rows.unshift(f.form.value);
     const index = this.rows.findIndex(function (item, i) {
       return item.id === f.form.get('id').value.toString();
     });

     if (index > -1) {
       this.rows[index] = f.form.value;
     }
     this.rows = this.rows;
     $('#add_resident').modal('hide');
     this.getAllRecords();
     console.log('\'all-residents\' component-> New Resident Created Successfully!');
     console.log(response);
     this.loader.hide();
   }).catch(error => {
     this.showErrorMsg = true;
     this.ResidentAdded = 'Failed, Please Retry or Contact App Support';
     this.loader.hide();
     console.log('\'all-residents\' component-> Error in creating new Resident!');
     console.log(error.response);
   });

 }



 ////////////////////////View Resident///////////////////////////////////////////////////////////


 viewResident(item) {
   console.log(item);
   console.log(this.pictures);
   if (item.pictures !== undefined) {
     this.profile_pic = item.pictures;
   } else {
     this.profile_pic = null;
   }
   this.viewEmp = item;
 }



 ////////////////////////Edit and update Resident///////////////////////////////////////////////////////////

 onEdit(item) {
   if (item.pictures !== undefined) {
     this.pictures = item.pictures;
   }
   this.uptEmp = Object.assign({}, item);
   if (this.uptEmp.registration_date != undefined) {
     var registration_dateParts = item.registration_date.split("/");
     this.uptEmp.registration_date = registration_dateParts[2] + "-" + registration_dateParts[1] + "-" + registration_dateParts[0];
   }
 }
 UpdateUserWithPics(f) {
   console.log(f.form.value);
   this.uptEmployeeValidation = false;
   if (f.invalid == true) {
     this.uptEmployeeValidation = true;
   } else {
     this.loader.show();
     if (this.havePictures) {
       this.uploadPicsAndFiles().then(Response => {
         console.log(Response);
         this.updateResident(f);
         this.havePictures = false;
       }).catch(error => {
         console.log(error);
         this.havePictures = false;
       });
     } else {
       this.updateResident(f);
     }
   }
 }
 updateResident(f) {
   f.form.value.registration_date = this.projectService.reformatDate(f.form.get('registration_date').value);
   f.form.value.creation_date = this.currentDate;
   f.form.value.pictures = this.pictures;
   this.myInit.body = f.form.value;
   API.put(this.apiName, this.path, this.myInit).then(response => {
     this.showSuccessMsg = true;
     this.ResidentAdded = 'Resident Updated Successfully!';
     $('#edit_resident').modal('hide');
     this.getAllRecords();
     console.log('\'all-residents\' component-> New Resident Updated Successfully!');
     console.log(response);
   }).catch(error => {
     this.showErrorMsg = true;
     this.ResidentAdded = 'Failed, Please Retry or Contact App Support';

     this.loader.hide();
     console.log('\'all-residents\' component-> Error in Updation new Resident!');
     console.log(error.response);
   });

 }
 /////////////////////////////////////delete resident//////////////////////////////////////////////

 onDelete(c) {
   this.projectService.onDelete(c);
 }

 deleteConfirmation(){
   this.projectService.deleteConfirmation(this.apiName,this.pathDelete).then(response =>{
     this.delete_results=response;
   }).catch(error =>{
     this.delete_results=error;
   });
 }

 deleteResult(){
   $('#delete_result').modal('hide');
   this.getAllRecords();
 }


 /////////////////////////////////////Permissions//////////////////////////////////////////////

 getPermissions() {
   this.projectService.getPermissions().then(response => {
     this.permissions_view = response[0].residents_view;
     this.permissions_create = response[0].residents_create;
     this.permissions_edit = response[0].residents_update;
     this.permissions_delete = response[0].residents_delete;
   }).catch(error => {
     console.log(error);
   })
 }

}

