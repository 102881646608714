/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_content_delivery_bucket": "properlydashboards3bucket-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d3o9gqxycp9tt1.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-southeast-1:5ca29b39-4048-40b9-8b9c-3562e9264765",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_BpcBVzY9i",
    "aws_user_pools_web_client_id": "d0msksf2mvabgnsrnhe523pnh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "ledTypeOfUnit",
            "endpoint": "https://kbx0brm919.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "LEDlocation",
            "endpoint": "https://44arxq8ifj.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledElement",
            "endpoint": "https://h8o357woxi.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledDefect",
            "endpoint": "https://0yz5ygxs6f.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledCompetencies",
            "endpoint": "https://u7e34l4en6.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledDaysToExecute",
            "endpoint": "https://td34ne48t8.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledPriority",
            "endpoint": "https://fv509g0pp0.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledNextActionCat",
            "endpoint": "https://oi6bocjpz6.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "ledCause",
            "endpoint": "https://s1tml6pif6.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "mpConAdmin",
            "endpoint": "https://h3tfygxd66.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "bookingvenues",
            "endpoint": "https://wpyphoggl5.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "bookings",
            "endpoint": "https://1spftoknz0.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "servicesCategory",
            "endpoint": "https://nmlx1v1rue.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "servicesSubCategory",
            "endpoint": "https://miq5whehi2.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "settingsServices",
            "endpoint": "https://n9htx9nz1i.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "socialLogin",
            "endpoint": "https://8wpl600wdk.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "settingsPrecincts",
            "endpoint": "https://mzm0onlcqd.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "settingsSubPrecincts",
            "endpoint": "https://6qjpoz4x61.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "notifications",
            "endpoint": "https://q5oc5qd8pk.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "invoices",
            "endpoint": "https://w1ga5ukmh1.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "languages",
            "endpoint": "https://wy50danfjk.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "visitors",
            "endpoint": "https://jfvdfki03m.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "tenancymanagment",
            "endpoint": "https://eubaqb6ld1.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "residents",
            "endpoint": "https://75e18o2645.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "users",
            "endpoint": "https://92ntahejp7.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "woapprovallog",
            "endpoint": "https://lncjkiknz0.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "contractors",
            "endpoint": "https://0o82ywnf72.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "propertydevelopers",
            "endpoint": "https://ppiq9v9av0.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "utilities",
            "endpoint": "https://ydzy3zkc77.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "workorders",
            "endpoint": "https://s053unekzj.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "properties",
            "endpoint": "https://o355zm9aw9.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "contentManagment",
            "endpoint": "https://ld5xnhvat1.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "contentManagmentTab",
            "endpoint": "https://1s3qdcpoi1.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "contentManagmentSubTab",
            "endpoint": "https://nimkr9dwc2.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "residentsDocuments",
            "endpoint": "https://n6c57e47o7.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "userlevelandtask",
            "endpoint": "https://6gxne72dmb.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "pltstask",
            "endpoint": "https://9tpaq4deu4.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "taskanduserlevel",
            "endpoint": "https://rqcs3w6v36.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "mobileresidentdocuments",
            "endpoint": "https://2gva2nd6p5.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "leadPropertyDevelopers",
            "endpoint": "https://8g3s0tcx53.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "agents",
            "endpoint": "https://ehnxiygtf1.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "pltsdefect",
            "endpoint": "https://qbeyvy9blf.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
