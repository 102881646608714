
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from '../app.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { IMyOptions } from 'mydatepicker';
import { UploadFileService } from '../services/upload-file.service';
import { Router, ActivatedRoute, UrlSerializer } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AuthService } from 'src/app/auth/auth.service';
import * as XLSX from 'xlsx';
import { exists } from 'fs';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { endTimeRange } from '@angular/core/src/profile/wtf_impl';

const SCRIPT_PATH = 'https://app.smplrspace.com/lib/smplr.js';
declare let smplr: any;
@Component({
  selector: 'app-new-comp',
  templateUrl: './new-comp.component.html',
  styleUrls: ['./new-comp.component.css'],
  providers: [DatePipe],
})
export class NewCompComponent implements OnInit {
  /////////////alert variable//////////////
  showloading: boolean = false;
  showSuccessMsg = false;
  nextActionLoad: Boolean = false;
  TicketSubmitted = '';
  locationload: Boolean = false;
  elementload: Boolean = false;
  defectLoad: Boolean = false;
  goteditFlag: boolean = false;
  competencyLoad: Boolean = false;
  daysToExecuteLoad: Boolean = false;
  addWorkOrderValidation = false;
  priorityLoad: Boolean = false;
  causeLoad: Boolean = false;

  /////////////////add work order variables///////
  add_new_work_orders_add_work_order = 'Add New Work Order';
  add_new_work_orders_created_by = 'Created By';
  add_new_work_orders_work_order_id = 'Work Order ID';
  add_new_work_orders_property_developer = 'Client';
  public propertyDevelopers = [];
  pdCodeList = [];
  addT: any = [];
  edititem;
  public prioritys = [];
  showPD = false;
  add_new_work_orders_floor = 'Floor';
  add_new_work_orders_street = 'Street';
  propertiesStreetsList = [];
  propertiesHouseNumberList = [];
  propertiesPrecinctList = [];
  propertiesList = [];
  add_new_work_orders_location = 'Location';
  public locations = [];
  public selectedElement;
  public elements = [];
  public competencys = [];
  progress = 0;
  public selectedLocation;
  public selectDefect;
  loadsmplrflag: boolean = false;
  public selectedCompetency;
  currentDate: string;
  currentTime: string;
  public contractorsList = [];
  public userLevel;
  public allContractorsList = [];
  public workOrderPriorities = [];
  add_new_work_orders_house_number = 'House Number';
  add_new_work_orders_defect = 'Defect';
  add_new_work_orders_costs = 'Costs';
  add_new_work_orders_competency = 'Competency';
  add_new_work_orders_invoice = 'Invoice';
  add_new_work_orders_days_to_execute = 'Days To Execute';
  add_new_work_orders_charge_to = 'Charge To';
  add_new_work_orders_priority = 'Priority';
  add_new_work_orders_main_contractor = 'Main Contractor';
  add_new_work_orders_next_action = 'Next Action';
  add_new_work_orders_date_scheduled = 'Date Scheduled';
  public workOrderDaysToExecute = [];
  public workOrderCompetencies = [];
  public userNickName;
  public nextActions = [];
  rows = [];
  selectedPics: FileList;
  workorderCounterId;
  public days_to_executes = [];
  public submitterActions = [];
  add_new_work_orders_time_scheduled = 'Time Scheduled';
  add_new_work_orders_am_pm = 'AM/PM';
  add_new_work_orders_upload_documents_pdf_only = 'Upload Documents (PDF Only)';
  add_new_work_orders_create_new_work_order = 'Submit Work Order';
  add_new_work_orders_work_order_description = 'Work Order Description';
  add_new_work_orders_upload_pictures = 'Upload Pictures';
  add_new_work_orders_cause = 'Cause';
  public timeList = [];
  selectedFiles: FileList;
  showErrorMsg = false;
  haveFiles: boolean = false;
  eventFiles;
  havePictures: boolean = false;
  eventPics;
  pictures = [];
  add_new_work_orders_precinct_project = 'Precinct / Project';
  add_new_work_orders_element = 'Element';
  files = [];
  all_work_orders_all_work_orders = 'All Work Orders';
  all_work_orders_print_work_order = 'Print Work Order';
  my_pending_work_orders_my_pending_work_orders = 'My Pending Work Orders';
  my_pending_work_orders_refresh_list = 'Refresh List';
  my_pending_work_orders_add_work_order = 'Add Work Order';
  work_order_details_my_pending_work_orders = 'My Pending Work Orders';
  work_order_details_print = 'Print';
  work_order_details_edit_work_order = 'Edit Work Order';
  work_order_details_remarks_and_approval_log = 'Remarks And Approval Log';
  work_order_details_created_date = 'Created Date';
  work_order_details_created_time = 'Created Time';
  work_order_details_created_by = 'Created By';
  work_order_details_action = 'Action';
  work_order_details_remarks = 'Remarks';
  work_order_details_next_action = 'Next Action';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';
  public languages = [];
  public defects = [];
  public causes = [];
  ////////////extra /////////////
  public super_Admin = 'Super Admin';
  public pla_admin = 'PLA Admin';
  public pla_frontdesk = 'PLA Frontdesk';
  public pla_supervisor = 'PLA Supervisor';
  public pd_admin = 'PD Admin';
  public pd_frontdesk = 'PD Frontdesk';
  public pd_supervisor = 'PD Supervisor';
  public pd_managment = 'PD Management';
  public con_admin = 'CON Admin';
  public con_supervisor = 'CON Supervisor';
  public con_crew = 'CON Crew';
  notificationsUrl = "https://fcm.googleapis.com/fcm/send";
  ////////////////API///////////////////////
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API
  getPropertiessInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameLocation = 'LEDlocation'; // replace this with your api name.
  apipathLocation = '/ledLocation'; // replace this with the path you have configured on your API
  getLocationInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameDaysToExecute = 'ledDaysToExecute'; // replace this with your api name.
  apipathDaysToExecute = '/ledDaysToExecute'; // replace this with the path you have configured on your API
  getDaysToExecuteInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiName = 'workorders'; // replace this with your api name.
  path = '/workorders'; // replace this with the path you have configured on your API
  apiPathUpdate = '/workorders/update'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNamepltstask = 'pltstask'; // replace this with your api name.
  apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API
  getpltstask = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';
  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameNextAction = 'ledCause'; // replace this with your api name.
  apipathNextAction = '/ledCause'; // replace this with the path you have configured on your API
  getNextActionInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNamePriority = 'ledPriority'; // replace this with your api name.
  apipathPriority = '/ledPriority'; // replace this with the path you have configured on your API
  getPriorityInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameUtilities = 'utilities';
  apiPathUtilities = '/utilities';
  getUtilitiesInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameElement = 'ledElement'; // replace this with your api name.
  apipathElement = '/ledElement'; // replace this with the path you have configured on your API
  getElementInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameDefect = 'ledDefect'; // replace this with your api name.
  apipathDefect = '/ledDefect'; // replace this with the path you have configured on your API
  getDefectInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameCompetency = 'ledCompetencies'; // replace this with your api name.
  apipathCompetency = '/ledCompetencies'; // replace this with the path you have configured on your API
  getCompetencyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };


  apiNameNotifications = 'notifications'; // replace this with your api name.
  pathNotifications = '/notifications'; // replace this with the path you have configured on your API
  putMyInitNotifications = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  putUtilitiesInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL                        
  };
  constructor(@Inject(DOCUMENT) private document: Document, public http: HttpClient, private renderer: Renderer2, private loader: LoaderService, private datePipe: DatePipe, private projectService: AppService, private appService: AppService, private uploadService: UploadFileService
  ) {
    this.submitterActions = this.appService.pdAdminActions;
    this.userNickName = localStorage.getItem('nick_name');
    this.workOrderPriorities = this.appService.workOrderPriorities;
    this.workOrderDaysToExecute = this.appService.workOrderDaysToExecute;
    this.workOrderCompetencies = this.appService.workOrderCompetencies;
    this.userLevel = localStorage.getItem('user_level');
    this.timeList = appService.timeList;
  }
  private myDatePickerOptions1: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
  };

  reports = [
    {
      id: 'f3ae406c-5309-57c8-b670-9aa08d0e2c66',
      title: 'Wall crack',
      created_at: '2022-03-12T06:35:15.311Z',
      image: '',
      status: 'pending review',
      position: { levelIndex: 0, x: 29.38179647768483, z: -12.514474283723528, elevation: 0.0010000000474974513 }
    }
  ]
  ngOnInit() {
    if (this.userLevel === 'PLA Admin' || this.userLevel === 'PLA Frontdesk' || this.userLevel === 'Super Admin') {
      this.showPD = true;
    }
    const itemm = localStorage.getItem('itemm');
    localStorage.removeItem('itemm');
    if (itemm != undefined && itemm != null) {
      this.gotedit(JSON.parse(itemm));
    } else {
      this.gotNew();
    }
  }

  gotedit(item) {
    //console.log(item);
    this.add_new_work_orders_add_work_order = 'Edit Work Order'
    this.showloading = true;
    this.loadsmplrflag = true;
    this.progress = 10;
    this.edititem = item;
    this.goteditFlag = true;
    this.getContractors();
    this.onChangetou();
    this.getProperties();
    this.getDynamicNextActions();
    this.getPropertyDevelopers();
  }

  gotNew() {
    this.getContractors();
    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      //console.log(language);
      this.setLanguage(language);
    }
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString();
    const randomnumber = Math.floor(Math.random() * 99999999);
    // //console.log(this.currentDate);
    this.addT = {
      'id': 'Auto',
      'days_open': '0',
      'created_by': localStorage.getItem('nick_name'),
      'property_developer': localStorage.getItem('property_developer')
    };

    this.onChangetou();
    this.getProperties();
    this.getDynamicNextActions();
    this.getPropertyDevelopers();
  }
  /////////get properly developers////////
  getPropertyDevelopers() {
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      const results = response.data.filter(element => {
        return element.pd_name !== undefined && element.pd_name !== "" && element.pd_name !== "New Lead" && element.pd_name !== "" && element.pd_name !== "New Lead";
      });
      this.propertyDevelopers.splice(0, this.propertyDevelopers.length);
      this.propertyDevelopers.push(...results);
      //console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      //console.log(this.propertyDevelopers);
      this.setPdCode();
    }).catch(error => {
      //console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      //console.log(error);
    });
  }
  setPdCode() {
    var pd = localStorage.getItem("property_developer");
    // //console.log(pd);
    // //console.log(this.propertyDevelopers);
    var p = [];
    const filters = { pd_name: [pd] },
      results = this.propertyDevelopers,
      Filtered = this.projectService.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.company_code;
      p.push(x);
    });

    this.pdCodeList.splice(0, this.pdCodeList.length);
    this.pdCodeList.push(...p);
    this.pdCodeList = this.pdCodeList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.pdCodeList.sort();
    // //console.log(this.pdCodeList[0]+'-'+new Date().getFullYear().toString().substr(-2));

  }
  getContractors() {
    this.contractorsList.push('');
    var t = [];
    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {

      response.data.forEach(function (arrayItem) {
        var y = arrayItem.con_name;
        t.push(y);
      });
      this.contractorsList.push(...t);
      this.allContractorsList.push(...t);
      //console.log('\'add-new-work-order\' component-> All Contractors Retrieved Successfully!');
      //console.log(response);
    }).catch(error => {
      //console.log('\'add-new-work-order\' component-> Error in Retreiving All Contractors from server!');
      //console.log(error.response);
    });
  }
  /////////////get properties street list//////////

  getProperties() {
    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesPrecinctList.push('');


    API.get(this.apiNameProperties, this.apiPathProperties, this.getPropertiessInit).then(response => {
      for (let i = 0; i < response.data.length; i++) {
        // //console.log(response.data[i]);
        this.propertiesList = response.data;
        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();
        localStorage.setItem('propertiesPrecinctList', JSON.stringify(this.propertiesPrecinctList));
        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();
        localStorage.setItem('propertiesHouseNumberList', JSON.stringify(this.propertiesHouseNumberList));

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();
        localStorage.setItem('propertiesStreetsList', JSON.stringify(this.propertiesStreetsList));

      }


      //console.log('\'tickets\' component-> All properties Retrieved Successfully!');
      //console.log(response);
    }).catch(error => {

      //console.log('\'tickets\' component-> Error in Retreiving All properties from server!');
      //console.log(error.response);
    });
  }
  onChangePrecinct(precinct) {
    ////console.log(precinct);
    ////console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.projectService.multiFilter(results, filters);
    ////console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();
  }
  onChangeStreet(street) {
    ////console.log(street);
    ////console.log(this.propertiesList);
    var p = [];
    var t = [];
    var g = [];
    var h = [];

    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.projectService.multiFilter(results, filters);
    ////console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      var y = arrayItem.main_contractor;
      var z = arrayItem.precinct;
      var f = arrayItem.floor_plan_id;

      p.push(x);
      t.push(y);
      g.push(z);
      h.push(f);

    });

    this.LoadSmplrSpace(h[0]);

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();
    this.addT.house_number = this.propertiesHouseNumberList[0];


    this.propertiesPrecinctList.splice(0, this.propertiesPrecinctList.length);
    this.propertiesPrecinctList.push(...g);
    this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesPrecinctList.sort();
    this.addT.precinct = this.propertiesPrecinctList[0];


    this.contractorsList.splice(0, this.contractorsList.length);
    this.contractorsList.push(...t);
    this.contractorsList = this.contractorsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.contractorsList.push(...this.allContractorsList);
    this.addT.main_contractor = this.contractorsList[0];

  }

  //////////////////change locations///////////
  onChangetou() {
    this.locations.splice(0, this.locations.length);

    var tou = 'Tanah Makmur KotaSAS Sdn Bhd';

    if (tou) {
      this.locationload = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (tou != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(tou);
      }
      this.projectService.scanContainsAnd(this.apiNameLocation, this.apipathLocation, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.locations.push(response[i].location);
        }
        this.locations = this.locations.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.locations.sort();
        this.locationload = false;
      });

      if (this.goteditFlag) {
        this.progress = 20;
        this.onChangeLocation(this.edititem.location)
      }
    }
  }
  onChangeLocation(location) {
    let unsortedrows = [];
    let sorted = [];
    sorted.splice(0, sorted.length);
    this.elements.splice(0, this.elements.length);
    this.selectedLocation = location;

    if (location) {
      this.elementload = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (location != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(location);
      }

      this.projectService.scanContainsAnd(this.apiNameElement, this.apipathElement, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.elements.push(response[i].element);
        }
        this.elements = this.elements.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.elements.sort();
        this.elementload = false;
      });

      if (this.goteditFlag) {
        this.progress = 30;
        this.onChangeElement(this.edititem.element)
      }
    }
  }

  onChangeElement(element) {
    const sorted = [];
    const unsortedrows = [];
    console.log(this.defects);
    sorted.splice(0, sorted.length);
    this.defects.splice(0, this.defects.length);
    this.selectedElement = element;
    if (element) {
      this.defectLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (element != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(element);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      this.projectService.scanContainsAnd(this.apiNameDefect, this.apipathDefect, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.defects.push(response[i].defect);
        }
        this.defects = this.defects.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.defects.sort();
        this.defectLoad = false;
      });

      if (this.goteditFlag) {
        this.progress = 50;
        this.onChangeDefect(this.edititem.defect)
      }

    }
  }

  onChangeDefect(defect) {
    let sorted = [];
    sorted.splice(0, sorted.length);
    this.competencys.splice(0, this.competencys.length);
    this.selectDefect = defect;
    if (defect) {
      //console.log(defect);
      this.competencyLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (defect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(defect);
      }
      this.projectService.scanContainsAnd(this.apiNameCompetency, this.apipathCompetency, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.competencys.push(response[i].competency);
          this.competencys.push(...this.workOrderCompetencies);
        }
        this.competencys = this.competencys.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.competencys.sort();
        this.competencyLoad = false;
        if (this.goteditFlag) {
          console.log('if')
          this.onChangeCompetency(this.edititem.subject)
          this.progress = 60;
        } else {
          console.log('else')
          this.onChangeCompetency(this.competencys[0]);
          this.addT.subject = this.competencys[0];
        }
      });
    }

  }

  onChangeCompetency(competency) {
    let sorted = [];
    let prioritys = [];
    let next_actions = [];
    sorted.splice(0, sorted.length);
    this.selectedCompetency = competency;
    this.days_to_executes.splice(0, this.days_to_executes.length);
    prioritys.splice(0, prioritys.length);
    next_actions.splice(0, next_actions.length);
    if (competency) {
      this.daysToExecuteLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (this.selectDefect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(this.selectDefect);
      }
      if (competency != undefined) {
        srchFiltersNames.push('competency');
        srchFiltersValues.push(competency);
      }
      this.projectService.scanContainsAnd(this.apiNameDaysToExecute, this.apipathDaysToExecute, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.days_to_executes.push(response[i].days_to_execute);
        }
        this.days_to_executes.push(...this.workOrderDaysToExecute);
        this.days_to_executes = this.days_to_executes.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.days_to_executes.sort();
        this.daysToExecuteLoad = false;
        if (this.goteditFlag) {
          this.progress = 70;
          this.onChangeDaysToExecute(this.edititem.days_to_execute)
        } else {
          this.onChangeDaysToExecute(this.days_to_executes[0]);
          this.addT.days_to_execute = this.days_to_executes[0];
        }
      });


    }
  }

  onChangeDaysToExecute(days_to_execute) {
    let sorted = [];
    let next_actions = [];

    sorted.splice(0, sorted.length);
    this.prioritys.splice(0, this.prioritys.length);
    next_actions.splice(0, next_actions.length);
    if (days_to_execute) {
      this.priorityLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];
      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (this.selectDefect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(this.selectDefect);
      }
      if (this.selectedCompetency != undefined) {
        srchFiltersNames.push('competency');
        srchFiltersValues.push(this.selectedCompetency);
      }
      this.projectService.scanContainsAnd(this.apiNamePriority, this.apipathPriority, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.prioritys.push(response[i].priority);

        }
        this.prioritys.push(...this.workOrderPriorities);
        this.prioritys = this.prioritys.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.prioritys.sort();
        this.priorityLoad = false;
        if (this.goteditFlag) {
          this.progress = 80;
          this.onChangePriority(this.edititem.priority)
        } else {
          this.onChangePriority(this.prioritys[0]);
          this.addT.priority = this.prioritys[0];
        }
      });
    }

  }

  onChangePriority(priority) {
    let sorted = [];
    let next_actions = [];
    sorted.splice(0, sorted.length);
    next_actions.splice(0, next_actions.length);

    if (priority) {
      //console.log(priority);
      this.causeLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (this.selectedElement != undefined) {
        srchFiltersNames.push('element');
        srchFiltersValues.push(this.selectedElement);
      }
      if (this.selectedLocation != undefined) {
        srchFiltersNames.push('location');
        srchFiltersValues.push(this.selectedLocation);
      }
      if (this.selectDefect != undefined) {
        srchFiltersNames.push('defect');
        srchFiltersValues.push(this.selectDefect);
      }
      if (this.selectedCompetency != undefined) {
        srchFiltersNames.push('competency');
        srchFiltersValues.push(this.selectedCompetency);
      }
      if (priority != undefined) {
        srchFiltersNames.push('priority');
        srchFiltersValues.push(priority);
      }
      this.projectService.scanContainsAnd(this.apiNameNextAction, this.apipathNextAction, srchFiltersNames, srchFiltersValues).then(response => {
        for (var i = 0; i < response.length; i++) {
          this.causes.push(response[i].cause);
        }
        this.causes = this.causes.filter(function (value, index, array) {
          return array.indexOf(value) == index;
        });
        this.causes.sort();
        this.causeLoad = false;

        if (this.goteditFlag && this.loadsmplrflag) {
          this.addT = this.edititem;
          this.progress = 100;
          this.showloading = false;
          this.LoadSmplrSpace(this.edititem.floor_plan_report.id);
          this.loadsmplrflag = false;
        } else {
          this.addT.cause = this.causes[0];
        }
      });
    }
  }
  LoadSmplrSpace(id) {
    const scriptElement = this.loadJsScript(SCRIPT_PATH);
    scriptElement.onload = () => {
      //console.log('Script loaded');
      //console.log(smplr);
      const space = new smplr.Space({
        spaceId: id,
        spaceToken: 'X',
        containerId: 'smplr-container'
      })
      space.startViewer({
        preview: true,
        mode: '2d',
        allowModeChange: false,
        onReady: () => {
          //console.log('Viewer is ready');
          space.enablePickingMode({
            onPick: ({ coordinates }) => {
              //console.log(coordinates);
              this.reports[0].id = id;
              this.reports[0].position.elevation = coordinates.elevation;
              this.reports[0].position.levelIndex = coordinates.levelIndex;
              this.reports[0].position.x = coordinates.x;
              this.reports[0].position.z = coordinates.z;
              space.addDataLayer({
                id: 'reports',
                type: 'point',
                diameter: 0.6,
                data: this.reports,
                color: '#1e7bd3',
              })
            }
          })
        },
        onError: error => console.error('Could not start viewer', error)
      })
    }
    scriptElement.onerror = () => {
      //console.log('Could not load the Script!');
    }
  }
  public loadJsScript(src: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }



  getDynamicNextActions() {
    let unsortedrows2 = [];
    this.nextActions.splice(0, this.nextActions.length);
    var pd = localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if (user_level) {
      // //console.log(user_level);
      this.nextActionLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];


      if (pd != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(pd);
      }

      if (user_level != undefined) {
        srchFiltersNames.push('from_user_level');
        srchFiltersValues.push(user_level);
      }
      this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
        this.nextActions.push('');
        unsortedrows2.push(...response);
        this.nextActions.push(...this.appService.sortByKey(unsortedrows2, 'order'));
        this.nextActionLoad = false;
      });
    }
  }
  ////////////////////ADD//////////////

  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }
  uploadAllFiles(event) {
    this.selectedFiles = event.target.files;
    this.eventFiles = event;
    this.haveFiles = true;
  }
  getWorkOrderCounter(f) {
    //console.log(f.form.value);
    if (f.invalid === true) {
      this.addWorkOrderValidation = true;
    } else {
      this.loader.show();
      if (this.goteditFlag) {
        if (this.haveFiles || this.havePictures) {
          this.uploadPicsAndFiles().then(Response => {
            //console.log(Response);
            this.addTicket(f, f.form.value.id);
          }).catch(error => {
            //console.log(error);
          });
        } else {
          this.addTicket(f, f.form.value.id);
        }
      } else {
        API.get(this.apiNameUtilities, this.apiPathUtilities, this.putUtilitiesInit).then(response => {
          //console.log('\'add-new-work-order\' component-> All Utilities Retrieved Successfully!');
          //console.log(response);
          this.workorderCounterId = response[0].workordercounter;
          //console.log(this.workorderCounterId);
          this.addWorkOrderCounter(this.workorderCounterId);

          if (this.haveFiles || this.havePictures) {
            this.uploadPicsAndFiles().then(Response => {
              //console.log(Response);
              this.addTicket(f, this.workorderCounterId);
            }).catch(error => {
              //console.log(error);
            });
          } else {
            this.addTicket(f, this.workorderCounterId);
          }

        }).catch(error => {
          this.showErrorMsg = true;
          this.TicketSubmitted = 'Work Order Submission Failed, Please Retry!';
          //console.log('\'add-new-work-order\' component-> Error in Retreiving All Utilities from server!');
          //console.log(error);
        });
      }
    }

  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          //console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              //console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          //console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              //console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Ticket Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          //console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          //console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }

  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          //console.log("File Uploaded Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All Files uploaded");
          }
        }).catch(error => {
          //console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }

  addTicket(f, id) {
    var workorderid;
    if (this.goteditFlag) {
      workorderid = id
    } else {
      workorderid = this.pdCodeList[0] + '-' + new Date().getFullYear().toString().substr(-2) + '-' + id;
    }
    var dateScheduled = '';
    if (f.form.get('date_scheduled').value == undefined || f.form.get('date_scheduled').value === null) {
    } else {
      dateScheduled = this.projectService.reformatDate(f.form.get('date_scheduled').value);
    }

    if (f.invalid === true) {
      this.addWorkOrderValidation = true;
    } else {

      this.reports[0].title = f.form.get('description').value;
      this.reports[0].created_at = this.currentDate;
      this.reports[0].status = f.form.get('next_action').value;

      ////console.log(this.reports[0]);
      if (this.goteditFlag) {
        this.currentDate = this.edititem.created_date;;
      }


      f.form.value.created_date = this.currentDate,
        f.form.value.id = workorderid,
        f.form.value.date_scheduled = dateScheduled,
        f.form.value.closed_by = this.userNickName,
        f.form.value.date_closed = this.currentDate,
        f.form.value.time_closed = this.currentTime,
        f.form.value.images = this.pictures,
        f.form.value.documents = this.files,
        f.form.value.floor_plan_report = this.reports[0]
      this.putMyInit.body = f.form.value;
      //console.log(createTicketBody);
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.addWorkOrderValidation = false;
        this.TicketSubmitted = 'Work Order Submitted Successfully. Work Order ID:' + workorderid;
        f.form.value.status = f.form.get('next_action').value;
        f.form.value.created_date = this.currentDate;
        f.form.value.date_scheduled = f.form.get('date_scheduled').value;
        f.form.value.closed_by = this.userNickName;
        this.rows = this.rows;
        //console.log('\'tickets\' component-> New Ticket Created Successfully!');
        //console.log(response);
        this.havePictures = false;
        this.haveFiles = false;
        this.saveNotifications("Work Order (" + workorderid + ") Created", "Your Work Order " + workorderid + " has been Created.", "workorders", workorderid);
        this.loader.hide();

        if (this.goteditFlag) {
          this.goteditFlag = false;
        } else {
          this.addT = {
            'id': 'Auto',
            'days_open': '0',
            'created_by': localStorage.getItem('nick_name'),
            'date_scheduled': ''
          };
        }
      }).catch(error => {
        this.showErrorMsg = true;
        this.TicketSubmitted = 'Work Order Submission Failed, Please Retry!';
        //console.log('\'tickets\' component-> Error in creating new Ticket!');
        //console.log(error);
        this.loader.hide();

      });
    }
  }
  saveNotifications(title, body, moduleName, id) {
    const randomnumber = Math.floor(Math.random() * 999999999);
    const createBody = {
      id: randomnumber.toString(),
      title: title,
      body: body,
      deepLinkArticleId: id,
      moduleName: moduleName,
      creation_date: this.currentDate,
      creation_time: this.currentTime
    };
    this.putMyInitNotifications.body = createBody;
    API.put(this.apiNameNotifications, this.pathNotifications, this.putMyInitNotifications).then(response => {
      //console.log('\'notifications\' component-> New notification added Successfully!');
      //console.log(response);
      this.sendNotifications(title, body, moduleName, id);

      this.loader.hide();

    }).catch(error => {
      //console.log('\'notifications\' component-> Error in creating new notifications!');
      //console.log(error.response);
    });
  }

  sendNotifications(title, body, moduleName, id) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=' + "AAAA5mIllzA:APA91bH-wa6UVq3IvGUIE4oliUiVO1t3tWu5DqocJG8y9p1O9grxBC05dwLCVIxkftFsoXKcpqsK3MHNZaaNM0Wo9xs0vfndkUGGJRqJ9sEk91JJNhtTkNXnQ3RJBxQVETXiZBeuReKL"
    });

    var callibayaqPlzObj = {
      "to": "/topics/houseResidents",
      "notification": {
        "body": body,
        "content_available": true,
        "priority": "high",
        "title": title
      },
      "data": {
        "sound": true,
        "module": moduleName,
        "deepLinkArticleId": id,
        "content_available": true,
        "priority": "high",
        "title": "Testing Deep Linking"
      }
    }

    this.http.post(this.notificationsUrl, callibayaqPlzObj, { headers }).subscribe(res => {
      //console.log(res);
    }, error => {
      //console.log(error);
    });
  }
  addWorkOrderCounter(counter) {
    const id = 318;
    const utilitiesBody = {
      'id': id.toString(),
      'workordercounter': counter + 1
    };
    this.putUtilitiesInit.body = utilitiesBody;
    API.put(this.apiNameUtilities, this.apiPathUtilities, this.putUtilitiesInit).then(response => {
      //console.log('\'tickets\' component-> Utilities workorder counter added Successfully!');
      //console.log(response);
    }).catch(error => {
      this.showErrorMsg = true;
      this.TicketSubmitted = 'Work Order Submission Failed, Please Retry!';
      //console.log('\'tickets\' component-> Error in saving utilities work order counter!');
      //console.log(error);
      this.loader.hide();


    });
  }
  /////////////////////////languages///////
  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.add_new_work_orders_add_work_order = response.Items[0].add_new_work_orders_add_work_order;
      this.add_new_work_orders_created_by = response.Items[0].add_new_work_orders_created_by;
      this.add_new_work_orders_work_order_id = response.Items[0].add_new_work_orders_work_order_id;
      this.add_new_work_orders_street = response.Items[0].add_new_work_orders_street;
      this.add_new_work_orders_house_number = response.Items[0].add_new_work_orders_house_number;
      this.add_new_work_orders_location = response.Items[0].add_new_work_orders_location;
      this.add_new_work_orders_precinct_project = response.Items[0].add_new_work_orders_precinct_project;
      this.add_new_work_orders_element = response.Items[0].add_new_work_orders_element;
      this.add_new_work_orders_floor = response.Items[0].add_new_work_orders_floor;
      this.add_new_work_orders_defect = response.Items[0].add_new_work_orders_defect;
      this.add_new_work_orders_costs = response.Items[0].add_new_work_orders_costs;
      this.add_new_work_orders_competency = response.Items[0].add_new_work_orders_competency;
      this.add_new_work_orders_invoice = response.Items[0].add_new_work_orders_invoice;
      this.add_new_work_orders_days_to_execute = response.Items[0].add_new_work_orders_days_to_execute;
      this.add_new_work_orders_charge_to = response.Items[0].add_new_work_orders_charge_to;
      this.add_new_work_orders_priority = response.Items[0].add_new_work_orders_priority;
      this.add_new_work_orders_main_contractor = response.Items[0].add_new_work_orders_main_contractor;
      this.add_new_work_orders_next_action = response.Items[0].add_new_work_orders_next_action;
      this.add_new_work_orders_date_scheduled = response.Items[0].add_new_work_orders_date_scheduled;
      this.add_new_work_orders_cause = response.Items[0].add_new_work_orders_cause;
      this.add_new_work_orders_time_scheduled = response.Items[0].add_new_work_orders_time_scheduled;
      this.add_new_work_orders_am_pm = response.Items[0].add_new_work_orders_am_pm;
      this.add_new_work_orders_work_order_description = response.Items[0].add_new_work_orders_work_order_description;
      this.add_new_work_orders_upload_pictures = response.Items[0].add_new_work_orders_upload_pictures;
      this.add_new_work_orders_upload_documents_pdf_only = response.Items[0].add_new_work_orders_upload_documents_pdf_only;
      this.add_new_work_orders_create_new_work_order = response.Items[0].add_new_work_orders_create_new_work_order;
      this.all_work_orders_all_work_orders = response.Items[0].all_work_orders_all_work_orders;
      this.all_work_orders_print_work_order = response.Items[0].all_work_orders_print_work_order;
      this.my_pending_work_orders_my_pending_work_orders = response.Items[0].my_pending_work_orders_my_pending_work_orders;
      this.my_pending_work_orders_refresh_list = response.Items[0].my_pending_work_orders_refresh_list;
      this.my_pending_work_orders_add_work_order = response.Items[0].my_pending_work_orders_add_work_order;
      this.work_order_details_my_pending_work_orders = response.Items[0].work_order_details_my_pending_work_orders;
      this.work_order_details_print = response.Items[0].work_order_details_print;
      this.work_order_details_edit_work_order = response.Items[0].work_order_details_edit_work_order;
      this.work_order_details_remarks_and_approval_log = response.Items[0].work_order_details_remarks_and_approval_log;
      this.work_order_details_created_date = response.Items[0].work_order_details_created_date;
      this.work_order_details_created_time = response.Items[0].work_order_details_created_time;
      this.work_order_details_created_by = response.Items[0].work_order_details_created_by;
      this.work_order_details_action = response.Items[0].work_order_details_action;
      this.work_order_details_remarks = response.Items[0].work_order_details_remarks;
      this.work_order_details_next_action = response.Items[0].work_order_details_next_action;

      this.add_new_work_orders_property_developer = response.Items[0].properties_property_developer;


      this.generals_search = response.Items[0].generals_search;
      this.generals_clear_search = response.Items[0].generals_clear_search;
      this.generals_view_details = response.Items[0].generals_view_details;
      this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
      this.generals_edit = response.Items[0].generals_edit;
      this.generals_delete = response.Items[0].generals_delete;
      this.generals_creation_date = response.Items[0].generals_creation_date;
      this.generals_actions = response.Items[0].generals_actions;
    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }
}
