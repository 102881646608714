import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { AuthService } from 'src/app/auth/auth.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css'],
  providers: [DatePipe]
})
export class AgentsComponent implements OnInit {

  public addCON: any = {};
  public uptCON: any = [];
  public viewCON: any = [];
  public genderOptions = [];
  addContractorValidation = false;
  passwordsMismatch = false;

  conList = [];

  rows = [];
  srch = [];

  
  deleteID;
  delete_results;
  searchT: any = [];


  itemObj: any;
  totalList: any;

  countryList = [];

  DeleteFeedback = '';
  showDeletionResponse = false;
  showSuccessMsg = false;
  showSuccessMsgUpdate = false;

  showErrorMsg = false;

  ContractorAdded = '';
  CONUpdated = '';

  public userLevel;
  public userNickName;
  currentDate: string;

  public propertyDevelopers = [];
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameDelete = 'agents'; // replace this with your api name.
  pathDelete = '/agents/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  apiNameContractors = 'agents';
  apiPathContractors = '/agents';

  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  public AllPd = [];
  public AllPdList = [];
  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  contractors_contractor_id='Agent ID';
  contractors_contractor_name='Agent Name ';
  contractors_contractor_type='Agent Type ';
  contractors_company_ssm_code='Company SSM Code';
  contractors_company_code='Company Code';
  contractors_registration_code='Registration Date';
  contractors_house_number='House Number';
  contractors_street='Street';
  contractors_city='City';
  contractors_postal_code='Postal Code';
  contractors_state='State';
  contractors_country='Country';
  contractors_company_telephone='Company Telephone';
  contractors_company_email='Company Email';
  contractors_status='Status';
  contractors_property_developer='Client';
  contractors_pd_city='City';
  contractors_township='Township';
  contractors_precincts='Precincts/Projects/Condos';
  contractors_property_units='Property Units';
  contractors_pic_name='PIC Name';
  contractors_pic_ic_no='PIC IC No';
  contractors_date_of_birth='Date of Birth';
  contractors_designation='Designation';
  contractors_gender='Gender';
  contractors_pic_telephone='PIC Telephone';
  contractors_pic_email='PIC Email';
  contractors_remarks='Remarks';
  contractors_upload_ic_pic='Upload IC PIC';
  contractors_upload_form_9='Upload Form 9';
  contractors_upload_contracts='Upload Contracts';
  contractors_upload_bank_information='Upload Bank information';
  contractors_upload_support_documents='Upload Support Documents';
  
  contractors_create_contractor='Create Agent';
  contractors_add_contractor='Add Agent';
  contractors_edit_contractor='Edit Agent';
  contractors_update_contractor='Update Agent';

  title = 'All Agents';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';

  permissions_userlevel;
  permissions_view=false;
  permissions_create=false;
  permissions_edit=false;
  permissions_delete=false;

  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  putContractorsInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  constructor(
    private userService: AppService,
    private cookieService: CookieService,
    private loader: LoaderService,
    private authService: AuthService,
    private appService: AppService,
    private datePipe: DatePipe


  ) {

    this.genderOptions = appService.genderOptions;
    this.countryList = appService.countryList;
    this.userLevel = localStorage.getItem('user_level');
    this.userNickName = localStorage.getItem('nick_name');
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');

  }

  ngOnInit() {
    this.getPermissions();
    this.getContractors();
    this.getPropertyDevelopers();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }

  }

  addReset() {
    const randomnumber = Math.floor(Math.random() * 999999999);
    this.addCON = {
      id: randomnumber,
      status: 'Confirmed',
      reg_date: '',
      date_of_birth: ''
    };
    $('#add_contractor').modal('show');
  }

  addContractor(f) {

    console.log(f);

    const date_of_birth = f.form.get('date_of_birth').value;
    let date_of_birth_formatted;
    if (date_of_birth) {
      date_of_birth_formatted = this.reformatDate(f.form.get('date_of_birth').value);
    }

    console.log(f.form.get('reg_date').value);
    const reg_date = f.form.get('reg_date').value;
    let reg_date_formatted;
    if (reg_date) {
      reg_date_formatted = this.reformatDate(f.form.get('reg_date').value);
    }

    if (f.invalid === true) {
      this.addContractorValidation = true;
    } else {

      this.loader.show();
      const addContractorBody = {
        created_by: this.userNickName,
        creation_date: this.currentDate,
        id: f.form.get('id').value.toString(),
        con_name: f.form.get('con_name').value,
        con_type: f.form.get('con_type').value,
        company_ssm_code: f.form.get('company_ssm_code').value,
        company_code: f.form.get('company_code').value,
        reg_date: reg_date_formatted,
        house_number: f.form.get('house_number').value,
        street: f.form.get('street').value,
        city: f.form.get('city').value,
        postal_code: f.form.get('postal_code').value,
        state: f.form.get('state').value,
        country: f.form.get('country').value,
        company_telephone: f.form.get('company_telephone').value,
        company_email: f.form.get('company_email').value,
        pic_name: f.form.get('pic_name').value,
        pic_ic_no: f.form.get('pic_ic_no').value,
        date_of_birth: date_of_birth_formatted,
        designation: f.form.get('designation').value,
        gender: f.form.get('gender').value,
        pic_telephone: f.form.get('pic_telephone').value,
        pic_email: f.form.get('pic_email').value,
        status: f.form.get('status').value,
        property_developer: f.form.get('property_developer').value,
        pd_city: f.form.get('pd_city').value,
        township: f.form.get('township').value,
        precincts: f.form.get('precincts').value,
        property_units: f.form.get('property_units').value,
        remarks: f.form.get('remarks').value,
      };

      if (f.form.get('company_ssm_code').value === '') {
        delete addContractorBody.company_ssm_code;
      }
      if (f.form.get('pic_ic_no').value === '') {
        delete addContractorBody.pic_ic_no;
      }
      if (f.form.get('date_of_birth').value === '') {
        delete addContractorBody.date_of_birth;
      }
      if (f.form.get('remarks').value === '') {
        delete addContractorBody.remarks;
      }

      this.putContractorsInit.body = addContractorBody;

      console.log(addContractorBody);

      API.put(this.apiNameContractors, this.apiPathContractors, this.putContractorsInit).then(response => {
        this.showSuccessMsg = true;
        this.ContractorAdded = 'Agent Added Successfully!';
        this.CONUpdated = 'Agent Updated Successfully!';

        this.rows.unshift(f.form.value);
        this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_contractor').modal('hide');
        console.log('\'Agent\' component-> New Agent Created Successfully!');
        console.log(response);
        this.loader.hide();
        this.totalList = this.totalList + 1;
      }).catch(error => {
        this.showErrorMsg = true;
        this.ContractorAdded = 'Agent Submission Failed, Please Retry!';
        this.CONUpdated = 'Agent Updation Failed, Please Retry!';

        console.log('\'Agent\' component-> Error in creating new Agent!');
        console.log(error.response);
        this.loader.hide();
      });

    }

  }

  UpdateContractor(f) {

    const date_of_birth = f.form.get('date_of_birth').value;
    let date_of_birth_formatted;
    if (date_of_birth) {
      date_of_birth_formatted = this.reformatDate(f.form.get('date_of_birth').value);
    }

    console.log(f.form.get('reg_date').value);
    const reg_date = f.form.get('reg_date').value;
    let reg_date_formatted;
    if (reg_date) {
      reg_date_formatted = this.reformatDate(f.form.get('reg_date').value);
    }

    if (f.invalid === true) {
      this.addContractorValidation = true;
    } else {

      this.loader.show();
      const addContractorBody = {
        created_by: this.userNickName,
        creation_date: this.currentDate,
        id: f.form.get('id').value.toString(),
        con_name: f.form.get('con_name').value,
        con_type: f.form.get('con_type').value,
        company_ssm_code: f.form.get('company_ssm_code').value,
        company_code: f.form.get('company_code').value,
        reg_date: reg_date_formatted,
        house_number: f.form.get('house_number').value,
        street: f.form.get('street').value,
        city: f.form.get('city').value,
        postal_code: f.form.get('postal_code').value,
        state: f.form.get('state').value,
        country: f.form.get('country').value,
        company_telephone: f.form.get('company_telephone').value,
        company_email: f.form.get('company_email').value,
        pic_name: f.form.get('pic_name').value,
        pic_ic_no: f.form.get('pic_ic_no').value,
        date_of_birth: date_of_birth_formatted,
        designation: f.form.get('designation').value,
        gender: f.form.get('gender').value,
        pic_telephone: f.form.get('pic_telephone').value,
        pic_email: f.form.get('pic_email').value,
        status: f.form.get('status').value,
        property_developer: f.form.get('property_developer').value,
        pd_city: f.form.get('pd_city').value,
        township: f.form.get('township').value,
        precincts: f.form.get('precincts').value,
        property_units: f.form.get('property_units').value,
        remarks: f.form.get('remarks').value,
        
      };

      if (f.form.get('company_ssm_code').value === '') {
        delete addContractorBody.company_ssm_code;
      }
      if (f.form.get('pic_ic_no').value === '') {
        delete addContractorBody.pic_ic_no;
      }
      if (f.form.get('date_of_birth').value === '') {
        delete addContractorBody.date_of_birth;
      }
      if (f.form.get('remarks').value === '') {
        delete addContractorBody.remarks;
      }

      this.putContractorsInit.body = addContractorBody;

      console.log(addContractorBody);

      API.put(this.apiNameContractors, this.apiPathContractors, this.putContractorsInit).then(response => {
        this.showSuccessMsgUpdate = true;
        this.CONUpdated = 'Agent Updated Successfully!';
        this.rows = this.rows;
        console.log('\'Agent\' component-> Agent Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showSuccessMsgUpdate = true;
        this.CONUpdated = 'Agent Updation Failed, Please Retry!';

        console.log('\'Agent\' component-> Error in Updating Agent!');
        console.log(error.response);
        this.loader.hide();
      });

    }

  }

  onEdit(item) {
    this.uptCON = Object.assign({}, item);

    if (item.rreg_date != undefined) {
      var regdateParts = item.reg_date.split("/");
      this.uptCON.reg_date = regdateParts[2] + "-" + regdateParts[1] + "-" + regdateParts[0];

    } if (item.date_of_birth != undefined) {
      var date_of_birthParts = item.date_of_birth.split("/");
      this.uptCON.date_of_birth = date_of_birthParts[2] + "-" + date_of_birthParts[1] + "-" + date_of_birthParts[0];
    }

    console.log(this.uptCON);

    $('#edit_contractor').modal('show');
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getContractors();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }



  getContractors() {
    this.loader.show();
    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);
      this.conList = response.data;

      this.loader.hide();
      console.log('\'Agent\' component-> All Agents Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Agents\' component-> Error in Retreiving All Agents from server!');
      console.log(error.response);
    });
  }


  viewContractor(item) {
    let temp;
    // console.log(item);
    temp = item;
    this.viewCON = temp;
  }
  searchConName(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { con_name: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchCompCode(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { company_code: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }
  searchCity(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { city: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchCountry(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === 'All Countries') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { country: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchConType(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { con_type: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchPD(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === 'All Property Developers') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { property_developer: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const property_developer = f.form.get('property_developer').value;
    const con_name = f.form.get('con_name').value;
    const company_code = f.form.get('company_code').value;
    const city = f.form.get('city').value;
    const country = f.form.get('country').value;
    const con_type = f.form.get('con_type').value;

    if (property_developer != undefined && property_developer != "") {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(property_developer);
    }
    if (con_name != undefined && con_name != "") {
      srchFiltersNames.push('con_name');
      srchFiltersValues.push(con_name);
    }
    if (company_code != undefined && company_code != "") {
      srchFiltersNames.push('company_code');
      srchFiltersValues.push(company_code);
    }
    if (city != undefined && city != "") {
      srchFiltersNames.push('city');
      srchFiltersValues.push(city);
    }
    if (country != undefined && country != "") {
      srchFiltersNames.push('country');
      srchFiltersValues.push(country);
    } 
    if (con_type != undefined && con_type != "") {
      srchFiltersNames.push('con_type');
      srchFiltersValues.push(con_type);
    } 

    if (srchFiltersNames.length == 0) {
      // this.scan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Results';
    } else {

      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[srchFiltersNames[i]] = srchFiltersValues[i];
      }
      console.log(ExpressionAttributeValues);

      var Filtered = this.conList.filter(function (item) {
        for (var key in ExpressionAttributeValues) {
          if (item[key] === undefined || item[key] != ExpressionAttributeValues[key])
            return false;
        }
        return true;
      });
      console.log(Filtered);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);

    }
  }

  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});

    this.rows.splice(0, this.rows.length);
    this.srch.splice(0,this.srch.length);

    this.rows.push(...this.conList);
    this.totalList = Object.keys(this.rows).length;
    this.srch.push(...this.rows);
  }

  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element!=="" && element!=="New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Agent\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Agents\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }
  reformatDate(dateStr) {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.title = this.rowslanguages[0].contractors;
        this.contractors_contractor_id = this.rowslanguages[0].contractors_contractor_id;
        this.contractors_contractor_name = this.rowslanguages[0].contractors_contractor_name;
        this.contractors_contractor_type =this.rowslanguages[0].contractors_contractor_type;
        this.contractors_company_ssm_code = this.rowslanguages[0].contractors_company_ssm_code;
        this.contractors_company_code = this.rowslanguages[0].contractors_company_code;
        this.contractors_registration_code = this.rowslanguages[0].contractors_registration_code;
        this.contractors_house_number = this.rowslanguages[0].contractors_house_number;
        this.contractors_street = this.rowslanguages[0].contractors_street;
        this.contractors_city = this.rowslanguages[0].contractors_city;
        this.contractors_postal_code = this.rowslanguages[0].contractors_postal_code;
        this.contractors_state = this.rowslanguages[0].contractors_state;
        this.contractors_country = this.rowslanguages[0].contractors_country;
        this.contractors_company_telephone = this.rowslanguages[0].contractors_company_telephone;
        this.contractors_company_email = this.rowslanguages[0].contractors_company_email;
        this.contractors_status = this.rowslanguages[0].contractors_status;
        this.contractors_property_developer = this.rowslanguages[0].contractors_property_developer;
        this.contractors_pd_city = this.rowslanguages[0].contractors_pd_city;
        this.contractors_township = this.rowslanguages[0].contractors_township;
        this.contractors_precincts = this.rowslanguages[0].contractors_precincts;
        this.contractors_property_units = this.rowslanguages[0].contractors_property_units;
        this.contractors_pic_name = this.rowslanguages[0].contractors_pic_name;
        this.contractors_pic_ic_no = this.rowslanguages[0].contractors_pic_ic_no;
        this.contractors_date_of_birth = this.rowslanguages[0].contractors_date_of_birth;
        this.contractors_designation = this.rowslanguages[0].contractors_designation;
        this.contractors_gender = this.rowslanguages[0].contractors_gender;
        this.contractors_pic_telephone =this.rowslanguages[0].contractors_pic_telephone;
        this.contractors_pic_email = this.rowslanguages[0].contractors_pic_email;
        this.contractors_remarks = this.rowslanguages[0].contractors_remarks;
        this.contractors_upload_ic_pic = this.rowslanguages[0].contractors_upload_ic_pic;
        this.contractors_upload_form_9 = this.rowslanguages[0].contractors_upload_form_9;
        this.contractors_upload_contracts = this.rowslanguages[0].contractors_upload_contracts;
        this.contractors_upload_bank_information = this.rowslanguages[0].contractors_upload_bank_information;
        this.contractors_upload_support_documents = this.rowslanguages[0].contractors_upload_support_documents;
        
        this.contractors_create_contractor = this.rowslanguages[0].contractors_create_contractor;
        this.contractors_add_contractor = this.rowslanguages[0].contractors_add_contractor;
        this.contractors_edit_contractor = this.rowslanguages[0].contractors_edit_contractor;
        this.contractors_update_contractor = this.rowslanguages[0].contractors_update_contractor;

        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_actions = this.rowslanguages[0].generals_actions;
        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  
  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  getPermissions() {
    API.get(this.apiNamePermissions, this.apiPathPermissions, this.getPermissionsInit).then(response => {
      this.loader.hide();
      this.permissions_userlevel = localStorage.getItem('user_level');
      const filters = { user_level: [this.permissions_userlevel] },
      results = response.data,
      Filtered = this.multiFilter(results, filters);

      this.permissions_view=Filtered[0].agents_view;
      this.permissions_create=Filtered[0].agents_create;
      this.permissions_edit=Filtered[0].agents_update;
      this.permissions_delete=Filtered[0].agents_delete;
     
    
      console.log(Filtered);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Sidebar\' component-> Error in Retreiving All permissions from server!'+error);
      console.log(error.response);
    });
  }
  ngOnDestroy() {
    $('#add_contractor').modal('hide');
  }

}
