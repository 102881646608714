import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';


const SCRIPT_PATH = 'https://app.smplrspace.com/lib/smplr.js';
declare let smplr: any;

@Component({
  selector: 'app-floorplans',
  templateUrl: './floorplans.component.html',
  styleUrls: ['./floorplans.component.css']
})
export class FloorplansComponent implements OnInit {
  
  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2,private router: Router,
  ) { }

  ngOnInit() {
    const scriptElement = this.loadJsScript(SCRIPT_PATH);
    const floor_plan_id=localStorage.getItem('floor_plan_id');
    localStorage.removeItem("floor_plan_id");
    scriptElement.onload = () => {
      console.log('Script loaded');
      console.log(smplr);
      const space = new smplr.Space({
      spaceId: floor_plan_id,
      spaceToken: 'X',
      containerId: 'smplr-container'
    })
    space.startViewer({
      preview: true,
      mode: '3d',
      allowModeChange: true,
      onReady: () => console.log('Viewer is ready'),
      onError: error => console.error('Could not start viewer', error)
    })
     }
     scriptElement.onerror = () => {
       console.log('Could not load the Script!');
     }
  }

  public loadJsScript(src: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  public goback(){
    this.router.navigate(['properties']);

  }
}
