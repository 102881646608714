import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { Lightbox } from 'ngx-lightbox';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-residents-documents-list',
  templateUrl: './residents-documents-list.component.html',
  styleUrls: ['./residents-documents-list.component.css']
})
export class ResidentsDocumentsListComponent implements OnInit {
  ///////////alert variables////////
  scanResults = '';
scan: boolean = false;
  /////////total list variable//////
  totalList: any;
  property_id;
  //////////////get properly developers variables//////
  public PropertyDevelopers = [];
  searchT: any = [];
  documentsList;
  rows = [];
  public viewP: any = [];
  ///////////pictures/////////
  viewPictures = [];
  albums = [];
  viewFiles = [];
  totalPics;
  totalFiles;
  ////////////////api////////////////
  apiName = 'residentsDocuments';
  apiPath = '/residentsDocuments';
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  getInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  constructor(private _lightbox: Lightbox,private projectService: AppService,private loader: LoaderService) { }

  ngOnInit() {
    this.getPropertyDevelopers();
    this.property_id=localStorage.getItem('temp_property_id');
    localStorage.removeItem("temp_property_id");
    if(this.property_id!=null){
      this.searchRecord();
    }else{
      this.getAll();
    }
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  ////////////////get residents functions////////////
  getPropertyDevelopers() {
    this.PropertyDevelopers.push('');
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.PropertyDevelopers.push(...response.data);
      console.log('\'Properties\' component-> All Client Retrieved Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'Properties\' component-> Error in Retreiving All Property Developers from server!');
      console.log(error.response);
    });
  }
  getAll() {
    this.scan = true;
    this.scanResults = "Getting Residents, Please Wait."
    API.get(this.apiName, this.apiPath, this.getInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.documentsList=response.data;
      this.totalList = Object.keys(this.rows).length;
      this.loader.hide();
      this.scan = false;
      console.log('\'ResidentsDocumentsListComponent\' component-> All Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'ResidentsDocumentsListComponent\' component-> Error in Retreiving All from server!');
      console.log(error.response);
    });
  }
  //////////////searching////////////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.documentsList);
    console.log(Filtered);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
   this.rows.splice(0,this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAll();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.documentsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.documentsList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }
  searchRecord() {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    srchFiltersNames.splice(0, srchFiltersNames.length);
    srchFiltersValues.splice(0,srchFiltersValues.length);
  
    const property_id = this.property_id;
  
    if (property_id != undefined) {
      srchFiltersNames.push('property_id');
      srchFiltersValues.push(property_id);
    }
    this.projectService.scanContainsAnd(this.apiName, this.apiPath, srchFiltersNames, srchFiltersValues).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.Items);
      this.totalList = Object.keys(this.rows).length;
  });
  }
  
 ////////////////view image//////
 openImageView(index: number): void {
  this._lightbox.open(this.albums, index);
}
///////////////////view/////////////////
onView(item) {
  console.log(item);
  let temp;
  temp = item;
  this.viewP = temp;

  this.viewPictures = [];
  this.viewFiles = [];

  if (item.images) {
    if (item.images.length > 0) {
      this.totalPics = item.images.length;
      if(item.images.length<=50){
        this.viewPictures = item.images;
      }
    }
  }
  if (item.documents) {
    if (item.documents.length > 0) {
      this.viewFiles = item.documents;
      this.totalFiles = this.viewFiles.length;
    }
  }


  $('#view_modal').modal('show');

  this.prepareAlbumLightbox();

}

prepareAlbumLightbox() {
  //check if viewPictures is null
  for (let i = 0; i < this.viewPictures.length; i++) {
    if (this.viewPictures[i] == null || this.viewPictures[i] == "") {
      this.viewPictures[i] = "NoURL";
    }
    const src = this.viewPictures[i];
    const caption = "";
    const thumb = "";
    const album = {
      src: src,
      caption: caption,
      thumb: thumb
    };
    this.albums.push(album);
  }
}
}
