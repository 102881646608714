import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { DataTableModule } from 'angular2-datatable';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { MyDatePickerModule } from 'mydatepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FullCalendarModule } from 'ng-fullcalendar';
import { MorrisJsModule } from 'angular-morris-js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { enableProdMode } from '@angular/core';
import { NgxPrintModule } from 'ngx-print';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { LightboxModule } from 'ngx-lightbox';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MatTabsModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material';



import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AllEmployeesComponent } from './residents/all-residents/all-residents.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ProjectComponent } from './Properties/properties/project.component';
import { TicketsComponent } from './work_orders/All_work_orders/tickets.component';
import { TicketsDetailsComponent } from './work_orders/tickets-details/tickets-details.component';
import { TicketsEditComponent } from './work_orders/tickets-edit/tickets-edit.component';
import { UsersComponent } from './users/users.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { LoginCredentialService } from 'src/app/services/login-credentials.service';
import { AuthGuard as AuthGuard } from 'src/app/auth/auth.guard';
import { CookieService } from 'ngx-cookie-service';
import { TicketsLocalService } from 'src/app/services/tickets_local.service';
import { MyPendingWorkOrdersComponent } from './work_orders/my-pending-work-orders/my-pending-work-orders.component';
import { WorkOrderApprovalComponent } from './work_orders/work-order-approval/work-order-approval.component';
import { PropertyDevelopersComponent } from './property-developers/property-developers.component';
import { ContractorsComponent } from './all_contractors/contractors/contractors.component';
import { propertiesIDsLocalService } from './services/propertiesIDsLocal.service';
import { AddNewWorkOrderComponent } from './work_orders/add-new-work-order/add-new-work-order.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { SettingsmainComponent } from './settings/settingsmain/settingsmain.component';
import { TypeofunitComponent } from './settings/LED/typeofunit/typeofunit.component';
import { LocationComponent } from './settings/LED/location/location.component';
import { ElementComponent } from './settings/LED/element/element.component';
import { DefectComponent } from './settings/LED/defect/defect.component';
import { CompetencyComponent } from './settings/LED/competency/competency.component';
import { DaystoexecuteComponent } from './settings/LED/daystoexecute/daystoexecute.component';
import { PriorityComponent } from './settings/LED/priority/priority.component';
import { NextactionComponent } from './settings/LED/nextaction/nextaction.component';
import { CauseComponent } from './settings/LED/cause/cause.component';
import { MpSuperAdminComponent } from './settings/module_permissions/mp-super-admin/mp-super-admin.component';
import { VenuesComponent } from './bookings/venues/venues.component';
import { VenuebookingsComponent } from './bookings/venuebookings/venuebookings.component';
import { ServicesCategoryComponent } from './settings_services/services-category/services-category.component';
import { ServicesSubCategoryComponent } from './settings_services/services-sub-category/services-sub-category.component';
import { SettingsServicesComponent } from './settings_services/settings-services/settings-services.component';
import { LeadResidentsComponent } from './residents/lead-residents/lead-residents.component';
import { PrecinctsComponent } from './settings/generals/precincts/precincts.component';
import { SubPrecinctsComponent } from './settings/generals/sub-precincts/sub-precincts.component';
import { HttpClientModule } from '@angular/common/http';
import { TempateUploadPropertiesComponent } from './settings/tempate-upload-properties/tempate-upload-properties.component';
import { TempateUploadResidentsComponent } from './settings/tempate-upload-residents/tempate-upload-residents.component';
import { PaymentsComponent } from './payments/payments.component';
import { LanguagesComponent } from './settings/languages/languages.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { VisitorsManagmentComponent } from './visitors-managment/visitors-managment.component';
import { VisitorsManagmentDashbboardComponent } from './visitors-managment-dashbboard/visitors-managment-dashbboard.component';
import { ContentManagmentComponent } from './content_managment_mod/content-managment/content-managment.component';
import { TabComponent } from './content_managment_mod/tab/tab.component';
import { SubTabComponent } from './content_managment_mod/sub-tab/sub-tab.component';
import { ResidentDocumentsComponent } from './residents/resident-documents/resident-documents.component';
import { ResidentsDocumentsListComponent } from './residents/residents-documents-list/residents-documents-list.component';
import { UserLevelComponent } from './settings/plts_settings/user-level/user-level.component';
import { ActionsComponent } from './settings/plts_settings/workflows/actions.component';
import { TaskComponent } from './settings/plts_settings/NextActions/task.component';
import { AddLeadPdComponent } from './CRM/add-lead-pd/add-lead-pd.component';
import { AllLeadPdComponent } from './CRM/all-lead-pd/all-lead-pd.component';
import { MyPendingLeadsComponent } from './CRM/my-pending-leads/my-pending-leads.component';
import { TempateUploadWorkordersComponent } from './settings/tempate-upload-workorders/tempate-upload-workorders.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FloorplansComponent } from './floorplans/floorplans.component';
import { AuthComponent } from './auth/auth.component';
import { PltsComponent } from './plts/All-plts/plts.component';
import { MyPendingContractorsComponent } from './all_contractors/my-pending-contractors/my-pending-contractors.component';
import { AddPropertyComponent } from './plts/add-property/add-property.component';
import { MyPendingPltsComponent } from './plts/my-pending-plts/my-pending-plts.component';
import { NewCompComponent } from './new-comp/new-comp.component';
import { ExtraCompComponent } from './extra-comp/extra-comp.component';
import { OtherCompComponent } from './other-comp/other-comp.component';
import { AgentsComponent } from './agents/agents.component';
import { TempateUploadUserlevelComponent } from './settings/tempate-upload-userlevel/tempate-upload-userlevel.component';
import { TempateUploadActionsComponent } from './settings/tempate-upload-actions/tempate-upload-actions.component';
import { TempateUploadWorkflowsComponent } from './settings/tempate-upload-workflows/tempate-upload-workflows.component';
import { ClientRegistrationCompComponent } from './client-registration-comp/client-registration-comp.component';
import { AddDefectComponent } from './add-defect/add-defect.component';
import { AllDefectsComponent } from './all-defects/all-defects.component';
import { MyPendingDefectsComponent } from './my-pending-defects/my-pending-defects.component';
import { ReportsComponent } from './CRM/reports/reports.component';
import { ThousandsSeparatorPipe } from './CRM/thousands-separator.pipe';
import { PltsReportsComponent } from './plts-reports/plts-reports.component';




enableProdMode();

const routes: Routes = [

  { path: '', redirectTo: 'pages/login', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings', component: SettingsmainComponent, children: [
      { path: '', redirectTo: '/settings', pathMatch: 'full' },
      { path: 'LED/type-of-unit', component: TypeofunitComponent, canActivate: [AuthGuard] },
      { path: 'LED/location', component: LocationComponent, canActivate: [AuthGuard] },
      { path: 'LED/element', component: ElementComponent, canActivate: [AuthGuard] },
      { path: 'LED/defect', component: DefectComponent, canActivate: [AuthGuard] },
      { path: 'LED/competency', component: CompetencyComponent, canActivate: [AuthGuard] },
      { path: 'LED/days-to-execute', component: DaystoexecuteComponent, canActivate: [AuthGuard] },
      { path: 'LED/priority', component: PriorityComponent, canActivate: [AuthGuard] },
      { path: 'LED/next-action', component: NextactionComponent, canActivate: [AuthGuard] },
      { path: 'LED/cause', component: CauseComponent, canActivate: [AuthGuard] },
      { path: 'module_permissions', component: MpSuperAdminComponent, canActivate: [AuthGuard] },
      { path: 'generals/precincts', component: PrecinctsComponent, canActivate: [AuthGuard] },
      { path: 'generals/sub_precincts', component: SubPrecinctsComponent, canActivate: [AuthGuard] },
      { path: 'templateUpload/properties', component: TempateUploadPropertiesComponent, canActivate: [AuthGuard] },
      { path: 'templateUpload/Residents', component: TempateUploadResidentsComponent, canActivate: [AuthGuard] },
      { path: 'templateUpload/workorders', component: TempateUploadWorkordersComponent, canActivate: [AuthGuard] },
      { path: 'templateUpload/actions', component: TempateUploadActionsComponent, canActivate: [AuthGuard] },
      { path: 'templateUpload/userlevels', component: TempateUploadUserlevelComponent, canActivate: [AuthGuard] },
      { path: 'templateUpload/workflows', component: TempateUploadWorkflowsComponent, canActivate: [AuthGuard] },
      { path: 'languages', component: LanguagesComponent, canActivate: [AuthGuard] },
      { path: 'actions', component: ActionsComponent,canActivate: [AuthGuard] },
      { path: 'user-level', component: UserLevelComponent,canActivate: [AuthGuard]},
      { path: 'task', component: TaskComponent,canActivate: [AuthGuard]}
    ]
  },
  { path: 'other/comp', component: OtherCompComponent, canActivate: [AuthGuard]},
  { path: 'extra/comp', component: ExtraCompComponent, canActivate: [AuthGuard]},
  { path: 'agents', component: AgentsComponent,canActivate: [AuthGuard]},
  { path: 'new/comp', component: NewCompComponent, canActivate: [AuthGuard]},
  { path: 'plts/pendings', component: MyPendingPltsComponent, canActivate: [AuthGuard]},
  { path: 'plts/add', component: AddPropertyComponent, canActivate: [AuthGuard]},
  { path: 'plts', component: PltsComponent, canActivate: [AuthGuard]},
  { path: 'properties/floorplans', component: FloorplansComponent, canActivate: [AuthGuard]},
  { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard]},
  { path: 'crm/pending_leads', component: MyPendingLeadsComponent, canActivate: [AuthGuard]},
  { path: 'registration', component: AddLeadPdComponent},
  { path: 'crm/all_leads', component: AllLeadPdComponent,canActivate: [AuthGuard]},
  { path: 'properties/upload-documents', component: ResidentDocumentsComponent,canActivate: [AuthGuard] },
  { path: 'properties/uploaded-documents', component: ResidentsDocumentsListComponent,canActivate: [AuthGuard] },
  { path: 'content_managment/tab', component: TabComponent,canActivate: [AuthGuard]  },
  { path: 'content_managment/sub_tab', component: SubTabComponent,canActivate: [AuthGuard]  },
  { path: 'content_management', component: ContentManagmentComponent,canActivate: [AuthGuard]  },
  { path: 'visitors_managment_dashboard', component: VisitorsManagmentDashbboardComponent,canActivate: [AuthGuard] },
  { path: 'visitors_managment', component: VisitorsManagmentComponent,canActivate: [AuthGuard] },
  { path: 'announcements', component: AnnouncementsComponent, canActivate: [AuthGuard] },
  { path: 'residents/all-residents', component: AllEmployeesComponent, canActivate: [AuthGuard] },
  { path: 'residents/lead-residents', component: LeadResidentsComponent, canActivate: [AuthGuard] },
  { path: 'properties', component: ProjectComponent, canActivate: [AuthGuard] },
  { path: 'work_orders/submit_work_order', component: AddNewWorkOrderComponent, canActivate: [AuthGuard] },
  { path: 'work_orders/all_work_orders', component: TicketsComponent, canActivate: [AuthGuard] },
  { path: 'work_orders/my_pending_work_orders', component: MyPendingWorkOrdersComponent, canActivate: [AuthGuard] },
  { path: 'work_orders/edit', component: TicketsEditComponent, canActivate: [AuthGuard] },
  { path: 'work_orders/details', component: TicketsDetailsComponent, canActivate: [AuthGuard] },
  { path: 'work_orders/approval', component: WorkOrderApprovalComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent,canActivate: [AuthGuard]},
  { path: 'property_developers', component: PropertyDevelopersComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: PaymentsComponent, canActivate: [AuthGuard] },
  { path: 'bookings/venues', component: VenuesComponent, canActivate: [AuthGuard] },
  { path: 'bookings/venuebookings', component: VenuebookingsComponent, canActivate: [AuthGuard] },
  { path: 'services/category', component: ServicesCategoryComponent, canActivate: [AuthGuard] },
  { path: 'services/sub_category', component: ServicesSubCategoryComponent, canActivate: [AuthGuard] },
  { path: 'services', component: SettingsServicesComponent, canActivate: [AuthGuard] },
  { path: 'contractors', component: ContractorsComponent, canActivate: [AuthGuard] },
  { path: 'my-pending-contractors', component: MyPendingContractorsComponent, canActivate: [AuthGuard] },
  { path: 'pages', component: LoginPageComponent },
  { path: 'client-registration', component: ClientRegistrationCompComponent },
  { path: 'add-defect', component: AddDefectComponent ,canActivate: [AuthGuard]},
  { path: 'all-defects', component: AllDefectsComponent ,canActivate: [AuthGuard]},
  { path: 'my-pending-defects', component: MyPendingDefectsComponent ,canActivate: [AuthGuard]},
  { path: 'reports', component: ReportsComponent ,canActivate: [AuthGuard]},
  { path: 'plts-reports', component: PltsReportsComponent ,canActivate: [AuthGuard]},
  { path: 'pages/login', component: LoginPageComponent },
  { path: '**', component: LoginPageComponent, pathMatch: 'full', canActivate: [AuthGuard] }


];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DashboardComponent,
    AllEmployeesComponent,
    SidebarComponent,
    HeaderComponent,
    ProjectComponent,
    TicketsComponent,
    TicketsDetailsComponent,
    TicketsEditComponent,
    UsersComponent,
    LoginPageComponent,
    MyPendingWorkOrdersComponent,
    WorkOrderApprovalComponent,
    PropertyDevelopersComponent,
    ContractorsComponent,
    AddNewWorkOrderComponent,
    SettingsmainComponent,
    TypeofunitComponent,
    LocationComponent,
    ElementComponent,
    DefectComponent,
    CompetencyComponent,
    DaystoexecuteComponent,
    PriorityComponent,
    NextactionComponent,
    CauseComponent,
    MpSuperAdminComponent,
    VenuesComponent,
    VenuebookingsComponent,
    ServicesCategoryComponent,
    ServicesSubCategoryComponent,
    SettingsServicesComponent,
    LeadResidentsComponent,
    PrecinctsComponent,
    SubPrecinctsComponent,
    TempateUploadPropertiesComponent,
    TempateUploadResidentsComponent,
    PaymentsComponent,
    LanguagesComponent,
    AnnouncementsComponent,
    VisitorsManagmentComponent,
    VisitorsManagmentDashbboardComponent,
    ContentManagmentComponent,
    TabComponent,
    SubTabComponent,
    ResidentDocumentsComponent,
    ResidentsDocumentsListComponent,
    UserLevelComponent,
    ActionsComponent,
    TaskComponent,
    AddLeadPdComponent,
    AllLeadPdComponent,
    MyPendingLeadsComponent,
    TempateUploadWorkordersComponent,
    WelcomeComponent,
    ThousandsSeparatorPipe,
    FloorplansComponent, 
    AuthComponent, PltsComponent, MyPendingContractorsComponent, AddPropertyComponent, MyPendingPltsComponent, NewCompComponent, AgentsComponent,ExtraCompComponent, OtherCompComponent, TempateUploadUserlevelComponent, TempateUploadActionsComponent, TempateUploadWorkflowsComponent, ClientRegistrationCompComponent, AddDefectComponent, AllDefectsComponent, MyPendingDefectsComponent, ReportsComponent, PltsReportsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    DataTableModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgSlimScrollModule,
    MyDatePickerModule,
    NgxDatatableModule,
    FullCalendarModule,
    MorrisJsModule,
    TooltipModule.forRoot(),
    RouterModule.forRoot(routes),
    AmplifyAngularModule,
    FontAwesomeModule,
    NgxPrintModule,
    PinchZoomModule,
    LightboxModule,
    NgxQRCodeModule,
    HttpClientModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressBarModule,

  ],
  entryComponents: [LoaderComponent],

  providers: [
    AmplifyService,
    LoginCredentialService,
    AuthGuard,
    CookieService,
    TicketsLocalService,
    propertiesIDsLocalService,
    UploadFileService,


    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: false
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
