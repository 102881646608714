import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-lead-residents',
  templateUrl: './lead-residents.component.html',
  styleUrls: ['./lead-residents.component.css']
})
export class LeadResidentsComponent implements OnInit {
 /////////title/////////////
title = 'Lead Residents';
totalList: any;
///////////searching variables//////
residents_name='Name';
residents_email='Email';
properties_house_number="House No";
residents_social_identity = 'Social Identity';
generals_search = 'Search';
generals_clear_search = 'Clear Search';
property_developers="Client";
properties_street='Street';
ldList = [];
public userLevel;
public srch = [];
public rows = [];
searchT: any = [];
scanResults = '';
scan: boolean = false;
///////////////languages///////
residents_resident_id= 'Resident ID';
residents_ic_no='IC No';
residents_password='Password';
residents_confirm_password='Confirm Password';
residents_nationality='Nationality';
residents_registration_date='Registration Date';
residents_gender='Gender';
residents_resident_status='Resident Status';
residents_telephone_1='Telephone 1';
residents_telephone_2='Telephone 2';
residents_resident_remarks='Resident Remarks';
residents_upload_profile_picture='Upload Profile Picture';
residents_create_resident='Create Resident';
residents_add_resident='Add Resident';
residents_edit_resident='Edit Resident';
residents_update_resident='Update Resident';
generals_uploaded_images = 'Uploaded Images'
generals_edit = 'Edit'
generals_delete = 'Delete';
generals_uploaded_files = 'Uploaded Files';
generals_actions = 'Actions';
generals_view_details = 'View Details';
public languages = [];
//////////API//////////
apiName = 'socialLogin'; // replace this with your api name.
  path = '/socialLogin'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  myInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  constructor(private loader: LoaderService,private projectService: AppService) {  this.userLevel = localStorage.getItem('user_level');}

  ngOnInit() {
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    this.getResidents();

    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.setLanguage(language);
    }else{
      //console.log("null5");
    }
  }


  /////////get pending resident//////

  getResidents() {
    this.scan = true;
    this.scanResults = "Getting Residents, Please Wait."
    this.ldList.splice(0,this.ldList.length);
    API.get(this.apiName, this.path, this.myInit2).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);
      this.ldList = response.data;
      this.loader.hide();
      this.scan = false;
      console.log('\'all-residents\' component-> All Lead Residents Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'all-residents\' component-> Error in Retreiving All  Lead Residents from server!');
      console.log(error);
    });
  }
/////////////search variables//////////
searchRecordLocally(f) {
  let Filtered = this.projectService.searchRecordLocally(f, this.ldList);
  this.rows.splice(0, this.rows.length);
  this.rows.push(...Filtered);
  console.log(Filtered);
  this.totalList = Object.keys(this.rows).length;
}
clearSearch() {
  this.rows.splice(0, this.rows.length);
  this.searchT = Object.keys(this.searchT).reduce(
    (accumulator, current) => {
      accumulator[current] = null;
      return accumulator
    }, {});

  this.getResidents();
}
instantSearch(val, name) {
  this.rows.splice(0, this.rows.length);
  this.rows.push(...this.projectService.instantSearch(val, this.ldList, name, ''))
  this.totalList = Object.keys(this.rows).length;
}
instantSearchDropDown(val, name) {
  this.rows.splice(0, this.rows.length);
  this.rows.push(...this.projectService.instantSearch(val, this.ldList, name, 'All'))
  this.totalList = Object.keys(this.rows).length;
}
///////////////////////////languages//////////////////
setLanguage(language) {
  this.loader.show();
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('language');
  srchFiltersValues.push(language);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.title = response.Items[0].lresidents;
    this.residents_resident_id = response.Items[0].residents_resident_id;
    this.residents_ic_no = response.Items[0].residents_ic_no;
    this.residents_name =response.Items[0].residents_name;
    this.residents_email = response.Items[0].residents_email;
    this.properties_house_number = response.Items[0].properties_house_number;
    this.properties_street = response.Items[0].properties_street;

    this.residents_password = response.Items[0].residents_password;
    this.residents_confirm_password = response.Items[0].residents_confirm_password;
    this.residents_nationality = response.Items[0].residents_nationality;
    this.residents_registration_date = response.Items[0].residents_registration_date;
    this.residents_gender = response.Items[0].residents_gender;
    this.residents_resident_status = response.Items[0].residents_resident_status;
    this.residents_telephone_1 = response.Items[0].residents_telephone_1;
    this.residents_telephone_2 = response.Items[0].residents_telephone_2;
    this.residents_resident_remarks = response.Items[0].residents_resident_remarks;
    this.residents_upload_profile_picture = response.Items[0].residents_upload_profile_picture;

    this.residents_create_resident = response.Items[0].residents_create_resident;
    this.residents_add_resident = response.Items[0].residents_add_resident;
    this.residents_edit_resident = response.Items[0].residents_edit_resident;
    this.residents_update_resident = response.Items[0].residents_update_resident;

    
    this.generals_search = response.Items[0].generals_search;
    this.generals_clear_search = response.Items[0].generals_clear_search;
    this.generals_view_details = response.Items[0].generals_view_details;
    this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
    this.generals_edit = response.Items[0].generals_edit;
    this.generals_delete = response.Items[0].generals_delete;
    this.generals_uploaded_files = response.Items[0].generals_uploaded_files;
    this.generals_actions = response.Items[0].generals_actions;
    this.residents_social_identity = response.Items[0].residents_social_identity;
    this.property_developers=response.Items[0].property_developers;
  }).catch(error => {
    this.loader.hide();
    console.log(error);
  });
}
retriveLanguages() {
  var status = 'Completed';
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('status');
  srchFiltersValues.push(status);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.languages = response.Items;
  }).catch(error => {
    console.log(error);
  });
}


}
