import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-my-pending-leads',
  templateUrl: './my-pending-leads.component.html',
  styleUrls: ['./my-pending-leads.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})



export class MyPendingLeadsComponent implements OnInit {



  //////////////////////get pd variables/////////////
  totalList: any;
  scan: boolean = false;
  scanResults = '';
  searchT: any = [];
  public rows = [];
  AllList = [];
  ///////nextaction variables////
  //////////////////////close get pd variables/////////////

  //////////get permission variables/////////////////////
  permissions_userlevel;
  permissions_view = false;
  permissions_create = false;
  permissions_edit = false;
  permissions_delete = false;
  //////////remarks variables/////////////////////
  currentDate: string;
  currentTime: string;
  userLevel: string;


  ///////////////////view variables////////////////////////

  viewT: any = [];
  NextActionValidation: boolean = false;
  upvalidate: boolean = false;
  uptoValidation: boolean = false;
  nextcontactSuccess = false;
  nextActionSuccess = false;

  product_interest_array;
  prefered_contact_method;

  ///////////////////approvallogscan variables////////////////////////
  approvalLogScan = false;
  scanResultsApproval = '';
  approvalLog = [];
  contacts = [];


  //////////////////////edit variables/////////////////////
  showSuccessMsg = false;
  editT: any = [];
  Added = '';
  showErrorMsg = false;
  Validation: boolean = false;

  /////////////////close edit variables////////////////////

  /////////////////delete variables////////////////////


  /////////////////country variables////////////////////
  public countryList = [];
  //////////////////drop down items list///////////
  preferredContactMethodselectedItemsList = [];
  productInterestselectedItemsList = [];
  public nextActionsMyPending = [];
  ////////////////////add remarks variables/////
  nextActionMsg;
  contact;
  ////////////next action varaible////
  nextActionsfromuser = [];
  colors;
  /////////////////API////////////////////
  apiNamepltstask = 'pltstask'; // replace this with your api name.
  apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API

  pathUpdate = "/propertydevelopers/update";
  apiName = 'propertydevelopers'; // replace this with your api name.
  path = '/propertydevelopers'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameWOApprovalLog = 'leadPropertyDevelopers'; // replace this with your api name.
  pathWOApprovalLog = '/leadPropertyDevelopers'; // replace this with the path you have configured on your API
  getWOApprovalLogInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  putWOApprovalLogInit = {
    body: {},
    headers: {}
  };
  apiNameNextActions = 'taskanduserlevel'; // replace this with your api name.
  pathNextActions = '/taskanduserlevel'; // replace this with the path you have configured on your API
  getMyInitNextActions = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  uploadPicsFolder = '/General/UsersProfile';
  uptValidation: boolean = false;

  productInterestDataList = []
  preferredContactMethodDataList = []
  albums = [];
  public timeList = [];
  selectedPics: FileList;
  selectedFiles: FileList;
  haveFiles: boolean = false;
  eventFiles;
  eventPics;
  pictures = [];
  files = [];
  totalFiles;
  totalPics;
  viewFiles = [];
  viewFilesNames = [];
  existingPics = [];
  havePictures: boolean = false;
  apiNameDelete = 'propertydevelopers'; // replace this with your api name.
  pathDelete = '/propertydevelopers/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  delete_results;
  next_action: any;
  profile_pic = [];
  public assign_to = [];

  apiNameUsers = 'users'; // replace this with your api name.
  pathUsers = '/users'; // replace this with the path you have configured on your API
  myInitUsers = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  constructor(private _lightbox: Lightbox, private uploadService: UploadFileService, private projectService: AppService, private loader: LoaderService, private datePipe: DatePipe) {
    this.countryList = projectService.countryList;
    this.productInterestDataList = this.projectService.productInterestDataList;
    this.preferredContactMethodDataList = this.projectService.preferredContactMethodDataList;
    this.timeList = projectService.timeList;
  }
  ngOnInit() {
    this.getAllNextActions();
    this.getPermissions();
    this.getDynamicNextActionsfromUserlevel();
    this.getUsers();



    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString('en-GB');
    this.userLevel = localStorage.getItem('user_level');

  }

  /////////////////// ////////////////////////next action/////////////////

  getUsers() {
    this.assign_to.splice(0, this.assign_to.length);

    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('property_developer');
    srchFiltersValues.push('ProperLy Business Development');


    this.projectService.scanContainsAnd(this.apiNameUsers, this.pathUsers, srchFiltersNames, srchFiltersValues).then(response => {
      for (let i = 0; i < response.length; i++) {
        this.assign_to.push(response[i].user_level);
      }
      this.assign_to.sort();
      console.log('\'users\' component-> All Users Retrieved Successfully!');
      console.log(this.assign_to);

    });

  }

  ///////////////////////selected items functions///////////////
  changeSelectionProductInterest() {
    this.fetchSelectedItemsProductInterest();
    //console.log(this.productInterestselectedItemsList);
  }

  fetchSelectedItemsProductInterest() {
    this.productInterestselectedItemsList = this.productInterestDataList.filter((value, index) => {
      return value.isChecked
    });
  }

  changeSelectionpreferredContactMethod() {
    this.fetchSelectedItemspreferredContactMethod();
    //console.log(this.preferredContactMethodselectedItemsList);
  }

  fetchSelectedItemspreferredContactMethod() {
    this.preferredContactMethodselectedItemsList = this.preferredContactMethodDataList.filter((value, index) => {
      return value.isChecked
    });
  }
  //////////////////pictures////////////////
  uploadAttachments(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }
  uploadAllFiles(event) {
    this.selectedFiles = event.target.files;
    this.eventFiles = event;
    this.haveFiles = true;
  }
  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Updated Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          console.log("Error in updated pic" + error);
          reject(error);
        });
      }
    });
  }
  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("File updated Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All files uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }
  updateUserWithPics(f) {

    console.log(f);
    if (f.invalid == true) {
      this.uptValidation = true;
    } else {
      this.loader.show();
      if (this.havePictures || this.haveFiles) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.onUpdate(f);
          this.havePictures = false;
          this.haveFiles = false;
        }).catch(error => {
          this.havePictures = false;
          this.haveFiles = false;
          console.log(error);
        });
      }
      else {
        this.onUpdate(f);
      }
    }
  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          //console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              //console.log(Response);
              resolve("Add  Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add  Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          //console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              //console.log(Response);
              resolve("Add  Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add  Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Now!")
      }
    });
  }
  openImageView(index: number): void {
    this._lightbox.open(this.albums, index);
  }
  prepareAlbumLightbox() {
    //check if viewPictures is null
    for (let i = 0; i < this.profile_pic.length; i++) {
      if (this.profile_pic[i] == null || this.profile_pic[i] == "") {
        this.profile_pic[i] = "NoURL";
      }
      const src = this.profile_pic[i];
      const caption = "";
      const thumb = "";
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this.albums.push(album);
    }
  }
  /////////////////get pending leads///////////////////////////

  getAllLeads(next_actions) {
    this.rows.splice(0, this.rows.length);
    let srchFiltersValues = [];
    let srchFiltersNames = [];
    srchFiltersNames.splice(0, srchFiltersNames.length);
    srchFiltersValues.splice(0, srchFiltersValues.length);

    for (let i = 0; i < next_actions.length; i++) {
      srchFiltersNames.push('status');
      srchFiltersValues.push(next_actions[i]);
    }
    this.scan = true;
    this.scanResults = "In Progress...";
    this.projectService.scanEqualsOr(this.apiName, this.path, srchFiltersNames, srchFiltersValues).then(response => {
      this.rows.push(...response.filter((obj) => obj.assign_to == 'All' || obj.assign_to == this.userLevel || obj.assign_to == '' || obj.assign_to == undefined));
      this.AllList.push(...this.rows);
      this.totalList = Object.keys(this.rows).length;
      this.scanResults = "Completed.";
      this.scan = false;
    });

  }

  /////////////////// ////////////////////////next action/////////////////
  getDynamicNextActionsToUserlevel() {
    this.AllList.splice(0, this.AllList.length);
    this.rows.splice(0, this.rows.length);
    this.scan = true;
    let nextActions = [];
    this.scanResults = 'In Progress...';
    nextActions.splice(0, nextActions.length);
    var pd = localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if (user_level) {

      const srchFiltersValues = [];
      const srchFiltersNames = [];


      if (pd != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(pd);
      }

      if (user_level != undefined) {
        srchFiltersNames.push('to_user_level');
        srchFiltersValues.push(user_level);
      }
      this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
        for (let i = 0; i < response.length; i++) {
          nextActions.push(response[i].task);
        }

        console.log(nextActions);
        this.getAllLeads(nextActions);
        this.scanResults = "Completed.";
        this.scan = false;
      });
    }
  }


  //////////////////////view//////////////////////
  onView(item) {
    this.NextActionValidation = false;
    this.nextActionSuccess = false;
    this.showSuccessMsg = false;
    this.nextcontactSuccess = false;
    this.viewT = item;

    console.log(this.viewT);

    // this.next_action=item.action;
    this.viewT.approver_remarks = "";
    this.product_interest_array = '';
    if (item.product_interest != undefined) {
      for (let i = 0; i < item.product_interest.length; i++) {
        this.product_interest_array = this.product_interest_array + item.product_interest[i].label + ","
      }
    }
    this.prefered_contact_method = '';
    if (item.prefered_contact_method != undefined) {
      for (let j = 0; j < item.prefered_contact_method.length; j++) {
        this.prefered_contact_method = this.prefered_contact_method + item.prefered_contact_method[j].label + ","
      }
    }

    $('#view').modal('show');
    this.searchRecordApprovalLog(item.id);
    if (item.pictures) {
      if (item.pictures.length > 0) {
        this.totalPics = item.pictures.length;
        if (item.pictures.length <= 50) {
          this.profile_pic = item.pictures;
        }
      }
    }
    else {
      this.profile_pic = ['https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png'];
    }
    if (item.files) {
      if (item.files.length > 0) {
        this.viewFiles = item.files;
        for (let i = 0; i < item.files.length; i++) {
          this.viewFilesNames.push(item.files[i].split('--')[1]);
        }
        this.totalFiles = this.viewFiles.length;
      }
    }
    this.prepareAlbumLightbox();

  }
  searchRecordApprovalLog(id) {
    this.approvalLogScan = true;
    this.scanResultsApproval = 'In Progress...';
    let unsortedApprovalLog = [];
    unsortedApprovalLog.splice(0, unsortedApprovalLog.length);
    this.approvalLog.splice(0, this.approvalLog.length);
    this.contacts.splice(0, this.contacts.length);
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const parentTicketId = id;
    //console.log(id);

    if (parentTicketId != undefined) {
      srchFiltersNames.push('parentTicketId');
      srchFiltersValues.push(parentTicketId);
    }

    this.projectService.scanContainsAnd(this.apiNameWOApprovalLog, this.pathWOApprovalLog, srchFiltersNames, srchFiltersValues).then(response => {
      unsortedApprovalLog.push(...response);
      const sorted = unsortedApprovalLog.sort((t1, t2) => {
        const name1 = t1.created_time;
        const name2 = t2.created_time;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.approvalLog.splice(0, this.approvalLog.length);
      this.approvalLog.push(...sorted);
      this.contacts.push(...sorted);
      this.approvalLog = this.approvalLog.filter((obj) => obj.action);
      this.contacts = this.contacts.filter((obj) => !obj.action);

      this.scanResultsApproval = 'Completed';
      this.approvalLogScan = false;
    });
  }
  getDynamicNextActionsfromUserlevel() {
    this.AllList.splice(0, this.AllList.length);
    this.scan = true;

    this.scanResults = 'In Progress...';
    this.nextActionsfromuser.splice(0, this.nextActionsfromuser.length);
    var pd = localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if (user_level) {

      const srchFiltersValues = [];
      const srchFiltersNames = [];


      if (pd != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(pd);
      }

      if (user_level != undefined) {
        srchFiltersNames.push('from_user_level');
        srchFiltersValues.push(user_level);
      }
      this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
        console.log(response);
        response.sort(function (a, b) {
          return a.order - b.order;
        });
        for (let i = 0; i < response.length; i++) {
          this.nextActionsfromuser.push(response[i].task);
        }
        this.scanResults = "Completed.";
        this.scan = false;
      });
    }
  }
  /////////////////add remarks functions////////////
  updateTicketNextAction(f) {
    console.log(f);
    console.log(f.approver_remarks);

    if (f.approver_remarks == undefined || f.status == undefined || f.approver_remarks == "" || f.status == "") {
      this.NextActionValidation = true;
    } else {
      this.loader.show();
      this.projectService.updateAnyFieldInAnyTable(f.id, "status", f.status, this.apiName, this.pathUpdate).then(response => {
        this.projectService.updateAnyFieldInAnyTable(f.id, "assign_to", f.assign_to, this.apiName, this.pathUpdate).then(response => {
          this.projectService.updateAnyFieldInAnyTable(f.id, "updated_date", this.datePipe.transform(new Date(), 'dd/MM/yyyy'), this.apiName, this.pathUpdate).then(response => {
            this.loader.hide();
            console.log(response);
            this.addApprovalLog(f.id, this.userLevel, f.status, f.approver_remarks);
          }).catch(error => {
            this.loader.hide();
            this.nextActionSuccess = true;
            this.nextActionMsg = 'Next Action Failed, Please Try again';
            console.log('\'Add tickets\' component-> Error in Creating Connection!');
            console.log(error);
          });
        }).catch(error => {
          this.loader.hide();
          this.nextActionSuccess = true;
          this.nextActionMsg = 'Next Action Failed, Please Try again';
          console.log('\'Add tickets\' component-> Error in Creating Connection!');
          console.log(error);
        });

      }).catch(error => {
        this.loader.hide();
        this.nextActionSuccess = true;
        this.nextActionMsg = 'Next Action Failed, Please Try again';
        console.log('\'Add tickets\' component-> Error in Creating Connection!');
        console.log(error);
      });
    }
  }
  addApprovalLog(ticketID, created_by, action, remarks) {
    const randomnumber = Math.floor(Math.random() * 99999999);
    const id = 'AL-' + randomnumber;

    const approvalLogBody = {
      id: id,
      parentTicketId: ticketID,
      created_date: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      created_time: new Date().toLocaleTimeString('en-GB'),
      created_by: created_by,
      action: action,
      remarks: remarks
    };
    this.putWOApprovalLogInit.body = approvalLogBody;
    API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
      this.loader.hide();
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Performed Successfully!';
      this.approvalLog.push(approvalLogBody);
      this.viewT.approver_remarks = "";
      //console.log('\'work-order-approval\' component-> Approval Log Recorded Successfully!');
      //console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Failed to Perform, Please Try Again';
      //console.log('\'work-order-approval\' component-> Error in  Recording Approval Log!');
      //console.log(error);
      this.loader.hide();
    });

  }
  ///////////////////////add contacts//////////////////
  updatecontacts(f) {
    console.log(f);
    if (f.name_n == "" || f.department_t == "" || f.designation_c == "" || f.telephone_e == "" || f.email_l == "" || f.remarks_s == "") {
      this.uptoValidation = true;
    }
    else if (f.name_n == undefined || f.department_t == undefined || f.designation_c == undefined || f.telephone_e == undefined || f.email_l == undefined || f.remarks_s == undefined) {
      this.uptoValidation = true;
    }
    else {

      this.addApprovalLogcontacts(f.id, this.userLevel, f.name_n, f.department_t, f.designation_c, f.telephone_e, f.email_l, f.remarks_s,);

    }
  }
  addApprovalLogcontacts(ticketID, created_by, name_n, department_t, designation_c, telephone_e, email_l, remarks_s) {
    const randomnumber = Math.floor(Math.random() * 99999999);
    const id = 'AL-' + randomnumber;

    const contactBody = {
      id: id,
      parentTicketId: ticketID,
      created_date: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      created_time: new Date().toLocaleTimeString('en-GB'),
      created_by: created_by,
      name_n: name_n,
      department_t: department_t,
      designation_c: designation_c,
      telephone_e: telephone_e,
      email_l: email_l,
      remarks_s: remarks_s,
    };
    this.putWOApprovalLogInit.body = contactBody;
    console.log(contactBody);
    API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
      this.loader.hide();
      this.nextcontactSuccess = true;
      this.contact = 'Contact Added Successfully!';
      // this.viewT.approver_remarks = "";
      //console.log('\'work-order-approval\' component-> Approval Log Recorded Successfully!');
      //console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      this.nextcontactSuccess = true;
      this.contact = 'Failed to Perform, Please Try Again';
      //console.log('\'work-order-approval\' component-> Error in  Recording Approval Log!');
      //console.log(error);
      this.loader.hide();
    });

  }
  //////////////////////edit function///////////////////
  onEdit(f) {
    console.log(f)
    this.uptValidation = false;
    this.Validation = false;
    this.showSuccessMsg = false;
    this.nextActionSuccess = false;
    this.editT = f;
    if (f.prefered_contact_method != undefined && f.product_interest.length != undefined) {
      this.preferredContactMethodselectedItemsList = f.prefered_contact_method;
      this.productInterestselectedItemsList = f.product_interest;
      for (var j = 0; j < f.product_interest.length; j++) {
        let index = this.productInterestDataList.findIndex(x => x.id === f.product_interest[j].id);
        if (index != -1) {
          this.productInterestDataList[index].isChecked = true;
        }
      }
      for (var i = 0; i < f.prefered_contact_method.length; i++) {
        let index = this.preferredContactMethodDataList.findIndex(x => x.id === f.prefered_contact_method[i].id);
        if (index != -1) {
          this.preferredContactMethodDataList[index].isChecked = true;
        }
      }
    }
    $('#view').modal('hide');
    setTimeout(function () {
      $('#edit_view').modal({
        backdrop: 'static'// to disable click close
      });
    }, 500);
  }
  onEditFromView(f) {

    this.Validation = false;
    this.showSuccessMsg = false;
    this.nextActionSuccess = false;
    this.editT = f;
    if (f.prefered_contact_method != undefined && f.product_interest.length != undefined) {
      this.preferredContactMethodselectedItemsList = f.prefered_contact_method;
      this.productInterestselectedItemsList = f.product_interest;
      for (var j = 0; j < f.product_interest.length; j++) {
        let index = this.productInterestDataList.findIndex(x => x.id === f.product_interest[j].id);
        if (index != -1) {
          this.productInterestDataList[index].isChecked = true;
        }
      }
      for (var i = 0; i < f.prefered_contact_method.length; i++) {
        let index = this.preferredContactMethodDataList.findIndex(x => x.id === f.prefered_contact_method[i].id);
        if (index != -1) {
          this.preferredContactMethodDataList[index].isChecked = true;
        }
      }
    }
    $('#view').modal('hide');
    setTimeout(function () {
      $('#edit_view').modal({
        backdrop: 'static'// to disable click close
      });
    }, 500);

  }
  onUpdate(f) {
    console.log(f);
    if (f.invalid == false) {

      // this.loader.show();
      f.form.value.pictures = this.pictures;
      f.form.value.files = this.files;
      f.form.value.updated_date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      f.form.value.product_interest = this.productInterestselectedItemsList;
      f.form.value.prefered_contact_method = this.preferredContactMethodselectedItemsList;
      if (f.form.get('date_scheduled').value != undefined) {
        f.form.value.date_scheduled = this.projectService.reformatDate(f.form.get('date_scheduled').value);
      }
      this.myInit.body = f.form.value;
      Object.keys(this.myInit.body).forEach(key => this.myInit.body[key] === undefined ? delete this.myInit.body[key] : {});
      API.put(this.apiName, this.path, this.myInit).then(response => {
        this.showSuccessMsg = true;
        this.Added = 'Updated Successfully.';
        console.log('\'all-residents\' component-> Update Successfully!');
        console.log(response);
        this.loader.hide();
        this.addApprovalLog(f.form.get('id').value, this.userLevel, f.form.get('status').value, f.form.get('approver_remarks').value);
        $('#edit_view').modal('hide');

      }).catch(error => {
        this.showErrorMsg = true;
        this.Added = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'all-residents\' component-> Error in update');
        console.log(error);
      });

    } else {
      this.Validation = true;

    }
  }
  //////////////search functions/////////////////
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.AllList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.AllList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.AllList);
    console.log(Filtered);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getDynamicNextActionsToUserlevel();
  }


  //////////permission function///////////////
  getPermissions() {
    this.projectService.getPermissions().then(response => {

      this.permissions_view = response[0].crm_view;
      this.permissions_create = response[0].crm_create;
      this.permissions_edit = response[0].crm_update;
      this.permissions_delete = response[0].crm_delete;
    }).catch(error => {
      console.log(error);
    })
  }

  //////////////////////////////////delete result////////////////////////////////////////////
  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getDynamicNextActionsToUserlevel();
  }
  getAllNextActions() {
    this.loader.show();
    API.get(this.apiNameNextActions, this.pathNextActions, this.getMyInitNextActions).then(response => {
      this.loader.hide();
      this.colors = response;
      console.log('\'project\' component-> All  nextActions Retrieved Successfully!');
      console.log(response);
      this.getDynamicNextActionsToUserlevel()
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }

  getTheColor(status) {
    return this.colors.filter(item => item.task === status && item.property_developer === localStorage.getItem('property_developer'));
  }

  getfinalColor(status) {
    if (this.getTheColor(status).length > 0 && this.getTheColor(status)[0].hasOwnProperty("Background")) {
      return this.getTheColor(status)[0].Background;
    } else {
      return '#FFFFFFF';
    }
  }

  getTheFont(status) {
    if (this.getTheColor(status).length > 0 && this.getTheColor(status)[0].hasOwnProperty("font")) {
      return this.getTheColor(status)[0].font;
    } else {
      return '#000000';
    }
  }
}
