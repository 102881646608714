import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { CookieService } from 'ngx-cookie-service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { TicketsLocalService } from 'src/app/services/tickets_local.service';
import { propertiesIDsLocalService } from 'src/app/services/propertiesIDsLocal.service';
import { Lightbox } from 'ngx-lightbox';
import { DatePipe, DOCUMENT } from '@angular/common';
import * as XLSX from 'xlsx';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';


declare const $: any;
type AOA = any[][];

const SCRIPT_PATH = 'https://app.smplrspace.com/lib/smplr.js';
declare let smplr: any;

@Component({
  selector: 'app-all-defects',
  templateUrl: './all-defects.component.html',
  styleUrls: ['./all-defects.component.css'],
  providers: [DatePipe]

})
export class AllDefectsComponent implements OnInit {
  excelData: AOA = [[1, 2], [3, 4]];

  public superAdminLoggedIn: boolean;
  public ticket_id_counter = 0;
  public userLevel;
  public userNickName;

  public myDatePickerOptions1: IMyDpOptions = {
    todayBtnTxt: 'Today',
    dateFormat: 'dd/mm/yyyy',
    firstDayOfWeek: 'su',
    sunHighlight: true,
    inline: false,
    height: '48px'
  };

  itemObj: any;

  DeleteFeedback = '';
  showDeletionResponse = false;

  eventPics;
  eventFiles;
  havePictures: boolean = false;
  haveFiles: boolean = false;

  properties = [];
  propertiesHouseNumberList = [];
  propertiesStreetsList = [];
  showSuccessMsg = false;

  totalPics;
  totalFiles;
  showErrorMsg = false;
  viewPictures = [];
  viewFiles = [];

  selectedPics: FileList;
  selectedFiles: FileList;

  nextActionSuccess = false;
  nextActionMsg;
  approvalLog = [];

  ticketScan = false;
  approvalLogScan = false;
  addRemarkFromAll = false;

  pictures = [];
  existingPics = [];
  files = [];
  existingFiles = [];

  propertiesList = [];

  enableZoom: Boolean = true;
  cont: any = [];
  scanResultsApproval = '';
  scanResults = '';

  TicketSubmitted = '';
  propertiesPrecinctList = [];

  rows = [];
  unsortedrows = [];
  unsortedApprovalLog = [];

  srch = [];

  viewT: any = [];
  editT: any = [];
  addT: any = [];
  searchT: any = [];

  next_action_from_edit;

  public timeList = [];
  public submitterActions = [];
  public statuses = [];

  public AllPd = [];
  public AllPdList = [];

  public super_Admin = 'Super Admin';
  public pla_admin = 'PLA Admin';
  public pla_frontdesk = 'PLA Frontdesk';
  public pla_supervisor = 'PLA Supervisor';
  public pd_admin = 'PD Admin';
  public pd_frontdesk = 'PD Frontdesk';
  public pd_supervisor = 'PD Supervisor';
  public pd_managment = 'PD Management';
  public con_admin = 'CON Admin';
  public con_supervisor = 'CON Supervisor';
  public con_crew = 'CON Crew';

  notificationsUrl = "https://fcm.googleapis.com/fcm/send";


  addWorkOrderValidation = false;
  addWorkOrderFlag = false;
  showPD = false;
  showfloorplan = true;

  totalList: any;


  arrayBuffer: any;
  file: File;

  currentDate: string;
  currentTime: string;

  albums = [];
  residentArray = [];
  residentname = "loading...";
  residentEmail = "loading...";
  residentphone = "loading...";

  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API
  getPropertiessInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  public propertyDevelopers = [];


  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  apiNameWOApprovalLog = 'woapprovallog'; // replace this with your api name.
  pathWOApprovalLog = '/woapprovallog'; // replace this with the path you have configured on your API
  getWOApprovalLogInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  putWOApprovalLogInit = {
    body: {},
    headers: {}
  };

  apiName = 'pltsdefect'; // replace this with your api name.
  path = '/pltsdefect'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };


  apiNameDelete = 'pltsdefect'; // replace this with your api name.
  pathDelete = '/pltsdefect/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  apiNameResident = 'residents'; // replace this with your api name.
  pathResident = '/residents'; // replace this with the path you have configured on your API
  putMyInitResident = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInitResident = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameNotifications = 'notifications'; // replace this with your api name.
  pathNotifications = '/notifications'; // replace this with the path you have configured on your API
  putMyInitNotifications = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };


  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  deleteID;
  delete_results;

  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';
  generals_uploaded_files = 'Uploaded Files';
  generals_download = 'Download';


  add_new_work_orders_add_work_order = 'Add New Defect';
  add_new_work_orders_created_by = 'Created By';
  add_new_work_orders_work_order_id = 'Defect ID';
  add_new_work_orders_street = 'Street';
  add_new_work_orders_house_number = 'House Number';
  add_new_work_orders_location = 'Location';
  add_new_work_orders_precinct_project = 'Precinct / Project';
  add_new_work_orders_element = 'Element';
  add_new_work_orders_floor = 'Floor';
  add_new_work_orders_defect = 'Defect';
  add_new_work_orders_costs = 'Costs';
  add_new_work_orders_competency = 'Competency';
  add_new_work_orders_invoice = 'Invoice';
  add_new_work_orders_days_to_execute = 'Days To Execute';
  add_new_work_orders_charge_to = 'Charge To';
  add_new_work_orders_priority = 'Priority';
  add_new_work_orders_main_contractor = 'Main Contractor';
  add_new_work_orders_next_action = 'Next Action';
  add_new_work_orders_date_scheduled = 'Date Scheduled';
  add_new_work_orders_cause = 'Cause';
  add_new_work_orders_time_scheduled = 'Time Scheduled';
  add_new_work_orders_am_pm = 'AM/PM';
  add_new_work_orders_work_order_description = 'Defect Description';
  add_new_work_orders_upload_pictures = 'Upload Pictures';
  add_new_work_orders_upload_documents_pdf_only = 'Upload Documents (PDF Only)';
  add_new_work_orders_create_new_work_order = 'Create Defect';
  all_work_orders_all_work_orders = 'All Defects';
  all_work_orders_print_work_order = 'Print Defect';
  my_pending_work_orders_my_pending_work_orders = 'My Pending Defects';
  my_pending_work_orders_refresh_list = 'Refresh List';
  my_pending_work_orders_add_work_order = 'Add Defect';
  work_order_details_my_pending_work_orders = 'My Pending Defects';
  work_order_details_print = 'Print';
  work_order_details_edit_work_order = 'Edit Defect';
  work_order_details_remarks_and_approval_log = 'Remarks And Approval Log';
  work_order_details_created_date = 'Created Date';
  work_order_details_created_time = 'Created Time';
  work_order_details_created_by = 'Created By';
  work_order_details_action = 'Action';
  work_order_details_remarks = 'Remarks';
  work_order_details_next_action = 'Next Action';

  dashboard_property_developer = 'Client';
  users_status = 'Status';
  work_order_details_days_open = 'Days Open';
  work_order_details_date_closed = 'Date Closed';
  work_order_details_update_work_order = 'Update Defect';
  work_order_details_work_order_details = 'Defect Details';
  work_order_details_resident_name = 'Resident Name';
  work_order_details_resident_phone = 'Resident Phone';
  work_order_details_time_closed = 'Time Closed';
  work_order_details_closed_by = 'Closed By';
  work_order_details_add_remark = 'Add Remark';

  add_new_work_orders_property_developer = 'Client';


  dataArray = [];
  permissions_userlevel;
  permissions_view = false;
  permissions_create = false;
  permissions_edit = false;
  permissions_delete = false;

  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  getMyInitCont = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';
  colors;
  public nextActions = [];
  nextActionLoad: Boolean = false;
  unsortedrows2 = [];
  apiNamepltstask = 'pltstask'; // replace this with your api name.
  apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API
  getpltstask = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameNextActions = 'taskanduserlevel'; // replace this with your api name.
  pathNextActions = '/taskanduserlevel'; // replace this with the path you have configured on your API
  getMyInitNextActions = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  constructor(
    private appService: AppService,
    private router: Router,
    private cookieService: CookieService,
    private loader: LoaderService,
    private ticketsService: TicketsLocalService,
    private propertiesIDsService: propertiesIDsLocalService,
    private uploadService: UploadFileService,
    private _lightbox: Lightbox,
    private datePipe: DatePipe,
    public http: HttpClient,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document

  ) {

    this.timeList = appService.timeList;
    this.statuses = this.appService.statuses;
    this.submitterActions = appService.pdAdminActions;
    this.userLevel = localStorage.getItem('user_level');
    this.userNickName = localStorage.getItem('nick_name');
    this.setVisibility();
  }


  ngOnInit() {
    this.getAllNextActions();
    this.getPropertyDevelopers();
    this.getProperties();
    this.getPermissions();
    this.getDynamicNextActions();
    this.getContractors();

    if (this.userLevel === 'PLA Admin' || this.userLevel === 'PLA Frontdesk' || this.userLevel === 'Super Admin') {
      this.showPD = true;
    }


    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      console.log(language);
      this.searchlanguage(language);
    } else {
      //console.log("null5");
    }

    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString('en-GB');
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }

  LoadSmplrSpace(reports) {
    console.log(reports);
    this.dataArray.push(reports);
    const scriptElement = this.loadJsScript(SCRIPT_PATH);
    scriptElement.onload = () => {
      console.log('Script loaded');
      console.log(smplr);
      const space = new smplr.Space({
        spaceId: reports.id,
        spaceToken: 'X',
        containerId: 'smplr-container'
      })
      space.startViewer({
        preview: true,
        mode: '2d',
        allowModeChange: false,
        onReady: () => {
          console.log('Viewer is ready');
          space.addDataLayer({
            id: 'reports',
            type: 'point',
            diameter: 0.6,
            data: this.dataArray,
            color: '#1e7bd3',
          })
        },
        onError: error => console.error('Could not start viewer', error)
      })
    }
    scriptElement.onerror = () => {
      console.log('Could not load the Script!');
    }
  }

  public loadJsScript(src: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  setVisibility() {
    if (this.userLevel === 'Super Admin') {
      this.superAdminLoggedIn = true;
    }
    if (this.userLevel === 'PD Admin' || this.userLevel === 'PLA Admin' || this.userLevel === 'Super Admin') {
      this.addWorkOrderFlag = true;
    }
  }

  onEdit(item) {
    localStorage.setItem('itemm', JSON.stringify(item));
    this.router.navigate(['work_orders/submit_work_order']);
  }

  onEditFromView(item) {
    localStorage.setItem('itemm', JSON.stringify(item));
    this.router.navigate(['work_orders/submit_work_order']);
  }

  updateEdittedTicket(f) {

    if (f.invalid === true) {
      this.addWorkOrderValidation = true;
    } else {
      this.loader.show();
      if (this.haveFiles || this.havePictures) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.updateTicket(f);
        }).catch(error => {
          console.log(error);
        });
      } else {
        this.updateTicket(f);
      }
    }
  }


  updateTicket(f) {

    var picstoUpload = [];
    var filestoUpload = [];

    if (this.pictures.length > 0) {
      picstoUpload = this.pictures;
      if (this.existingPics != undefined) {
        picstoUpload.push(...this.existingPics);
      }
    } else {
      picstoUpload = this.existingPics;
    }
    // console.log("existing pic"+this.existingPics);
    // console.log(this.pictures);
    // console.log(picstoUpload);

    if (this.files.length > 0) {
      filestoUpload = this.files;
      if (this.existingFiles != undefined) {
        filestoUpload.push(...this.existingFiles);
      }
    } else {
      filestoUpload = this.existingFiles;
    }
    // console.log("existing files"+this.existingFiles);
    // console.log(this.files);
    // console.log(filestoUpload);

    const ticketid = f.form.get('id').value.toString();
    const date_scheduled = f.form.get('date_scheduled').value;
    let date_scheduled_formatted = '';
    if (date_scheduled) {
      date_scheduled_formatted = date_scheduled.formatted;
    }
    if (f.invalid === true) {
      this.addWorkOrderValidation = true;
    } else {
      const createTicketBody = {
        created_by: f.form.get('created_by').value,
        id: f.form.get('id').value.toString(),
        house_number: f.form.get('house_number').value,
        street: f.form.get('street').value,
        subject: f.form.get('subject').value,
        days_to_execute: f.form.get('days_to_execute').value,
        priority: f.form.get('priority').value,
        floor: f.form.get('floor').value,
        location: f.form.get('location').value,
        element: f.form.get('element').value,
        defect: f.form.get('defect').value,
        cause: f.form.get('cause').value,
        costs: f.form.get('costs').value,
        ampm: f.form.get('ampm').value,
        days_open: f.form.get('days_open').value,
        next_action: f.form.get('next_action').value,
        next_action_by: this.getNextActionBy(f.form.get('next_action').value),
        main_contractor: f.form.get('main_contractor').value,
        date_scheduled: date_scheduled_formatted,
        time_scheduled: f.form.get('time_scheduled').value,
        invoice: f.form.get('invoice').value,
        charge_to: f.form.get('charge_to').value,
        created_date: f.form.get('created_date').value,
        description: f.form.get('description').value,
        status: f.form.get('next_action').value,
        closed_by: this.userNickName,
        date_closed: this.currentDate,
        time_closed: this.currentTime,
        images: picstoUpload,
        documents: filestoUpload

      };

      if (picstoUpload.length > 0) {
      } else {
        delete createTicketBody.images;
      }
      if (filestoUpload.length > 0) {
      } else {
        delete createTicketBody.documents;
      }
      if (f.form.get('costs').value === '') {
        delete createTicketBody.costs;
      }
      if (f.form.get('invoice').value === '') {
        delete createTicketBody.invoice;
      }
      if (f.form.get('charge_to').value === '') {
        delete createTicketBody.charge_to;
      }
      if (date_scheduled) {
      } else {
        delete createTicketBody.date_scheduled;
      }
      if (f.form.get('time_scheduled').value === '') {
        delete createTicketBody.time_scheduled;
      }
      if (f.form.get('ampm').value === '') {
        delete createTicketBody.ampm;
      }
      if ((this.getNextActionBy(f.form.get('next_action').value)) != 'Closed') {
        delete createTicketBody.closed_by;
        delete createTicketBody.date_closed;
        delete createTicketBody.time_closed;
      }


      this.putMyInit.body = createTicketBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.TicketSubmitted = 'Work order Updated Successfully.';
        this.saveNotifications("Defect (" + f.form.get('id').value.toString() + ") Updated", "Your Defect " + f.form.get('id').value.toString() + " has been Updated.", 'workorders', f.form.get('id').value.toString());

        this.havePictures = false;
        this.haveFiles = false;
        this.pictures.splice(0, this.pictures.length);
        this.files.splice(0, this.files.length);

        const index = this.rows.findIndex(function (item, i) {
          return item.id === ticketid;
        });

        if (index > -1) {
          f.form.value.status = f.form.get('next_action').value;
          this.rows[index] = f.form.value;
        }

        const randomnumber = Math.floor(Math.random() * 99999999);
        const id = 'AL-' + randomnumber;
        this.addApprovalLog(id, f.form.get('id').value.toString(), this.currentDate, this.currentTime, this.userNickName, 'Edited', 'This Defect Was Modified By ' + this.userNickName);

        const randomnumber2 = Math.floor(Math.random() * 99999999);
        const id2 = 'AL-' + randomnumber2;
        if (this.next_action_from_edit != f.form.get('next_action').value) {
          // console.log('next action changed'+this.editT);
          this.addApprovalLog(id2, f.form.get('id').value.toString(), this.currentDate, this.currentTime, this.userNickName, f.form.get('next_action').value.toString(), 'Status Modified By ' + this.userNickName + ' From ' + '\'' + this.next_action_from_edit + '\'' + ' To ' + '\'' + f.form.get('next_action').value.toString() + '\'');
        }

        console.log('\'tickets\' component-> Defect Updated Successfully.');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.TicketSubmitted = 'Defect Updation Failed, Please Retry!';
        console.log('\'tickets\' component-> Error in Updation  Ticket!');
        console.log(error.response);
        this.loader.hide();
      });
    }

  }

  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }

  uploadAllFiles(event) {
    this.selectedFiles = event.target.files;
    this.eventFiles = event;
    this.haveFiles = true;
  }


  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("File Uploaded Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All Files uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }

  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Ticket Now!")
      }
    });
  }

  searchRecord(f) {
    console.log(f.form.value);
    // console.log(f.form.value.status);
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    var property_developer;
    if (f.form.get('property_developer') != undefined) {
      property_developer = f.form.get('property_developer').value;
    }
    const status = f.form.get('status').value;
    const id = f.form.get('id').value;
    const house_number = f.form.get('house_number').value;
    const street = f.form.get('street').value;
    const precinct = f.form.get('precinct').value;
    const main_contractor = f.form.get('main_contractor').value;
    const subject = f.form.get('subject').value;
    const date_scheduled = f.form.get('date_scheduled').value;
    const priority = f.form.get('priority').value;
    const days_open = f.form.get('days_open').value;
    const created_date = f.form.get('created_date').value;
    const date_closed = f.form.get('date_closed').value;



    if (property_developer != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(property_developer);
    }
    if (f.form.value.status != undefined) {
      if (f.form.value.status == 'Open') {
        srchFiltersNames.push('status');
        srchFiltersValues.push('n');
      } else {
        srchFiltersNames.push('status');
        srchFiltersValues.push(status);
      }
    }
    if (id != undefined) {
      srchFiltersNames.push('id');
      srchFiltersValues.push(id);
    }
    if (house_number != undefined) {
      srchFiltersNames.push('house_number');
      srchFiltersValues.push(house_number);
    }
    if (street != undefined) {
      srchFiltersNames.push('street');
      srchFiltersValues.push(street);
    }
    if (precinct != undefined) {
      srchFiltersNames.push('precinct');
      srchFiltersValues.push(precinct);
    }
    if (main_contractor != undefined) {
      srchFiltersNames.push('main_contractor');
      srchFiltersValues.push(main_contractor);
    }
    if (subject != undefined) {
      srchFiltersNames.push('subject');
      srchFiltersValues.push(subject);
    }
    if (date_scheduled != undefined) {
      srchFiltersNames.push('date_scheduled');
      srchFiltersValues.push(this.reformatDate(date_scheduled));
    }
    if (priority != undefined) {
      srchFiltersNames.push('priority');
      srchFiltersValues.push(priority);
    }
    if (days_open != undefined) {
      srchFiltersNames.push('days_open');
      srchFiltersValues.push(days_open);
    }
    if (created_date != undefined) {
      srchFiltersNames.push('created_date');
      srchFiltersValues.push(this.reformatDate(created_date));
    }
    if (date_closed != undefined) {
      srchFiltersNames.push('date_closed');
      srchFiltersValues.push(this.reformatDate(date_closed));
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Defects';
    } else {
      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanTickets();
    }

  }

  scanTickets() {
    this.ticketScan = true;
    this.scanResults = 'In Progress...';
    this.getTickets(this.apiName, this.path, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);

      localStorage.setItem('workOrdersLocalStorage', JSON.stringify(this.rows));
      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanTickets();
      } else {
        this.scanResults = 'Completed';
        this.ticketScan = false;
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No Results Found for this Search Please Try Different Search parameters!';
        }
        this.srch.push(...this.rows);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      if (this.totalList != 0) {
        this.scanResults = 'Incomplete, Records List Reached to its maximum limit, Please Narrow Down your Search by Adding More Filters ';
      } else {
        this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
      }
    });
  }

  getTickets(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'tickets\' component->  Tickets Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'tickets\' component-> Error in Retreiving Tickets from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  getAll() {
    this.ticketScan = true;
    this.scanResults = 'In Progress!'
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.ticketScan = false;
      this.rows = response;
      this.srch.push(...response);
      this.totalList = Object.keys(this.rows).length;
      console.log('\'\' component-> All  Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.ticketScan = false;
      console.log('\'\' component-> Error in Retreiving All from server!');
      console.log(error);
    });
  }

  getNextActionBy(next_action) {

    if (next_action === 'Inspection' || next_action === 'WO Question') {
      return this.pd_supervisor;
    } else if (next_action === 'Execute') {
      return this.con_admin;
    } else if (next_action === 'On hold' || next_action === 'Temp closed' || next_action === 'Pending Information' || next_action === 'Resident Action') {
      return this.pd_admin;
    } else if (next_action === 'Closed') {
      return 'Closed';
    } else if (next_action === 'Schedule appointment') {
      return this.pd_admin;
    } else {
      return '';
    }
  }





  getProperties() {
    //this.loader.show();
    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesPrecinctList.push('');


    API.get(this.apiNameProperties, this.apiPathProperties, this.getPropertiessInit).then(response => {
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]);
        this.propertiesList = response.data;
        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();
        localStorage.setItem('propertiesPrecinctList', JSON.stringify(this.propertiesPrecinctList));
        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();
        localStorage.setItem('propertiesHouseNumberList', JSON.stringify(this.propertiesHouseNumberList));

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();
        localStorage.setItem('propertiesStreetsList', JSON.stringify(this.propertiesStreetsList));

      }

      this.loader.hide();
      console.log('\'tickets\' component-> All properties Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'tickets\' component-> Error in Retreiving All properties from server!');
      console.log(error.response);
    });
  }






  onDelete(c) {
    console.log(c.id);
    this.deleteID = c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed " + this.deleteID);
    const createBody = {
      "key": {
        "id": this.deleteID
      }
    }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete, this.pathDelete, this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results = "Deleted Successfully."
      // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results = "Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAll();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }




  onView(item) {
    //console.log(item);
    this.totalPics = "0";
    this.totalFiles = "0";


    if (item.floor_plan_report != undefined) {
      this.LoadSmplrSpace(item.floor_plan_report)
    } else {
      this.showfloorplan = false;
    }
    //  this.getHouseOwnerInfo(item);

    this.nextActionSuccess = false;
    this.viewPictures = [];
    this.viewFiles = [];


    if (item.date_closed == undefined) {
      item.days_open = this.differenceVetweenDates(this.currentDate.toString(), item.created_date).toString();
    } else {
      item.days_open = this.differenceVetweenDates(item.date_closed, item.created_date).toString();
    }

    this.searchRecordApprovalLog(item.id);

    if (item.images) {
      if (item.images.length > 0) {
        this.totalPics = item.images.length;
        if (item.images.length <= 50) {
          this.viewPictures = item.images;
        }
      }
    }
    if (item.documents) {
      if (item.documents.length > 0) {
        this.viewFiles = item.documents;
        this.totalFiles = this.viewFiles.length;
      }
    }
    this.viewT = item;
    $('#view_all_work_order').modal('show');

    this.prepareAlbumLightbox();
  }



  searchPriority(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === 'All Priorities') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
    } else {
      const filters = { priority: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchStatus(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { status: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchHouseNumber(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { house_number: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchStreet(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { street: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchWOid(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);

    } else {
      const filters = { id: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchContractor(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
    } else {
      const filters = { main_contractor: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;
      //   console.log(Filtered);
    }
  }

  searchSubject(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
    } else {
      const filters = { subject: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchDateCreated(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
    } else {
      const filters = { created_date: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  searchDateClosed(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
    } else {
      const filters = { date_closed: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }



  incomingfile(event) {
    this.file = event.target.files[0];
  }

  onFileUpload(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      // console.log(this.excelData);


      for (let i = 1; i < this.excelData.length; i++) {

        // console.log(this.excelData[i]);

        this.addTicketFromFile(this.excelData[i][0], this.excelData[i][1], this.excelData[i][2],
          this.excelData[i][3], this.excelData[i][4], this.excelData[i][5],
          this.excelData[i][6], this.excelData[i][7], this.excelData[i][8],
          this.excelData[i][9], this.excelData[i][10], this.excelData[i][11],
          this.excelData[i][12], this.excelData[i][13], this.excelData[i][14], this.excelData[i][15],
          this.excelData[i][16], this.excelData[i][17], this.excelData[i][18], this.excelData[i][19],
          this.excelData[i][20], this.excelData[i][21], this.excelData[i][22], this.excelData[i][23], this.excelData[i][24], this.excelData[i][25],
          this.excelData[i][26]
        );
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  addTicketFromFile(created_by, id, house_number, street, subject, days_to_execute, priority, floor,
    location, element, defect, cause, costs, days_open, next_action, main_contractor, date_scheduled, time_scheduled,
    ampm, invoice, charge_to, created_date, description, status, date_closed, time_closed, deleted) {

    const createTicketBody = {
      created_by: created_by,
      id: id,
      house_number: house_number,
      street: street,
      subject: subject,
      days_to_execute: days_to_execute,
      priority: priority,
      floor: floor,
      location: location,
      element: element,
      defect: defect,
      cause: cause,
      costs: costs,
      ampm: ampm,
      days_open: days_open,
      next_action: next_action,
      main_contractor: main_contractor,
      date_scheduled: date_scheduled,
      time_scheduled: time_scheduled,
      invoice: invoice,
      charge_to: charge_to,
      created_date: created_date,
      description: description,
      status: status,
      date_closed: date_closed,
      time_closed: time_closed,
      deleted: deleted
    };

    if (costs === '') {
      delete createTicketBody.costs;
    }
    if (invoice === '') {
      delete createTicketBody.invoice;
    }
    if (charge_to === '') {
      delete createTicketBody.charge_to;
    }
    if (date_scheduled === '') {
      delete createTicketBody.date_scheduled;
    }
    if (time_scheduled === '') {
      delete createTicketBody.time_scheduled;
    }
    if (ampm === '') {
      delete createTicketBody.ampm;
    }
    if (date_closed === '') {
      delete createTicketBody.date_closed;
    }
    if (time_closed === '') {
      delete createTicketBody.time_closed;
    }
    if (days_open === '') {
      delete createTicketBody.days_open;
    }

    this.putMyInit.body = createTicketBody;
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.TicketSubmitted = 'Ticket Submitted Successfully!';
      console.log('\'tickets\' component-> New Ticket Created Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.TicketSubmitted = 'Ticket Submission Failed, Please Retry!';
      console.log('\'tickets\' component-> Error in creating new Ticket!');
      console.log(error.response);
      this.loader.hide();
    });

  }



  searchRecordApprovalLog(id) {
    this.unsortedApprovalLog.splice(0, this.unsortedApprovalLog.length);
    this.approvalLog.splice(0, this.approvalLog.length);
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const parentTicketId = id;
    console.log(id);

    if (parentTicketId != undefined) {
      srchFiltersNames.push('parentTicketId');
      srchFiltersValues.push(parentTicketId);
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    this.getWOApprovalLogInit.body.FilterExpression = FilterExpression;
    this.getWOApprovalLogInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
    this.getWOApprovalLogInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
    this.scanApprovalLog();

  }

  scanApprovalLog() {

    this.approvalLogScan = true;
    this.scanResultsApproval = 'In Progress...';
    this.getApprovalLog(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.getWOApprovalLogInit).then(response => {

      this.unsortedApprovalLog.push(...response.Items);
      const sorted = this.unsortedApprovalLog.sort((t1, t2) => {
        const name1 = t1.created_time;
        const name2 = t2.created_time;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.approvalLog.splice(0, this.approvalLog.length);
      this.approvalLog.push(...sorted);

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getWOApprovalLogInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanApprovalLog();
      } else {
        this.scanResultsApproval = 'Completed';
        this.approvalLogScan = false;

        delete this.getWOApprovalLogInit.body['ExclusiveStartKey'];
        console.log(this.approvalLog);
        return;
      }
    }).catch(error => {
      console.log(error);
      this.scanResultsApproval = 'Sorry Some Error Occured While Scanning, Please Try again ';
    });
  }

  getApprovalLog(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'tickets-details\' component->  Approval Log Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'tickets-details\' component-> Error in Retreiving Approval Log from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }



  updateApprovalLog(approval: any) {
    console.log(approval);
    this.addApprovalLog(approval.id, approval.parentTicketId, approval.created_date, approval.created_time, approval.created_by, approval.action, approval.remarks);
  }

  addApprovalLog(id, ticketID, created_date, created_time, created_by, action, remarks) {

    const approvalLogBody = {
      id: id,
      parentTicketId: ticketID,
      created_date: created_date,
      created_time: created_time,
      created_by: created_by,
      action: action,
      remarks: remarks
    };
    this.putWOApprovalLogInit.body = approvalLogBody;

    API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Performed Successfully!';
      this.approvalLog.push(approvalLogBody);
      this.loader.hide();

      console.log('\'work-order-edit\' component-> Approval Log Recorded Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Failed to Perform, Please Try Again';
      console.log('\'work-order-edit\' component-> Error in  Recording Approval Log!');
      console.log(error);
    });
  }


  AddRemarkFromAllWorkOrder(f) {
    if (f.approver_remarks == undefined || f.approver_remarks == '') {
      this.addRemarkFromAll = true;
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Remark field was left empty. Add a new Remark';
    } else {
      this.loader.show();
      const randomnumber = Math.floor(Math.random() * 99999999);
      const id = 'AL-' + randomnumber;
      this.addRemarkFromAll = true;
      this.addApprovalLog(id, f.id, this.currentDate, this.currentTime, this.userNickName, f.status, f.approver_remarks);
      f.approver_remarks = '';
    }
  }


  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }

  differenceVetweenDates(dateStart, dateEnd): any {
    const start = dateStart;
    const end = dateEnd;


    const report_date_string = new String(start);
    const rectify_date_string = new String(end);

    const report_date_final = report_date_string.split('/');
    const month1 = report_date_final[0];
    const day1 = report_date_final[1];
    const year1 = report_date_final[2];

    const rectify_date_final = rectify_date_string.split('/');
    const month2 = rectify_date_final[0];
    const day2 = rectify_date_final[1];
    const year2 = rectify_date_final[2];

    const report_datetime = new Date(<any>year1, <any>day1, <any>month1 - 1);
    const rectify_datetime = new Date(<any>year2, <any>day2, <any>month2 - 1);
    const diff = Math.abs(((rectify_datetime.getTime() - report_datetime.getTime()) / (24 * 3600 * 1000)));
    return diff;
  }

  openImageView(index: number): void {
    this._lightbox.open(this.albums, index);
  }



  closeImageView(): void {
    this._lightbox.close();
  }

  prepareAlbumLightbox() {
    //check if viewPictures is null
    for (let i = 0; i < this.viewPictures.length; i++) {
      if (this.viewPictures[i] == null || this.viewPictures[i] == "") {
        this.viewPictures[i] = "NoURL";
      }
      const src = this.viewPictures[i];
      const caption = "";
      const thumb = "";
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this.albums.push(album);
    }
  }


  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAll();
  }

  ngOnDestroy() {
    $('#add_ticket').modal('hide');
    $('#edit_work_order').modal('hide');
    $('#delete_work_order').modal('hide');
    $('#view_all_work_order').modal('hide');
  }

  getHouseOwnerInfo(item) {
    this.residentname = "loading...";
    this.residentEmail = "loading...";
    this.residentphone = "loading...";
    var filter = {
      street: item.street,
      house_number: item.house_number
    };
    var Filtered = this.propertiesList.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });
    // var house_owner = Filtered[0].house_owner;
    // this.searchResident(house_owner);

  }

  searchResident(house_owner) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    if (house_owner != undefined) {
      srchFiltersNames.push('name');
      srchFiltersValues.push(house_owner);
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {

    } else {
      this.residentArray.splice(0, this.residentArray.length);
      this.getMyInitResident.body.FilterExpression = FilterExpression;
      this.getMyInitResident.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitResident.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanResident();
    }

  }

  scanResident() {

    this.getResident(this.apiNameResident, this.pathResident, this.getMyInitResident).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.residentArray.splice(0, this.rows.length);
      this.residentArray.push(...sorted);

      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanResident();
      } else {
        this.residentname = this.residentArray[0].name;
        this.residentEmail = this.residentArray[0].email;
        this.residentphone = this.residentArray[0].telephone1;
        console.log(this.residentArray);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getResident(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'resident\' component->  resident Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'resident\' component-> Error in Retreiving resident from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  onChangePrecinct(precinct) {
    //console.log(precinct);
    //console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();
  }

  onChangeStreet(street) {
    //console.log(street);
    //console.log(this.propertiesList);
    var p = [];
    var t = [];
    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      var y = arrayItem.main_contractor;
      p.push(x);
      t.push(y);
    });

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push('');
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();
  }

  reformatDate(dateStr) {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
  }

  getPropertyDevelopers() {
    var p = [];

    this.propertyDevelopers.push('');
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.propertyDevelopers.push(...response.data);
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead" && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Properties\' component-> All Client Retrieved Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'Properties\' component-> Error in Retreiving All Property Developers from server!');
      console.log(error.response);
    });
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Defects';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rows.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        this.add_new_work_orders_add_work_order = this.rowslanguages[0].add_new_work_orders_add_work_order;
        this.add_new_work_orders_created_by = this.rowslanguages[0].add_new_work_orders_created_by;
        this.add_new_work_orders_work_order_id = this.rowslanguages[0].add_new_work_orders_work_order_id;
        this.add_new_work_orders_street = this.rowslanguages[0].add_new_work_orders_street;
        this.add_new_work_orders_house_number = this.rowslanguages[0].add_new_work_orders_house_number;
        this.add_new_work_orders_location = this.rowslanguages[0].add_new_work_orders_location;
        this.add_new_work_orders_precinct_project = this.rowslanguages[0].add_new_work_orders_precinct_project;
        this.add_new_work_orders_element = this.rowslanguages[0].add_new_work_orders_element;
        this.add_new_work_orders_floor = this.rowslanguages[0].add_new_work_orders_floor;
        this.add_new_work_orders_defect = this.rowslanguages[0].add_new_work_orders_defect;
        this.add_new_work_orders_costs = this.rowslanguages[0].add_new_work_orders_costs;
        this.add_new_work_orders_competency = this.rowslanguages[0].add_new_work_orders_competency;
        this.add_new_work_orders_invoice = this.rowslanguages[0].add_new_work_orders_invoice;
        this.add_new_work_orders_days_to_execute = this.rowslanguages[0].add_new_work_orders_days_to_execute;
        this.add_new_work_orders_charge_to = this.rowslanguages[0].add_new_work_orders_charge_to;
        this.add_new_work_orders_priority = this.rowslanguages[0].add_new_work_orders_priority;
        this.add_new_work_orders_main_contractor = this.rowslanguages[0].add_new_work_orders_main_contractor;
        this.add_new_work_orders_next_action = this.rowslanguages[0].add_new_work_orders_next_action;
        this.add_new_work_orders_date_scheduled = this.rowslanguages[0].add_new_work_orders_date_scheduled;
        this.add_new_work_orders_cause = this.rowslanguages[0].add_new_work_orders_cause;
        this.add_new_work_orders_time_scheduled = this.rowslanguages[0].add_new_work_orders_time_scheduled;
        this.add_new_work_orders_am_pm = this.rowslanguages[0].add_new_work_orders_am_pm;
        this.add_new_work_orders_work_order_description = this.rowslanguages[0].add_new_work_orders_work_order_description;
        this.add_new_work_orders_upload_pictures = this.rowslanguages[0].add_new_work_orders_upload_pictures;
        this.add_new_work_orders_upload_documents_pdf_only = this.rowslanguages[0].add_new_work_orders_upload_documents_pdf_only;
        this.add_new_work_orders_create_new_work_order = this.rowslanguages[0].add_new_work_orders_create_new_work_order;
        this.all_work_orders_all_work_orders = this.rowslanguages[0].all_work_orders_all_work_orders;
        this.all_work_orders_print_work_order = this.rowslanguages[0].all_work_orders_print_work_order;
        this.my_pending_work_orders_my_pending_work_orders = this.rowslanguages[0].my_pending_work_orders_my_pending_work_orders;
        this.my_pending_work_orders_refresh_list = this.rowslanguages[0].my_pending_work_orders_refresh_list;
        this.my_pending_work_orders_add_work_order = this.rowslanguages[0].my_pending_work_orders_add_work_order;
        this.work_order_details_my_pending_work_orders = this.rowslanguages[0].work_order_details_my_pending_work_orders;
        this.work_order_details_print = this.rowslanguages[0].work_order_details_print;
        this.work_order_details_edit_work_order = this.rowslanguages[0].work_order_details_edit_work_order;
        this.work_order_details_remarks_and_approval_log = this.rowslanguages[0].work_order_details_remarks_and_approval_log;
        this.work_order_details_created_date = this.rowslanguages[0].work_order_details_created_date;
        this.work_order_details_created_time = this.rowslanguages[0].work_order_details_created_time;
        this.work_order_details_created_by = this.rowslanguages[0].work_order_details_created_by;
        this.work_order_details_action = this.rowslanguages[0].work_order_details_action;
        this.work_order_details_remarks = this.rowslanguages[0].work_order_details_remarks;
        this.work_order_details_next_action = this.rowslanguages[0].work_order_details_next_action;

        this.dashboard_property_developer = this.rowslanguages[0].dashboard_property_developer;
        this.users_status = this.rowslanguages[0].users_status;
        this.work_order_details_days_open = this.rowslanguages[0].work_order_details_days_open;
        this.work_order_details_date_closed = this.rowslanguages[0].work_order_details_date_closed;
        this.work_order_details_update_work_order = this.rowslanguages[0].work_order_details_update_work_order;
        this.work_order_details_work_order_details = this.rowslanguages[0].work_order_details_work_order_details;
        this.work_order_details_resident_name = this.rowslanguages[0].work_order_details_resident_name;
        this.work_order_details_resident_phone = this.rowslanguages[0].work_order_details_resident_phone;
        this.work_order_details_time_closed = this.rowslanguages[0].work_order_details_time_closed;
        this.work_order_details_closed_by = this.rowslanguages[0].work_order_details_closed_by;
        this.work_order_details_add_remark = this.rowslanguages[0].work_order_details_add_remark;

        this.add_new_work_orders_property_developer = this.rowslanguages[0].properties_property_developer;

        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_creation_date = this.rowslanguages[0].generals_creation_date;
        this.generals_actions = this.rowslanguages[0].generals_actions;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_download = this.rowslanguages[0].generals_download;

        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }



  saveNotifications(title, body, moduleName, id) {
    const randomnumber = Math.floor(Math.random() * 999999999);
    const createBody = {
      id: randomnumber.toString(),
      title: title,
      body: body,
      deepLinkArticleId: id,
      moduleName: moduleName,
      creation_date: this.currentDate,
      creation_time: this.currentTime
    };
    this.putMyInitNotifications.body = createBody;
    API.put(this.apiNameNotifications, this.pathNotifications, this.putMyInitNotifications).then(response => {
      console.log('\'notifications\' component-> New notification added Successfully!');
      console.log(response);
      this.sendNotifications(title, body, moduleName, id);

      this.loader.hide();
    }).catch(error => {
      console.log('\'notifications\' component-> Error in creating new notifications!');
      console.log(error.response);
    });
  }

  sendNotifications(title, body, moduleName, id) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=' + "AAAA5mIllzA:APA91bH-wa6UVq3IvGUIE4oliUiVO1t3tWu5DqocJG8y9p1O9grxBC05dwLCVIxkftFsoXKcpqsK3MHNZaaNM0Wo9xs0vfndkUGGJRqJ9sEk91JJNhtTkNXnQ3RJBxQVETXiZBeuReKL"
    });

    var callibayaqPlzObj = {
      "to": "/topics/houseResidents",
      "notification": {
        "body": body,
        "content_available": true,
        "priority": "high",
        "title": title
      },
      "data": {
        "sound": true,
        "module": moduleName,
        "deepLinkArticleId": id,
        "content_available": true,
        "priority": "high",
        "title": "Testing Deep Linking"
      }
    }

    this.http.post(this.notificationsUrl, callibayaqPlzObj, { headers }).subscribe(res => {
      console.log(res);
    }, error => {
      console.log(error);
    });
  }

  getPermissions() {
    API.get(this.apiNamePermissions, this.apiPathPermissions, this.getPermissionsInit).then(response => {
      this.loader.hide();
      this.permissions_userlevel = localStorage.getItem('user_level');
      const filters = { user_level: [this.permissions_userlevel] },
        results = response.data,
        Filtered = this.multiFilter(results, filters);

      this.permissions_view = Filtered[0].work_orders_view;
      this.permissions_create = Filtered[0].work_orders_create;
      this.permissions_edit = Filtered[0].work_orders_update;
      this.permissions_delete = Filtered[0].work_orders_delete;


      console.log(Filtered);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Sidebar\' component-> Error in Retreiving All permissions from server!' + error);
      console.log(error.response);
    });
  }

  getDynamicNextActions() {
    this.nextActions.splice(0, this.nextActions.length);
    var pd = localStorage.getItem('property_developer');
    // //console.log(user_level);
    this.nextActionLoad = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    if (pd != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(pd);
    }

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    //console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    //console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    //console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {

    } else {
      this.unsortedrows2.splice(0, this.unsortedrows2.length);
      this.nextActions.splice(0, this.nextActions.length);
      this.getpltstask.body.FilterExpression = FilterExpression;
      this.getpltstask.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getpltstask.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanTask();
    }

  }

  scanTask() {
    this.nextActions.push('');
    let unsortedrows2 = [];
    this.getTask(this.apiNamepltstask, this.apiPathpltstask, this.getpltstask).then(response => {
      unsortedrows2.push(...response.Items);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getpltstask.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanTask();
      } else {
        this.nextActions.push(...this.appService.sortByKey(unsortedrows2, 'order'));
        this.nextActionLoad = false;
        delete this.getpltstask.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getTask(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        //console.log('\'Location\' component->  For Location Next Actions (tasks) Retrieved Successfully!');
        //console.log(response);
        resolve(response);
      }).catch(error => {
        //console.log('\'Location\' component-> For Location Error in Retreiving Next Actions (tasks) from server!');
        //console.log(error.response);
        reject(error.response);
      });

    });
  }


  getContractors() {
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    srchFiltersNames.splice(0, srchFiltersNames.length);
    srchFiltersValues.splice(0, srchFiltersValues.length);
    var pd = localStorage.getItem('property_developer');

    if (pd != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(pd);
    }


    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    this.getMyInitCont.body.FilterExpression = FilterExpression;
    this.getMyInitCont.body.ExpressionAttributeNames = ExpressionAttributeNames;
    this.getMyInitCont.body.ExpressionAttributeValues = ExpressionAttributeValues;
    this.scanContractors();

  }


  scanContractors() {
    this.getCont(this.apiNameContractors, this.apiPathContractors, this.getMyInitCont).then(response => {
      this.cont.push(...response.Items);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitCont.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanContractors();
      } else {
        console.log(this.cont);
        delete this.getMyInitCont.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
    });
  }


  getCont(apiName, apiPath, getMyInitCont): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInitCont).then(response => {
        console.log('\'tickets\' component->  cont Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'tickets\' component-> Error in Retreiving cont from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  getAllNextActions() {
    this.loader.show();
    API.get(this.apiNameNextActions, this.pathNextActions, this.getMyInitNextActions).then(response => {
      this.loader.hide();
      this.colors = response;
      console.log('\'project\' component-> All  nextActions Retrieved Successfully!');
      console.log(response);
      this.getAll()
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }

  getTheColor(status) {
    return this.colors.filter(item => item.task === status && item.property_developer === localStorage.getItem('property_developer'));
  }

  getfinalColor(status) {
    if (this.getTheColor(status).length > 0 && this.getTheColor(status)[0].hasOwnProperty("Background")) {
      return this.getTheColor(status)[0].Background;
    } else {
      return '#FFFFFFF';
    }
  }

  getTheFont(status) {
    if (this.getTheColor(status).length > 0 && this.getTheColor(status)[0].hasOwnProperty("font")) {
      return this.getTheColor(status)[0].font;
    } else {
      return '#000000';
    }
  }


}
