import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ISlimScrollOptions } from 'ngx-slimscroll';
import { LoaderService } from '../loader/loader.service';
import { API } from 'aws-amplify';
import { AppService } from '../app.service';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  opts: ISlimScrollOptions;
  public url;
  url2;
  homeView = true;
  chatView = false;
  mailView = false;
  messageView = false;
  composeView = false;
  settingsView = false;
  callView = false;
  taskView = false;
  userLevel ;
  
  
  
  language;

  payments = "Payments";
  pp = "Precincts/Projects";
  tu = "Template Uploads";
  lresidents = "All Lead Residents";
  epayments = "eGHL Dashboard";
  properties = "Properties";
  abookings = "Bookings";
  apayments = "All Invoices";
  sservices = "Sub Category";
  work_orders = "Work Orders";
  spp = "Sub Precincts/Projects/Condos";
  rtu = "Residents";
  ptu = "Properties";
  vbookings = "Venues";
  cservices = "Category";
  adwork_orders = "Add New Work Order";
  cause = "Cause";
  contractors = "Contractors";
  element = "Element";
  residents = "Residents";
  location_re = "Location";
  property_developers = "Clients";
  competency = "Competency";
  alanguages = "All Languages";
  settings = "Settings";
  services = "Services";
  dashboard = "Reports & Stats";
  led = "LED";
  priority = "Priority";
  pwork_orders = "My Pending Work Orders";
  aresidents = "All Residents";
  bookings = "Bookings";
  aservices = "Services";
  awork_orders = "All Work Orders";
  bth = "Back To Home";
  na = "Next Action";
  dte = "Days To Execute";
  defect = "Defect"
  module_permission = "Permissions";
  tou = "Type Of Unit";
  users = "Users";
  languages_title = "Languages";
  announcements = "Announcements";


  permissions_userlevel;
  permissionsobj;


  
  rows = [];
  unsortedrows = [];



  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiName = 'languages'; // replace this with your api name.
  path = '/languages'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  constructor(private appService: AppService,private location: Location,private loader: LoaderService, private router: Router, private activatedRoute: ActivatedRoute, private renderer: Renderer2) {

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        $('.modal').modal('hide');
        // console.log(event.url);
      }

      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')[1];
        this.url2 = event.url.split('/')[2];
        // console.log(event.url);
        // console.log(this.url);
        // console.log(this.url2);

        const height = $(window).height();
        $('.page-wrapper').css('min-height', height);

        $('.main-wrapper').removeClass('slide-nav-toggle');
        $('#chat_sidebar').removeClass('opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.task-overlay').removeClass('opened');

        if (this.url == 'settings') {
          this.homeView  = false;
          this.chatView  = false;
          this.mailView  = false;
          this.settingsView = true;
          this.messageView = false;
          this.composeView = false;
          this.callView = false;
          this.taskView = false;
         }
     
      }

      if (event instanceof NavigationError) {
        // console.log(event.error);
      }
    });

  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
    this.getPermissions();
    this.getUnconfirmedStores();
    this.getnewLeadStores();

    if(this.language!='undefined' && this.language!='English'){
    //  console.log("Languagerizi: "+this.language);
      this.searchlanguage(this.language);
    }else{
     // console.log("Languagerizi:  English");
    }

    this.opts = {
      barBackground: '#ccc',
      gridBackground: 'transparent',
      barOpacity: '0.4',
      barBorderRadius: '6',
      barWidth: '6',
      gridWidth: '0',
      alwaysVisible: false,
      // height:'100%'
    };

    const h = $(window).height() - 60;
    $('.slimscroll-wrapper').height(h);

    $(window).resize(function() {
    const h = $(window).height() - 60;
    $('.slimscroll-wrapper').height(h);
    });
  }


    getPermissions() {
    API.get(this.apiNamePermissions, this.apiPathPermissions, this.getPermissionsInit).then(response => {
      this.permissions_userlevel = localStorage.getItem('user_level');
      const filters = { user_level: [this.permissions_userlevel] },
      results = response.data,
      Filtered = this.multiFilter(results, filters);
      this.permissionsobj=Filtered[0];
      console.log(this.permissionsobj);
    }).catch(error => {
      console.log('\'Sidebar\' component-> Error in Retreiving All permissions from server!'+error);
      console.log(error.response);
    });
  }
  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].includes(v))));
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {

    this.getLanguage(this.apiName, this.path, this.getMyInit).then(response => {
      console.log(response.Items);

      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
       // console.log(this.rows);
        this.payments = this.rows[0].payments;
        this.pp = this.rows[0].pp;
        this.tu = this.rows[0].tu;
        this.lresidents = this.rows[0].lresidents;
        this.epayments = this.rows[0].epayments;
        this.properties = this.rows[0].properties;
        this.abookings = this.rows[0].abookings;
        this.apayments = this.rows[0].apayments;
        this.sservices = this.rows[0].sservices;
        this.work_orders = this.rows[0].work_orders;
        this.spp = this.rows[0].spp;
        this.rtu = this.rows[0].rtu;
        this.ptu = this.rows[0].ptu;
        this.vbookings = this.rows[0].vbookings;
        this.cservices = this.rows[0].cservices;
        this.adwork_orders = this.rows[0].adwork_orders;
        this.cause = this.rows[0].cause;
        this.contractors = this.rows[0].contractors;
        this.element = this.rows[0].element;
        this.residents = this.rows[0].residents;
        this.location_re = this.rows[0].location;
        this.property_developers = this.rows[0].property_developers;
        this.competency = this.rows[0].competency;
        this.alanguages = this.rows[0].alanguages;
        this.settings = this.rows[0].settings;
        this.services = this.rows[0].services;
        this.dashboard = this.rows[0].dashboard;
        this.led = this.rows[0].led;
        this.priority = this.rows[0].priority;
        this.pwork_orders = this.rows[0].pwork_orders;
        this.aresidents = this.rows[0].aresidents;
        this.bookings = this.rows[0].bookings;
        this.aservices = this.rows[0].aservices;
        this.awork_orders = this.rows[0].awork_orders;
        this.bth = this.rows[0].bth;
        this.na = this.rows[0].na;
        this.dte = this.rows[0].dte;
        this.defect = this.rows[0].defect;
        this.module_permission = this.rows[0].module_permission;
        this.tou = this.rows[0].tou;
        this.users = this.rows[0].users;
        this.languages_title = this.rows[0].languages_title;
        this.announcements = this.rows[0].announcements;


        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }


  usersCompleteList:boolean=false;
  usersComplete:boolean=false;
    /////////////////API////////////////////
    apiNamepltstask = 'propertydevelopers'; // replace this with your api name.
    apiPathpltstask = '/propertydevelopers'; // replace this with the path you have configured on your API
  
  apiNameUsers = 'users'; // replace this with your api name.
  apiPathUsers = '/users'; // replace this with the path you have configured on your API
  getUnconfirmedStores() {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

      srchFiltersNames.push('status');
      srchFiltersValues.push('Unconfirmed');

    this.appService.scanEqualsOr(this.apiNameUsers, this.apiPathUsers, srchFiltersNames, srchFiltersValues).then(response => {
      console.log(response);
      if(response.length>0){
        this.usersCompleteList=true;
      }else{
        this.usersCompleteList=false;
      }
    
    }).catch(error => {
      console.log(error);
    });
  }
  getnewLeadStores() {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

      srchFiltersNames.push('status');
      srchFiltersValues.push('New Lead');

    this.appService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
      console.log(response);
      console.log("helkoooppooo");
      if(response.length>0){
        console.log("echeck");
        console.log(response);
        this.usersComplete=true;

      }else{
        console.log("echeckkk");
        console.log(response);
        this.usersComplete=false;
      }
    
    }).catch(error => {
      console.log(error);
    });
  }

}

