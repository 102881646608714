import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { API } from 'aws-amplify';

declare const $: any;
type AOA = any[][];
@Component({
  selector: 'app-tempate-upload-workorders',
  templateUrl: './tempate-upload-workorders.component.html',
  styleUrls: ['./tempate-upload-workorders.component.css']
})
export class TempateUploadWorkordersComponent implements OnInit {

  uploadFile = false;
  Results = '';
  excelData: AOA = [[1, 2], [3, 4]];
  showSuccessMsg = false;
  showErrorMsg = false;
  ResidentAdded = '';


  apiName = 'workorders'; // replace this with your api name.
  path = '/workorders'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  myInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  constructor() { }

  ngOnInit() {
  }

  DownloadTemplate() {
   window.open("https://workordersfiles920.s3.amazonaws.com/General/TemplateUploads/workOrdersTemplateUpdated.xlsx");
  }

  onFileUpload(evt: any) {
    this.showErrorMsg=false;
    this.uploadFile = false;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { 
    this.showErrorMsg = true;
    this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
    throw new Error('Cannot use multiple files');
   }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.excelData);

      if(this.excelData.length>=2){
      if (this.excelData[1].length == 28) {
        this.uploadFile = true;
        this.Results = "File Uploaded Successfully! Please Press Submit To Upload.";
        // this.asynccalltoupload(this.excelData);
      }else {
        this.showErrorMsg = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    } else {
      this.showErrorMsg = true;
      this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  async asynccalltoupload(excelData) {
    for (var i = 2; i < excelData.length; i++) {
      await new Promise<void>(resolve => {
        this.addTicketFromFile(this.excelData[i][0], this.excelData[i][1], this.excelData[i][2],
          this.excelData[i][3], this.excelData[i][4], this.excelData[i][5],
          this.excelData[i][6], this.excelData[i][7], this.excelData[i][8],
          this.excelData[i][9], this.excelData[i][10], this.excelData[i][11],
          this.excelData[i][12], this.excelData[i][13], this.excelData[i][14], this.excelData[i][15],
          this.excelData[i][16], this.excelData[i][17], this.excelData[i][18], this.excelData[i][19],
          this.excelData[i][20], this.excelData[i][21], this.excelData[i][22], this.excelData[i][23], this.excelData[i][24], this.excelData[i][25],this.excelData[i][26],this.excelData[i][27],
          i
        ).then(response => {
          console.log('This is iteration ' + i);
          resolve();
        }).catch(error => {
          console.log(error);
        });
      });
    }
    this.Results = "Completed. Please Go to Work Orders Module to view your Uploaded WorkOrders.";
  }


  addTicketFromFile(created_by, id,property_developer, precinct, street, house_number, subject, days_to_execute, priority, floor,
    location, element, defect, cause, costs, days_open, next_action, main_contractor, date_scheduled, time_scheduled,
    ampm, invoice, charge_to, created_date, description, status, date_closed, time_closed,i) {

      const createTicketBody = {
        created_by: created_by,
        id: id,
        property_developer:property_developer,
        precinct: precinct,
        house_number: house_number,
        street: street,
        subject: subject,
        days_to_execute: days_to_execute,
        priority: priority,
        floor: floor,
        location: location,
        element: element,
        defect: defect,
        cause: cause,
        costs: costs,
        ampm: ampm,
        days_open: days_open,
        next_action: next_action,
        main_contractor: main_contractor,
        date_scheduled: date_scheduled,
        time_scheduled: time_scheduled,
        invoice: invoice,
        charge_to: charge_to,
        created_date: created_date,
        description: description,
        status: status,
        date_closed: date_closed,
        time_closed: time_closed,
      };
  
      if (costs === '') {
        delete createTicketBody.costs;
      }
      if (invoice === '') {
        delete createTicketBody.invoice;
      }
      if (charge_to === '') {
        delete createTicketBody.charge_to;
      }
      if (date_scheduled === '') {
        delete createTicketBody.date_scheduled;
      }
      if (time_scheduled === '') {
        delete createTicketBody.time_scheduled;
      }
      if (ampm === '') {
        delete createTicketBody.ampm;
      }
      if (date_closed === '') {
        delete createTicketBody.date_closed;
      }
      if (time_closed === '') {
        delete createTicketBody.time_closed;
      }
      if (days_open === '') {
        delete createTicketBody.days_open;
      }
  
    this.myInit.body = createTicketBody;
    console.log(createTicketBody);
    return new Promise<string>((resolve, reject) => {
    API.put(this.apiName, this.path, this.myInit).then(response => {
      this.showSuccessMsg = true;
      this.ResidentAdded = i+": "+id + ' Added Successfully!';
      console.log('\'all-residents\' component-> New Workorder Created Successfully!');
      console.log(response);
      resolve(response);
    }).catch(error => {
      this.showErrorMsg = true;
      this.Results = id + ' Workorder Creation Failed, Please Retry!';
      console.log('\'all-residents\' component-> Error in creating new Workorder!');
      console.log(error.response);
      reject(error.response);
    });
  });

  }

  submit(){
    this.Results='Under Progress, Please Wait.'
    this.asynccalltoupload(this.excelData);
  }


}
