import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute, UrlSerializer } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AppService } from 'src/app/app.service';
import { UploadFileService } from 'src/app/services/upload-file.service';


@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css'],
  providers: [DatePipe]

})
export class ContractorsComponent implements OnInit {

  //////////////title varaible////////////////
  title = 'All Contractors';

  ////////////add contractor////////////////
  contractors_add_contractor = 'Add Contractor';

  //////////////////alert varaible////////
  scanResults = '';
  showDeletionResponse = false;
  ContractorAdded = '';
  showSuccessMsg = false;
  DeleteFeedback = '';
  scan: boolean = false;
  showErrorMsg = false;
  passwordsMismatch = false;
  addContractorValidation = false;
  CONUpdated = '';
  showSuccessMsgUpdate = false;



  /////////////////search variable///////////////
  contractors_property_developer = 'Client';
  contractors_city = 'City';
  contractors_country = 'Country';
  contractors_contractor_type = 'Contractor Type ';
  contractors_company_code = 'Company Code';
  contractors_contractor_name = 'Contractor Name ';
  generals_search = 'Search';
  contractors_remarks = 'Remarks';
  generals_clear_search = 'Clear Search';
  searchT: any = [];
  countryList = [];
  public AllPd = [];
  public AllPdList = [];


  ///////////////////permission//////////////////
  permissions_userlevel;
  permissions_view = false;
  permissions_create = false;
  permissions_edit = false;
  permissions_delete = false;



  ///////////////////get contractor variables//////////
  conList = [];
  totalList: any;
  rows = [];
  srch = [];


  ////////////////////////view variable//////////////////
  generals_view_details = 'View Details';
  public viewCON: any = [];
  contractors_contractor_id = 'Contractor ID';
  contractors_company_ssm_code = 'Company SSM Code';
  contractors_registration_code = 'Registration Date';
  contractors_house_number = 'House Number';
  contractors_street = 'Street';
  contractors_postal_code = 'Postal Code';
  contractors_state = 'State';
  contractors_company_telephone = 'Company Telephone';
  contractors_company_email = 'Company Email';
  contractors_status = 'Status';
  contractors_precincts = 'Precincts/Projects/Condos';
  contractors_property_units = 'Property Units';
  contractors_pd_city = 'City';
  contractors_township = 'Township';
  contractors_pic_name = 'PIC Name';
  contractors_pic_ic_no = 'PIC IC No';
  contractors_date_of_birth = 'Date of Birth';
  contractors_designation = 'Designation';
  contractors_gender = 'Gender';
  contractors_pic_telephone = 'PIC Telephone';
  contractors_pic_email = 'PIC Email';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';
  generals_uploaded_images = 'Uploaded Images'



  //////////////////////Add contractor///////////////
  public addCON: any = {};
  contractors_upload_ic_pic = 'Upload IC PIC';
  contractors_upload_form_9 = 'Upload Form 9';
  contractors_upload_contracts = 'Upload Contracts';
  contractors_upload_bank_information = 'Upload Bank information';
  contractors_upload_support_documents = 'Upload Support Documents';
  contractors_create_contractor = 'Create Contractor';

  public genderOptions = [];
  public userNickName;
  currentDate: string;

  ////////////////////////edit contractor////////////////
  contractors_edit_contractor = 'Edit Contractor';
  contractors_update_contractor = 'Update Contractor';
  generals_edit = 'Edit'

  public uptCON: any = [];

  ////////////////////////delete variables//////////////
  delete_results;
  generals_delete = 'Delete';


  /////////////languages Variables//////////////////////
  public languages = [];
  constructor(private router: Router, private projectService: AppService, private loader: LoaderService, private datePipe: DatePipe, private uploadService: UploadFileService) {

    this.countryList = projectService.countryList;
    this.genderOptions = projectService.genderOptions;

  }

  ////////////////////////////////API///////////////////////////
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';

  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameDelete = 'contractors'; // replace this with your api name.
  pathDelete = '/contractors/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  putContractorsInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  ngOnInit() {
    this.getPermissions();
    this.getContractors();
    this.getPropertyDevelopers();
    this.userNickName = localStorage.getItem('nick_name');
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      this.setLanguage(language);
    }
  }
  /////////////////////////////////get contractor/////////////////////////

  getContractors() {
    this.scan = true;
    this.scanResults = "In Progress...";
    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.scanResults = "Completed.";
      this.scan = false;
      this.srch.push(...this.rows);
      this.conList = response.data;

      console.log('\'Contractor\' component-> All Contractors Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All Contractors from server!');
      console.log(error.response);
    });
  }




  /////////////////////////////////get properly developers/////////////////
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }




  //////////////////////////////////SEARCH Functions///////////////////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.conList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getContractors();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.conList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.conList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }



  //////////////////////////////////////view contractor////////////////////////
  viewContractor(item) {
    let temp;
    // console.log(item);
    temp = item;
    this.viewCON = temp;
  }


  ////////////////////////////////////////Add contractor////////////////////////
  addReset() {
    const randomnumber = Math.floor(Math.random() * 999999999);
    this.addCON = {
      id: 'CON-' + randomnumber,
      status: 'Confirmed',
      reg_date: '',
      date_of_birth: ''
    };
    $('#add_contractor').modal('show');
  }
  addContractor(f) {

    const date_of_birth = f.form.get('date_of_birth').value;
    let date_of_birth_formatted = '';
    if (date_of_birth) {
      date_of_birth_formatted = this.projectService.reformatDate(f.form.get('date_of_birth').value);
    }

    console.log(f.form.get('reg_date').value);
    const reg_date = f.form.get('reg_date').value;
    let reg_date_formatted = '';
    if (reg_date) {
      reg_date_formatted = this.projectService.reformatDate(reg_date);
    }
    if (f.invalid === true) {
      console.log(f.form.value);
      this.addContractorValidation = true;
    } else {
      this.loader.show();
      f.form.value.created_by = this.userNickName;
      f.form.value.creation_date = this.currentDate;
      f.form.value.reg_date = reg_date_formatted;
      f.form.value.date_of_birth = date_of_birth_formatted;
      this.putContractorsInit.body = f.form.value;
      console.log(f.form.value);
      Object.keys(this.putContractorsInit.body).forEach(key => this.putContractorsInit.body[key] === undefined ? delete this.putContractorsInit.body[key] : {});
      API.put(this.apiNameContractors, this.apiPathContractors, this.putContractorsInit).then(response => {
        this.showSuccessMsg = true;
        this.ContractorAdded = 'Contractor Added Successfully!';
        this.CONUpdated = 'Contractor Updated Successfully!';
        this.rows = this.rows;
        $('#add_contractor').modal('hide');
        console.log('\'Contractor\' component-> New Contractor Created Successfully!');
        console.log(response);
        this.loader.hide();
        this.totalList = this.totalList + 1;
      }).catch(error => {
        this.showErrorMsg = true;
        this.ContractorAdded = 'Contractor Submission Failed, Please Retry!';
        this.CONUpdated = 'Contractor Updation Failed, Please Retry!';

        console.log('\'Contractor\' component-> Error in creating new Contractor!');
        console.log(error.response);
        this.loader.hide();
      });

    }

  }





  //////////////////////////////////////////Edit Functions//////////////////////
  UpdateContractor(f) {

    const date_of_birth = f.form.get('date_of_birth').value;
    let date_of_birth_formatted;
    if (date_of_birth) {
      date_of_birth_formatted = this.projectService.reformatDate(f.form.get('date_of_birth').value);
    }

    console.log(f.form.get('reg_date').value);
    const reg_date = f.form.get('reg_date').value;
    let reg_date_formatted;
    if (reg_date) {
      reg_date_formatted = this.projectService.reformatDate(f.form.get('reg_date').value);
    }

    if (f.invalid === true) {
      this.addContractorValidation = true;
    } else {
      this.loader.show();
      f.form.value.created_by = this.userNickName,
        f.form.value.creation_date = this.currentDate,
        f.form.value.reg_date = reg_date_formatted,
        f.form.value.date_of_birth = date_of_birth_formatted,
        this.putContractorsInit.body = f.form.value;
      console.log(f.form.value);
      Object.keys(this.putContractorsInit.body).forEach(key => this.putContractorsInit.body[key] === undefined ? delete this.putContractorsInit.body[key] : {});
      API.put(this.apiNameContractors, this.apiPathContractors, this.putContractorsInit).then(response => {
        this.showSuccessMsgUpdate = true;
        this.CONUpdated = 'Contractor Updated Successfully!';
        this.rows = this.rows;
        console.log('\'Contractor\' component-> Contractor Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showSuccessMsgUpdate = true;
        this.CONUpdated = 'Contractor Updation Failed, Please Retry!';

        console.log('\'Contractor\' component-> Error in Updating Contractor!');
        console.log(error.response);
        this.loader.hide();
      });

    }

  }

  onEdit(item) {
    this.uptCON = Object.assign({}, item);
    if (item.reg_date != undefined) {
      var regdateParts = item.reg_date.split("/");
      this.uptCON.reg_date = regdateParts[2] + "-" + regdateParts[1] + "-" + regdateParts[0];

    } if (item.date_of_birth != undefined) {
      var date_of_birthParts = item.date_of_birth.split("/");
      this.uptCON.date_of_birth = date_of_birthParts[2] + "-" + date_of_birthParts[1] + "-" + date_of_birthParts[0];
    }

    console.log(this.uptCON);

    $('#edit_contractor').modal('show');
  }



  ////////////////////////////////////////////delete variables////////////////////
  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getContractors();
  }




  //////////////////////////////////////////////languages//////////////////////////
  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.title = response.Items[0].contractors;
      this.contractors_contractor_id = response.Items[0].contractors_contractor_id;
      this.contractors_contractor_name = response.Items[0].contractors_contractor_name;
      this.contractors_contractor_type = response.Items[0].contractors_contractor_type;
      this.contractors_company_ssm_code = response.Items[0].contractors_company_ssm_code;
      this.contractors_company_code = response.Items[0].contractors_company_code;
      this.contractors_registration_code = response.Items[0].contractors_registration_code;
      this.contractors_house_number = response.Items[0].contractors_house_number;
      this.contractors_street = response.Items[0].contractors_street;
      this.contractors_city = response.Items[0].contractors_city;
      this.contractors_postal_code = response.Items[0].contractors_postal_code;
      this.contractors_state = response.Items[0].contractors_state;
      this.contractors_country = response.Items[0].contractors_country;
      this.contractors_company_telephone = response.Items[0].contractors_company_telephone;
      this.contractors_company_email = response.Items[0].contractors_company_email;
      this.contractors_status = response.Items[0].contractors_status;
      this.contractors_property_developer = response.Items[0].contractors_property_developer;
      this.contractors_pd_city = response.Items[0].contractors_pd_city;
      this.contractors_township = response.Items[0].contractors_township;
      this.contractors_precincts = response.Items[0].contractors_precincts;
      this.contractors_property_units = response.Items[0].contractors_property_units;
      this.contractors_pic_name = response.Items[0].contractors_pic_name;
      this.contractors_pic_ic_no = response.Items[0].contractors_pic_ic_no;
      this.contractors_date_of_birth = response.Items[0].contractors_date_of_birth;
      this.contractors_designation = response.Items[0].contractors_designation;
      this.contractors_gender = response.Items[0].contractors_gender;
      this.contractors_pic_telephone = response.Items[0].contractors_pic_telephone;
      this.contractors_pic_email = response.Items[0].contractors_pic_email;
      this.contractors_remarks = response.Items[0].contractors_remarks;
      this.contractors_upload_ic_pic = response.Items[0].contractors_upload_ic_pic;
      this.contractors_upload_form_9 = response.Items[0].contractors_upload_form_9;
      this.contractors_upload_contracts = response.Items[0].contractors_upload_contracts;
      this.contractors_upload_bank_information = response.Items[0].contractors_upload_bank_information;
      this.contractors_upload_support_documents = response.Items[0].contractors_upload_support_documents;

      this.contractors_create_contractor = response.Items[0].contractors_create_contractor;
      this.contractors_add_contractor = response.Items[0].contractors_add_contractor;
      this.contractors_edit_contractor = response.Items[0].contractors_edit_contractor;
      this.contractors_update_contractor = response.Items[0].contractors_update_contractor;

      this.generals_search = response.Items[0].generals_search;
      this.generals_clear_search = response.Items[0].generals_clear_search;
      this.generals_view_details = response.Items[0].generals_view_details;
      this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
      this.generals_edit = response.Items[0].generals_edit;
      this.generals_delete = response.Items[0].generals_delete;
      this.generals_uploaded_files = response.Items[0].generals_uploaded_files;
      this.generals_actions = response.Items[0].generals_actions;

    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }



  ////////////////////////////////////////////permissions variable////////////////////

  getPermissions() {
    this.projectService.getPermissions().then(response => {
      this.permissions_view = response[0].contractors_view;
      this.permissions_create = response[0].contractors_create;
      this.permissions_edit = response[0].contractors_update;
      this.permissions_delete = response[0].contractors_delete;
    }).catch(error => {
      console.log(error);
    })
  }



  ///////////////////////////////////////////Destroy functions///////////////////////////////////
  ngOnDestroy() {
    $('#add_contractor').modal('hide');
    $('#view_contractor').modal('hide');
    $('#edit_contractor').modal('hide');
  }
}
