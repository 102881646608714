import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]

})
export class AddPropertyComponent implements OnInit {
  pictures=[];
  files=[];
  selectedFiles: FileList;
  haveFiles: boolean = false;
  eventFiles;
  addEmployeeValidation = false;
  public rows = [];
  public srch = [];
  profile_pic;
  public addLT: any = {};
  public uptLT: any = {};
  public PropertyDevelopers = [];
  addTValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public AddedMsg;
  totalList: any;
  public AllPd = [];
  residentsList=[];
  uploadPicsFolder = '/General/UsersProfile';
  apiName = 'tenancymanagment'; // replace this with your api name.
  apiPath = '/tenancymanagment'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'tenancymanagment'; // replace this with your api name.
  pathDelete = '/tenancymanagment/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  getMyInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  public propertyDevelopers = [];
  public pds = [];

  pdCodeList = [];
  public timeList = [];
  public nextActions = [];
  languagesList = [];
  scanResults = '';
  public userLevel;
  addPropertyValidation = false;
  addResponseSuc = false;
  addResponseFail = false;
  unsortedrows2 = [];
  op: boolean = false;
  searchT: any = [];
  public AllPdList = [];
  nextActionLoad:Boolean=false;
  addP: any = [];
  ///////////picture variable/////
  selectedPics: FileList;
  eventPics;
  havePictures: boolean = false;
  apiNamepltstask = 'pltstask'; // replace this with your api name.
  apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API
  getpltstask = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  public countryList = []
  scan: boolean = false;
  constructor(    private loader: LoaderService,private datePipe: DatePipe,private projectService: AppService, private uploadService: UploadFileService
    ) {    this.countryList = projectService.countryList;
      this.timeList = projectService.timeList;
    }

  ngOnInit() {
    this.getPropertyDevelopers();
    this.getDynamicNextActionsMyPending();
  }
  addReset() {
    this.addTValidation = false;
  }
  getResidents() {
    this.scan=true;
    this.scanResults= "Getting Residents, Please Wait."
    API.get(this.apiName, this.apiPath, this.putMyInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.residentsList = response.data;
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);
      this.loader.hide();
      this.scan=false;
      console.log('\'all-residents\' component-> All Residents Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'all-residents\' component-> Error in Retreiving All Residents from server!');
      console.log(error);
    });
  }
  
  addUserWithPics(f) {
    this.addEmployeeValidation = false;
    if (f.invalid == true) {
      this.addEmployeeValidation = true;
    }  else {
      this.loader.show();
      if (this.havePictures || this.haveFiles) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.addFormPlts(f);
          this.havePictures = false;
          this.haveFiles =false
        }).catch(error => {
          console.log(error);
          this.havePictures = false;
          this.haveFiles = false;
        });
      } else {
        this.addFormPlts(f);
      }
    }
  }
  addFormPlts(f){
      f.form.value.client = localStorage.getItem("property_developer");
      f.form.value.pictures = this.pictures;
      f.form.value.files = this.files;
      this.putMyInit.body = f.form.value;
      console.log(this.pictures);
      console.log(this.files);
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      console.log(f.form.value);
      API.put(this.apiName, this.apiPath, this.putMyInit).then(response => {
        this.loader.hide();
        this.addResponseSuc=true;
        this.showSuccessMsg = true;
        this.addP = {
          'id': this.pdCodeList[0]+'-'+Math.floor(Math.random() * 5000000000),
          'site_information_created_by': localStorage.getItem('nick_name'),
        };
       
        console.log('\'project\' component-> New plts Created Successfully!');
        console.log(response);
      }).catch(error => {
        this.loader.hide();
        this.addResponseFail=true;
        console.log('\'project\' component-> Error in creating new plts!');
        console.log(error);
      });
  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          //console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              //console.log(Response);
              resolve("Add  Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add  Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          //console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              //console.log(Response);
              resolve("Add  Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add  Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }
  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("File Uploaded Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All files uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }



  getPropertyDevelopers() {
    this.loader.show();
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      const results = response.data.filter(element => {
        return element.pd_name !== undefined && element.pd_name!=="" && element.pd_name!=="New Lead" && element.pd_name!=="" && element.pd_name!=="New Lead";
      });
      this.propertyDevelopers.splice(0, this.propertyDevelopers.length);
      this.propertyDevelopers.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.propertyDevelopers);
      this.setPdCode();
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error);
    });
  }

  setPdCode() {
    var pd=localStorage.getItem("property_developer");
    // //console.log(pd);
    // //console.log(this.propertyDevelopers);
    var p = [];
    const filters = { pd_name: [pd] },
      results = this.propertyDevelopers,
      Filtered = this.multiFilter(results, filters);
      this.pds.push(...Filtered);
//   console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.company_code;
      p.push(x);
    });

    this.pdCodeList.splice(0, this.pdCodeList.length);
    this.pdCodeList.push(...p);
    this.pdCodeList = this.pdCodeList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.pdCodeList.sort();

    this.addP = {
      'id':this.pdCodeList[0]+'-'+Math.floor(Math.random() * 500000),
      'site_information_created_by': localStorage.getItem('nick_name'),
    };
   // //console.log(this.pdCodeList[0]+'-'+new Date().getFullYear().toString().substr(-2));

  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }


  getDynamicNextActionsMyPending() {
    this.nextActions.splice(0,this.nextActions.length);
    var pd=localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if(user_level){
    this.nextActionLoad = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];    

    if (pd != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(pd);
    }

    if (user_level != undefined) {
      srchFiltersNames.push('from_user_level');
      srchFiltersValues.push(user_level);
    } 

      srchFiltersNames.push('workflow_for');
      srchFiltersValues.push('PLTS Workflow');
 
    this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.nextActions = response;
    }).catch(error => {
      console.log(error);
    });
  }
  
}
//////////////upload picture////////
uploadAttachments(event) {
  this.selectedPics = event.target.files;
  this.eventPics = event;
  this.havePictures = true;
}
uploadAllFiles(event) {
  this.selectedFiles = event.target.files;
  this.eventFiles = event;
  this.haveFiles = true;
}

}

