import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { DatePipe, DOCUMENT } from '@angular/common';
import { LoaderService } from 'src/app/loader/loader.service';
import { Lightbox } from 'ngx-lightbox';
import { UploadFileService } from '../services/upload-file.service';
import { Router, ActivatedRoute } from '@angular/router';
const SCRIPT_PATH = 'https://app.smplrspace.com/lib/smplr.js';
declare let smplr: any;
@Component({
  selector: 'app-other-comp',
  templateUrl: './other-comp.component.html',
  styleUrls: ['./other-comp.component.css'],
  providers: [DatePipe]
})
export class OtherCompComponent implements OnInit {
  
///////////////front button variables///////
all_work_orders_all_work_orders = 'All Work Orders';
all_work_orders_print_work_order = 'Print Work Order';
totalList: any;
scanResults = '';
my_pending_work_orders_refresh_list = 'Refresh List';
add_new_work_orders_add_work_order = 'Add New Work Order';



///////////alert variables////////
ticketScan = false;
addRemarkFromAll = false;
public superAdminLoggedIn: boolean;
nextActionSuccess = false;
nextActionMsg;
showSuccessMsg = false;
showfloorplan = true;
showErrorMsg = false;
addWorkOrderFlag = false;
approvalLogScan = false;
addWorkOrderValidation = false;
nextActionLoad: Boolean = false;
showPD = false;
havePictures: boolean = false;
haveFiles: boolean = false;
//////////get all work orders variables//////
dashboard_property_developer = 'Client';
public AllPd = [];
cont: any = [];


searchT: any = [];
public AllPdList = [];
users_status = 'Status';
public nextActions = [];
public nextActionsMyPending = [];
add_new_work_orders_work_order_id = 'Work Order ID';
add_new_work_orders_precinct_project = 'Precinct / Project';
propertiesPrecinctList = [];
propertiesHouseNumberList = [];
propertiesStreetsList = [];
propertiesList = [];
add_new_work_orders_street = 'Street';
add_new_work_orders_house_number = 'House Number';
add_new_work_orders_main_contractor = 'Main Contractor';
add_new_work_orders_priority = 'Priority';
add_new_work_orders_next_action = 'Next Action';
add_new_work_orders_date_scheduled = 'Date Scheduled';
work_order_details_days_open = 'Days Open';
work_order_details_date_closed = 'Date Closed';
add_new_work_orders_competency = 'Competency';
work_order_details_created_date = 'Created Date';
srch = [];
public propertyDevelopers = [];
rows = [];
ticketsList = [];
add_new_work_orders_location = 'Location';
add_new_work_orders_element = 'Element';
add_new_work_orders_floor = 'Floor';
add_new_work_orders_defect = 'Defect';
add_new_work_orders_costs = 'Costs';
generals_search = 'Search';
generals_clear_search = 'Clear Search';
/////////////date and time variale///////
addT: any = [];
currentDate: string;
public timeList = [];
currentTime: string;
//////////////////////lanuguages/////////////////
add_new_work_orders_created_by = 'Created By';
my_pending_work_orders_my_pending_work_orders='My Pending Work Orders';
add_new_work_orders_invoice = 'Invoice';
add_new_work_orders_days_to_execute = 'Days To Execute';
add_new_work_orders_charge_to = 'Charge To';
add_new_work_orders_cause = 'Cause';
add_new_work_orders_time_scheduled = 'Time Scheduled';
add_new_work_orders_am_pm = 'AM/PM';
add_new_work_orders_work_order_description = 'Work Order Description';
add_new_work_orders_upload_pictures = 'Upload Pictures';
add_new_work_orders_upload_documents_pdf_only = 'Upload Documents (PDF Only)';
add_new_work_orders_create_new_work_order = 'Create Work Order';
my_pending_work_orders_add_work_order = 'Add Work Order';
work_order_details_my_pending_work_orders = 'My Pending Work Orders';
work_order_details_print = 'Print';
work_order_details_edit_work_order = 'Edit Work Order';
work_order_details_remarks_and_approval_log = 'Remarks And Approval Log';
work_order_details_created_time = 'Created Time';
work_order_details_created_by = 'Created By';
work_order_details_action = 'Action';
work_order_details_remarks = 'Remarks';
work_order_details_next_action = 'Next Action';
add_new_work_orders_property_developer = 'Client';
work_order_details_update_work_order = 'Update Work Order';
work_order_details_work_order_details = 'Work Order Details';
work_order_details_resident_name = 'Resident Name';
work_order_details_resident_phone = 'Resident Phone';
work_order_details_time_closed = 'Time Closed';
work_order_details_closed_by = 'Closed By';
work_order_details_add_remark = 'Add Remark';
generals_view_details = 'View Details';
generals_uploaded_images = 'Uploaded Images'
generals_edit = 'Edit'
generals_delete = 'Delete';
generals_creation_date = 'Creation Date';
generals_actions = 'Actions';
public languages = [];
generals_uploaded_files = 'Uploaded Files';
generals_download = 'Download';

userLevel: string;
//////////////////user variables//////////
public super_Admin = 'Super Admin';
public pla_admin = 'PLA Admin';
public pla_frontdesk = 'PLA Frontdesk';
public pla_supervisor = 'PLA Supervisor';
public pd_admin = 'PD Admin';
public pd_frontdesk = 'PD Frontdesk';
public pd_supervisor = 'PD Supervisor';
public pd_managment = 'PD Management';
public con_admin = 'CON Admin';
public con_supervisor = 'CON Supervisor';
public con_crew = 'CON Crew';
public userNickName;
notificationsUrl = "https://fcm.googleapis.com/fcm/send";
//////////////////view/////////////////
viewT: any = [];
residentArray = [];
residentname = "loading...";
residentEmail = "loading...";
residentphone = "loading...";
totalPics;
approvalLog = [];
scanResultsApproval = '';
viewPictures = [];
albums = [];
totalFiles;
eventPics;
eventFiles;
dataArray = [];
pictures = [];
files = [];
selectedPics: FileList;
selectedFiles: FileList;
viewFiles = [];
/////////////delete variables//////
delete_results;
/////////////edit variables////////
existingPics = [];
TicketSubmitted = '';
existingFiles = [];
next_action_from_edit;
editT: any = [];
public submitterActions = [];
public statuses = [];
////////permissiion variables///////
permissions_userlevel;
permissions_view=false;
permissions_create=false;
permissions_edit=false;
permissions_delete=false;
//////////////api//////////////////

apiNamePD = 'propertydevelopers';
apiPathPD = '/propertydevelopers';
getPDInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {  // OPTIONAL
    name: 'param'
  }
};
apiNamepltstask = 'pltstask'; // replace this with your api name.
apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API
getpltstask = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

apiNameProperties = 'properties'; // replace this with your api name.
apiPathProperties = '/properties'; // replace this with the path you have configured on your API

getPropertiessInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {  // OPTIONAL
    name: 'param'
  }
};
apiNamelanguages = 'languages'; // replace this with your api name.
pathlanguages = '/languages'; // replace this with the path you have configured on your API
getMyInitlanguages = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
putWOApprovalLogInit = {
  body: {},
  headers: {}
};
apiNameNotifications = 'notifications'; // replace this with your api name.
pathNotifications = '/notifications'; // replace this with the path you have configured on your API
putMyInitNotifications = {
  body: {}, // replace this with attributes you need
  headers: {} // OPTIONAL
};
apiNameContractors = 'contractors';
apiPathContractors = '/contractors';
getContractorsInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {  // OPTIONAL
    name: 'param'
  }
};
apiNameWOApprovalLog = 'woapprovallog'; // replace this with your api name.
pathWOApprovalLog = '/woapprovallog'; // replace this with the path you have configured on your API
getWOApprovalLogInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
apiName = 'workorders'; // replace this with your api name.
path = '/workorders'; // replace this with the path you have configured on your API
pathUpdate = "/workorders/update";
putMyInit = {
  body: {}, // replace this with attributes you need
  headers: {} // OPTIONAL
};
getMyInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
apiNameResident = 'residents'; // replace this with your api name.
pathResident = '/residents'; // replace this with the path you have configured on your API
putMyInitResident = {
  body: {}, // replace this with attributes you need
  headers: {} // OPTIONAL
};
getMyInitResident = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
apiNameDelete = 'workorders'; // replace this with your api name.
pathDelete = '/workorders/delete'; // replace this with the path you have configured on your API
myInitDelete = {
  body: {}, // replace this with attributes you need
};
constructor(private router: Router, private uploadService: UploadFileService, public http: HttpClient, private renderer: Renderer2, private _lightbox: Lightbox,
  @Inject(DOCUMENT) private document: Document, private datePipe: DatePipe, private loader: LoaderService, private appService: AppService, private projectService: AppService)
   {
    this.timeList = appService.timeList;
    this.statuses = this.appService.statuses;
    this.submitterActions = appService.pdAdminActions;
    this.userLevel = localStorage.getItem('user_level');
    this.userNickName = localStorage.getItem('nick_name');
    this.setVisibility();

    }

  ngOnInit() {
    this.getPropertyDevelopers();
    this.getProperties();
    this.getAll();
    this.getPermissions();
    this.getDynamicNextActions();
    this.getContractors();
    if(this.userLevel==='PLA Admin' || this.userLevel ==='PLA Frontdesk' || this.userLevel==='Super Admin'){
      this.showPD=true;
    }
    
    
        var language = localStorage.getItem('language');
        if(language!='undefined' && language!='English'){
          console.log(language);
          this.setLanguage(language);
        }else{
          //console.log("null5");
        }
    
        this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
        this.currentTime = new Date().toLocaleTimeString();
        $('.floating').on('focus blur', function (e) {
          $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
        }).trigger('blur');

  }

/////////get property developers//////////
getPropertyDevelopers() {
  var p = [];

  this.propertyDevelopers.push('');
  API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
    this.propertyDevelopers.push(...response.data);
    this.AllPdList.push(...response.data)
    response.data.forEach(function (arrayItem) {
      var x = arrayItem.pd_name;
      p.push(x);
    });
    const results = p.filter(element => {
      return element !== undefined && element!=="" && element!=="New Lead" && element!=="" && element!=="New Lead";
    });
    this.AllPd.splice(0, this.AllPd.length);
    this.AllPd.push(...results);
    console.log('\'Properties\' component-> All Client Retrieved Successfully!');
    console.log(response);
    this.loader.hide();
  }).catch(error => {
    this.loader.hide();
    console.log('\'Properties\' component-> Error in Retreiving All Property Developers from server!');
    console.log(error.response);
  });
}
getProperties() {
  //this.loader.show();
  this.propertiesHouseNumberList.push('');
  this.propertiesStreetsList.push('');
  this.propertiesPrecinctList.push('');


  API.get(this.apiNameProperties, this.apiPathProperties, this.getPropertiessInit).then(response => {
    for (let i = 0; i < response.data.length; i++) {
      // console.log(response.data[i]);
      this.propertiesList = response.data;
      this.propertiesPrecinctList.push(response.data[i].precinct);
      this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.propertiesPrecinctList.sort();
      localStorage.setItem('propertiesPrecinctList', JSON.stringify(this.propertiesPrecinctList));
      this.propertiesHouseNumberList.push(response.data[i].house_number);
      this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.propertiesHouseNumberList.sort();
      localStorage.setItem('propertiesHouseNumberList', JSON.stringify(this.propertiesHouseNumberList));

      this.propertiesStreetsList.push(response.data[i].street);
      this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.propertiesStreetsList.sort();
      localStorage.setItem('propertiesStreetsList', JSON.stringify(this.propertiesStreetsList));

    }

    this.loader.hide();
    console.log('\'tickets\' component-> All properties Retrieved Successfully!');
    console.log(response);
  }).catch(error => {
    this.loader.hide();
    console.log('\'tickets\' component-> Error in Retreiving All properties from server!');
    console.log(error.response);
  });
}
getAll() {
  this.ticketsList.splice(0, this.ticketsList.length);
  this.ticketScan=true;
  this.scanResults='In Progress!'
  API.get(this.apiName, this.path, this.getMyInit).then(response => {
    this.ticketScan=false;
    this.rows = response;
    this.ticketsList.push(...this.rows);
    this.totalList = Object.keys(this.rows).length;
    console.log('\'\' component-> All  Retrieved Successfully!');
    console.log(response);
  }).catch(error => {
    this.ticketScan=false;
    console.log('\'\' component-> Error in Retreiving All from server!');
    console.log(error);
  });
}
getDynamicNextActions() {
  let unsortedrows2=[];
  this.nextActions.splice(0,this.nextActions.length);
  var pd=localStorage.getItem('property_developer');
 // //console.log(user_level);
  this.nextActionLoad = true;
  const srchFiltersValues = [];
  const srchFiltersNames = [];    


  if (pd != undefined) {
    srchFiltersNames.push('property_developer');
    srchFiltersValues.push(pd);
  }
  this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
    unsortedrows2.push('');
    unsortedrows2.push(...response); 
    this.nextActions.push(...this.appService.sortByKey(unsortedrows2,'order'));
    this.nextActionLoad = false;
  });
}
getContractors() {
  const srchFiltersValues = [];
  const srchFiltersNames = [];
  srchFiltersNames.splice(0, srchFiltersNames.length);
  srchFiltersValues.splice(0, srchFiltersValues.length);
  var pd=localStorage.getItem('property_developer');

  if (pd != undefined) {
    srchFiltersNames.push('property_developer');
    srchFiltersValues.push(pd);
  }

  this.projectService.scanContainsAnd(this.apiNameContractors, this.apiPathContractors, srchFiltersNames, srchFiltersValues).then(response => {
    this.cont.push('');
        this.cont.push(...response);
    console.log(this.cont);
  });
  }
  setVisibility() {
    if (this.userLevel === 'Super Admin') {
      this.superAdminLoggedIn = true;
    }
    if (this.userLevel === 'PD Admin' || this.userLevel === 'PLA Admin' || this.userLevel === 'Super Admin') {
      this.addWorkOrderFlag = true;
    }
  }
/////////////search variables//////////
searchRecordLocally(f) {
  let Filtered = this.projectService.searchRecordLocally(f, this.ticketsList);
  this.rows.splice(0, this.rows.length);
  this.rows.push(...Filtered);
  console.log(Filtered);
  this.totalList = Object.keys(this.rows).length;
}
clearSearch() {
  this.rows.splice(0, this.rows.length);
  this.searchT = Object.keys(this.searchT).reduce(
    (accumulator, current) => {
      accumulator[current] = null;
      return accumulator
    }, {});

  this.getAll();
}
instantSearch(val, name) {
  this.rows.splice(0, this.rows.length);
  this.rows.push(...this.projectService.instantSearch(val, this.ticketsList, name, ''))
  this.totalList = Object.keys(this.rows).length;
}
onChangePrecinct(precinct) {
  //console.log(precinct);
  //console.log(this.propertiesList);
  var p = [];
  const filters = { precinct: [precinct] },
    results = this.propertiesList,
    Filtered = this.projectService.multiFilter(results, filters);
  //console.log(Filtered);
  Filtered.forEach(function (arrayItem) {
    var x = arrayItem.street;
    p.push(x);
  });

  this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
  this.propertiesStreetsList.push('');
  this.propertiesStreetsList.push(...p);
  this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
  this.propertiesStreetsList.sort();
}

onChangeStreet(street) {
  //console.log(street);
  //console.log(this.propertiesList);
  var p = [];
  var t = [];
  const filters = { street: [street] },
    results = this.propertiesList,
    Filtered = this.projectService.multiFilter(results, filters);
  //console.log(Filtered);
  Filtered.forEach(function (arrayItem) {
    var x = arrayItem.house_number;
    var y = arrayItem.main_contractor;
    p.push(x);
    t.push(y);
  });

  this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
  this.propertiesHouseNumberList.push('');
  this.propertiesHouseNumberList.push(...p);
  this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
  this.propertiesHouseNumberList.sort();
}
////////////////view work orders/////////////
openImageView(index: number): void {
  this._lightbox.open(this.albums, index);
}

closeImageView(): void {
  this._lightbox.close();
}
onView(item) {
  //console.log(item);
  this.totalPics = "0";
  this.totalFiles = "0";


  if(item.floor_plan_report!=undefined){
    this.LoadSmplrSpace(item.floor_plan_report)
  }else{
    this.showfloorplan=false;
  }
  this.getHouseOwnerInfo(item);

  this.nextActionSuccess = false;
  this.viewPictures = [];
  this.viewFiles = [];


  if (item.date_closed == undefined) {
    item.days_open = this.projectService.differenceBetweenDates(this.currentDate.toString(), item.created_date).toString();
  } else {
    item.days_open = this.projectService.differenceBetweenDates(item.date_closed, item.created_date).toString();
  }

  this.searchRecordApprovalLog(item.id);

  if (item.images) {
    if (item.images.length > 0) {
      this.totalPics = item.images.length;
      if(item.images.length<=50){
        this.viewPictures = item.images;
      }
    }
  }
  if (item.documents) {
    if (item.documents.length > 0) {
      this.viewFiles = item.documents;
      this.totalFiles = this.viewFiles.length;
    }
  }
  this.viewT = item;
  $('#view_all_work_order').modal('show');

  this.prepareAlbumLightbox();
}
searchRecordApprovalLog(id) {
  let unsortedApprovalLog = [];
  unsortedApprovalLog.splice(0, unsortedApprovalLog.length);
  this.approvalLog.splice(0, this.approvalLog.length);
  const srchFiltersValues = [];
  const srchFiltersNames = [];
  const parentTicketId = id;
  console.log(id);

  if (parentTicketId != undefined) {
    srchFiltersNames.push('parentTicketId');
    srchFiltersValues.push(parentTicketId);
  }
  this.approvalLogScan = true;
  this.scanResultsApproval = 'In Progress...';
  this.projectService.scanContainsAnd(this.apiNameWOApprovalLog, this.pathWOApprovalLog, srchFiltersNames, srchFiltersValues).then(response => {
    unsortedApprovalLog.push(...response);
    const sorted = unsortedApprovalLog.sort((t1, t2) => {
      const name1 = t1.created_time;
      const name2 = t2.created_time;
      if (name1 < name2) { return 1; }
      if (name1 > name2) { return -1; }
      return 0;
    });
    this.approvalLog.splice(0, this.approvalLog.length);
    this.approvalLog.push(...sorted);
    this.scanResultsApproval = 'Completed';
    this.approvalLogScan = false;
  });


}
getHouseOwnerInfo(item) {
  this.residentname = "loading...";
  this.residentEmail = "loading...";
  this.residentphone = "loading...";
  var filter = {
    street: item.street,
    house_number: item.house_number
  };
  var Filtered = this.propertiesList.filter(function (item) {
    for (var key in filter) {
      if (item[key] === undefined || item[key] != filter[key])
        return false;
    }
    return true;
  });
  var house_owner = Filtered[0].house_owner;
  this.searchResident(house_owner);

}
searchResident(house_owner) {
  const srchFiltersValues = [];
  const srchFiltersNames = [];
  let unsortedrows = [];


  if (house_owner != undefined) {
    srchFiltersNames.push('name');
    srchFiltersValues.push(house_owner);
  }

  this.projectService.scanContainsAnd(this.apiNameResident, this.pathResident, srchFiltersNames, srchFiltersValues).then(response => {
    unsortedrows.push(...response);
    const sorted = unsortedrows.sort((t1, t2) => {
      const name1 = t1.id;
      const name2 = t2.id;
      if (name1 < name2) { return 1; }
      if (name1 > name2) { return -1; }
      return 0;
    });
    this.residentArray.splice(0, this.rows.length);
    this.residentArray.push(...sorted);
    this.residentname = this.residentArray[0].name;
    this.residentEmail = this.residentArray[0].email;
    this.residentphone = this.residentArray[0].telephone1;
    console.log(this.residentArray);
  });

}

prepareAlbumLightbox() {
  //check if viewPictures is null
  for (let i = 0; i < this.viewPictures.length; i++) {
    if (this.viewPictures[i] == null || this.viewPictures[i] == "") {
      this.viewPictures[i] = "NoURL";
    }
    const src = this.viewPictures[i];
    const caption = "";
    const thumb = "";
    const album = {
      src: src,
      caption: caption,
      thumb: thumb
    };
    this.albums.push(album);
  }
}

LoadSmplrSpace(reports){
  console.log(reports);
  this.dataArray.push(reports);
  const scriptElement = this.loadJsScript(SCRIPT_PATH);
  scriptElement.onload = () => {
    console.log('Script loaded');
    console.log(smplr);
    const space = new smplr.Space({
    spaceId: reports.id,
    spaceToken: 'X',
    containerId: 'smplr-container'
  })
  space.startViewer({
    preview: true,
    mode: '2d',
    allowModeChange: false,
    onReady: () => {
      console.log('Viewer is ready');
      space.addDataLayer({
        id: 'reports',
        type: 'point',
        diameter: 0.6,
        data: this.dataArray,
        color: '#1e7bd3',
      })
    },
    onError: error => console.error('Could not start viewer', error)
  })
   }
   scriptElement.onerror = () => {
     console.log('Could not load the Script!');
   }
}

public loadJsScript(src: string): HTMLScriptElement {
  const script = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  this.renderer.appendChild(this.document.body, script);
  return script;
}
/////////////////edit///////////////
uploadAllPictures(event) {
  this.selectedPics = event.target.files;
  this.eventPics = event;
  this.havePictures = true;
}
uploadAllFiles(event) {
  this.selectedFiles = event.target.files;
  this.eventFiles = event;
  this.haveFiles = true;
}


onEditFromView(item) {
  localStorage.setItem('itemm',JSON.stringify(item));
  this.router.navigate(['work_orders/submit_work_order']);
  // this.showSuccessMsg = false;
  // this.nextActionSuccess = false;
  // this.editT = item;
  // this.existingPics = item.images;
  // this.existingFiles = item.documents;
  // this.next_action_from_edit = item.next_action;

  // $('#view_all_work_order').modal('hide');
  // setTimeout(function () {
  //   $('#edit_work_order').modal({
  //     backdrop: 'static'// to disable click close
  //   });
  // }, 500);

  // const url = this.router.serializeUrl(this.router.createUrlTree(['work_orders/edit'], { queryParams: { 'id': item.id } }));
  // window.opener = null;
  // window.open(url, '_blank');
}
onEdit(item) {
  // console.log(item);
   localStorage.setItem('itemm',JSON.stringify(item));
   this.router.navigate(['work_orders/submit_work_order']);

   // this.editT=Object.assign({}, item);

   // if(item.date_scheduled!=undefined){
   //   var date_scheduledParts = item.date_scheduled.split("/");
   //   this.editT.date_scheduled = date_scheduledParts[2]+"-"+date_scheduledParts[1]+"-"+date_scheduledParts[0];
   // }

   // this.showSuccessMsg = false;
   // this.nextActionSuccess = false;
   // this.next_action_from_edit = item.next_action;
   // this.existingPics = item.images;
   // this.existingFiles = item.documents;
   // console.log(this.existingPics);

   // this.searchRecordApprovalLog(item.id);


   // $('#edit_work_order').modal('show');

   // const url = this.router.serializeUrl(this.router.createUrlTree(['work_orders/edit'], { queryParams: { 'id': item.id } }));
   // window.opener = null;
   // window.open(url, '_blank');
 }
 updateEdittedTicket(f) {

  if (f.invalid === true) {
    this.addWorkOrderValidation = true;
  } else {
    this.loader.show();
    if (this.haveFiles || this.havePictures) {
      this.uploadPicsAndFiles().then(Response => {
        console.log(Response);
        this.updateTicket(f);
      }).catch(error => {
        console.log(error);
      });
    } else {
      this.updateTicket(f);
    }
  }
}
uploadPicsAndFiles() {
  return new Promise<string>((resolve, reject) => {

    if (this.havePictures) {
      this.uploadPics().then(Response => {
        console.log(Response);
        this.eventPics.srcElement.value = null;
        if (this.haveFiles) {
          this.uploadFiles().then(Response => {
            this.eventFiles.srcElement.value = null;
            console.log(Response);
            resolve("Add Ticket Now!")
          }).catch(error => {
            console.log(error);
            reject(error);
          });
        } else {
          resolve("Add Ticket Now!")
        }
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    } else if (this.haveFiles) {
      this.uploadFiles().then(Response => {
        console.log(Response);
        this.eventFiles.srcElement.value = null;
        if (this.havePictures) {
          this.uploadPics().then(Response => {
            this.eventPics.srcElement.value = null;
            console.log(Response);
            resolve("Add Ticket Now!")
          }).catch(error => {
            console.log(error);
            reject(error);
          });
        } else {
          resolve("Add Ticket Now!")
        }
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    } else {
      resolve("Add Ticket Now!")
    }
  });
}
uploadPics() {
  return new Promise<string>((resolve, reject) => {
    for (let i = 0; i < this.selectedPics.length; i++) {
      const file = this.selectedPics.item(i);
      this.uploadService.uploadfile(file, "").then(Response => {
        console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
        this.pictures.push(Response.Location);
        if (this.pictures.length === this.selectedPics.length) {
          resolve("All pics uploaded");
        }
      }).catch(error => {
        console.log("Error in uploading pic" + error);
        reject(error);
      });
    }
  });
}
uploadFiles() {
  return new Promise<string>((resolve, reject) => {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles.item(i);
      this.uploadService.uploadfile(file, "").then(Response => {
        console.log("File Uploaded Successfully " + JSON.stringify(Response));
        this.files.push(Response.Location);
        if (this.files.length === this.selectedFiles.length) {
          resolve("All Files uploaded");
        }
      }).catch(error => {
        console.log("Error in uploading file" + error);
        reject(error);
      });
    }
  });
}

updateTicket(f) {

  var picstoUpload=[];
  var filestoUpload=[];

  if (this.pictures.length > 0) {
    picstoUpload = this.pictures;
    if (this.existingPics != undefined) {
      picstoUpload.push(...this.existingPics);
    }
  } else {
    picstoUpload = this.existingPics;
  }
  // console.log("existing pic"+this.existingPics);
  // console.log(this.pictures);
  // console.log(picstoUpload);

  if (this.files.length > 0) {
    filestoUpload = this.files;
    if (this.existingFiles != undefined) {
      filestoUpload.push(...this.existingFiles);
    }
  } else {
    filestoUpload = this.existingFiles;
  }
  // console.log("existing files"+this.existingFiles);
  // console.log(this.files);
  // console.log(filestoUpload);

  const ticketid = f.form.get('id').value.toString();
  const date_scheduled = f.form.get('date_scheduled').value;
  let date_scheduled_formatted = '';
  if (date_scheduled) {
    date_scheduled_formatted = date_scheduled.formatted;
  }
  if (f.invalid === true) {
    this.addWorkOrderValidation = true;
  } else {
     f.form.value. next_action_by= this.getNextActionBy(f.form.get('next_action').value),
      f.form.value.date_scheduled= date_scheduled_formatted,
      f.form.value.closed_by= this.userNickName,
      f.form.value. date_closed= this.currentDate,
      f.form.value.time_closed=this.currentTime,
      f.form.value.images= picstoUpload,
      f.form.value.documents= filestoUpload
    this.putMyInit.body = f.form.value;
    Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.TicketSubmitted = 'Work order Updated Successfully.';
      this.saveNotifications("Work Order ("+ f.form.get('id').value.toString()+ ") Updated","Your Work Order "+ f.form.get('id').value.toString()  +" has been Updated.",'workorders',f.form.get('id').value.toString());
      this.havePictures = false;
      this.haveFiles = false;
      this.pictures.splice(0, this.pictures.length);
      this.files.splice(0, this.files.length);

      const index = this.rows.findIndex(function (item, i) {
        return item.id === ticketid;
      });

      if (index > -1) {
        f.form.value.status = f.form.get('next_action').value;
        this.rows[index] = f.form.value;
      }

      const randomnumber = Math.floor(Math.random() * 99999999);
      const id = 'AL-' + randomnumber;
      this.addApprovalLog(id, f.form.get('id').value.toString(), this.currentDate, this.currentTime, this.userNickName, 'Edited', 'This Work Order Was Modified By ' + this.userNickName);

      const randomnumber2 = Math.floor(Math.random() * 99999999);
      const id2 = 'AL-' + randomnumber2;
      if (this.next_action_from_edit != f.form.get('next_action').value) {
        // console.log('next action changed'+this.editT);
        this.addApprovalLog(id2, f.form.get('id').value.toString(), this.currentDate, this.currentTime, this.userNickName, f.form.get('next_action').value.toString(), 'Status Modified By ' + this.userNickName + ' From ' + '\'' + this.next_action_from_edit + '\'' + ' To ' + '\'' + f.form.get('next_action').value.toString() + '\'');
      }

      console.log('\'tickets\' component-> Work Order Updated Successfully.');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.TicketSubmitted = 'Work Order Updation Failed, Please Retry!';
      console.log('\'tickets\' component-> Error in Updation  Ticket!');
      console.log(error.response);
      this.loader.hide();
    });
  }

}
addApprovalLog(id, ticketID, created_date, created_time, created_by, action, remarks) {

  const approvalLogBody = {
    id: id,
    parentTicketId: ticketID,
    created_date: created_date,
    created_time: created_time,
    created_by: created_by,
    action: action,
    remarks: remarks
  };
  this.putWOApprovalLogInit.body = approvalLogBody;

  API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
    this.nextActionSuccess = true;
    this.nextActionMsg = 'Performed Successfully!';
    this.approvalLog.push(approvalLogBody);
    this.loader.hide();

    console.log('\'work-order-edit\' component-> Approval Log Recorded Successfully!');
    console.log(response);
  }).catch(error => {
    this.loader.hide();
    this.nextActionSuccess = true;
    this.nextActionMsg = 'Failed to Perform, Please Try Again';
    console.log('\'work-order-edit\' component-> Error in  Recording Approval Log!');
    console.log(error);
  });
}
getNextActionBy(next_action) {

  if (next_action === 'Inspection' || next_action === 'WO Question') {
    return this.pd_supervisor;
  } else if (next_action === 'Execute') {
    return this.con_admin;
  } else if (next_action === 'On hold' || next_action === 'Temp closed' || next_action === 'Pending Information' || next_action === 'Resident Action') {
    return this.pd_admin;
  } else if (next_action === 'Closed') {
    return 'Closed';
  } else if (next_action === 'Schedule appointment') {
    return this.pd_admin;
  } else {
    return '';
  }
}
saveNotifications(title, body, moduleName,id) {
  const randomnumber = Math.floor(Math.random() * 999999999);
  const createBody = {
    id: randomnumber.toString(),
    title:title,
    body: body,
    deepLinkArticleId: id,
    moduleName: moduleName,
    creation_date:this.currentDate,
    creation_time:this.currentTime
  };
  this.putMyInitNotifications.body = createBody;
  API.put(this.apiNameNotifications, this.pathNotifications, this.putMyInitNotifications).then(response => {
    console.log('\'notifications\' component-> New notification added Successfully!');
    console.log(response);
    this.sendNotifications(title, body, moduleName,id);

    this.loader.hide();
  }).catch(error => {
    console.log('\'notifications\' component-> Error in creating new notifications!');
    console.log(error.response);
  });
}

sendNotifications(title, body, moduleName,id){
  const headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'key=' + "AAAA5mIllzA:APA91bH-wa6UVq3IvGUIE4oliUiVO1t3tWu5DqocJG8y9p1O9grxBC05dwLCVIxkftFsoXKcpqsK3MHNZaaNM0Wo9xs0vfndkUGGJRqJ9sEk91JJNhtTkNXnQ3RJBxQVETXiZBeuReKL"
  });

  var callibayaqPlzObj = {
    "to": "/topics/houseResidents",
    "notification": {
      "body": body,
      "content_available": true,
      "priority": "high",
      "title": title
    },
    "data": {
      "sound": true,
      "module": moduleName,
      "deepLinkArticleId": id,
      "content_available": true,
      "priority": "high",
      "title": "Testing Deep Linking"
    }
  }

  this.http.post(this.notificationsUrl, callibayaqPlzObj, { headers }).subscribe(res => {
    console.log(res);
  }, error => {
    console.log(error);
  });
}

////////////////add remarks//////////////
AddRemarkFromAllWorkOrder(f) {
  if (f.approver_remarks == undefined || f.approver_remarks == '') {
    this.addRemarkFromAll = true;
    this.nextActionSuccess = true;
    this.nextActionMsg = 'Remark field was left empty. Add a new Remark';
  } else {
    this.loader.show();
    const randomnumber = Math.floor(Math.random() * 99999999);
    const id = 'AL-' + randomnumber;
    this.addRemarkFromAll = true;
    this.addApprovalLog(id, f.id, this.currentDate, this.currentTime, this.userNickName, f.status, f.approver_remarks);
    f.approver_remarks = '';
  }
}
///////////deletion////////
onDelete(c) {
  this.projectService.onDelete(c);
}

deleteConfirmation() {
  this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
    this.delete_results = response;
  }).catch(error => {
    this.delete_results = error;
  });
}

deleteResult() {
  $('#delete_result').modal('hide');
  this.getAll();
}
 /////////////////////////languages///////////
 setLanguage(language) {
  this.loader.show();
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('language');
  srchFiltersValues.push(language);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.add_new_work_orders_add_work_order = response.Items[0].add_new_work_orders_add_work_order;
    this.add_new_work_orders_created_by =response.Items[0].add_new_work_orders_created_by;
    this.add_new_work_orders_work_order_id = response.Items[0].add_new_work_orders_work_order_id;
    this.add_new_work_orders_street = response.Items[0].add_new_work_orders_street;
    this.add_new_work_orders_house_number = response.Items[0].add_new_work_orders_house_number;
    this.add_new_work_orders_location = response.Items[0].add_new_work_orders_location;
    this.add_new_work_orders_precinct_project = response.Items[0].add_new_work_orders_precinct_project;
    this.add_new_work_orders_element = response.Items[0].add_new_work_orders_element;
    this.add_new_work_orders_floor = response.Items[0].add_new_work_orders_floor;
    this.add_new_work_orders_defect = response.Items[0].add_new_work_orders_defect;
    this.add_new_work_orders_costs = response.Items[0].add_new_work_orders_costs;
    this.add_new_work_orders_competency = response.Items[0].add_new_work_orders_competency;
    this.add_new_work_orders_invoice = response.Items[0].add_new_work_orders_invoice;
    this.add_new_work_orders_days_to_execute = response.Items[0].add_new_work_orders_days_to_execute;
    this.add_new_work_orders_charge_to = response.Items[0].add_new_work_orders_charge_to;
    this.add_new_work_orders_priority = response.Items[0].add_new_work_orders_priority;
    this.add_new_work_orders_main_contractor = response.Items[0].add_new_work_orders_main_contractor;
    this.add_new_work_orders_next_action = response.Items[0].add_new_work_orders_next_action;
    this.add_new_work_orders_date_scheduled = response.Items[0].add_new_work_orders_date_scheduled;
    this.add_new_work_orders_cause = response.Items[0].add_new_work_orders_cause;
    this.add_new_work_orders_time_scheduled = response.Items[0].add_new_work_orders_time_scheduled;
    this.add_new_work_orders_am_pm = response.Items[0].add_new_work_orders_am_pm;
    this.add_new_work_orders_work_order_description = response.Items[0].add_new_work_orders_work_order_description;
    this.add_new_work_orders_upload_pictures = response.Items[0].add_new_work_orders_upload_pictures;
    this.add_new_work_orders_upload_documents_pdf_only = response.Items[0].add_new_work_orders_upload_documents_pdf_only;
    this.add_new_work_orders_create_new_work_order = response.Items[0].add_new_work_orders_create_new_work_order;
    this.all_work_orders_all_work_orders = response.Items[0].all_work_orders_all_work_orders;
    this.all_work_orders_print_work_order = response.Items[0].all_work_orders_print_work_order;
    this.my_pending_work_orders_my_pending_work_orders = response.Items[0].my_pending_work_orders_my_pending_work_orders;
    this.my_pending_work_orders_refresh_list = response.Items[0].my_pending_work_orders_refresh_list;
    this.my_pending_work_orders_add_work_order = response.Items[0].my_pending_work_orders_add_work_order;
    this.work_order_details_my_pending_work_orders = response.Items[0].work_order_details_my_pending_work_orders;
    this.work_order_details_print = response.Items[0].work_order_details_print;
    this.work_order_details_edit_work_order = response.Items[0].work_order_details_edit_work_order;
    this.work_order_details_remarks_and_approval_log = response.Items[0].work_order_details_remarks_and_approval_log;
    this.work_order_details_created_date = response.Items[0].work_order_details_created_date;
    this.work_order_details_created_time = response.Items[0].work_order_details_created_time;
    this.work_order_details_created_by = response.Items[0].work_order_details_created_by;
    this.work_order_details_action = response.Items[0].work_order_details_action;
    this.work_order_details_remarks = response.Items[0].work_order_details_remarks;
    this.work_order_details_next_action = response.Items[0].work_order_details_next_action;

    this.dashboard_property_developer = response.Items[0].dashboard_property_developer;
    this.users_status = response.Items[0].users_status;
    this.work_order_details_days_open = response.Items[0].work_order_details_days_open;
    this.work_order_details_date_closed = response.Items[0].work_order_details_date_closed;
    this.work_order_details_update_work_order = response.Items[0].work_order_details_update_work_order;
    this.work_order_details_work_order_details = response.Items[0].work_order_details_work_order_details;
    this.work_order_details_resident_name = response.Items[0].work_order_details_resident_name;
    this.work_order_details_resident_phone = response.Items[0].work_order_details_resident_phone;
    this.work_order_details_time_closed = response.Items[0].work_order_details_time_closed;
    this.work_order_details_closed_by = response.Items[0].work_order_details_closed_by;
    this.work_order_details_add_remark = response.Items[0].work_order_details_add_remark;

    this.add_new_work_orders_property_developer = response.Items[0].properties_property_developer;

    this.generals_search = response.Items[0].generals_search;
    this.generals_clear_search = response.Items[0].generals_clear_search;
    this.generals_view_details = response.Items[0].generals_view_details;
    this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
    this.generals_edit = response.Items[0].generals_edit;
    this.generals_delete = response.Items[0].generals_delete;
    this.generals_creation_date = response.Items[0].generals_creation_date;
    this.generals_actions = response.Items[0].generals_actions;
    this.generals_uploaded_files = response.Items[0].generals_uploaded_files;
    this.generals_download = response.Items[0].generals_download;

  }).catch(error => {
    this.loader.hide();
    console.log(error);
  });
}
retriveLanguages() {
  var status = 'Completed';
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('status');
  srchFiltersValues.push(status);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.languages = response.Items;
  }).catch(error => {
    console.log(error);
  });
}
///////////permisions////////
getPermissions() {
  this.projectService.getPermissions().then(response => {
    
    this.permissions_view=response[0].work_orders_view;
    this.permissions_create=response[0].work_orders_create;
    this.permissions_edit=response[0].work_orders_update;
    this.permissions_delete=response[0].work_orders_delete;


  }).catch(error => {
    console.log(error);
  })
}

ngOnDestroy() {
  $('#add_ticket').modal('hide');
  $('#edit_work_order').modal('hide');
  $('#delete_work_order').modal('hide');
  $('#view_all_work_order').modal('hide');
}
}