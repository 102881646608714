import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
import { LoaderService } from 'src/app/loader/loader.service';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  ////////total list//////////
  totalList: any;
  ///////////alert variables///////
  scan: boolean = false;
  public addMsg;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  scanResults = '';
  editFlag: boolean = false;
  addTypeOfUnitValidation: Boolean = false;
  typeofunitload: Boolean = false;
  //////////////search variables//////////
  searchT: any = [];
  public addLT: any = {};
  public uptLT: any = {};
  public rows = [];
  public tabList = [];
  public AllPd = [];
  public AllPdList = [];
  precinctsList = [];
  public typeOfUnit = [];
  unsortedrows = [];
  ////////////deletion/////////
  delete_results;
  generals_delete = "Delete";
  ///////////API//////////////
  apiName = 'taskanduserlevel'; // replace this with your api name.
  path = '/taskanduserlevel'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'taskanduserlevel'; // replace this with your api name.
  pathDelete = '/taskanduserlevel/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameTou = 'userlevelandtask'; // replace this with your api name.
  apiPathTou = '/userlevelandtask'; // replace this with the path you have configured on your API
  getTouInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  constructor(private loader: LoaderService, private projectService: AppService) { }

  ngOnInit() {
    this.getAll();
    this.getPropertyDevelopers();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

  }
  /////////////get functions////////////
  getAll() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.precinctsList = response;
      this.scan = false;
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response);
      this.totalList = Object.keys(this.rows).length;
      console.log('\'project\' component-> All  precincts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  ///////////search record /////////
  searchRecordLocally(f) {
    this.scanResults = "in progress";
    let Filtered = this.projectService.searchRecordLocally(f, this.precinctsList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    this.totalList = Object.keys(this.rows).length;

  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAll();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.precinctsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  /////////////add form////////////
  addform(id, f) {
    //console.log(f.form.value);
    this.loader.show();
    f.form.value.id = id;
    this.putMyInit.body = f.form.value;
    Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.loader.hide();

      if (this.editFlag == false) {
        this.rows.unshift(f.form.value);
        this.rows = this.rows;
        this.addMsg = 'Task Added Successfully!';
        $('#add_modal').modal('hide');
      } else {
        this.addMsg = "Updated Successfully."
        $('#Edit_modal').modal('hide');
      }
      console.log('\'type of unit\' component-> User Level added Successfully!');
      console.log(response);

    }).catch(error => {
      this.showErrorMsg = true;
      this.addMsg = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'type of unit\' component-> Error in creating new User Level!');
      console.log(error.response);
    });


  }
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');
  }
  onAdd(f) {
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      const randomnumber = Math.floor(Math.random() * 999999999);
      this.addform(randomnumber.toString(), f)
    }
  }

  onChangePD(property_developer) {
    this.typeOfUnit.splice(0, this.typeOfUnit.length);

    if (property_developer) {
      console.log(property_developer);
      this.typeofunitload = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (property_developer != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(property_developer);
      }
      this.projectService.scanContainsAnd(this.apiNameTou, this.apiPathTou, srchFiltersNames, srchFiltersValues).then(response => {
        this.typeOfUnit.push('');
        this.unsortedrows.push(...response);
        const sorted = this.unsortedrows.sort((t1, t2) => {
          const name1 = t1.id;
          const name2 = t2.id;
          if (name1 < name2) { return 1; }
          if (name1 > name2) { return -1; }
          return 0;
        });
        this.typeOfUnit.push(...sorted);
        this.typeofunitload = false;
        // if(this.editFlag==true){
        //   this.uptLT = this.updateItem;
        //   this.editFlag = false;
        // }
      });
    }

  }
  //////////////edit/////////////
  onEdit(item) {
    this.uptLT = item;
    $('#Edit_modal').modal('show');
  }
  onUpdate(f) {
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      this.addform(f.form.value.id, f);
      this.editFlag = true;
    }
  }

  ///////////////deletion///////////

  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAll();
  }

}
