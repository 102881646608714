import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { CookieService } from 'ngx-cookie-service';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { AuthService } from 'src/app/auth/auth.service';
import * as bootstrap from 'bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-property-developers',
  templateUrl: './property-developers.component.html',
  styleUrls: ['./property-developers.component.css'],
  providers: [DatePipe]

})
export class PropertyDevelopersComponent implements OnInit {


  public addPD: any = {};
  public uptPD: any = [];
  public viewPD: any = [];

  public genderOptions = [];
  DeleteFeedback = '';
  totalList: any;

  countryList = [];
  pdList = [];


  searchT: any = [];

  itemObj: any;
  showDeletionResponse = false;

  addPDValidation = false;
  passwordsMismatch = false;

  rows = [];
  srch = [];
  deleteID;
  delete_results;
  showSuccessMsg = false;
  showErrorMsg = false;
  PDAdded = '';
  PDUpdated = '';

  public userLevel;
  public userNickName;
  currentDate: string;

  scanResults = '';
  ticketScan = false;



  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameDelete = 'propertydevelopers'; // replace this with your api name.
  pathDelete = '/propertydevelopers/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  permissions_userlevel;
  permissions_view=false;
  permissions_create=false;
  permissions_edit=false;
  permissions_delete=false;

  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  property_developers_property_developer_id='Client Name & ID ';
  property_developer_property_developer_name='Client Name ';
  property_developers_company_type='Company Type ';
  property_developers_company_ssm_code='Company SSM Code ';
  property_developers_company_code='Company Code ';
  property_developers_company_start_date='Contract Start Date ';
  property_developers_house_number='House Number ';
  property_developers_street='Street ';
  property_developers_city='City ';
  property_developers_postal_code='Postal Code ';
  property_developers_state='State ';
  property_developers_country='Country ';
  property_developers_company_telephone='Company Telephone ';
  property_developers_company_email='Company Email ';
  property_developers_status='Status ';
  property_developers_pic_name='PIC Name ';
  property_developers_pic_ic_no='PIC IC No ';
  property_developers_pic_date_of_birth='PIC Date of Birth ';
  property_developers_pic_designation='PIC Designation ';
  property_developers_pic_gender='PIC Gender ';
  property_developers_pic_telephone='PIC Telephone ';
  property_developers_pic_email='PIC Email ';
  property_developers_cs_pic_name='CS PIC name ';
  property_developers_cs_pic_ic_no='CS PIC IC no ';
  property_developers_cs_pic_date_of_birth='CS PIC Date of Birth ';
  property_developers_cs_pic_designation='CS PIC Designation ';
  property_developers_cs_pic_gender='CS PIC Gender ';
  property_developers_cs_pic_telephone='CS PIC Telephone ';
  property_developers_cs_pic_email='CS PIC Email ';
  property_developers_qs_pic_name='QS PIC name ';
  property_developers_qs_pic_ic_no='QS PIC IC no ';
  property_developers_qs_pic_date_of_birth='QS PIC Date of Birth ';
  property_developers_qs_pic_designation='QS PIC Designation ';
  property_developers_qs_pic_gender='QS PIC Gender ';
  property_developers_qs_pic_telephone='QS PIC Telephone ';
  property_developers_qs_pic_email='QS PIC Email ';
  property_developers_accounts_pic_name='Accounts PIC name ';
  property_developers_accounts_pic_ic_no='Accounts PIC IC no ';
  property_developers_accounts_pic_date_of_birth='Accounts PIC Date of Birth ';
  property_developers_accounts_pic_designation='Accounts PIC Designation ';
  property_developers_accounts_pic_gender='Accounts PIC Gender ';
  property_developers_accounts_pic_telephone='Accounts PIC Telephone ';
  property_developers_accounts_pic_email='Accounts PIC Email ';
  property_developers_remarks='Remarks ';
  property_developers_upload_ic_pic='Upload IC PIC ';
  property_developers_upload_form_9='Upload Form 9 ';
  property_developers_upload_contracts='Upload Contracts ';
  property_developers_upload_bank_information='Upload Bank information ';
  property_developers_upload_support_documents='Upload Support Documents ';
  property_developers_create_property_developer='Create Client ';
  property_developers_add_property_developer='Add Client ';
  property_developers_edit_property_developer='Edit Client ';
  property_developers_update_property_developer='Update Client ';
  
  title = 'Clients';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';

  putPDInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  srchFiltersValues = [];
  srchFiltersNames = [];
  unsortedrows = [];


  constructor(
    private userService: AppService,
    private cookieService: CookieService,
    private loader: LoaderService,
    private authService: AuthService,
    private appService: AppService,
    private datePipe: DatePipe


  ) {
    this.genderOptions = appService.genderOptions;
    this.countryList = appService.countryList;
    this.userLevel = localStorage.getItem('user_level');
    this.userNickName = localStorage.getItem('nick_name');
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
  }



  ngOnInit() {
    this.getPermissions();

    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');


    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }

    this.getPropertyDevelopers();
  }

  getPropertyDevelopers() {

   var condition = [];
   condition.push("Client Accepted");
   condition.push("Pending Verification");

    this.srchFiltersNames.splice(0, this.srchFiltersNames.length);
    this.srchFiltersValues.splice(0, this.srchFiltersValues.length);

    for (let i = 0; i < condition.length; i++){
      this.srchFiltersNames.push('status');
      this.srchFiltersValues.push(condition[i]);
    }

    let FilterExpression = '';
    for (let i = 0; i < this.srchFiltersNames.length; i++) {
      FilterExpression += '(#' + this.srchFiltersNames[i] + ' = ' + ':e' + i + ')';
      if (i != this.srchFiltersNames.length - 1) {
        FilterExpression += ' or ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
      ExpressionAttributeNames['#status' ] = 'status';
    
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < this.srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':e' + i] = this.srchFiltersValues[i];
    }

    console.log(ExpressionAttributeValues);

    if (this.srchFiltersNames.length == 0) {
      this.ticketScan = true;
      this.scanResults = 'No Property Developers Found';
    } else {
      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanPd();
    }

  }


  scanPd() {
    this.ticketScan = true;
    this.scanResults = 'In Progress...';
    this.getPd(this.apiNamePD, this.apiPathPD, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      this.pdList.push(...this.rows);
      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanPd();
      } else {
        this.scanResults = 'Completed';
        this.ticketScan = false;
        this.srch.push(...this.rows);
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No Property Developers Found';
        }
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
    });
  }


  getPd(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'tickets\' component->   Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'tickets\' component-> Error in Retreiving  from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  addPropertyDeveloper(f) {
    //console.log('clicked');
    console.log(f.form.value);

    if (f.invalid === true) {
      this.addPDValidation = true;
    } else {

      let reg_date_formatted;
      if (f.form.get('reg_date').value == undefined) {
      } else {
        reg_date_formatted = this.reformatDate(f.form.get('reg_date').value);
      }

      const pic_date_of_birth = f.form.get('pic_date_of_birth').value;
      let pic_date_of_birth_formatted;
      if (pic_date_of_birth) {
        pic_date_of_birth_formatted = this.reformatDate(f.form.get('pic_date_of_birth').value);
      }

      const cs_pic_date_of_birth = f.form.get('cs_pic_date_of_birth').value;
      let cs_pic_date_of_birth_formatted;
      if (cs_pic_date_of_birth) {
        cs_pic_date_of_birth_formatted = this.reformatDate(f.form.get('cs_pic_date_of_birth').value);
      }

      const qs_pic_date_of_birth = f.form.get('qs_pic_date_of_birth').value;
      let qs_pic_date_of_birth_formatted;
      if (qs_pic_date_of_birth) {
        qs_pic_date_of_birth_formatted = this.reformatDate(f.form.get('qs_pic_date_of_birth').value);
      }

      const pd_pic_date_of_birth = f.form.get('pd_pic_date_of_birth').value;
      let pd_pic_date_of_birth_formatted;
      if (pd_pic_date_of_birth) {
        pd_pic_date_of_birth_formatted = this.reformatDate(f.form.get('pd_pic_date_of_birth').value);
      }

      const pd_renewal_date = f.form.get('pd_renewal_date').value;
      let pd_renewal_date_formatted;
      if (pd_renewal_date) {
        pd_renewal_date_formatted = this.reformatDate(f.form.get('pd_renewal_date').value);
      }



      this.loader.show();
      const addPDBody = {
        created_by: this.userNickName,
        creation_date: this.currentDate,
        id: f.form.get('id').value.toString(),
        pd_name: f.form.get('pd_name').value,
        pd_type: f.form.get('pd_type').value,
        company_ssm_code: f.form.get('company_ssm_code').value,
        company_code: f.form.get('company_code').value,
        reg_date: reg_date_formatted,
        pd_renewal_date: pd_renewal_date_formatted,
        house_number: f.form.get('house_number').value,
        street: f.form.get('street').value,
        city: f.form.get('city').value,
        postal_code: f.form.get('postal_code').value,
        state: f.form.get('state').value,
        country: f.form.get('country').value,
        company_telephone: f.form.get('company_telephone').value,
        company_email: f.form.get('company_email').value,
        status: f.form.get('status').value,

        pic_name: f.form.get('pic_name').value,
        pic_ic_no: f.form.get('pic_ic_no').value,
        pic_date_of_birth: pic_date_of_birth_formatted,
        pic_designation: f.form.get('pic_designation').value,
        pic_gender: f.form.get('pic_gender').value,
        pic_telephone: f.form.get('pic_telephone').value,
        pic_email: f.form.get('pic_email').value,

        cs_pic_name: f.form.get('cs_pic_name').value,
        cs_pic_ic_no: f.form.get('cs_pic_ic_no').value,
        cs_pic_date_of_birth: cs_pic_date_of_birth_formatted,
        cs_pic_designation: f.form.get('cs_pic_designation').value,
        cs_pic_gender: f.form.get('cs_pic_gender').value,
        cs_pic_telephone: f.form.get('cs_pic_telephone').value,
        cs_pic_email: f.form.get('cs_pic_email').value,

        qs_pic_name: f.form.get('qs_pic_name').value,
        qs_pic_ic_no: f.form.get('qs_pic_ic_no').value,
        qs_pic_date_of_birth: qs_pic_date_of_birth_formatted,
        qs_pic_designation: f.form.get('qs_pic_designation').value,
        qs_pic_gender: f.form.get('qs_pic_gender').value,
        qs_pic_telephone: f.form.get('qs_pic_telephone').value,
        qs_pic_email: f.form.get('qs_pic_email').value,

        pd_pic_name: f.form.get('pd_pic_name').value,
        pd_pic_ic_no: f.form.get('pd_pic_ic_no').value,
        pd_pic_date_of_birth: pd_pic_date_of_birth_formatted,
        pd_pic_designation: f.form.get('pd_pic_designation').value,
        pd_pic_gender: f.form.get('pd_pic_gender').value,
        pd_pic_telephone: f.form.get('pd_pic_telephone').value,
        pd_pic_email: f.form.get('pd_pic_email').value,

        remarks: f.form.get('remarks').value,
      };


      if (f.form.get('company_ssm_code').value === '') {
        delete addPDBody.company_ssm_code;
      }


      if (f.form.get('pic_ic_no').value === '') {
        delete addPDBody.pic_ic_no;
      }
      if (f.form.get('pic_date_of_birth').value === '') {
        delete addPDBody.pic_date_of_birth;
      }


      if (f.form.get('cs_pic_ic_no').value === '') {
        delete addPDBody.cs_pic_ic_no;
      }
      if (f.form.get('cs_pic_date_of_birth').value === '') {
        delete addPDBody.cs_pic_date_of_birth;
      }


      if (f.form.get('qs_pic_ic_no').value === '') {
        delete addPDBody.qs_pic_ic_no;
      }
      if (f.form.get('qs_pic_date_of_birth').value === '') {
        delete addPDBody.qs_pic_date_of_birth;
      }
      if (f.form.get('qs_pic_designation').value === '') {
        delete addPDBody.qs_pic_designation;
      }
      if (f.form.get('qs_pic_gender').value === '') {
        delete addPDBody.qs_pic_gender;
      }
      if (f.form.get('qs_pic_telephone').value === '') {
        delete addPDBody.qs_pic_telephone;
      }
      if (f.form.get('qs_pic_email').value === '') {
        delete addPDBody.qs_pic_email;
      }


      if (f.form.get('pd_pic_ic_no').value === '') {
        delete addPDBody.pd_pic_ic_no;
      }
      if (f.form.get('pd_pic_date_of_birth').value === '') {
        delete addPDBody.pd_pic_date_of_birth;
      }
      if (f.form.get('pd_pic_designation').value === '') {
        delete addPDBody.pd_pic_designation;
      }
      if (f.form.get('pd_pic_gender').value === '') {
        delete addPDBody.pd_pic_gender;
      }
      if (f.form.get('pd_pic_telephone').value === '') {
        delete addPDBody.pd_pic_telephone;
      }
      if (f.form.get('pd_pic_email').value === '') {
        delete addPDBody.pd_pic_email;
      }


      this.putPDInit.body = addPDBody;

      API.put(this.apiNamePD, this.apiPathPD, this.putPDInit).then(response => {
        this.showSuccessMsg = true;
        this.PDAdded = 'Client Added Successfully!';
        this.PDUpdated = 'Client Updated Successfully!';

        this.rows.unshift(f.form.value);
        this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_property_developer').modal('hide');
        console.log('\'Client\' component-> New Client Created Successfully!');
        console.log(response);
        this.loader.hide();
        this.totalList = this.totalList + 1;
      }).catch(error => {
        this.showErrorMsg = true;
        this.PDAdded = 'Client Submission Failed, Please Retry!';
        this.PDUpdated = 'Client Updation Failed, Please Retry!';

        console.log('\'Client\' component-> Error in creating new Client!');
        console.log(error.response);
        this.loader.hide();
      });

    }
  }

  addReset() {
    const randomnumber = Math.floor(Math.random() * 999999999);
    this.addPDValidation = false;

    this.addPD = {
      id: 'PD-' + randomnumber,
      status: 'Client Accepted',
      reg_date: ''
    };
    $('#add_property_developer').modal('show');
  }

  onEdit(item) {
    this.uptPD = Object.assign({}, item);
    if (this.uptPD.reg_date != undefined) {
      var reg_dateParts = item.reg_date.split("/");
      this.uptPD.reg_date = reg_dateParts[2] + "-" + reg_dateParts[1] + "-" + reg_dateParts[0];
    }
    if (this.uptPD.pic_date_of_birth != undefined) {
      var pic_date_of_birthParts = item.pic_date_of_birth.split("/");
      this.uptPD.pic_date_of_birth = pic_date_of_birthParts[2] + "-" + pic_date_of_birthParts[1] + "-" + pic_date_of_birthParts[0];
    }
    if (this.uptPD.cs_pic_date_of_birth != undefined) {
      var cs_pic_date_of_birthParts = item.cs_pic_date_of_birth.split("/");
      this.uptPD.cs_pic_date_of_birth = cs_pic_date_of_birthParts[2] + "-" + cs_pic_date_of_birthParts[1] + "-" + cs_pic_date_of_birthParts[0];
    }
    if (this.uptPD.qs_pic_date_of_birth != undefined) {
      var qs_pic_date_of_birthParts = item.qs_pic_date_of_birth.split("/");
      this.uptPD.qs_pic_date_of_birth = qs_pic_date_of_birthParts[2] + "-" + qs_pic_date_of_birthParts[1] + "-" + qs_pic_date_of_birthParts[0];
    }
    if (this.uptPD.pd_pic_date_of_birth != undefined) {
      var pd_pic_date_of_birthParts = item.pd_pic_date_of_birth.split("/");
      this.uptPD.pd_pic_date_of_birth = pd_pic_date_of_birthParts[2] + "-" + pd_pic_date_of_birthParts[1] + "-" + pd_pic_date_of_birthParts[0];
    }

    if (this.uptPD.pd_renewal_date != undefined) {
      var pd_renewal_dateParts = item.pd_renewal_date.split("/");
      this.uptPD.pd_renewal_date = pd_renewal_dateParts[2] + "-" + pd_renewal_dateParts[1] + "-" + pd_renewal_dateParts[0];
    }

    $('#edit_property_developer').modal('show');
  }

  // getPropertyDevelopers() {
  //   this.loader.show();
  //   API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
  //     this.rows.splice(0, this.rows.length);
  //     this.rows.push(...response.data);
  //     this.totalList = Object.keys(this.rows).length;
  //     this.srch.push(...this.rows);
  //     this.loader.hide();
  //     this.pdList = response.data;
  //     console.log('\'Client\' component-> All Client Retrieved Successfully!');
  //     console.log(response);
  //   }).catch(error => {
  //     this.loader.hide();
  //     console.log('\'Client\' component-> Error in Retreiving All Property Developers from server!');
  //     console.log(error.response);
  //   });
  // }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getPropertyDevelopers();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }

  viewPropertyDeveloper(item) {

    let temp;
    // console.log(item);
    temp = item;
    this.viewPD = temp;
  }
 

  searchName(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { pd_name: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchCompanyCode(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { company_code: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchCity(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { city: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchCountry(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { country: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      // console.log(Filtered);
    }
  }

  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const pd_name = f.form.get('pd_name').value;
    const company_code = f.form.get('company_code').value;
    const city = f.form.get('city').value;
    const country = f.form.get('country').value;

    if (pd_name != undefined && pd_name != "") {
      srchFiltersNames.push('pd_name');
      srchFiltersValues.push(pd_name);
    }
    if (company_code != undefined && company_code != "") {
      srchFiltersNames.push('company_code');
      srchFiltersValues.push(company_code);
    }
    if (city != undefined && city != "") {
      srchFiltersNames.push('city');
      srchFiltersValues.push(city);
    }
    if (country != undefined && country != "") {
      srchFiltersNames.push('country');
      srchFiltersValues.push(country);
    } 

    if (srchFiltersNames.length == 0) {
      // this.scan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Results';
    } else {

      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[srchFiltersNames[i]] = srchFiltersValues[i];
      }
      console.log(ExpressionAttributeValues);

      var Filtered = this.pdList.filter(function (item) {
        for (var key in ExpressionAttributeValues) {
          if (item[key] === undefined || item[key] != ExpressionAttributeValues[key])
            return false;
        }
        return true;
      });
      console.log(Filtered);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);

    }
  }


  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});

    this.rows.splice(0, this.rows.length);
    this.srch.splice(0,this.srch.length);

    this.rows.push(...this.pdList);
    this.totalList = Object.keys(this.rows).length;
    this.srch.push(...this.rows);
  }


  reformatDate(dateStr) {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
  }


  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.title = this.rowslanguages[0].property_developers;

        this.property_developers_property_developer_id = this.rowslanguages[0].property_developers_property_developer_id;
        this.property_developer_property_developer_name = this.rowslanguages[0].property_developer_property_developer_name;
        this.property_developers_company_type =this.rowslanguages[0].property_developers_company_type;
        this.property_developers_company_ssm_code = this.rowslanguages[0].property_developers_company_ssm_code;
        this.property_developers_company_code = this.rowslanguages[0].property_developers_company_code;
        this.property_developers_company_start_date = this.rowslanguages[0].property_developers_company_start_date;
        this.property_developers_house_number = this.rowslanguages[0].property_developers_house_number;
        this.property_developers_street = this.rowslanguages[0].property_developers_street;
        this.property_developers_city = this.rowslanguages[0].property_developers_city;
        this.property_developers_postal_code = this.rowslanguages[0].property_developers_postal_code;
        this.property_developers_state = this.rowslanguages[0].property_developers_state;
        this.property_developers_country = this.rowslanguages[0].property_developers_country;
        this.property_developers_company_telephone = this.rowslanguages[0].property_developers_company_telephone;
        this.property_developers_company_email = this.rowslanguages[0].property_developers_company_email;
        this.property_developers_status = this.rowslanguages[0].property_developers_status;
        this.property_developers_pic_name = this.rowslanguages[0].property_developers_pic_name;
        this.property_developers_pic_ic_no = this.rowslanguages[0].property_developers_pic_ic_no;
        this.property_developers_pic_date_of_birth = this.rowslanguages[0].property_developers_pic_date_of_birth;
        this.property_developers_pic_designation = this.rowslanguages[0].property_developers_pic_designation;
        this.property_developers_pic_gender = this.rowslanguages[0].property_developers_pic_gender;
        this.property_developers_pic_telephone = this.rowslanguages[0].property_developers_pic_telephone;
        this.property_developers_pic_email = this.rowslanguages[0].property_developers_pic_email;
        this.property_developers_cs_pic_name = this.rowslanguages[0].property_developers_cs_pic_name;
        this.property_developers_cs_pic_ic_no = this.rowslanguages[0].property_developers_cs_pic_ic_no;
        this.property_developers_cs_pic_date_of_birth = this.rowslanguages[0].property_developers_cs_pic_date_of_birth;
        this.property_developers_cs_pic_designation =this.rowslanguages[0].property_developers_cs_pic_designation;
        this.property_developers_cs_pic_gender = this.rowslanguages[0].property_developers_cs_pic_gender;
        this.property_developers_cs_pic_telephone = this.rowslanguages[0].property_developers_cs_pic_telephone;
        this.property_developers_cs_pic_email = this.rowslanguages[0].property_developers_cs_pic_email;
        this.property_developers_qs_pic_name = this.rowslanguages[0].property_developers_qs_pic_name;
        this.property_developers_qs_pic_ic_no = this.rowslanguages[0].property_developers_qs_pic_ic_no;
        this.property_developers_qs_pic_date_of_birth = this.rowslanguages[0].property_developers_qs_pic_date_of_birth;
        this.property_developers_qs_pic_designation = this.rowslanguages[0].property_developers_qs_pic_designation;
        this.property_developers_qs_pic_gender = this.rowslanguages[0].property_developers_qs_pic_gender;
        this.property_developers_qs_pic_telephone = this.rowslanguages[0].property_developers_qs_pic_telephone;
        this.property_developers_qs_pic_email = this.rowslanguages[0].property_developers_qs_pic_email;
        this.property_developers_accounts_pic_name = this.rowslanguages[0].property_developers_accounts_pic_name;
        this.property_developers_accounts_pic_ic_no = this.rowslanguages[0].property_developers_accounts_pic_ic_no;
        this.property_developers_accounts_pic_date_of_birth = this.rowslanguages[0].property_developers_accounts_pic_date_of_birth;
        this.property_developers_accounts_pic_designation = this.rowslanguages[0].property_developers_accounts_pic_designation;
        this.property_developers_accounts_pic_gender = this.rowslanguages[0].property_developers_accounts_pic_gender;
        this.property_developers_accounts_pic_telephone = this.rowslanguages[0].property_developers_accounts_pic_telephone;
        this.property_developers_accounts_pic_email = this.rowslanguages[0].property_developers_accounts_pic_email;
        this.property_developers_remarks = this.rowslanguages[0].property_developers_remarks;
        this.property_developers_upload_ic_pic = this.rowslanguages[0].property_developers_upload_ic_pic;
        this.property_developers_upload_form_9 = this.rowslanguages[0].property_developers_upload_form_9;
        this.property_developers_upload_contracts = this.rowslanguages[0].property_developers_upload_contracts;
        this.property_developers_upload_bank_information = this.rowslanguages[0].property_developers_upload_bank_information;
        this.property_developers_upload_support_documents = this.rowslanguages[0].property_developers_upload_support_documents;
       
        
        this.property_developers_create_property_developer = this.rowslanguages[0].property_developers_create_property_developer;
        this.property_developers_add_property_developer = this.rowslanguages[0].property_developers_add_property_developer;
        this.property_developers_edit_property_developer = this.rowslanguages[0].property_developers_edit_property_developer;
        this.property_developers_update_property_developer = this.rowslanguages[0].property_developers_update_property_developer;

        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_actions = this.rowslanguages[0].generals_actions;


        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  getPermissions() {
    API.get(this.apiNamePermissions, this.apiPathPermissions, this.getPermissionsInit).then(response => {
      this.loader.hide();
      this.permissions_userlevel = localStorage.getItem('user_level');
      const filters = { user_level: [this.permissions_userlevel] },
      results = response.data,
      Filtered = this.multiFilter(results, filters);

      this.permissions_view=Filtered[0].pd_view;
      this.permissions_create=Filtered[0].pd_create;
      this.permissions_edit=Filtered[0].pd_update;
      this.permissions_delete=Filtered[0].pd_delete;
     
    
      console.log(Filtered);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Sidebar\' component-> Error in Retreiving All permissions from server!'+error);
      console.log(error.response);
    });
  }

  ngOnDestroy() {
    $('#add_property_developer').modal('hide');
  }
}
