import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import * as XLSX from 'xlsx';



declare const $: any;
type AOA = any[][];
@Component({
  selector: 'app-competency',
  templateUrl: './competency.component.html',
  styleUrls: ['./competency.component.css']
})
export class CompetencyComponent implements OnInit {
///////////total  list/////////
totalList: any;

////////////Alert variable////////
scan: Boolean = false;
public scanResults;
public editFlag: boolean = false;
showErrorMsg: Boolean = false;
showSuccessMsg: Boolean = false;
public typeOfUnitAdd;
typeofunitload: Boolean = false;
locationload: Boolean = false;
elementload: Boolean = false;
defectLoad: Boolean = false;
competencyLoad: Boolean = false;
addCompetencyValidation: Boolean = false;
////////////languages/////////

led_cause_property_developer = 'Client';
led_cause_type_of_unit = 'Type Of Unit';
led_cause_location = 'Location';
led_cause_element = 'Element';
led_cause_defect = 'Defect';
led_cause_competency = 'Competency';
led_cause_days_to_execute = 'Days To Execute';
led_cause_priority = 'Priority';
led_cause_next_action = 'Next Action';
led_cause_cause = 'Cause';
public languages = [];
//////////search variable///////\
searchT: any = [];
precinctsList = [];
unsortedrows = [];
public rows = [];
public addLT: any = {};
public uptLT: any = {};
public propertyDevelopers = [];
public typeOfUnit = [];
public locations = [];
public elements = [];
public defects = [];
public competencys = [];
public AllPd = [];
public AllPdList = [];

///////////Edit variables/////////
public updateItem: any = {};
public edittou;
public editlocation;
public editElement;



/////////////delete/////
delete_results;
///////////file variables///////
uploadFile = false;
Results = '';
excelData: AOA = [[1, 2], [3, 4]];
RecordAdded = '';
///////////aPi////////////
apiName = 'ledCompetencies'; // replace this with your api name.
path = '/ledCompetencies'; // replace this with the path you have configured on your API
putMyInit = {
  body: {}, // replace this with attributes you need
  headers: {} // OPTIONAL
};
apiNameDelete = 'ledCompetencies'; // replace this with your api name.
pathDelete = '/ledCompetencies/delete'; // replace this with the path you have configured on your API
myInitDelete = {
  body: {}, // replace this with attributes you need
};
getMyInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

apiNameDefect = 'ledDefect'; // replace this with your api name.
apipathDefect = '/ledDefect'; // replace this with the path you have configured on your API
getDefectInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

apiNameElement = 'ledElement'; // replace this with your api name.
apipathElement = '/ledElement'; // replace this with the path you have configured on your API
getElementInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

apiNameLocation = 'LEDlocation'; // replace this with your api name.
apipathLocation = '/ledLocation'; // replace this with the path you have configured on your API
getLocationInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

apiNameTou = 'ledTypeOfUnit'; // replace this with your api name.
apiPathTou = '/ledTypeofUnit'; // replace this with the path you have configured on your API
getTouInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

apiNamePD = 'propertydevelopers';
apiPathPD = '/propertydevelopers';
getPDInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {  // OPTIONAL
    name: 'param'
  }
};
apiNamelanguages = 'languages'; // replace this with your api name.
pathlanguages = '/languages'; // replace this with the path you have configured on your API
getMyInitlanguages = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};

constructor(private leaveService: AppService, private loader: LoaderService, private projectService: AppService) {
}
ngOnInit() {
  this.getAll();
  this.getPropertyDevelopers();
  $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
  }).trigger('blur');
  var language = localStorage.getItem('language');
  if (language != 'undefined' && language != 'English') {
    console.log(language);
    this.setLanguage(language);
  } else {
    //console.log("null5");
  }
}
//////////Add competency//////
addCompetency(f) {
  if (f.invalid) {
    this.addCompetencyValidation = true;
  } else {
    this.loader.show();
    const randomnumber = Math.floor(Math.random() * 999999999);
    f.form.value.id = randomnumber.toString(),
      this.putMyInit.body = f.form.value;
    Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.typeOfUnitAdd = 'Competency Added Successfully!';

      this.rows.unshift(f.form.value);
      // this.srch.unshift(f.form.value);
      this.rows = this.rows;
      $('#add_competency').modal('hide');
      console.log('\'competency\' component-> New competency added Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.typeOfUnitAdd = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'competency\' component-> Error in creating new competency!');
      console.log(error.response);
    });
  }
}
addReset() {
  this.addCompetencyValidation = false;
  let randomnumber = Math.floor(Math.random() * 500);
  this.addLT = { 'id': randomnumber };
  $('#add_competency').modal('show');
}
//////////////Edit//////////////
onEdit(item) {
  this.addCompetencyValidation = false;
  this.editFlag = true;
  this.uptLT = {};
  this.updateItem = item;
  this.onChangePD(item.property_developer);
  this.edittou = item.type_of_unit;
  this.editlocation = item.location;
  this.editElement = item.element;
  $('#Edit_modal').modal('show');
}

onUpdate(f) {
  if (f.invalid) {
    this.addCompetencyValidation = true;
  } else {
    this.loader.show();
    const createBody = {
      id: f.form.value.id,
      property_developer: f.form.value.property_developer,
      type_of_unit: f.form.value.type_of_unit,
      location: f.form.value.location,
      element: f.form.value.element,
      defect: f.form.value.defect,
      competency: f.form.value.competency,
    };
    this.putMyInit.body = createBody;
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      var id = f.form.value.id;
      var arr = this.rows.find(function (item, i) {
        return item.id === id
      });

      arr.id = f.form.value.id;
      arr.property_developer = f.form.value.property_developer;
      arr.type_of_unit = f.form.value.type_of_unit;
      arr.location = f.form.value.location;
      arr.element = f.form.value.element;
      arr.defect = f.form.value.defect;
      arr.competency = f.form.value.competency;

      var index = this.rows.findIndex(function (item, i) {
        return item.id === id
      });
      if (index > -1) {
        this.rows.splice(index, 1);
      }

      this.rows.unshift(arr);
      this.rows = this.rows;
      $('#Edit_modal').modal('hide');
      console.log('\'competency\' component-> Updated Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.typeOfUnitAdd = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'competency\' component-> Error in Updating!');
      console.log(error.response);
    });
  }
}
onChangePD(property_developer) {
  this.typeOfUnit.splice(0, this.typeOfUnit.length);
  this.locations.splice(0, this.locations.length);
  this.elements.splice(0, this.elements.length);
  this.defects.splice(0, this.defects.length);



  if (property_developer) {
    console.log(property_developer);
    this.typeofunitload = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    if (property_developer != undefined) {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(property_developer);
    }
    this.projectService.scanContainsAnd(this.apiNameTou, this.apiPathTou, srchFiltersNames, srchFiltersValues).then(response => {
      this.typeOfUnit.push('');
      this.unsortedrows.push(...response);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.typeOfUnit.push(...sorted);

      if (this.editFlag == true) {
        this.onChangetou(this.edittou);
      }
      this.typeofunitload = false;
    });
  }
}
onChangetou(tou) {
  this.locations.splice(0, this.locations.length);
  this.elements.splice(0, this.elements.length);
  this.defects.splice(0, this.defects.length);



  if (tou) {
    console.log(tou);
    this.locationload = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    if (tou != undefined) {
      srchFiltersNames.push('type_of_unit');
      srchFiltersValues.push(tou);
    }
    this.projectService.scanContainsAnd(this.apiNameLocation, this.apipathLocation, srchFiltersNames, srchFiltersValues).then(response => {
      this.locations.push('');
      this.unsortedrows.push(...response);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.locations.push(...sorted);

      if (this.editFlag == true) {
        this.onChangeLocation(this.editlocation);
      }
      this.locationload = false;
    });
  }
}
onChangeLocation(location) {
  this.elements.splice(0, this.elements.length);
  this.defects.splice(0, this.defects.length);;



  if (location) {
    console.log(location);
    this.elementload = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    if (location != undefined) {
      srchFiltersNames.push('location');
      srchFiltersValues.push(location);
    }

    this.projectService.scanContainsAnd(this.apiNameElement, this.apipathElement, srchFiltersNames, srchFiltersValues).then(response => {
      this.elements.push('');
      this.unsortedrows.push(...response);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.elements.push(...sorted);

      if (this.editFlag == true) {
        this.onChangeElement(this.editElement);
      }
      this.elementload = false;
    });
  }

}

onChangeElement(element) {
  this.defects.splice(0, this.defects.length);
  if (element) {
    console.log(element);
    this.defectLoad = true;
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    if (element != undefined) {
      srchFiltersNames.push('element');
      srchFiltersValues.push(element);

    }
    this.projectService.scanContainsAnd(this.apiNameDefect, this.apipathDefect, srchFiltersNames, srchFiltersValues).then(response => {
      this.defects.push('');
      this.unsortedrows.push(...response);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.defects.push(...sorted);
      if (this.editFlag == true) {
        this.uptLT = this.updateItem;
        this.editFlag = false;
      }
      this.defectLoad = false;
    });

  }
}
//////////get functions/////////

getPropertyDevelopers() {
  var p = [];
  this.AllPd.splice(0, this.AllPd.length);
  API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
    this.AllPdList.push(...response.data)
    response.data.forEach(function (arrayItem) {
      var x = arrayItem.pd_name;
      p.push(x);
    });
    const results = p.filter(element => {
      return element !== undefined && element !== "" && element !== "New Lead";
    });
    this.AllPd.push(...results);
    console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
    console.log(this.AllPd);
  }).catch(error => {
    this.loader.hide();
    console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
    console.log(error.response);
  });
}
getAll() {
  this.scan = true;
  this.scanResults = 'In Progress...';
  API.get(this.apiName, this.path, this.getMyInit).then(response => {
    this.precinctsList = response;
    this.scan = false;
    this.rows.splice(0, this.rows.length);
    this.rows.push(...response);
    this.totalList = Object.keys(this.rows).length;
    console.log('\'project\' component-> All  precincts Retrieved Successfully!');
    console.log(response);
  }).catch(error => {
    this.loader.hide();
    console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
    console.log(error.response);
  });
}
//////////file upload///////////
// onFileUpload(evt: any) {
//   /* wire up file reader */
//   const target: DataTransfer = <DataTransfer>(evt.target);
//   if (target.files.length !== 1) {
//     this.uploadFile = true;
//     this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
//     throw new Error('Cannot use multiple files');
//   }
//   const reader: FileReader = new FileReader();
//   reader.onload = (e: any) => {
//     /* read workbook */
//     const bstr: string = e.target.result;
//     const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

//     /* grab first sheet */
//     const wsname: string = wb.SheetNames[0];
//     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

//     /* save data */
//     this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
//     console.log(this.excelData);
//     console.log(this.excelData[1].length);

//     if (this.excelData.length >= 2) {
//       if (this.excelData[1].length == 7) {
//         this.uploadFile = true;
//         this.Results = "Under Progress...";
//         this.asynccalltoupload(this.excelData);
//       } else {
//         this.uploadFile = true;
//         this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
//       }
//     } else {
//       this.uploadFile = true;
//       this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
//     }
//   };
//   reader.readAsBinaryString(target.files[0]);
// }

// async asynccalltoupload(excelData) {
//   for (var i = 2; i < excelData.length; i++) {
//     await new Promise<void>(resolve => {
//       this.addFromFile(excelData[i][0], excelData[i][1], excelData[i][2], excelData[i][3], excelData[i][4], excelData[i][5], excelData[i][6], i
//       ).then(response => {
//         console.log('This is iteration ' + i);
//         resolve();
//       }).catch(error => {
//         console.log(error);
//       });
//     });
//   }
//   this.Results = "Completed. Please Go ";
// }

// addFromFile(location, competency, property_developer, type_of_unit, defect, id, element, i): any {

//   const createBody = {
//     location: location,
//     competency: competency,
//     property_developer: property_developer,
//     type_of_unit: type_of_unit,
//     id: id,
//     element: element,
//     defect: defect
//   };

//   this.putMyInit.body = createBody;
//   //console.log(createBody);
//   return new Promise<string>((resolve, reject) => {
//     API.put(this.apiName, this.path, this.putMyInit).then(response => {
//       this.showSuccessMsg = true;
//       this.RecordAdded = i + ": " + id + '  Added Successfully!';
//       console.log('\'all-residents\' component-> New Created Successfully!');
//       console.log(response);
//       resolve(response);
//     }).catch(error => {
//       this.showErrorMsg = true;
//       this.RecordAdded = id + 'Failed, Please Retry or Contact App Support';
//       console.log('\'all-\' component-> Error in creating new !');
//       console.log(error.response);
//       reject(error.response);
//     });
//   });

// }
////////search functions/////
searchRecordLocally(f) {
  let Filtered = this.projectService.searchRecordLocally(f, this.precinctsList);
  this.rows.splice(0, this.rows.length);
  this.rows.push(...Filtered);
  this.totalList = Object.keys(this.rows).length;
}
clearSearch() {
  this.rows.splice(0, this.rows.length);
  this.searchT = Object.keys(this.searchT).reduce(
    (accumulator, current) => {
      accumulator[current] = null;
      return accumulator
    }, {});
  this.getAll();
}
instantSearch(val, name) {
  this.rows.splice(0, this.rows.length);
  this.rows.push(...this.projectService.instantSearch(val, this.precinctsList, name, ''))
  this.totalList = Object.keys(this.rows).length;
}
///////////////delete functions///////////

onDelete(c) {
  this.projectService.onDelete(c);
}

deleteConfirmation() {
  this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
    this.delete_results = response;
  }).catch(error => {
    this.delete_results = error;
  });
}

deleteResult() {
  $('#delete_result').modal('hide');
  this.getAll();
}

///////////languages/////////
setLanguage(language) {
  this.loader.show();
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('language');
  srchFiltersValues.push(language);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.led_cause_property_developer = response.Items[0].led_cause_property_developer;
    this.led_cause_type_of_unit = response.Items[0].led_cause_type_of_unit;
    this.led_cause_location = response.Items[0].led_cause_location;
    this.led_cause_element = response.Items[0].led_cause_element;
    this.led_cause_defect = response.Items[0].led_cause_defect;
    this.led_cause_competency = response.Items[0].led_cause_competency;
    this.led_cause_days_to_execute = response.Items[0].led_cause_days_to_execute;
    this.led_cause_priority = response.Items[0].led_cause_priority;
    this.led_cause_next_action = response.Items[0].led_cause_next_action;
    this.led_cause_cause = response.Items[0].led_cause_cause;

  }).catch(error => {
    this.loader.hide();
    console.log(error);
  });
}
retriveLanguages() {
  var status = 'Completed';
  const srchFiltersValues = [];
  const srchFiltersNames = [];

  srchFiltersNames.push('status');
  srchFiltersValues.push(status);

  this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
    this.loader.hide();
    this.languages = response.Items;
  }).catch(error => {
    console.log(error);
  });
}
}