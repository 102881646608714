import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import * as XLSX from 'xlsx';



declare const $: any;
type AOA = any[][];


@Component({
  selector: 'app-typeofunit',
  templateUrl: './typeofunit.component.html',
  styleUrls: ['./typeofunit.component.css']
})
export class TypeofunitComponent implements OnInit {
  /////////total list///
  totalList: any;

  /////////////alert variable///////////
  scan: boolean = false;
  scanResults = '';
  addTypeOfUnitValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public typeOfUnitAdd;
  //////////search variable///////
  searchT: any = [];
  public addLT: any = {};
  public uptLT: any = {};
  precinctsList = [];
  public rows = [];
  public PropertyDevelopers = [];
  excelData: AOA = [[1, 2], [3, 4]];
  /////////////language///////

  led_cause_property_developer = 'Client';
  led_cause_type_of_unit = 'Type Of Unit';
  led_cause_location = 'Location';
  led_cause_element = 'Element';
  led_cause_defect = 'Defect';
  led_cause_competency = 'Competency';
  led_cause_days_to_execute = 'Days To Execute';
  led_cause_priority = 'Priority';
  led_cause_next_action = 'Next Action';
  led_cause_cause = 'Cause';

  public languages = [];
  /////////////delete/////////
  delete_results;
  /////////files variables///
  uploadFile = false;
  Results = '';
  RecordAdded = '';
  public AllPd = [];
  public AllPdList = [];
  ////////////API?/////////
  apiName = 'ledTypeOfUnit'; // replace this with your api name.
  path = '/ledTypeofUnit'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameDelete = 'ledTypeOfUnit'; // replace this with your api name.
  pathDelete = '/ledTypeofUnit/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  constructor(private leaveService: AppService, private loader: LoaderService, private projectService: AppService) {

  }


  ngOnInit() {
    this.getPropertyDevelopers();
    this.getAll();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      console.log(language);
      this.setLanguage(language);
    } else {
      //console.log("null5");
    }
  }
  //////////////get functions////////
  getPropertyDevelopers() {
    var p = [];
    this.AllPd.splice(0, this.AllPd.length);
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  getAll() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.precinctsList = response;
      this.scan = false;
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response);
      this.totalList = Object.keys(this.rows).length;

      console.log('\'project\' component-> All  precincts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  ////////search functions/////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.precinctsList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAll();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.precinctsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  ///////////Add unit///////
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_type_of_unit').modal('show');
  }
  addtype_of_unit(f) {
    //console.log(f.form.value);
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      this.loader.show();
      const randomnumber = Math.floor(Math.random() * 999999999);
      f.form.value.id = randomnumber.toString();
      this.putMyInit.body = f.form.value;
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.typeOfUnitAdd = 'Type Of Unit Added Successfully!';

        this.rows.unshift(f.form.value);
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_type_of_unit').modal('hide');
        console.log('\'type of unit\' component-> New type of Unit added Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.typeOfUnitAdd = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'type of unit\' component-> Error in creating new Type Of Unit!');
        console.log(error.response);
      });
    }

  }
  ///////////Edit//////////

  onEdit(item) {
    this.uptLT = item;
    $('#Edit_modal').modal('show');
  }

  onUpdate(f) {
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      this.loader.show();
      const createBody = {
        id: f.form.value.id,
        property_developer: f.form.value.property_developer,
        type_of_unit: f.form.value.type_of_unit,
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        var id = f.form.value.id;
        var arr = this.rows.find(function (item, i) {
          return item.id === id
        });

        arr.id = f.form.value.id;
        arr.property_developer = f.form.value.property_developer;
        arr.type_of_unit = f.form.value.type_of_unit;

        var index = this.rows.findIndex(function (item, i) {
          return item.id === id
        });
        if (index > -1) {
          this.rows.splice(index, 1);
        }

        this.rows.unshift(arr);
        this.rows = this.rows;
        $('#Edit_modal').modal('hide');
        console.log('\'type of unit\' component-> Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.typeOfUnitAdd = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'type of unit\' component-> Error in Updating!');
        console.log(error.response);
      });
    }
  }
  // //////////////file upload////////
  // onFileUpload(evt: any) {
  //   /* wire up file reader */
  //   const target: DataTransfer = <DataTransfer>(evt.target);
  //   if (target.files.length !== 1) {
  //     this.uploadFile = true;
  //     this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
  //     throw new Error('Cannot use multiple files');
  //   }
  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {
  //     /* read workbook */
  //     const bstr: string = e.target.result;
  //     const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

  //     /* grab first sheet */
  //     const wsname: string = wb.SheetNames[0];
  //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //     /* save data */
  //     this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  //     console.log(this.excelData);
  //     console.log(this.excelData[1].length);

  //     if (this.excelData.length >= 2) {
  //       if (this.excelData[1].length == 3) {
  //         this.uploadFile = true;
  //         this.Results = "Under Progress...";
  //         this.asynccalltoupload(this.excelData);
  //       } else {
  //         this.uploadFile = true;
  //         this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
  //       }
  //     } else {
  //       this.uploadFile = true;
  //       this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
  //     }
  //   };
  //   reader.readAsBinaryString(target.files[0]);
  // }

  // async asynccalltoupload(excelData) {
  //   for (var i = 2; i < excelData.length; i++) {
  //     await new Promise<void>(resolve => {
  //       this.addFromFile(excelData[i][0], excelData[i][1], excelData[i][2], i
  //       ).then(response => {
  //         console.log('This is iteration ' + i);
  //         resolve();
  //       }).catch(error => {
  //         console.log(error);
  //       });
  //     });
  //   }
  //   this.Results = "Completed. Please Go ";
  // }

  // addFromFile(property_developer, ID, type_of_unit, i): any {

  //   const createBody = {
  //     id: ID,
  //     property_developer: property_developer,
  //     type_of_unit: type_of_unit,
  //   };

  //   this.putMyInit.body = createBody;
  //   //console.log(createBody);
  //   return new Promise<string>((resolve, reject) => {
  //     API.put(this.apiName, this.path, this.putMyInit).then(response => {
  //       this.showSuccessMsg = true;
  //       this.RecordAdded = i + ": " + ID + '  Added Successfully!';
  //       console.log('\'all-residents\' component-> New Created Successfully!');
  //       console.log(response);
  //       resolve(response);
  //     }).catch(error => {
  //       this.showErrorMsg = true;
  //       this.RecordAdded = ID + 'Failed, Please Retry or Contact App Support';
  //       console.log('\'all-\' component-> Error in creating new !');
  //       console.log(error.response);
  //       reject(error.response);
  //     });
  //   });

  // }
  ///////////////deletion///////////

  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAll();
  }

  //////////////////////////////////////////////languages//////////////////////////
  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.led_cause_property_developer = response.Items[0].led_cause_property_developer;
      this.led_cause_type_of_unit = response.Items[0].led_cause_type_of_unit;
      this.led_cause_location = response.Items[0].led_cause_location;
      this.led_cause_element = response.Items[0].led_cause_element;
      this.led_cause_defect = response.Items[0].led_cause_defect;
      this.led_cause_competency = response.Items[0].led_cause_competency;
      this.led_cause_days_to_execute = response.Items[0].led_cause_days_to_execute;
      this.led_cause_priority = response.Items[0].led_cause_priority;
      this.led_cause_next_action = response.Items[0].led_cause_next_action;
      this.led_cause_cause = response.Items[0].led_cause_cause;

    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }

}
