import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe } from '@angular/common'
import { AuthService } from '../auth/auth.service';
@Component({
  selector: 'app-client-registration-comp',
  templateUrl: './client-registration-comp.component.html',
  styleUrls: ['./client-registration-comp.component.css'],
  providers: [DatePipe]
})
export class ClientRegistrationCompComponent implements OnInit {
//////////Alert variable////////
showSuccessMsg = false;
addValidation = false;
  ////////variables//////
  currentDate:string;
  public countryList = [];
  public addEmp: any = {};
  //////////Language/////////
  public languages = [];
  //////////API///////////////
  apiNameLanguages = 'languages'; // replace this with your api name.
  pathLanguages = '/languages'; // replace this with the path you have configured on your API
  putMyInitLanguages = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameUser = 'users'; // replace this with your api name.
  pathUser = '/users'; // replace this with the path you have configured on your API
  myInitUser = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  constructor(private authService: AuthService,private projectService: AppService,private loader: LoaderService,private datePipe: DatePipe) { 
    this.countryList = projectService.countryList;
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
  }
  public addU: any = {};
  userAdded = '';
  scan: boolean = false;
  scanResults = '';
  passwordsMismatch = false;
  showErrorMsg = false;
  addUserValidation = false;
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  ngOnInit() {
    this.getAllLanguages();
    const randomnumber = Math.floor(Math.random() * 999999999);
    this.addEmp = {
      id: 'PA-' + randomnumber,
      status: 'Client Accepted',
      reg_date: ''
    };
  }
/////////get language///////
getAllLanguages() {

  var status = 'e';
  const srchFiltersValues = [];
  const srchFiltersNames = [];
  const sorted = [];
  const unsortedrows = [];

  srchFiltersNames.push('status');
  srchFiltersValues.push(status);

  this.projectService.scanContainsAnd(this.apiNameLanguages, this.pathLanguages, srchFiltersNames, srchFiltersValues).then(response => {
    unsortedrows.push(...response);
    for (var i = 0; i < unsortedrows.length; i++) {
      sorted.push(unsortedrows[i].language);

    }
    this.languages.push(...sorted);
    this.languages.sort();
  });
}


signUp(f) {
  this.showSuccessMsg = false;
  console.log(f.form.value)
  this.addUserValidation = false;
  if (f.invalid == true) {
    this.addUserValidation = true;
  } else {
    if (f.form.get('pass').value != f.form.get('Rpass').value) {
      this.passwordsMismatch = true;
    } else{
    this.loader.show();
    this.authService.signUp({
      'email': f.form.get('company_email').value.toLowerCase(),
      'password': f.form.get('pass').value,
      'username': f.form.get('company_email').value,
      'userLevel':"MANAGER",
      'nickname': f.form.get('pd_name').value,
      'property_developer': f.form.get('pd_name').value,
    })
      .then((data) => {
        this.addUser(f);
      })
      .catch((error) => {
        console.log(error);
        this.showErrorMsg = true;
        this.userAdded = 'Failed, ' + error.message + ' Please Contact App Support';
        this.loader.hide();
        console.log('\'users\' component-> Error in creating new User!');
        console.log(error.response);
      });
  }
}
}
addUser(f) {
    const addPD = {
      date_created: this.currentDate,
      property_developer: f.form.get('pd_name').value,
      id: f.form.get('id').value.toString(),
      nickname: f.form.get('pd_name').value,
      full_name: f.form.get('pd_name').value,
      password: f.form.get('pass').value,
      confirm_password: f.form.get('Rpass').value,
      user_name: f.form.get('company_email').value,
      email: f.form.get('company_email').value,
      telephone: f.form.get('company_telephone').value,
      company_type: f.form.get('company_type').value,
      user_level: "MANAGER",
      designation: f.form.get('designation').value,
      status: "Unconfirmed",
      language: f.form.get("language").value,
    };
    this.myInitUser.body = addPD;
    console.log(f.form.value);
    console.log("User data is above on that line:-");
    // Object.keys(this.myInitUser.body).forEach(key => this.myInitUser.body[key] === undefined ? delete this.myInitUser.body[key] : {});
    API.put(this.apiNameUser, this.pathUser, this.myInitUser).then(response => {
      this.addClient(f);
      this.showSuccessMsg = true;
      this.userAdded = 'User Added Successfully! Please contact App Support for the confirmation of User.';
      console.log('\'users\' component-> New user Created Successfully!');
      console.log(response);
    }).catch(error => {
      this.showErrorMsg = true;
      this.userAdded = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'users\' component-> Error in creating new User!');
      console.log(error.response);
    });
}
addClient(f){
    const addPDBody = {
      date_created: this.currentDate,
      reg_date:this.currentDate,
      pd_name: f.form.get('pd_name').value,
      id: f.form.get('id').value.toString(),
      company_ssm_code: f.form.get('company_ssm_code').value,
      company_code: f.form.get('company_code').value,
  
      company_email: f.form.get('company_email').value,
      company_telephone: f.form.get('company_telephone').value,
      pd_type: f.form.get('company_type').value,
      status: "Pending Verification",
      state: f.form.get("state").value,
      country: f.form.get("country").value,
      postal_code: f.form.get("postal_code").value,
      city: f.form.get("city").value,
      street: f.form.get("street").value,
      house_number: f.form.get("house_number").value,
    };
    this.putMyInit.body = addPDBody;
    console.log(f.form.value)
    console.log("client data is above on that line:-");
    // Object.keys(this.myInitUser.body).forEach(key => this.myInitUser.body[key] === undefined ? delete this.myInitUser.body[key] : {});
    API.put(this.apiNamePD, this.apiPathPD, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.userAdded = 'client Added Successfully! Please contact App Support for the confirmation of User.';
      console.log('\'client\' component-> New client Created Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.userAdded = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'client\' component-> Error in creating new client!');
      console.log(error.response);
    });

  }
}
