import { Component, OnInit, AfterContentInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';
import { AppService } from '../app.service';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { LoginCredentialService } from 'src/app/services/login-credentials.service';
import { CookieService } from 'ngx-cookie-service';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { faHouseDamage } from '@fortawesome/free-solid-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { API } from 'aws-amplify';
import { LoaderService } from '../loader/loader.service';
import { DatePipe } from '@angular/common';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { exists } from 'fs';
import { endTimeRange } from '@angular/core/src/profile/wtf_impl';

const moment = extendMoment(Moment);

declare const $: any;
declare const Morris: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe]

})
export class DashboardComponent implements OnInit, AfterContentInit {

  public AllPd = [];
  public AllPdList = [];
  showPD = false;
  faHome = faHome;
  faClipboardList = faClipboardList;
  faClipboardCheck = faClipboardCheck;
  faPercentage = faPercentage;
  faHouseDamage = faHouseDamage;
  faLayerGroup = faLayerGroup;
  faCalendarAlt = faCalendarAlt;
  faHeadset = faHeadset;
  faCrown = faCrown;

  scanResults = '';
  ticketScan = false;
  propertiesList = [];

  TotalTicketsFromServer = [];

  searchT: any = [];
  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };



  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API
  getMyInitProperties = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameTickets = 'workorders'; // replace this with your api name.
  pathTickets = '/workorders'; // replace this with the path you have configured on your API
  getMyInitTickets = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ProjectionExpression: '',
      ExpressionAttributeValues: {
      }
    }
  };

  public myDatePickerOptions: IMyDpOptions = {
    todayBtnTxt: 'Today',
    dateFormat: 'yyyy-mm-dd',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    inline: false
  };

  propertiesHouseNumberList = [];
  propertiesStreetsList = [];
  propertiesPrecinctList = [];
  propertiesMainContractorsList = [];
  propertiesPropertyDevelopersList = [];

  public show_search_boxes = false;
  public projects = [];
  public clients = [];
  public invoices = [];
  public payments = [];
  public userLevel: string;
  public scan: Boolean = false;
  public superAdminLoggedIn: boolean;

  public total_pus;
  public total_pus_load: boolean;

  public total_work_orders;
  public total_work_orders_load: boolean;

  public open_work_orders;
  public open_work_orders_load: boolean;

  public open_percent;
  public open_percent_load: boolean;

  public affected_pus;
  public affected_pus_load: boolean;

  public created_work_orders;
  public created_work_orders_load: boolean;

  public closed_work_orders;
  public closed_work_orders_load: boolean;

  public closed_percent;
  public closed_percent_load: boolean;

  public age_open_wo;
  public age_open_wo_load: boolean;

  public age_closed_wo;
  public age_closed_wo_load: boolean;


  public front_desk_wo;
  public front_desk_wo_load: boolean;

  public supervisor_wo;
  public supervisor_wo_load: boolean;

  currentDate: string;

  public propertyDevelopers = [];
  public AllContractors = [];
  public AllContractorsList = [];


  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';

  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  public rowslanguages = [];

  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';


  dashboard_property_developer = 'Client';
  dashboard_main_contractor = 'Main Contractor';
  dashboard_precinct_project = 'Precincts/Projects/Condos';
  dashboard_street = 'Street';
  dashboard_house_number = 'House Number';
  dashboard_date_from = 'Date From';
  dashboard_date_to = 'Date To'
  dashboard_total_property_units = 'Total Property Units'
  dashboard_total_work_orders = 'Total Work Orders'
  dashboard_open_work_orders = 'Open Work Orders';
  dashboard_open_work_orders_percent = 'Open Work Orders %';
  dashboard_affected_property_units = 'Affected Property Units';
  dashboard_created_work_orders = 'Created Work Orders';
  dashboard_closed_work_orders = 'Closed Work Orders';
  dashboard_closed_work_orders_percent = 'Closed Work Orders %'
  dashboard_age_open_work_orders = 'Age Open Work Orders';
  dashboard_age_closed_work_orders = 'Age Closed Work Orders'
  dashboard_frontdesk_work_orders = 'Front Desk Work Orders';
  dashboard_supervisor_work_orders = 'Supervisor Work Orders';
  dashboard_created_closed_work_orders_quarter = 'Created / Closed Work Orders (Quarter)';
  dashboard_created_closed_work_orders_week = 'Created / Closed Work Orders (Week)';

  // Initialized to specific date (09.10.2018).
  public model: any = { date: { year: 2018, month: 10, day: 9 } };

  constructor(private appService: AppService, private router: Router,
    private loginService: LoginCredentialService,
    private cookieService: CookieService,
    private loader: LoaderService,
    private datePipe: DatePipe


  ) {
    this.userLevel = localStorage.getItem('user_level');
  }

  ngAfterContentInit() {

  }


  ngOnInit() {
    this.getProperties();
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.scan = false;
    this.getPropertyDevelopers();
    this.getContractors();
    if (this.userLevel === 'PLA Admin' || this.userLevel === 'PLA Frontdesk' || this.userLevel === 'Super Admin') {
      this.showPD = true;
    }

    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      console.log(language);
      this.searchlanguage(language);
    } else {
      //console.log("null5");
    }

    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    this.setUpUI();
    //this.setUpUI();
    // this.loginService.currentMessage.subscribe(temp => this.userLevel = temp);
    this.userLevel = localStorage.getItem('user_level');
    // this.getPermissions();
    console.log('From dashboard User Level-> ' + this.userLevel);

    if (this.userLevel == 'Super_admin') {
      this.superAdminLoggedIn = true;
    }

    const pro_heights = $('.panel-eqHeight-clients').map(function () {
      return $(this).height();
    }).get(),
      pro_maxHeight = Math.max.apply(null, pro_heights);
    $('.panel-eqHeight-projects').height(pro_maxHeight);
    $('.panel-eqHeight-clients').height(pro_maxHeight);

    const pay_heights = $('.panel-eqHeight-invoices').map(function () {
      return $(this).height();
    }).get(),
      pay_maxHeight = Math.max.apply(null, pay_heights);
    $('.panel-eqHeight-payments').height(pay_maxHeight);
    $('.panel-eqHeight-invoices').height(pay_maxHeight);

  }

  setUpUI() {
    this.TotalTicketsFromServer.splice(0, this.TotalTicketsFromServer.length);

    this.scan = false;
    this.total_work_orders_load = true;
    this.open_work_orders_load = true;
    this.open_percent_load = true;
    this.closed_work_orders_load = true;
    this.closed_percent_load = true;
    this.created_work_orders_load = true;
    this.front_desk_wo_load = true;
    this.supervisor_wo_load = true;
    this.age_open_wo_load = true;
    this.age_closed_wo_load = true;
    this.searchRecordTickets();
  }


  getProperties() {
    this.total_pus_load = true;
    this.affected_pus_load = true;

    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesMainContractorsList.push('');
    this.propertiesPrecinctList.push('');
    this.propertiesPropertyDevelopersList.push('');

    API.get(this.apiNameProperties, this.apiPathProperties, this.getMyInitProperties).then(response => {

      console.log('\'Dashboard\' component-> All properties Retrieved Successfully!');
      console.log(response);
      this.total_pus_load = false;
      this.total_pus = response.data.length;

      for (let i = 0; i < response.data.length; i++) {
        this.total_pus_load = false;
        this.propertiesList = response.data;

        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();

        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();

        this.propertiesMainContractorsList.push(response.data[i].main_contractor);
        this.propertiesMainContractorsList = this.propertiesMainContractorsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesMainContractorsList.sort();

        this.propertiesPropertyDevelopersList.push(response.data[i].property_developer);
        this.propertiesPropertyDevelopersList = this.propertiesPropertyDevelopersList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPropertyDevelopersList.sort();
      }
    }).catch(error => {
      this.total_pus_load = false;
      this.total_pus = 'Please Refresh Page!';
      console.log('\'Dashboard\' component-> Error in Retreiving All properties from server!');
      console.log(error);
    });
  }

  enumerateDaysBetweenDates(startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate, dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(now.format('DD/MM/YYYY'));
      now.add(1, 'days');
    }
    return dates;
  };

  searchRecord(f) {
    this.scan = false;
    var DateFilteredTickets = [];

    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const property_developer = f.form.get('property_developer').value;
    const precinct = f.form.get('precinct').value;
    const house_number = f.form.get('house_number').value;
    const street = f.form.get('street').value;
    const main_contractor = f.form.get('main_contractor').value;
    const created_date = f.form.get('created_date').value;
    const date_closed = f.form.get('date_closed').value;

    if (created_date != undefined && created_date != "") {
      if (date_closed != undefined && date_closed != "") {
        const range = this.enumerateDaysBetweenDates(created_date, date_closed);
        console.log(range);
        for (let i = 0; i < range.length; i++) {
          const ExpressionAttributeValues2 = {};
          ExpressionAttributeValues2['created_date'] = range[i];
          var FilteredTickets2 = this.TotalTicketsFromServer.filter(function (item) {
            for (var key in ExpressionAttributeValues2) {
              if (item[key] === undefined || item[key] != ExpressionAttributeValues2[key])
                return false;
            }
            return true;
          });
          DateFilteredTickets.push(...FilteredTickets2);
        }

        for (let j = 0; j < range.length; j++) {
          const ExpressionAttributeValues3 = {};
          ExpressionAttributeValues3['date_closed'] = range[j];
          var FilteredTickets3 = this.TotalTicketsFromServer.filter(function (item) {
            for (var key in ExpressionAttributeValues3) {
              if (item[key] === undefined || item[key] != ExpressionAttributeValues3[key])
                return false;
            }
            return true;
          });
          DateFilteredTickets.push(...FilteredTickets3);
        }

      } else {
        DateFilteredTickets.push(...this.TotalTicketsFromServer)
      }
    } else {
      DateFilteredTickets.push(...this.TotalTicketsFromServer)

    }


    // console.log(DateFilteredTickets);

    if (property_developer != undefined && property_developer != "") {
      srchFiltersNames.push('property_developer');
      srchFiltersValues.push(property_developer);
    }
    if (precinct != undefined && precinct != "") {
      srchFiltersNames.push('precinct');
      srchFiltersValues.push(precinct);
    }
    if (house_number != undefined && house_number != "") {
      srchFiltersNames.push('house_number');
      srchFiltersValues.push(house_number);
    }
    if (street != undefined && street != "") {
      srchFiltersNames.push('street');
      srchFiltersValues.push(street);
    }
    if (main_contractor != undefined && main_contractor != "") {
      srchFiltersNames.push('main_contractor');
      srchFiltersValues.push(main_contractor);
    }


    var finalTickets = [];
    var finalProperties = [];


    if (srchFiltersNames.length == 0) {
      finalTickets.push(...DateFilteredTickets);
      finalProperties.push(...this.propertiesList);
      console.log("rizi" + finalProperties);
      //this.scan = true;
      //this.scanResults = 'Please Enter Atleast One Search Field to get Results or Click Refresh Button To get ALL';
    } else {

      const ExpressionAttributeValues = {};
      for (let i = 0; i < srchFiltersNames.length; i++) {
        ExpressionAttributeValues[srchFiltersNames[i]] = srchFiltersValues[i];
      }
      console.log(ExpressionAttributeValues);

      finalTickets = DateFilteredTickets.filter(function (item) {
        for (var key in ExpressionAttributeValues) {
          if (item[key] === undefined || item[key] != ExpressionAttributeValues[key])
            return false;
        }
        return true;
      });

      finalProperties = this.propertiesList.filter(function (item) {
        for (var key in ExpressionAttributeValues) {
          if (item[key] === undefined || item[key] != ExpressionAttributeValues[key])
            return false;
        }
        return true;
      });
    }

    this.calculateTotals(finalTickets, finalProperties);

  }




  searchRecordTickets() {

    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const deleted = 'yes';

    srchFiltersNames.push('deleted');
    srchFiltersValues.push(deleted);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' <> ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    ExpressionAttributeNames['#reserved_property_developer'] = 'property_developer';
    ExpressionAttributeNames['#reserved_status'] = 'status';
    ExpressionAttributeNames['#reserved_created_date'] = 'created_date';
    ExpressionAttributeNames['#reserved_date_closed'] = 'date_closed';
    ExpressionAttributeNames['#reserved_house_number'] = 'house_number';
    ExpressionAttributeNames['#reserved_street'] = 'street';
    ExpressionAttributeNames['#reserved_precinct'] = 'precinct';
    ExpressionAttributeNames['#reserved_main_contractor'] = 'main_contractor';

    const ProjectionExpression = '#reserved_property_developer,#reserved_status,#reserved_created_date,#reserved_date_closed,#reserved_house_number,#reserved_street,#reserved_precinct,#reserved_main_contractor';

    this.getMyInitTickets.body.FilterExpression = FilterExpression;
    this.getMyInitTickets.body.ExpressionAttributeNames = ExpressionAttributeNames;
    this.getMyInitTickets.body.ExpressionAttributeValues = ExpressionAttributeValues;
    this.getMyInitTickets.body.ProjectionExpression = ProjectionExpression;
    this.scanTickets();


  }
  scanTickets() {
    this.ticketScan = true;
    this.scanResults = 'In Progress...';
    this.getTickets(this.apiNameTickets, this.pathTickets, this.getMyInitTickets).then(response => {
      this.TotalTicketsFromServer.push(...response.Items);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitTickets.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanTickets();
      } else {

        console.log('scan completed!');
        this.show_search_boxes = true;
        delete this.getMyInitTickets.body['ExclusiveStartKey'];
        this.calculateTotals(this.TotalTicketsFromServer, this.propertiesList);
        // this.setupGraphs();
        return;
      }
    }).catch(error => {
      this.total_work_orders_load = false;
      console.log(error);
      this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
    });
  }
  getTickets(apiNameTickets, apiPathTickets, getMyIntTickets): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiNameTickets, apiPathTickets, getMyIntTickets).then(response => {
        console.log('\'Dashboard\' component->  Tickets Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.total_work_orders_load = false;
        console.log('\'Dashboard\' component-> Error in Retreiving Tickets from server!');
        console.log(error.response);
        reject(error.response);
      });
    });
  }

  calculateTotals(ticketsFromServer, propertiesList) {
    console.log(ticketsFromServer);

    this.total_pus_load = false;
    this.total_pus = propertiesList.length;

    this.total_work_orders_load = false;
    this.scanResults = 'Completed';
    this.ticketScan = false;
    const total_work_orders = ticketsFromServer.length;
    this.total_work_orders = ticketsFromServer.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');


    const filters = { status: ['Closed'] },
      results = ticketsFromServer,
      Filtered = this.multiFilterExcluded(results, filters);
    this.open_work_orders_load = false;
    const open_work_orders = Filtered.length;
    this.open_work_orders = Filtered.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');


    let openWoPuids = [];
    for (var f = 0; f < Filtered.length; f++) {
      openWoPuids.push(Filtered[f].street + Filtered[f].house_no);
    }
    var uniqueOpenWoPuids = openWoPuids.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.affected_pus_load = false;
    this.affected_pus = uniqueOpenWoPuids.length;



    this.open_percent_load = false;
    this.open_percent = (open_work_orders / total_work_orders) * 100;
    this.open_percent = this.open_percent.toFixed(2);


    this.created_work_orders_load = false;
    this.created_work_orders = this.open_work_orders;


    const filters2 = { status: ['Closed'] },
      results2 = ticketsFromServer,
      Filtered2 = this.multiFilterIncluded(results2, filters2);
    this.closed_work_orders_load = false;
    const closed_work_orders = Filtered2.length;
    this.closed_work_orders = Filtered2.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');


    this.closed_percent_load = false;
    this.closed_percent = (closed_work_orders / total_work_orders) * 100;
    this.closed_percent = this.closed_percent.toFixed(2);


    const filters3 = { status: ['On hold', 'Temp closed', 'Schedule appointment', 'Appoint 3rd party', 'Approved'] },
      results3 = ticketsFromServer,
      Filtered3 = this.multiFilterIncluded(results3, filters3);
    this.front_desk_wo_load = false;
    this.front_desk_wo = Filtered3.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');


    const filters4 = { status: ['Inspection', 'Joint Inspection PD SV', 'Inspection PD SV'] },
      results4 = ticketsFromServer,
      Filtered4 = this.multiFilterIncluded(results4, filters4);
    this.supervisor_wo_load = false;
    this.supervisor_wo = Filtered4.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');




    const ageOpenWO = [];
    const filters5 = { status: ['Closed'] },
      results5 = ticketsFromServer,
      Filtered5 = this.multiFilterExcluded(results5, filters5);
    for (let i = 1; i < Filtered5.length; i++) {
      const diff = this.differenceVetweenDates(Filtered5[i].created_date, this.currentDate);
      ageOpenWO.push(diff);
    }
    if (ageOpenWO.length != 0) {
      const averageOpenWO = Math.round(ageOpenWO.filter(Boolean).reduce((acc, next) => acc + next) / ageOpenWO.length);
      this.age_open_wo_load = false;
      this.age_open_wo = averageOpenWO;
    } else {
      this.age_open_wo_load = false;
      this.age_open_wo = 0;
    }




    const ageCloseWO = [];
    const filters6 = { status: ['Closed'] },
      results6 = ticketsFromServer,
      Filtered6 = this.multiFilterIncluded(results6, filters6);
    for (let i = 1; i < Filtered6.length; i++) {
      const diff = this.differenceVetweenDates(Filtered6[i].created_date, Filtered6[i].date_closed);
      ageCloseWO.push(diff);
    }
    if (ageCloseWO.length != 0) {
      const averageCloseWO = Math.round(ageCloseWO.filter(Boolean).reduce((acc, next) => acc + next) / ageCloseWO.length);
      this.age_closed_wo_load = false;
      this.age_closed_wo = averageCloseWO;
    } else {
      this.age_closed_wo_load = false;
      this.age_closed_wo = 0;
    }
  }

  // setupGraphs() {
  //   // Bar Chart quarter
  //   var filtersQ1 = { created_date: ["01/2019", "02/2019", "03/2019"] },
  //     filteredQ1 = this.multiFilter(this.TotalTicketsFromServer, filtersQ1);

  //   var filtersQ1Closed = { status: ["Closed"] },
  //     filteredQ1Closed = this.multiFilter(filteredQ1, filtersQ1Closed);



  //   var filtersQ2 = { created_date: ["04/2019", "05/2019", "06/2019"] },
  //     filteredQ2 = this.multiFilter(this.TotalTicketsFromServer, filtersQ2);

  //   var filtersQ2Closed = { status: ["Closed"] },
  //     filteredQ2Closed = this.multiFilter(filteredQ2, filtersQ2Closed);



  //   var filtersQ3 = { created_date: ["07/2019", "08/2019", "09/2019"] },
  //     filteredQ3 = this.multiFilter(this.TotalTicketsFromServer, filtersQ3);

  //   var filtersQ3Closed = { status: ["Closed"] },
  //     filteredQ3Closed = this.multiFilter(filteredQ3, filtersQ3Closed);



  //   var filtersQ4 = { created_date: ["10/2019", "11/2019", "12/2019"] },
  //     filteredQ4 = this.multiFilter(this.TotalTicketsFromServer, filtersQ4);

  //   var filtersQ4Closed = { status: ["Closed"] },
  //     filteredQ4Closed = this.multiFilter(filteredQ4, filtersQ4Closed);


  //   var filtersQ5 = { created_date: ["01/2020", "02/2020", "03/2020"] },
  //     filteredQ5 = this.multiFilter(this.TotalTicketsFromServer, filtersQ5);

  //   var filtersQ5Closed = { status: ["Closed"] },
  //     filteredQ5Closed = this.multiFilter(filteredQ5, filtersQ5Closed);



  //   var filtersQ6 = { created_date: ["04/2020", "05/2020", "06/2020"] },
  //     filteredQ6 = this.multiFilter(this.TotalTicketsFromServer, filtersQ6);

  //   var filtersQ6Closed = { status: ["Closed"] },
  //     filteredQ6Closed = this.multiFilter(filteredQ6, filtersQ6Closed);



  //   var filtersQ7 = { created_date: ["07/2020", "08/2020", "09/2020"] },
  //     filteredQ7 = this.multiFilter(this.TotalTicketsFromServer, filtersQ7);

  //   var filtersQ7Closed = { status: ["Closed"] },
  //     filteredQ7Closed = this.multiFilter(filteredQ7, filtersQ7Closed);



  //   var filtersQ8 = { created_date: ["10/2020", "11/2020", "12/2020"] },
  //     filteredQ8 = this.multiFilter(this.TotalTicketsFromServer, filtersQ8);

  //   var filtersQ8Closed = { status: ["Closed"] },
  //     filteredQ8Closed = this.multiFilter(filteredQ8, filtersQ8Closed);

  //   Morris.Bar({
  //     element: 'bar-chart_quarter',
  //     data: [
  //       { y: 'Q1 (2019)', a: filteredQ1.length, b: filteredQ1Closed.length },
  //       { y: 'Q2 (2019)', a: filteredQ2.length, b: filteredQ2Closed.length },
  //       { y: 'Q3 (2019)', a: filteredQ3.length, b: filteredQ3Closed.length },
  //       { y: 'Q4 (2019)', a: filteredQ4.length, b: filteredQ4Closed.length },
  //       { y: 'Q1 (2020)', a: filteredQ5.length, b: filteredQ5Closed.length },
  //       { y: 'Q2 (2020)', a: filteredQ6.length, b: filteredQ6Closed.length },
  //       { y: 'Q3 (2020)', a: filteredQ7.length, b: filteredQ7Closed.length },
  //       { y: 'Q4 (2020)', a: filteredQ8.length, b: filteredQ8Closed.length }
  //     ],
  //     xkey: 'y',
  //     ykeys: ['a', 'b'],
  //     labels: ['Total WO Created', 'Total WO Closed'],
  //     lineColors: ['#00c5fb', '#0253cc'],
  //     lineWidth: '3px',
  //     barColors: ['#00c5fb', '#0253cc'],
  //     resize: true,
  //     redraw: true
  //   });

  //   // Bar Chart weekly

  //   var filtersW1 = { created_date: ["01/11/2020", "02/11/2020", "03/11/2020", "04/11/2020", "05/11/2020", "06/11/2020", "07/11/2020"] },
  //     filteredW1 = this.multiFilter(this.TotalTicketsFromServer, filtersW1);

  //   var filtersW1Closed = { status: ["Closed"] },
  //     filteredW1Closed = this.multiFilter(filteredW1, filtersW1Closed);



  //   var filtersW2 = { created_date: ["08/11/2020", "09/11/2020", "10/11/2020", "11/11/2020", "12/11/2020", "13/11/2020", "14/11/2020"] },
  //     filteredW2 = this.multiFilter(this.TotalTicketsFromServer, filtersW2);

  //   var filtersW2Closed = { status: ["Closed"] },
  //     filteredW2Closed = this.multiFilter(filteredW2, filtersW2Closed);



  //   var filtersW3 = { created_date: ["15/11/2020", "16/11/2020", "17/11/2020", "18/11/2020", "19/11/2020", "20/11/2020", "21/11/2020"] },
  //     filteredW3 = this.multiFilter(this.TotalTicketsFromServer, filtersW3);

  //   var filtersW3Closed = { status: ["Closed"] },
  //     filteredW3Closed = this.multiFilter(filteredW3, filtersW3Closed);



  //   var filtersW4 = { created_date: ["22/11/2020", "23/11/2020", "24/11/2020", "25/11/2020", "26/11/2020", "27/11/2020", "28/11/2020", "29/11/2020", "30/12/2020"] },
  //     filteredW4 = this.multiFilter(this.TotalTicketsFromServer, filtersW4);

  //   var filtersW4Closed = { status: ["Closed"] },
  //     filteredW4Closed = this.multiFilter(filteredW4, filtersW4Closed);





  //   var filtersW5 = { created_date: ["01/12/2020", "02/12/2020", "03/12/2020", "04/12/2020", "05/12/2020", "06/12/2020", "07/12/2020"] },
  //     filteredW5 = this.multiFilter(this.TotalTicketsFromServer, filtersW5);

  //   var filtersW5Closed = { status: ["Closed"] },
  //     filteredW5Closed = this.multiFilter(filteredW5, filtersW5Closed);



  //   var filtersW6 = { created_date: ["08/12/2020", "09/12/2020", "10/12/2020", "11/12/2020", "12/12/2020", "13/12/2020", "14/12/2020"] },
  //     filteredW6 = this.multiFilter(this.TotalTicketsFromServer, filtersW6);

  //   var filtersW6Closed = { status: ["Closed"] },
  //     filteredW6Closed = this.multiFilter(filteredW6, filtersW6Closed);



  //   var filtersW7 = { created_date: ["15/12/2020", "16/12/2020", "17/12/2020", "18/12/2020", "19/12/2020", "20/12/2020", "21/12/2020"] },
  //     filteredW7 = this.multiFilter(this.TotalTicketsFromServer, filtersW7);

  //   var filtersW7Closed = { status: ["Closed"] },
  //     filteredW7Closed = this.multiFilter(filteredW7, filtersW7Closed);



  //   var filtersW8 = { created_date: ["22/12/2020", "23/12/2020", "24/12/2020", "25/12/2020", "26/12/2020", "27/12/2020", "28/12/2020", "29/12/2020", "30/12/2020"] },
  //     filteredW8 = this.multiFilter(this.TotalTicketsFromServer, filtersW8);

  //   var filtersW8Closed = { status: ["Closed"] },
  //     filteredW8Closed = this.multiFilter(filteredW8, filtersW8Closed);

  //   Morris.Bar({
  //     element: 'bar-chart_weekly',
  //     data: [
  //       { y: 'W1', a: filteredW1.length, b: filteredW1Closed.length },
  //       { y: 'W2', a: filteredW2.length, b: filteredW2Closed.length },
  //       { y: 'W3', a: filteredW3.length, b: filteredW3Closed.length },
  //       { y: 'W4', a: filteredW4.length, b: filteredW4Closed.length },
  //       { y: 'W5', a: filteredW1.length, b: filteredW5Closed.length },
  //       { y: 'W6', a: filteredW2.length, b: filteredW6Closed.length },
  //       { y: 'W7', a: filteredW3.length, b: filteredW7Closed.length },
  //       { y: 'W8', a: filteredW4.length, b: filteredW8Closed.length }
  //     ],
  //     xkey: 'y',
  //     ykeys: ['a', 'b'],
  //     labels: ['Total WO Created', 'Total WO Closed'],
  //     lineColors: ['#00c5fb', '#0253cc'],
  //     lineWidth: '3px',
  //     barColors: ['#00c5fb', '#0253cc'],
  //     resize: true,
  //     redraw: true
  //   });
  // }



  multiFilterExcluded(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => !o[k].toLowerCase().includes(v.toLowerCase()))));
  }

  multiFilterIncluded(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }





  reformatDate(dateStr) {
    var dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]; //ex out: "18/01/10"
  }

  differenceVetweenDates(dateStart, dateEnd): any {
    const start = dateStart;
    const end = dateEnd;


    const report_date_string = new String(start);
    const rectify_date_string = new String(end);

    const report_date_final = report_date_string.split('/');
    const month1 = report_date_final[0];
    const day1 = report_date_final[1];
    const year1 = report_date_final[2];

    const rectify_date_final = rectify_date_string.split('/');
    const month2 = rectify_date_final[0];
    const day2 = rectify_date_final[1];
    const year2 = rectify_date_final[2];

    const report_datetime = new Date(<any>year1, <any>day1, <any>month1 - 1);
    const rectify_datetime = new Date(<any>year2, <any>day2, <any>month2 - 1);
    const diff = Math.abs(((rectify_datetime.getTime() - report_datetime.getTime()) / (24 * 3600 * 1000)));
    return diff;
  }

  clearSearchandRefresh() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.setUpUI();
  }

  clearSearchOnly() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
  }


  onChangePrecinct(precinct) {
    //console.log(precinct);
    //console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();
  }

  onChangeStreet(street) {
    //console.log(street);
    //console.log(this.propertiesList);
    var p = [];
    var t = [];
    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      var y = arrayItem.main_contractor;
      p.push(x);
      t.push(y);
    });

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push('');
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();
  }





  getPropertyDevelopers() {
    var p = [];

    this.propertyDevelopers.push('');
    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.propertyDevelopers.push(...response.data);
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead" && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Properties\' component-> All Client Retrieved Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error);
    });
  }
  getContractors() {
    var p = [];

    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {

      this.AllContractorsList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.con_name;
        p.push(x);
      });
      this.AllContractors.splice(0, this.AllContractors.length);
      this.AllContractors.push('');
      this.AllContractors.push(...p);
      console.log('\'Contractor\' component-> All Contractors Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All Contractors from server!');
      console.log(error.response);
    });
  }

  onChangePD(pd) {
    // console.log(pd);
    // console.log(this.AllContractorsList);
    var p = [];
    if (pd == '') {
      this.AllContractorsList.forEach(function (arrayItem) {
        var x = arrayItem.con_name;
        p.push(x);
      });

      this.AllContractors.splice(0, this.AllContractors.length);
      this.AllContractors.push('');
      this.AllContractors.push(...p);
      this.AllContractors = this.AllContractors.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.AllContractors.sort();
    } else {
      const filters = { property_developer: [pd] },
        results = this.AllContractorsList,
        Filtered = this.multiFilter(results, filters);
      console.log(Filtered);
      Filtered.forEach(function (arrayItem) {
        var x = arrayItem.con_name;
        p.push(x);
      });

      this.AllContractors.splice(0, this.AllContractors.length);
      this.AllContractors.push('');
      this.AllContractors.push(...p);
      this.AllContractors = this.AllContractors.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.AllContractors.sort();

    }
  }


  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.dashboard_property_developer = this.rowslanguages[0].dashboard_property_developer;
        this.dashboard_main_contractor = this.rowslanguages[0].dashboard_main_contractor;
        this.dashboard_precinct_project = this.rowslanguages[0].dashboard_precinct_project;
        this.dashboard_street = this.rowslanguages[0].dashboard_street;
        this.dashboard_house_number = this.rowslanguages[0].dashboard_house_number;
        this.dashboard_date_from = this.rowslanguages[0].dashboard_date_from;
        this.dashboard_date_to = this.rowslanguages[0].dashboard_date_to;
        this.dashboard_total_property_units = this.rowslanguages[0].dashboard_total_property_units;
        this.dashboard_total_work_orders = this.rowslanguages[0].dashboard_total_work_orders;
        this.dashboard_open_work_orders = this.rowslanguages[0].dashboard_open_work_orders;
        this.dashboard_open_work_orders_percent = this.rowslanguages[0].dashboard_open_work_orders_percent;
        this.dashboard_affected_property_units = this.rowslanguages[0].dashboard_affected_property_units;
        this.dashboard_created_work_orders = this.rowslanguages[0].dashboard_created_work_orders;
        this.dashboard_closed_work_orders = this.rowslanguages[0].dashboard_closed_work_orders;
        this.dashboard_closed_work_orders_percent = this.rowslanguages[0].dashboard_closed_work_orders_percent;
        this.dashboard_age_open_work_orders = this.rowslanguages[0].dashboard_age_open_work_orders;
        this.dashboard_age_closed_work_orders = this.rowslanguages[0].dashboard_age_closed_work_orders;
        this.dashboard_frontdesk_work_orders = this.rowslanguages[0].dashboard_frontdesk_work_orders;
        this.dashboard_supervisor_work_orders = this.rowslanguages[0].dashboard_supervisor_work_orders;
        this.dashboard_created_closed_work_orders_quarter = this.rowslanguages[0].dashboard_created_closed_work_orders_quarter;
        this.dashboard_created_closed_work_orders_week = this.rowslanguages[0].dashboard_created_closed_work_orders_week;


        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_creation_date = this.rowslanguages[0].generals_creation_date;
        this.generals_actions = this.rowslanguages[0].generals_actions;

        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }

  ngOnDestroy() {
  }

}
