import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { LoaderService } from '../loader/loader.service';
import { UploadFileService } from '../services/upload-file.service';
import { Lightbox } from 'ngx-lightbox';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.css'],
  providers: [DatePipe]

})
export class AnnouncementsComponent implements OnInit {
  public rows = [];
  public srch = [];

  public addLT: any = {};
  public uptLT: any = {};
  searchT: any = [];

  deleteID;
  delete_results;
  propertiesHouseNumberList = [];
  propertiesPrecinctList = [];
  propertiesStreetsList = [];
  propertiesList = [];

  srchFiltersValues = [];
  srchFiltersNames = [];

  public viewEmp: any = [];

  unsortedrows = [];

  notificationsUrl = "https://fcm.googleapis.com/fcm/send";

  scan = false;
  scanResults = '';

  result = false;
  results = '';

  totalList: any;
  ticketsList = [];

  currentDate: string;
  currentTime: string;


  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API
  getPropertiessInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameDelete = 'notifications'; // replace this with your api name.
  pathDelete = '/notifications/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  apiName = 'notifications'; // replace this with your api name.
  path = '/notifications'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  add_announcements_add_announcement='Add Announcement';
  add_announcements_precinct_project='Precinct / Project';
  add_announcements_street='Street';
  add_announcements_house_number='House Number';
  add_announcements_title='Title';
  add_announcements_announcement_text='Announcement Text';
  add_announcements_publication_date='Publication date';
  add_announcements_status='Status';
  add_announcements_upload_picture='Upload Picture';
  add_announcements_upload_pdf='Upload PDF';
  announcements_announcements='Announcements';
  announcements_refresh_list='Refresh List';
  announcements_add_announcements='Add Announcements';
  announcements_announcement_id='Announcement ID';
  announcements_print='Print';
  announcements_update_announcement='Update Announcement';
  announcements_edit_announcement='Edit Announcement';

  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';
  generals_publish = 'Publish';

  havePictures: boolean = false;
  haveFiles: boolean = false;
  selectedPics: FileList;
  selectedFiles: FileList;
  eventPics;
  eventFiles;

  public userLevel;

  pictures = [];
  files = [];
  albums = [];
  viewPictures = [];
  viewFiles = [];

  permissions_userlevel;
  permissions_view=false;
  permissions_create=false;
  permissions_edit=false;
  permissions_delete=false;

  apiNamePermissions = 'mpConAdmin';
  apiPathPermissions = '/mpConAdmin';

  getPermissionsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  constructor(public http:HttpClient,private loader: LoaderService,    private _lightbox: Lightbox,
    private uploadService: UploadFileService,    private datePipe: DatePipe,

    ) {   this.userLevel = localStorage.getItem('user_level');
  }

  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');

  }


  ngOnInit() {
    this.getProperties();
    this.searchRecord();
    this.getPermissions();

    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString('en-GB');
    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      console.log(language);
      this.searchlanguage(language);
    } else {
      //console.log("null5");
    }

    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }

  onView(item) {
    let temp;
    console.log(item);
    temp = item;
    this.viewEmp = temp;
    this.viewPictures = [];
    this.viewFiles = [];
    if (item.images) {
      if (item.images.length > 0) {
        if(item.images.length<=50){
          this.viewPictures = item.images;
        }
      }
    }
    if (item.documents) {
      if (item.documents.length > 0) {
        this.viewFiles = item.documents;
      }
    }
    $('#view_invoice').modal('show');

  }

  onEdit(item) {
    this.uptLT = item;
    $('#Edit_modal').modal('show');
  }

  searchRecord() {

    this.srchFiltersNames.splice(0, this.srchFiltersNames.length);
    this.srchFiltersValues.splice(0, this.srchFiltersValues.length);


      this.srchFiltersNames.push('moduleName');
      this.srchFiltersValues.push("announcements");    


    let FilterExpression = '';
    for (let i = 0; i < this.srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + this.srchFiltersNames[i] + ' , ' + ':' + this.srchFiltersNames[i] + ')';
      if (i != this.srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < this.srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + this.srchFiltersNames[i]] = this.srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < this.srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + this.srchFiltersNames[i]] = this.srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);


      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanTickets();
    

  }


  scanTickets() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    this.getTickets(this.apiName, this.path, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      this.ticketsList.push(...this.rows);
      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanTickets();
      } else {
        this.scanResults = 'Completed';
        this.scan = false;
        this.srch.push(...this.rows);
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No More Pending Work orders For you!';
        }
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
    });
  }


  getTickets(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'tickets\' component->  Tickets Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'tickets\' component-> Error in Retreiving Tickets from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  addTicket(f) {
    const randomnumber = Math.floor(Math.random() * 999999999);
    this.loader.show();


    if (this.haveFiles || this.havePictures) {
      this.uploadPicsAndFiles().then(Response => {
        console.log(Response);
        this.saveNotifications(randomnumber.toString(),f.form.get('title').value,f.form.get('body').value,"announcements","",f.form.get('publication_date').value,f.form.get('status').value,f.form.get('precinct').value,f.form.get('street').value,f.form.get('house_number').value);
      }).catch(error => {
        console.log(error);
      });
    } else {
      this.saveNotifications(randomnumber.toString(),f.form.get('title').value,f.form.get('body').value,"announcements","",f.form.get('publication_date').value,f.form.get('status').value,f.form.get('precinct').value,f.form.get('street').value,f.form.get('house_number').value);
    }


  }

  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              console.log(Response);
              resolve("Add Ticket Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add Ticket Now!")
          }
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Ticket Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }

  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("File Uploaded Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All Files uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }
  updateTicket(f) {
    this.loader.show();
    this.saveNotifications(f.form.get('id').value,f.form.get('title').value,f.form.get('body').value,"announcements","",f.form.get('publication_date').value,f.form.get('status').value,f.form.get('precinct').value,f.form.get('street').value,f.form.get('house_number').value);
  }

  onPublish(item){
    console.log(item.id);
    this.loader.show();
    this.saveNotifications(item.id,item.title,item.body,"announcements","",item.publication_date,"Published",item.precinct,item.street,item.house_number);
  }

  saveNotifications(id,title, body, moduleName, deepLinkArticleId,publication_date,status,precinct,street,house_number) {
    const createBody = {
      id: id,
      title: title,
      body: body,
      moduleName: moduleName,
      deepLinkArticleId: deepLinkArticleId,
      creation_date:this.currentDate,
      creation_time:this.currentTime,
      publication_date:publication_date,
      status:status,
      precinct:precinct,
      street:street,
      house_number:house_number,
      images: this.pictures,
      documents: this.files
        };
        if (this.pictures.length > 0) {
        } else {
          delete createBody.images;
        }
        if (this.files.length > 0) {
        } else {
          delete createBody.documents;
        }
    this.putMyInit.body = createBody;
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      console.log('\'notifications\' component-> New notification added Successfully!');
      console.log(response);

      if(status==='Published'){
      this.sendNotifications(title, body, moduleName, id,publication_date);
    }

      this.ngOnInit();

      this.loader.hide();
      $('#add_modal').modal('hide');
      $('#Edit_modal').modal('hide');

    }).catch(error => {
      console.log('\'notifications\' component-> Error in creating new notifications!');
      console.log(error.response);
    });
  }

  sendNotifications(title, body, moduleName, id, publication_date) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=' + "AAAA5mIllzA:APA91bH-wa6UVq3IvGUIE4oliUiVO1t3tWu5DqocJG8y9p1O9grxBC05dwLCVIxkftFsoXKcpqsK3MHNZaaNM0Wo9xs0vfndkUGGJRqJ9sEk91JJNhtTkNXnQ3RJBxQVETXiZBeuReKL"
    });

    var callibayaqPlzObj = {
      "to": "/topics/houseResidents",
      "notification": {
        "body": body,
        "content_available": true,
        "priority": "high",
        "title": title
      },
      "data": {
        "sound": true,
        "deepLinkArticleId": id,
        "module": moduleName,
        "content_available": true,
        "priority": "high",
        "title": "Testing Deep Linking",
        "publication_date": publication_date,
        "announcment_type": "General Announcement",
         "images":"link...",
         "files":"link...",
      }
    }

    this.http.post(this.notificationsUrl, callibayaqPlzObj, { headers }).subscribe(res => {

      this.result=true;
      this.results='Announcement Published Successfully.'

      console.log(res);
    }, error => {
      console.log(error);
    });
  }


  getProperties() {
    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesPrecinctList.push('');


    API.get(this.apiNameProperties, this.apiPathProperties, this.getPropertiessInit).then(response => {
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]);
        this.propertiesList = response.data;
        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();
        localStorage.setItem('propertiesPrecinctList', JSON.stringify(this.propertiesPrecinctList));
        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();
        localStorage.setItem('propertiesHouseNumberList', JSON.stringify(this.propertiesHouseNumberList));

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();
        localStorage.setItem('propertiesStreetsList', JSON.stringify(this.propertiesStreetsList));

      }
      console.log('\'announcements\' component-> All properties Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      console.log('\'announcements\' component-> Error in Retreiving All properties from server!');
      console.log(error.response);
    });
  }

  onChangePrecinct(precinct) {
    //console.log(precinct);
    //console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();
  }

  onChangeStreet(street) {
    // console.log(street);
    // console.log(this.propertiesList);
    var p = [];
    var t = [];
    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      var y = arrayItem.main_contractor;
      p.push(x);
      t.push(y);
    });

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push('');
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();

  }

  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        this.add_announcements_add_announcement = this.rowslanguages[0].add_announcements_add_announcement;
        this.add_announcements_precinct_project = this.rowslanguages[0].add_announcements_precinct_project;
        this.add_announcements_street = this.rowslanguages[0].add_announcements_street;
        this.add_announcements_house_number = this.rowslanguages[0].add_announcements_house_number;
        this.add_announcements_title = this.rowslanguages[0].add_announcements_title;
        this.add_announcements_announcement_text = this.rowslanguages[0].add_announcements_announcement_text;
        this.add_announcements_publication_date = this.rowslanguages[0].add_announcements_publication_date;
        this.add_announcements_status = this.rowslanguages[0].add_announcements_status;
        this.add_announcements_upload_picture = this.rowslanguages[0].add_announcements_upload_picture;
        this.add_announcements_upload_pdf = this.rowslanguages[0].add_announcements_upload_pdf;
        this.announcements_announcements = this.rowslanguages[0].announcements_announcements;
        this.announcements_refresh_list = this.rowslanguages[0].announcements_refresh_list;
        this.announcements_add_announcements = this.rowslanguages[0].announcements_add_announcements;
        this.announcements_announcement_id = this.rowslanguages[0].announcements_announcement_id;
        this.announcements_print = this.rowslanguages[0].announcements_print;
        this.announcements_update_announcement = this.rowslanguages[0].announcements_update_announcement;
        this.announcements_edit_announcement = this.rowslanguages[0].announcements_edit_announcement;

        

        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_creation_date = this.rowslanguages[0].generals_creation_date;
        this.generals_actions = this.rowslanguages[0].generals_actions;
        this.generals_publish = this.rowslanguages[0].generals_publish;

        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }


  clearSearchOnly() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
  }

  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }


  uploadAllFiles(event) {
    this.selectedFiles = event.target.files;
    this.eventFiles = event;
    this.haveFiles = true;
  }

  openImageView(index: number): void {
    this._lightbox.open(this.albums, index);
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.searchRecord();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }

  getPermissions() {
    API.get(this.apiNamePermissions, this.apiPathPermissions, this.getPermissionsInit).then(response => {
      this.loader.hide();
      this.permissions_userlevel = localStorage.getItem('user_level');
      const filters = { user_level: [this.permissions_userlevel] },
      results = response.data,
      Filtered = this.multiFilter(results, filters);

      this.permissions_view=Filtered[0].announcements_view;
      this.permissions_create=Filtered[0].announcements_create;
      this.permissions_edit=Filtered[0].announcements_update;
      this.permissions_delete=Filtered[0].announcements_delete;
     
    
      console.log(Filtered);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Sidebar\' component-> Error in Retreiving All permissions from server!'+error);
      console.log(error.response);
    });
  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }

}
