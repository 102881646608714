import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoginCredentialService {

  private messageSource = new BehaviorSubject('No User Details Found');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  setUserDeatils(message: string) {
    this.messageSource.next(message);
  }

}
