import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { CognitoUser } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/loader/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoginCredentialService } from 'src/app/services/login-credentials.service';
import { CookieService } from 'ngx-cookie-service';
import { API } from 'aws-amplify';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  signinForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required, Validators.min(6)])
  });

  emailInputError1 = 'Please enter a valid email address.';
  emailInputError2 = 'Email is required.';
  passwordInputError = 'Password is required.';

  hide = true;
  serverError: string;

  rows = [];
  unsortedrows = [];

  apiName = 'users'; // replace this with your api name.
  path = '/users'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  get emailInput() { return this.signinForm.get('email'); }
  get passwordInput() { return this.signinForm.get('password'); }


  constructor(
    public auth: AuthService,
    private _router: Router,
    private _notification: NotificationService,
    private _loader: LoaderService,
    private loginService: LoginCredentialService,
    private cookieService: CookieService) {
  }


  ngOnInit() {
  }
  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return this.emailInputError1;
    }
    if (this.emailInput.hasError('required')) {
      return this.emailInputError2;
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return this.passwordInputError;
    }
  }


  signIn() {
    this._loader.show();
    this.auth.signIn(this.emailInput.value, this.passwordInput.value)
      .then((user: CognitoUser | any) => {
        console.log(user.attributes);
        const userLevel = user.attributes['custom:User_level'];
        const nickname = user.attributes['nickname'];
        // this.loginService.setUserDeatils(userLevel);
        // this.cookieService.set('user_level', userLevel);
        // this.cookieService.set('nick_name', nickname);
      
        this.searchUser(user.attributes['email']);

      })
      .catch((error: any) => {
        this._loader.hide();
        // this._notification.show(error.message);
        this.passwordInputError = error.message;

        this.serverError = error.message;
        switch (error.code) {
          case 'UserNotConfirmedException':
            environment.confirm.email = this.emailInput.value;
            environment.confirm.password = this.passwordInput.value;
            this._router.navigate(['pages/login']);
            break;
          case 'UsernameExistsException':
            this._router.navigate(['pages/login']);
            break;
        }
      });
  }

  searchUser(email) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('email');
    srchFiltersValues.push(email);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanUsers();
    }

  }

  scanUsers() {

    this.getUsers(this.apiName, this.path, this.getMyInit).then(response => {
      console.log(response.Items);

      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanUsers();
      } else {
        console.log(this.rows);
        localStorage.setItem('profile_pic', this.rows[0].profile_pic);
        localStorage.setItem('language', this.rows[0].language);
        localStorage.setItem('user_level',this.rows[0].user_level);
        localStorage.setItem('nick_name', this.rows[0].nickname);
        localStorage.setItem('property_developer', this.rows[0].property_developer);

        this._loader.hide();
        this._router.navigate(['welcome']);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getUsers(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  Users Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving Users from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }
}
