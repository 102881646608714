import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class propertiesIDsLocalService {
    private messageSource = new BehaviorSubject([]);
    currentMessage = this.messageSource.asObservable();
    constructor() { }
    setPropertiesIDsLocally(message: any) {
        this.messageSource.next(message);
    }
}


