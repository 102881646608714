import { Component, ViewEncapsulation, OnInit} from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { Lightbox } from 'ngx-lightbox';
@Component({
  selector: 'app-plts',
  templateUrl: './plts.component.html',
  styleUrls: ['./plts.component.css'],
  providers: [DatePipe],
  encapsulation : ViewEncapsulation.None

})

export class PltsComponent implements OnInit {
  approver_remarks;
  selectedFiles: FileList;
  haveFiles: boolean = false;
  eventFiles;
  eventPics;
  existingFiles = [];
  colors;
  existingPics= [];
  havePictures: boolean = false;
  public rows = [];
  public srch = [];
  viewFiles=[];
  delete_results
  albums = [];
  pictures=[];
  files=[] ;
  currentDate: string;
  currentTime: string;
  public viewT: any = {};
  unsortedApprovalLog = [];
  allDefects = [];
  public addLT: any = {};
  public uptLT: any = {};
  public PropertyDevelopers = [];
  addTValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  selectedOption:String;
  public AddedMsg;
  totalList: any;
  completeList: any = [];
  public userNickName;
  profile_pic =[];
  
  public propertyDevelopers = [];
  public languages = [];
  approvalLogScan = false;
  scanResultsApproval = '';
  generals_uploaded_images='Uploaded Images';
  generals_uploaded_files='Uploaded Files';
  generals_download='Dowonload file';
  generals_delete='Delete';
  totalFiles;
  totalPics;
  languagesList = [];
  public pds = [];
  nextActionSuccess = false;
  addRemarkFromAll = false;
  selectedPics: FileList;
  public userLevel;
  apiName = 'tenancymanagment'; // replace this with your api name.
  apiPath = '/tenancymanagment'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameWOApprovalLog = 'woapprovallog'; // replace this with your api name.
  pathWOApprovalLog = '/woapprovallog'; // replace this with the path you have configured on your API
  getWOApprovalLogInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  putWOApprovalLogInit = {
    body: {},
    headers: {}
  };
  uploadPicsFolder = '/General/UsersProfile';
  apiNameDelete = 'tenancymanagment'; // replace this with your api name.
  pathDelete = '/tenancymanagment/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  getMyInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameNextActions = 'taskanduserlevel'; // replace this with your api name.
  pathNextActions = '/taskanduserlevel'; // replace this with the path you have configured on your API
  getMyInitNextActions = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameDefect = 'pltsdefect'; // replace this with your api name.
  pathDefect = '/pltsdefect'; // replace this with the path you have configured on your API
  putMyInitDefect = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInitDefect = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  addResponseSuc = false;
  addResponseFail = false;
  op: boolean = false;
  searchT: any = [];
  nextActionMsg;
  approvalLog = [];
  approvalLogg = [];
  addP: any = [];
  editP: any = [];
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  uptValidation: boolean= false;
  scan: boolean = false;
  constructor(  private _lightbox: Lightbox,private uploadService: UploadFileService,private appService: AppService,private loader: LoaderService,private projectService: AppService,private datePipe: DatePipe) 
  { 
    this.RevenueYear = appService.RevenueYear;
    this.RevenueMonth=appService.RevenueMonth;
    this.userNickName = localStorage.getItem('nick_name');

  }
  RevenueYear = [];
  RevenueMonth = [];

  ngOnInit() {
    this.getAllNextActions();
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString('en-GB');
  }
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add').modal('show');
    this.addTValidation = false;
  }

  getAllPDs(){
    this.loader.show();
    API.get(this.apiName, this.apiPath, this.getMyInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.completeList.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.loader.hide();
      console.log('\'project\' component-> All plts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All plts from server!');
      console.log(error.response);
    });
  }


  getAllDefects(item) {
    this.allDefects.splice(0, this.allDefects.length);

    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const parentTicketId = item.site_information_project_work_title;

    if (parentTicketId != undefined) {
      srchFiltersNames.push('store_work_title');
      srchFiltersValues.push(parentTicketId);
    }
    this.projectService.scanContainsAnd(this.apiNameDefect, this.pathDefect, srchFiltersNames, srchFiltersValues).then(response => {
      this.allDefects.push(response);
      console.log(this.allDefects);
    }).catch(error => {
      console.log(error);
    });
  }

  getAll(){
    this.loader.show();
    this.completeList.splice(0,this.completeList.length);
    var pd = localStorage.getItem('property_developer');
    let srchFiltersValues = [];
    let srchFiltersNames = [];
     var condition = [];
     condition.push(pd);
      srchFiltersNames.splice(0, srchFiltersNames.length);
      srchFiltersValues.splice(0, srchFiltersValues.length);
   
      for (let i = 0; i <= condition.length; i++){
        srchFiltersNames.push('client');
        srchFiltersValues.push(condition[i]);
      }      
    API.get(this.apiName, this.apiPath, this.getMyInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response.data);
      this.completeList.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.loader.hide();
      console.log('\'project\' component-> All plts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All plts from server!');
      console.log(error.response);
    });
  }
  openImageView(index: number): void {
    this._lightbox.open(this.albums, index);
  }
  prepareAlbumLightbox() {
    //check if viewPictures is null
    for (let i = 0; i < this.profile_pic.length; i++) {
      if (this.profile_pic[i] == null || this.profile_pic[i] == "") {
        this.profile_pic[i] = "NoURL";
      }
      const src = this.profile_pic[i];
      const caption = "";
      const thumb = "";
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this.albums.push(album);
    }
  }
  viewProfile(item){
    this.getAllDefects(item);
    this.totalPics = "0";
    this.totalFiles="0";
    this.viewT = item;
    this.viewT.approver_remarks="";
    $('#view_all_plts').modal('show');
    this.searchRecordApprovalLog(item.id);
    this.searchRecordApprovalLog1(item.id,"true");
    console.log(item.pictures);
    console.log(item.files);
    console.log(item.is_revenue);
    console.log(item);
    if (item.pictures) {
      if (item.pictures.length > 0) {
        this.totalPics = item.pictures.length;
        if(item.pictures.length<=50){
          this.profile_pic = item.pictures;
        }
      }
    }
    else{
      this.profile_pic =  ['https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png'];
    }
    if (item.files) {
      if (item.files.length > 0) {
        this.viewFiles = item.files;
        this.totalFiles = this.viewFiles.length;
      }
    }
    
    // if (item.pictures !== undefined) {
    //   this.profile_pic = item.pictures;
    // } else {
    //   this.profile_pic =  'https://workordersfiles920.s3.amazonaws.com/General/UsersProfile/user.png';
    // }
    // if (item.files !== undefined) {
    //   this.viewFiles= item.files;
    // } else {
    //   this.viewFiles = null;
    // }
    this.prepareAlbumLightbox();
  
  }
  onEdit(item){
    $('#edit_plts').modal('show');
    this.searchRecordApprovalLog1(item.id,"true");
    this.editP=item;

  }
  checkAlert():void{
    console.log("Event  :- ");
  }
  AddRemarkFromAllWorkOrder(f) {
    if (f.approver_remarks == undefined || f.approver_remarks == '') {
      this.addRemarkFromAll = true;
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Remark field was left empty. Add a new Remark';
    } else {
      this.loader.show();
      const randomnumber = Math.floor(Math.random() * 99999999);
      const id = 'AL-' + randomnumber;
      this.addRemarkFromAll = true;
      this.addApprovalLog(f.id, this.currentDate, this.currentTime, this.userNickName, f.status, f.approver_remarks);
      f.approver_remarks = '';
    }
  }
  addApprovalLog(ticketID, created_date, created_time, created_by, action, remarks) {
    const randomnumber = Math.floor(Math.random() * 99999999);
    const id = 'AL-' + randomnumber;
    action="Remark Added";
    const approvalLogBody = {
      id: id,
      parentTicketId: ticketID,
      created_date: created_date,
      created_time: created_time,
      created_by: created_by,
      action: action,
      remarks: remarks
    };
    this.putWOApprovalLogInit.body = approvalLogBody;
    API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Performed Successfully!';
      this.approvalLog.push(approvalLogBody);
      
      this.viewT.approver_remarks=null;
      this.loader.hide();
      console.log('\'work-order-edit\' component-> Approval Log Recorded Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Failed to Perform, Please Try Again';
      console.log('\'work-order-edit\' component-> Error in  Recording Approval Log!');
      console.log(error);
    });
  }
  searchRecordApprovalLog(id) {
    this.unsortedApprovalLog.splice(0, this.unsortedApprovalLog.length);
    this.approvalLog.splice(0, this.approvalLog.length);
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const parentTicketId = id;
    console.log(id);
    
    if (parentTicketId != undefined) {
      srchFiltersNames.push('parentTicketId');
      srchFiltersValues.push(parentTicketId);
    }
    this.approvalLogScan = true;
    this.scanResultsApproval = 'In Progress...';
    this.projectService.scanContainsAnd(this.apiNameWOApprovalLog,this.pathWOApprovalLog,srchFiltersNames,srchFiltersValues).then(response =>{
      this.approvalLog.push(...response);
      this.scanResultsApproval = 'Completed...';
      this.approvalLogScan = false;
    });
  }
  AddRemarkFromAllWorkOrder1(f) {
    if (f.Year == '' ||f.month == '' || f.revenue == '') {
      this.addRemarkFromAll = true;
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Remark field was left empty. Add a new Remark';
    } else {
      const randomnumber = Math.floor(Math.random() * 99999999);
      const id = 'AL-' + randomnumber;
      const is_revenue="true";
      this.addRemarkFromAll = true;
      this.addApprovalLog1(is_revenue,f.id, this.currentDate, this.currentTime, this.userNickName, f.Year, f.month,f.revenue);
      // f.approver_remarks = '';
      // f.Year='';
      // f.month='';
      // f.revenue='';
  }
  
  }
  addApprovalLog1(is_revenue,ticketID, created_date, created_time, created_by, Year, month,revenue) {
    const randomnumber = Math.floor(Math.random() * 99999999);
    const id = 'AL-' + randomnumber;
    const approvalLogBody = {
      id: id,
      check_revenue:is_revenue,
      parentTicketId: ticketID,
      created_date: created_date,
      created_time: created_time,
      created_by: created_by,
      Year: Year,
      month: month,
      revenue: revenue
    };
    this.putWOApprovalLogInit.body = approvalLogBody;
    API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Performed Successfully!';
      this.approvalLogg.push(approvalLogBody);
      console.log(approvalLogBody)
      console.log('\'work-order-edit\' component-> Approval Log Recorded Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Failed to Perform, Please Try Again';
      console.log('\'work-order-edit\' component-> Error in  Recording Approval Log!');
      console.log(error);
    });
  }
  searchRecordApprovalLog1(id,i_revenue) {
    this.unsortedApprovalLog.splice(0, this.unsortedApprovalLog.length);
    this.approvalLogg.splice(0, this.approvalLogg.length);
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const parentTicketId = id;
    const check_revenue =i_revenue ;
    console.log(id);
    console.log(check_revenue);
    
    if (parentTicketId != undefined) {
      srchFiltersNames.push('parentTicketId');
      srchFiltersValues.push(parentTicketId);
    }
    if(check_revenue!=undefined)
    {
      srchFiltersNames.push('check_revenue');
      srchFiltersValues.push(check_revenue);
    }

    this.approvalLogScan = true;
    this.scanResultsApproval = 'In Progress...';
    this.projectService.scanContainsAnd(this.apiNameWOApprovalLog,this.pathWOApprovalLog,srchFiltersNames,srchFiltersValues).then(response =>{
      this.approvalLogg.push(...response);
      this.scanResultsApproval = 'Completed...';
      this.approvalLogScan = false;
    });
  }


  
  updateUserWithPics(f) {
    if (f.invalid == true) {
      this.uptValidation = true;
    }  else {
      this.loader.show();
      if (this.havePictures || this.haveFiles) {
        this.uploadPicsAndFiles().then(Response => {
          console.log(Response);
          this.uptStore(f);
        }).catch(error => {
          console.log(error);
        });
      } else {
        this.uptStore(f);
      }
    }
  }
  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          //console.log(Response);
          this.eventPics.srcElement.value = null;
          if (this.haveFiles) {
            this.uploadFiles().then(Response => {
              this.eventFiles.srcElement.value = null;
              //console.log(Response);
              resolve("Add  Now!")
            }).catch(error => {
              console.log(error);
              reject(error);
            });
          } else {
            resolve("Add  Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else if (this.haveFiles) {
        this.uploadFiles().then(Response => {
          //console.log(Response);
          this.eventFiles.srcElement.value = null;
          if (this.havePictures) {
            this.uploadPics().then(Response => {
              this.eventPics.srcElement.value = null;
              //console.log(Response);
              resolve("Add  Now!")
            }).catch(error => {
              //console.log(error);
              reject(error);
            });
          } else {
            resolve("Add  Now!")
          }
        }).catch(error => {
          //console.log(error);
          reject(error);
        });
      } else {
        resolve("Add Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Updated Successfully " + JSON.stringify(Response));
          this.pictures.push(Response.Location);
          if (this.pictures.length === this.selectedPics.length) {
            resolve("All pics uploaded");
          }
        }).catch(error => {
          console.log("Error in updated pic" + error);
          reject(error);
        });
      }
    });
  }
  uploadFiles() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles.item(i);
        this.uploadService.uploadfile(file, "").then(Response => {
          console.log("File updated Successfully " + JSON.stringify(Response));
          this.files.push(Response.Location);
          if (this.files.length === this.selectedFiles.length) {
            resolve("All files uploaded");
          }
        }).catch(error => {
          console.log("Error in uploading file" + error);
          reject(error);
        });
      }
    });
  }
  uptStore(f){
    // // var picstoUpload=[];
    // // var filestoUpload=[];

    // // if (this.pictures.length > 0) {
    // //   picstoUpload = this.pictures;
    // //   if (this.existingPics != undefined) {
    // //     picstoUpload.push(...this.existingPics);
    // //   }
    // // } else {
    // //   picstoUpload = this.existingPics;
    // // }
    // // console.log("existing pic"+this.existingPics);
    // // console.log(this.pictures);
    // // console.log(picstoUpload);

    // // if (this.files.length > 0) {
    // //   filestoUpload = this.files;
    // //   if (this.existingFiles != undefined) {
    // //     filestoUpload.push(...this.existingFiles);
    // //   }
    // // } else {
    // //   filestoUpload = this.existingFiles;
    // // }
    // console.log("existing files"+this.existingFiles);
    // console.log(this.files);
    // console.log(filestoUpload);
      f.form.value.client = localStorage.getItem("property_developer");
      f.form.value.pictures=this.pictures;
      f.form.value.files=this.files;
      this.putMyInit.body = f.form.value;
      console.log(this.pictures);
      console.log(this.files);
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      console.log(this.putMyInit.body);
      API.put(this.apiName, this.apiPath, this.putMyInit).then(response => {
        this.loader.hide();
        this.addResponseSuc=true;
        this.showSuccessMsg = true;
        console.log('\'project\' component-> New plts Created Successfully!');
        console.log(response);
      }).catch(error => {
        this.loader.hide();
        this.addResponseFail=true;
        console.log('\'project\' component-> Error in creating new plts!');
        console.log(error);
      });
    }

    clearSearch() {
      this.rows.splice(0,this.rows.length);
      this.searchT = Object.keys(this.searchT).reduce(
        (accumulator, current) => {
          accumulator[current] = null;
          return accumulator
        }, {});
        if(localStorage.getItem('property_developer')==='ProperLy Business Development'){
          this.getAllPDs();
        }else{
          this.getAll()
        }    }
    instantSearch(val, name) {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.projectService.instantSearch(val, this.completeList, name, ''))
      this.totalList = Object.keys(this.rows).length;
    }
    instantSearchDropDown(val, name) {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.projectService.instantSearch(val, this.completeList, name, 'All'))
      this.totalList = Object.keys(this.rows).length;
    }

    getPropertyDevelopers() {
      this.loader.show();
      API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
        const results = response.data.filter(element => {
          return element.pd_name !== undefined && element.pd_name !== "" && element.pd_name !== "New Lead" && element.pd_name !== "" && element.pd_name !== "New Lead";
        });
        this.propertyDevelopers.splice(0, this.propertyDevelopers.length);
        this.propertyDevelopers.push(...results);
        var pd=localStorage.getItem("property_developer");
        var p = [];
        const filters = { pd_name: [pd] },
          results2 = this.propertyDevelopers,
          Filtered = this.multiFilter(results2, filters);
          this.pds.push(...Filtered);
        console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
        console.log(this.propertyDevelopers);
        this.loader.hide();
      }).catch(error => {
        this.loader.hide();
        console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
        console.log(error);
      });
    }

    
  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }
  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    if(localStorage.getItem('property_developer')==='ProperLy Business Development'){
      this.getAllPDs();
    }else{
      this.getAll()
    }  }
  
  uploadAttachments(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }
  uploadAllFiles(event) {
    this.selectedFiles = event.target.files;
    this.eventFiles = event;
    this.haveFiles = true;
  }
  getAllNextActions() {
    this.loader.show();
    API.get(this.apiNameNextActions, this.pathNextActions, this.getMyInitNextActions).then(response => {
      this.loader.hide();
      this.colors=response;
      console.log('\'project\' component-> All  nextActions Retrieved Successfully!');
      console.log(response);
      if(localStorage.getItem('property_developer')==='ProperLy Business Development'){
        this.getAllPDs();
      }else{
        this.getAll()
      }
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  
  getTheColor(status) {
    return this.colors.filter(item => item.task===status && item.property_developer===localStorage.getItem('property_developer'));
  }
  
  getfinalColor(status){
    if(this.getTheColor(status).length>0 && this.getTheColor(status)[0].hasOwnProperty("Background")){
     return this.getTheColor(status)[0].Background;
    }else{
      return '#FFFFFFF';
    }
  }
  
  getTheFont(status) {
    if(this.getTheColor(status).length>0 && this.getTheColor(status)[0].hasOwnProperty("font")){
      return this.getTheColor(status)[0].font;
     }else{
       return '#000000';
     }}
}

function ViewChild(arg0: string) {
  throw new Error('Function not implemented.');
}

