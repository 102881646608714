import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { API } from 'aws-amplify';

declare const $: any;
type AOA = any[][];
@Component({
  selector: 'app-tempate-upload-residents',
  templateUrl: './tempate-upload-residents.component.html',
  styleUrls: ['./tempate-upload-residents.component.css']
})
export class TempateUploadResidentsComponent implements OnInit {


  uploadFile = false;
  Results = '';
  excelData: AOA = [[1, 2], [3, 4]];
  showSuccessMsg = false;
  showErrorMsg = false;
  ResidentAdded = '';


  apiName = 'residents'; // replace this with your api name.
  path = '/residents'; // replace this with the path you have configured on your API
  myInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  myInit2 = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };


  constructor() { }

  ngOnInit() {
  }

  DownloadTemplate() {
    window.open("https://workordersfiles920.s3.amazonaws.com/General/TemplateUploads/residentsTemplate.xlsx");
  }

  onFileUpload(evt: any) {
    this.showErrorMsg=false;
    this.uploadFile = false;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { 
    this.showErrorMsg = true;
    this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
    throw new Error('Cannot use multiple files');
   }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.excelData);

      if(this.excelData.length>=2){
      if (this.excelData[1].length == 14) {
        this.uploadFile = true;
        this.Results = "File Uploaded Successfully! Please Press Submit To Upload.";
        // this.asynccalltoupload(this.excelData);
      }else {
        this.showErrorMsg = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    } else {
      this.showErrorMsg = true;
      this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support"
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  async asynccalltoupload(excelData) {
    for (var i = 2; i < excelData.length; i++) {
      await new Promise<void>(resolve => {
        this.addResidentFromFile(excelData[i][0], excelData[i][1], excelData[i][2],
          excelData[i][3], excelData[i][4], excelData[i][5],
          excelData[i][6], excelData[i][7], excelData[i][8],
          excelData[i][9], excelData[i][10], excelData[i][11],
          excelData[i][12], excelData[i][13],i
        ).then(response => {
          console.log('This is iteration ' + i);
          resolve();
        }).catch(error => {
          console.log(error);
        });
      });
    }
    this.Results = "Completed. Please Go to Residents Module to view your Uploaded Residents.";
  }

  addResidentFromFile(ID,IC_No,Name,Email,Password,Confirm_Password,Nationality,Registration_Date,Gender,Resident_Status,Telephone_1,Telephone_2,Resident_Remarks,Creation_date,i): any {

    const createResidentBody = {
      id: ID,
      email: Email,
      name: Name,
      icNo: IC_No,
      gender: Gender,
      registration_date: Registration_Date,
      telephone1: Telephone_1,
      nationality: Nationality,
      creation_date: Creation_date,
      telephone2: Telephone_2,
      resident_status: Resident_Status,
      res_remarks: Resident_Remarks,
      password: Password,
      confirm_password: Confirm_Password
    };

    if (Telephone_2 === '') {
      delete createResidentBody.telephone2;
    }

    this.myInit.body = createResidentBody;
    console.log(createResidentBody);
    return new Promise<string>((resolve, reject) => {
    API.put(this.apiName, this.path, this.myInit).then(response => {
      this.showSuccessMsg = true;
      this.ResidentAdded = i+": "+ID + ' Resident Added Successfully!';
      console.log('\'all-residents\' component-> New Resident Created Successfully!');
      console.log(response);
      resolve(response);
    }).catch(error => {
      this.showErrorMsg = true;
      this.Results = ID + ' Resident Creation Failed, Please Retry!';
      console.log('\'all-residents\' component-> Error in creating new Resident!');
      console.log(error.response);
      reject(error.response);
    });
  });

  }

  submit(){
    this.Results='Under Progress, Please Wait.'
    this.asynccalltoupload(this.excelData);
  }


}
