import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { AppService } from 'src/app/app.service';
import { API } from 'aws-amplify';

@Component({
  selector: 'app-precincts',
  templateUrl: './precincts.component.html',
  styleUrls: ['./precincts.component.css']
})
export class PrecinctsComponent implements OnInit {
/////////////total list variable//////////
totalList: any;

//////////////alert variables////////////
scan: boolean = false;
scanResults = '';
showSuccessMsg: Boolean = false;
addTPrecinctValidation: Boolean = false;
///////////search variables//////////
precincts_property_developer = "Client";
precincts_projects_precinct="Precincts/Projects/Condos";
precincts_projects_sub_precinct= "Sub Precincts";
generals_delete="Delete";
public PropertyDevelopers = [];
public AllPdList = [];
precinctsList = [];
public AllPd = [];
public addLT: any = {};
searchT: any = [];
public rows = [];
///////////////deletion/////////
delete_results;
//////////////add//////////
public AddedMsg;
/////////////update///////
public uptLT: any = {};
///////////languages/////
public languages = [];
public userLevel;
/////////////api////////////

apiNamePD = 'propertydevelopers';
apiPathPD = '/propertydevelopers';
getPDInit = {
  headers: {}, // OPTIONAL
  response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {  // OPTIONAL
    name: 'param'
  }
};
apiName = 'settingsPrecincts'; // replace this with your api name.
path = '/settingsPrecincts'; // replace this with the path you have configured on your API
putMyInit = {
  body: {}, // replace this with attributes you need
  headers: {} // OPTIONAL
};
getMyInit = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};
apiNameDelete = 'settingsPrecincts'; // replace this with your api name.
pathDelete = '/settingsPrecincts/delete'; // replace this with the path you have configured on your API
myInitDelete = {
  body: {}, // replace this with attributes you need
};
apiNamelanguages = 'languages'; // replace this with your api name.
pathlanguages = '/languages'; // replace this with the path you have configured on your API
getMyInitlanguages = {
  headers: {},
  body: {
    FilterExpression: '',
    ExpressionAttributeNames: {
    },
    ExpressionAttributeValues: {
    }
  }
};


  constructor(private loader: LoaderService,private projectService: AppService) {  this.userLevel = localStorage.getItem('user_level')}

  ngOnInit() {
  this.getAllPrecincts();
  this.getPropertyDevelopers();

  var language = localStorage.getItem('language');
  if(language!='undefined' && language!='English'){
    console.log(language);
    this.setLanguage(language);
  }else{
    //console.log("null5");
  }
  $('.floating').on('focus blur', function (e) {
    $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
  }).trigger('blur');
}

  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      // console.log('hello');
      // console.log(response);
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element!=="" && element!=="New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  getAllPrecincts() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.precinctsList=response;
      this.scan = false;
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response);
      this.totalList = Object.keys(this.rows).length;
      console.log('\'project\' component-> All  precincts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  /////////////searching///////////////////////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.precinctsList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.precinctsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAllPrecincts();
  }
  //////////////add percenint////////
  addPrecinct(f) {
    //console.log(f.form.value);
    if (f.invalid) {
      this.addTPrecinctValidation = true;
    } else {
      this.loader.show();
      const randomnumber = Math.floor(Math.random() * 999999999);
      f.form.value.id=randomnumber.toString();
      this.putMyInit.body = f.form.value;
      console.log(f.form.value);
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Precinct Added Successfully!';
        this.rows.unshift(f.form.value);
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_Precinct').modal('hide');
        console.log('\'add_Precinct\' component-> New add_Precinct added Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'add_Precinct\' component-> Error in creating new Type Of Unit!');
        console.log(error.response);
      });
    }

  }
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_Precinct').modal('show');
  }
  ///////////update//////
  onEdit(item) {
    this.uptLT = item;
    $('#Edit_modal').modal('show');
  }

  onUpdate(f) {
    if (f.invalid) {
      this.addTPrecinctValidation = true;
    } else {
      this.loader.show();
      this.putMyInit.body = f.form.value;
      console.log(f.form.value)
      Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Precinct Updated Successfully!';
        var id = f.form.value.id;
        var arr = this.rows.find(function (item, i) {
          return item.id === id
        });
        arr.id = f.form.value.id;
        arr.property_developer = f.form.value.property_developer;
        arr.precinct = f.form.value.precinct;

        var index = this.rows.findIndex(function (item, i) {
          return item.id === id
        });
        if (index > -1) {
          this.rows.splice(index, 1);
        }

        this.rows.unshift(arr);
        this.rows = this.rows;
        $('#Edit_modal').modal('hide');
        console.log('\'precinct\' component-> Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showSuccessMsg = true;
        this.AddedMsg = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'precinct\' component-> Error in Updating!');
        console.log(error.response);
      });
    }
  }

  ///////////////deletion///////////

   onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAllPrecincts();
  }


  ////////////////languages//////////////
  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.precincts_property_developer = response.Items[0].precincts_property_developer;
      this.precincts_projects_precinct = response.Items[0].precincts_projects_precinct;
      this.precincts_projects_sub_precinct =response.Items[0].precincts_projects_sub_precinct;

    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }
}
