import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
import { HttpHeaders, HttpClient, HttpEventType, HttpResponse, HttpParams } from '@angular/common/http';
import { DatePipe, DOCUMENT } from '@angular/common';
import { LoaderService } from 'src/app/loader/loader.service';
import { Lightbox } from 'ngx-lightbox';
import { UploadFileService } from '../services/upload-file.service';
import { Router, ActivatedRoute } from '@angular/router';
const SCRIPT_PATH = 'https://app.smplrspace.com/lib/smplr.js';
declare let smplr: any;

@Component({
  selector: 'app-extra-comp',
  templateUrl: './extra-comp.component.html',
  styleUrls: ['./extra-comp.component.css'],
  providers: [DatePipe],

})
export class ExtraCompComponent implements OnInit {
  ///////////////front button variables///////
  all_work_orders_all_work_orders = 'All Work Orders';
  all_work_orders_print_work_order = 'Print Work Order';
  my_pending_work_orders_my_pending_work_orders = 'My Pending Work Orders';
  totalList: any;
  scanResults = '';
  my_pending_work_orders_refresh_list = 'Refresh List';
  add_new_work_orders_add_work_order = 'Add New Work Order';
  ///////////alert variables////////
  ticketScan = false;
  nextActionSuccess = false;
  nextActionMsg;
  showSuccessMsg = false;
  showfloorplan = true;
  showErrorMsg = false;

  approvalLogScan = false;
  addWorkOrderValidation = false;
  nextActionLoad: Boolean = false;
  showPD = false;
  havePictures: boolean = false;
  haveFiles: boolean = false;
  //////////get all work orders variables//////
  dashboard_property_developer = 'Client';
  public AllPd = [];
  searchT: any = [];
  public AllPdList = [];
  users_status = 'Status';
  public nextActions = [];
  public nextActionsMyPending = [];
  add_new_work_orders_work_order_id = 'Work Order ID';
  add_new_work_orders_precinct_project = 'Precinct / Project';
  propertiesPrecinctList = [];
  propertiesHouseNumberList = [];
  propertiesStreetsList = [];
  propertiesList = [];
  add_new_work_orders_street = 'Street';
  add_new_work_orders_house_number = 'House Number';
  add_new_work_orders_main_contractor = 'Main Contractor';
  add_new_work_orders_priority = 'Priority';
  add_new_work_orders_next_action = 'Next Action';
  add_new_work_orders_date_scheduled = 'Date Scheduled';
  public contractorsList = [];
  work_order_details_days_open = 'Days Open';
  work_order_details_date_closed = 'Date Closed';
  add_new_work_orders_competency = 'Competency';
  work_order_details_created_date = 'Created Date';
  srch = [];

  rows = [];
  ticketsList = [];
  add_new_work_orders_location = 'Location';
  add_new_work_orders_element = 'Element';
  add_new_work_orders_floor = 'Floor';
  add_new_work_orders_defect = 'Defect';
  add_new_work_orders_costs = 'Costs';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  /////////////date and time variale///////
  addT: any = [];
  currentDate: string;
  public timeList = [];
  currentTime: string;

  //////////////////////lanuguages/////////////////
  add_new_work_orders_created_by = 'Created By';
  add_new_work_orders_invoice = 'Invoice';
  add_new_work_orders_days_to_execute = 'Days To Execute';
  add_new_work_orders_charge_to = 'Charge To';
  add_new_work_orders_cause = 'Cause';
  add_new_work_orders_time_scheduled = 'Time Scheduled';
  add_new_work_orders_am_pm = 'AM/PM';
  add_new_work_orders_work_order_description = 'Work Order Description';
  add_new_work_orders_upload_pictures = 'Upload Pictures';
  add_new_work_orders_upload_documents_pdf_only = 'Upload Documents (PDF Only)';
  add_new_work_orders_create_new_work_order = 'Create Work Order';
  my_pending_work_orders_add_work_order = 'Add Work Order';
  work_order_details_my_pending_work_orders = 'My Pending Work Orders';
  work_order_details_print = 'Print';
  work_order_details_edit_work_order = 'Edit Work Order';
  work_order_details_remarks_and_approval_log = 'Remarks And Approval Log';
  work_order_details_created_time = 'Created Time';
  work_order_details_created_by = 'Created By';
  work_order_details_action = 'Action';
  work_order_details_remarks = 'Remarks';
  work_order_details_next_action = 'Next Action';
  add_new_work_orders_property_developer = 'Client';
  work_order_details_update_work_order = 'Update Work Order';
  work_order_details_work_order_details = 'Work Order Details';
  work_order_details_resident_name = 'Resident Name';
  work_order_details_resident_phone = 'Resident Phone';
  work_order_details_time_closed = 'Time Closed';
  work_order_details_closed_by = 'Closed By';
  work_order_details_add_remark = 'Add Remark';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';
  public languages = [];
  generals_uploaded_files = 'Uploaded Files';
  generals_download = 'Download';

  userLevel: string;
  //////////////////user variables//////////
  public super_Admin = 'Super Admin';
  public pla_admin = 'PLA Admin';
  public pla_frontdesk = 'PLA Frontdesk';
  public pla_supervisor = 'PLA Supervisor';
  public pd_admin = 'PD Admin';
  public pd_frontdesk = 'PD Frontdesk';
  public pd_supervisor = 'PD Supervisor';
  public pd_managment = 'PD Management';
  public con_admin = 'CON Admin';
  public con_supervisor = 'CON Supervisor';
  public con_crew = 'CON Crew';
  public userNickName;
  notificationsUrl = "https://fcm.googleapis.com/fcm/send";
  //////////////////view/////////////////
  viewT: any = [];
  residentArray = [];
  residentname = "loading...";
  residentEmail = "loading...";
  residentphone = "loading...";
  totalPics;
  approvalLog = [];
  scanResultsApproval = '';
  viewPictures = [];
  albums = [];
  totalFiles;
  eventPics;
  eventFiles;
  dataArray = [];
  pictures = [];
  files = [];
  selectedPics: FileList;
  selectedFiles: FileList;
  viewFiles = [];
  /////////////delete variables//////
  delete_results;
  /////////////edit variables////////
  existingPics = [];
  TicketSubmitted = '';
  existingFiles = [];
  next_action_from_edit;
  editT: any = [];
  public submitterActions = [];
  ////////////////actions//////////////////////
  public pdAdminAction = [];
  public pdSupervisorActions = [];
  public conAdminActions = [];
  public conSupervisorActions = [];
  //////////////api//////////////////

  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamepltstask = 'pltstask'; // replace this with your api name.
  apiPathpltstask = '/pltstask'; // replace this with the path you have configured on your API
  getpltstask = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  apiNameProperties = 'properties'; // replace this with your api name.
  apiPathProperties = '/properties'; // replace this with the path you have configured on your API

  getPropertiessInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  putWOApprovalLogInit = {
    body: {},
    headers: {}
  };
  apiNameNotifications = 'notifications'; // replace this with your api name.
  pathNotifications = '/notifications'; // replace this with the path you have configured on your API
  putMyInitNotifications = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  apiNameContractors = 'contractors';
  apiPathContractors = '/contractors';
  getContractorsInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  apiNameWOApprovalLog = 'woapprovallog'; // replace this with your api name.
  pathWOApprovalLog = '/woapprovallog'; // replace this with the path you have configured on your API
  getWOApprovalLogInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiName = 'workorders'; // replace this with your api name.
  path = '/workorders'; // replace this with the path you have configured on your API
  pathUpdate = "/workorders/update";
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameResident = 'residents'; // replace this with your api name.
  pathResident = '/residents'; // replace this with the path you have configured on your API
  putMyInitResident = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInitResident = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  apiNameDelete = 'workorders'; // replace this with your api name.
  pathDelete = '/workorders/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  constructor(private router: Router, private uploadService: UploadFileService, public http: HttpClient, private renderer: Renderer2, private _lightbox: Lightbox,
    @Inject(DOCUMENT) private document: Document, private datePipe: DatePipe, private loader: LoaderService, private appService: AppService, private projectService: AppService) {
    this.timeList = appService.timeList;
    this.userLevel = localStorage.getItem('user_level');
    this.submitterActions = this.appService.pdAdminActions;
    this.conAdminActions = appService.conAdminActions;
    this.conSupervisorActions = appService.conSupervisorActions;
    this.pdSupervisorActions = appService.pdSupervisorActions;
    this.pdAdminAction = appService.pdAdminActions;
    this.userLevel = localStorage.getItem('user_level');
    this.userNickName = localStorage.getItem('nick_name');
    this.getProperties();
    this.getContractors();
    this.getDynamicNextActions();


  }

  ngOnInit() {
    this.getDynamicNextActionsMyPending();
    this.getPropertyDevelopers();
    if (this.userLevel === 'PLA Admin' || this.userLevel === 'PLA Frontdesk' || this.userLevel === 'Super Admin') {
      this.showPD = true;
    }
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.currentTime = new Date().toLocaleTimeString();

    var language = localStorage.getItem('language');
    if (language != 'undefined' && language != 'English') {
      console.log(language);
      this.setLanguage(language);
    } else {
      //console.log("null5");
    }

    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
    this.addT = {
      'id': 'Auto',
      'days_open': '0',
      'created_by': localStorage.getItem('nick_name'),
    };
  }

  ///////////////get properly developers////////////////
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead" && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Properties\' component-> All Client Retrieved Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.loader.hide();
      console.log('\'Properties\' component-> Error in Retreiving All Property Developers from server!');
      console.log(error.response);
    });
  }
  getDynamicNextActions() {
    this.nextActions.splice(0, this.nextActions.length);
    var pd = localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if (user_level) {
      // console.log(user_level);
      this.nextActionLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];


      if (pd != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(pd);
      }

      if (user_level != undefined) {
        srchFiltersNames.push('to_user_level');
        srchFiltersValues.push(user_level);
      }

      srchFiltersNames.push('workflow_for');
      srchFiltersValues.push('Defect Workflow');


      this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
        for (let i = 0; i < response.length; i++) {
          this.nextActions.push(response[i].task);
        }
        this.nextActionLoad = false;
        this.searchRecord();
      });
    }
  }
  searchRecord() {
    this.rows.splice(0, this.rows.length);
    this.ticketsList.splice(0, this.ticketsList.length);

    let srchFiltersValues = [];
    let srchFiltersNames = [];

    for (let i = 0; i < this.nextActions.length; i++) {
      srchFiltersNames.push('next_action');
      srchFiltersValues.push(this.nextActions[i]);
    }
    this.ticketScan = true;
    this.scanResults = 'In Progress...';
    this.projectService.scanEqualsOr(this.apiName, this.path, srchFiltersNames, srchFiltersValues).then(response => {
      this.rows.push(...response);
      this.ticketsList.push(...this.rows);
      this.totalList = Object.keys(this.rows).length;
      this.scanResults = 'Completed';
      this.ticketScan = false;
      if (this.totalList == 0) {
        this.scanResults = 'Completed. No More Pending Work orders For you!';
      }
    });
  }
  getDynamicNextActionsMyPending() {
    let unsortedrows2 = [];
    this.nextActionsMyPending.splice(0, this.nextActionsMyPending.length);
    var pd = localStorage.getItem('property_developer');
    var user_level = localStorage.getItem('user_level');
    if (user_level) {
      // console.log(user_level);
      this.nextActionLoad = true;
      const srchFiltersValues = [];
      const srchFiltersNames = [];


      if (pd != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(pd);
      }

      if (user_level != undefined) {
        srchFiltersNames.push('from_user_level');
        srchFiltersValues.push(user_level);
      }
      this.projectService.scanContainsAnd(this.apiNamepltstask, this.apiPathpltstask, srchFiltersNames, srchFiltersValues).then(response => {
        this.nextActionsMyPending.push('');
        unsortedrows2.push(...response);
        this.nextActionsMyPending.push(...this.appService.sortByKey(unsortedrows2, 'order'));
        this.nextActionLoad = false;
      });

    }

  }
  getProperties() {
    this.loader.show();
    this.propertiesHouseNumberList.push('');
    this.propertiesStreetsList.push('');
    this.propertiesPrecinctList.push('');


    API.get(this.apiNameProperties, this.apiPathProperties, this.getPropertiessInit).then(response => {
      for (let i = 0; i < response.data.length; i++) {
        // console.log(response.data[i]);
        this.propertiesList = response.data;
        this.propertiesPrecinctList.push(response.data[i].precinct);
        this.propertiesPrecinctList = this.propertiesPrecinctList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesPrecinctList.sort();
        localStorage.setItem('propertiesPrecinctList', JSON.stringify(this.propertiesPrecinctList));
        this.propertiesHouseNumberList.push(response.data[i].house_number);
        this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesHouseNumberList.sort();
        localStorage.setItem('propertiesHouseNumberList', JSON.stringify(this.propertiesHouseNumberList));

        this.propertiesStreetsList.push(response.data[i].street);
        this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.propertiesStreetsList.sort();
        localStorage.setItem('propertiesStreetsList', JSON.stringify(this.propertiesStreetsList));

      }

      this.loader.hide();
      console.log('\'tickets\' component-> All properties Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'tickets\' component-> Error in Retreiving All properties from server!');
      console.log(error.response);
    });
  }
  getContractors() {
    this.contractorsList.push('');
    var t = [];
    API.get(this.apiNameContractors, this.apiPathContractors, this.getContractorsInit).then(response => {
      response.data.forEach(function (arrayItem) {
        var y = arrayItem.con_name;
        t.push(y);
      });
      this.contractorsList.push(...t);
      console.log('\'add-new-work-order\' component-> All Contractors Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'add-new-work-order\' component-> Error in Retreiving All Contractors from server!');
      console.log(error.response);
    });
  }
  ///////////////view/////////////
  openImageView(index: number): void {
    this._lightbox.open(this.albums, index);
  }

  closeImageView(): void {
    this._lightbox.close();
  }
  searchRecordApprovalLog(id) {
    let unsortedApprovalLog = [];
    unsortedApprovalLog.splice(0, unsortedApprovalLog.length);
    this.approvalLog.splice(0, this.approvalLog.length);
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    const parentTicketId = id;
    console.log(id);

    if (parentTicketId != undefined) {
      srchFiltersNames.push('parentTicketId');
      srchFiltersValues.push(parentTicketId);
    }
    this.approvalLogScan = true;
    this.scanResultsApproval = 'In Progress...';
    this.projectService.scanContainsAnd(this.apiNameWOApprovalLog, this.pathWOApprovalLog, srchFiltersNames, srchFiltersValues).then(response => {
      unsortedApprovalLog.push(...response);
      const sorted = unsortedApprovalLog.sort((t1, t2) => {
        const name1 = t1.created_time;
        const name2 = t2.created_time;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.approvalLog.splice(0, this.approvalLog.length);
      this.approvalLog.push(...sorted);
      this.scanResultsApproval = 'Completed';
      this.approvalLogScan = false;
    });


  }
  LoadSmplrSpace(reports) {
    console.log(reports);
    this.dataArray.push(reports);
    const scriptElement = this.loadJsScript(SCRIPT_PATH);
    scriptElement.onload = () => {
      console.log('Script loaded');
      console.log(smplr);
      const space = new smplr.Space({
        spaceId: reports.id,
        spaceToken: 'X',
        containerId: 'smplr-container'
      })
      space.startViewer({
        preview: true,
        mode: '2d',
        allowModeChange: false,
        onReady: () => {
          console.log('Viewer is ready');
          space.addDataLayer({
            id: 'reports',
            type: 'point',
            diameter: 0.6,
            data: this.dataArray,
            color: '#1e7bd3',
          })
        },
        onError: error => console.error('Could not start viewer', error)
      })
    }
    scriptElement.onerror = () => {
      console.log('Could not load the Script!');
    }
  }


  public loadJsScript(src: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }
  onView(item) {
    if (item.floor_plan_report != undefined) {
      this.LoadSmplrSpace(item.floor_plan_report)
    } else {
      this.showfloorplan = false;
    }
    this.totalPics = "0";
    this.totalFiles = "0";
    this.getHouseOwnerInfo(item);
    this.viewPictures = [];
    this.viewFiles = [];
    this.nextActionSuccess = false;
    if (item.date_closed == undefined) {
      item.days_open = this.projectService.differenceBetweenDates(this.currentDate.toString(), item.created_date).toString();
    } else {
      item.days_open = this.projectService.differenceBetweenDates(item.date_closed, item.created_date).toString();
    }

    this.searchRecordApprovalLog(item.id);

    if (item.images) {
      if (item.images.length > 0) {
        this.totalPics = item.images.length;
        if (item.images.length <= 50) {
          this.viewPictures = item.images;
        }
      }
    }
    if (item.documents) {
      if (item.documents.length > 0) {
        this.viewFiles = item.documents;
        this.totalFiles = this.viewFiles.length;
      }
    }
    this.viewT = item;
    console.log(this.viewT);
    $('#view_pending_work_order').modal('show');
    this.prepareAlbumLightbox();


    // const url = this.router.serializeUrl(this.router.createUrlTree(['work_orders/approval'], { queryParams: { 'id': item.id } }));
    // window.opener = null;
    // window.open(url, '_blank');
  }
  prepareAlbumLightbox() {
    for (let i = 0; i < this.viewPictures.length; i++) {
      if (this.viewPictures[i] == null || this.viewPictures[i] == "") {
        this.viewPictures[i] = "NoURL";
      }
      const src = this.viewPictures[i];
      const caption = "";
      const thumb = "";
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this.albums.push(album);
    }
  }

 
  getHouseOwnerInfo(item) {
    this.residentname = "loading...";
    this.residentEmail = "loading...";
    this.residentphone = "loading...";
    var filter = {
      street: item.street,
      house_number: item.house_number
    };
    var Filtered = this.propertiesList.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key])
          return false;
      }
      return true;
    });
    console.log(Filtered);
    var house_owner = Filtered[0].house_owner;
    this.searchResident(house_owner);

  }

  searchResident(house_owner) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];
    let unsortedrows = [];


    if (house_owner != undefined) {
      srchFiltersNames.push('name');
      srchFiltersValues.push(house_owner);
    }

    this.projectService.scanContainsAnd(this.apiNameResident, this.pathResident, srchFiltersNames, srchFiltersValues).then(response => {
      unsortedrows.push(...response);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.residentArray.splice(0, this.rows.length);
      this.residentArray.push(...sorted);
      this.residentname = this.residentArray[0].name;
      this.residentEmail = this.residentArray[0].email;
      this.residentphone = this.residentArray[0].telephone1;
      console.log(this.residentArray);
    });

  }

  updateTicketNextAction(f) {
    //console.log(f);
    if (f.approver_remarks == undefined || f.approver_remarks == '') {
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Remark field was left empty. Add a new Remark';
    } else {


      this.loader.show();
      this.projectService.updateAnyFieldInAnyTable(f.id, "next_action", f.next_action, this.apiName, this.pathUpdate).then(response => {
        console.log(response);
        this.addApprovalLog(f.id, this.userLevel, f.next_action, f.approver_remarks);
        this.saveNotifications("Work Order (" + f.id + ") Updated", "Your Work Order " + f.id + " has been Updated.", 'workorders', f.id);
      }).catch(error => {
        this.loader.hide();
        this.nextActionSuccess = true;
        this.nextActionMsg = 'Next Action Failed, Please Try again';
        console.log('\'Add tickets\' component-> Error in Creating Connection!');
        console.log(error);
      });


      if ((f.next_action) === 'Closed') {
        this.projectService.updateAnyFieldInAnyTable(f.id, "closed_by", this.userNickName, this.apiName, this.pathUpdate).then(response => {
          this.projectService.updateAnyFieldInAnyTable(f.id, "date_closed", this.currentDate, this.apiName, this.pathUpdate).then(response => {
            this.projectService.updateAnyFieldInAnyTable(f.id, "time_closed", this.currentTime, this.apiName, this.pathUpdate).then(response => {
            }).catch(error => {
              console.log('\'Error tickets\' component-> Saving time_closed!');
              console.log(error);
            });
          }).catch(error => {
            console.log('\'Error tickets\' component-> Error in saving date_closed!');
            console.log(error);
          });
          }).catch(error => {
          console.log('\'Error tickets\' component-> Error in saving closed_by!');
          console.log(error);
        });
      }


    }
  }
  addApprovalLog(ticketID, created_by, action, remarks) {
    const randomnumber = Math.floor(Math.random() * 99999999);
    const id = 'AL-' + randomnumber;

    const approvalLogBody = {
      id: id,
      parentTicketId: ticketID,
      created_date: this.datePipe.transform(new Date(), 'dd/MM/yyyy'),
      created_time: new Date().toLocaleTimeString(),
      created_by: created_by,
      action: action,
      remarks: remarks
    };
    this.putWOApprovalLogInit.body = approvalLogBody;

    API.put(this.apiNameWOApprovalLog, this.pathWOApprovalLog, this.putWOApprovalLogInit).then(response => {
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Performed Successfully!';
      this.approvalLog.push(approvalLogBody);

      console.log('\'work-order-approval\' component-> Approval Log Recorded Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.nextActionSuccess = true;
      this.nextActionMsg = 'Failed to Perform, Please Try Again';
      console.log('\'work-order-approval\' component-> Error in  Recording Approval Log!');
      console.log(error);
      this.loader.hide();
    });

  }
  saveNotifications(title, body, moduleName, id) {
    const randomnumber = Math.floor(Math.random() * 999999999);
    const createBody = {
      id: randomnumber.toString(),
      title: title,
      body: body,
      deepLinkArticleId: id,
      moduleName: moduleName,
      creation_date: this.currentDate,
      creation_time: this.currentTime
    };
    this.putMyInitNotifications.body = createBody;
    API.put(this.apiNameNotifications, this.pathNotifications, this.putMyInitNotifications).then(response => {
      console.log('\'notifications\' component-> New notification added Successfully!');
      console.log(response);
      this.sendNotifications(title, body, moduleName, id);

      this.loader.hide();
    }).catch(error => {
      console.log('\'notifications\' component-> Error in creating new notifications!');
      console.log(error.response);
    });
  }
  sendNotifications(title, body, moduleName, id) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'key=' + "AAAA5mIllzA:APA91bH-wa6UVq3IvGUIE4oliUiVO1t3tWu5DqocJG8y9p1O9grxBC05dwLCVIxkftFsoXKcpqsK3MHNZaaNM0Wo9xs0vfndkUGGJRqJ9sEk91JJNhtTkNXnQ3RJBxQVETXiZBeuReKL"
    });

    var callibayaqPlzObj = {
      "to": "/topics/houseResidents",
      "notification": {
        "body": body,
        "content_available": true,
        "priority": "high",
        "title": title
      },
      "data": {
        "sound": true,
        "module": moduleName,
        "deepLinkArticleId": id,
        "content_available": true,
        "priority": "high",
        "title": "Testing Deep Linking"
      }
    }

    this.http.post(this.notificationsUrl, callibayaqPlzObj, { headers }).subscribe(res => {
      console.log(res);
    }, error => {
      console.log(error);
    });
  }


  /////////////searching functions///////////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.ticketsList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});

    this.getDynamicNextActions();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.ticketsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.ticketsList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }
  onChangePrecinct(precinct) {
    //console.log(precinct);
    //console.log(this.propertiesList);
    var p = [];
    const filters = { precinct: [precinct] },
      results = this.propertiesList,
      Filtered = this.projectService.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.street;
      p.push(x);
    });

    this.propertiesStreetsList.splice(0, this.propertiesStreetsList.length);
    this.propertiesStreetsList.push('');
    this.propertiesStreetsList.push(...p);
    this.propertiesStreetsList = this.propertiesStreetsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesStreetsList.sort();
  }

  onChangeStreet(street) {
    //console.log(street);
    //console.log(this.propertiesList);
    var p = [];
    var t = [];
    const filters = { street: [street] },
      results = this.propertiesList,
      Filtered = this.projectService.multiFilter(results, filters);
    //console.log(Filtered);
    Filtered.forEach(function (arrayItem) {
      var x = arrayItem.house_number;
      var y = arrayItem.main_contractor;
      p.push(x);
      t.push(y);
    });

    this.propertiesHouseNumberList.splice(0, this.propertiesHouseNumberList.length);
    this.propertiesHouseNumberList.push('');
    this.propertiesHouseNumberList.push(...p);
    this.propertiesHouseNumberList = this.propertiesHouseNumberList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.propertiesHouseNumberList.sort();

    this.contractorsList.splice(0, this.contractorsList.length);
    this.contractorsList.push('');
    this.contractorsList.push(...t);
    this.contractorsList = this.contractorsList.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.contractorsList.sort();
  }
  /////////////////////////Edit/////////////////////////

  addReset() {
    // this.ticket_id_counter = parseInt(localStorage.getItem('ticket_id_counter')) + 1;
    this.showSuccessMsg = false;
    this.nextActionSuccess = false;
    $('#add_ticket').modal('show');
  }
  onEdit(item) {
    // console.log(item);
    localStorage.setItem('itemm', JSON.stringify(item));
    this.router.navigate(['work_orders/submit_work_order']);
  }

  onEditFromView(item) {
    localStorage.setItem('itemm', JSON.stringify(item));
    this.router.navigate(['work_orders/submit_work_order']);
  }
  ///////////////////deletion///////
  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getContractors();
  }
  /////////////////////////languages///////////
  setLanguage(language) {
    this.loader.show();
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.add_new_work_orders_add_work_order = response.Items[0].add_new_work_orders_add_work_order;
      this.add_new_work_orders_created_by = response.Items[0].add_new_work_orders_created_by;
      this.add_new_work_orders_work_order_id = response.Items[0].add_new_work_orders_work_order_id;
      this.add_new_work_orders_street = response.Items[0].add_new_work_orders_street;
      this.add_new_work_orders_house_number = response.Items[0].add_new_work_orders_house_number;
      this.add_new_work_orders_location = response.Items[0].add_new_work_orders_location;
      this.add_new_work_orders_precinct_project = response.Items[0].add_new_work_orders_precinct_project;
      this.add_new_work_orders_element = response.Items[0].add_new_work_orders_element;
      this.add_new_work_orders_floor = response.Items[0].add_new_work_orders_floor;
      this.add_new_work_orders_defect = response.Items[0].add_new_work_orders_defect;
      this.add_new_work_orders_costs = response.Items[0].add_new_work_orders_costs;
      this.add_new_work_orders_competency = response.Items[0].add_new_work_orders_competency;
      this.add_new_work_orders_invoice = response.Items[0].add_new_work_orders_invoice;
      this.add_new_work_orders_days_to_execute = response.Items[0].add_new_work_orders_days_to_execute;
      this.add_new_work_orders_charge_to = response.Items[0].add_new_work_orders_charge_to;
      this.add_new_work_orders_priority = response.Items[0].add_new_work_orders_priority;
      this.add_new_work_orders_main_contractor = response.Items[0].add_new_work_orders_main_contractor;
      this.add_new_work_orders_next_action = response.Items[0].add_new_work_orders_next_action;
      this.add_new_work_orders_date_scheduled = response.Items[0].add_new_work_orders_date_scheduled;
      this.add_new_work_orders_cause = response.Items[0].add_new_work_orders_cause;
      this.add_new_work_orders_time_scheduled = response.Items[0].add_new_work_orders_time_scheduled;
      this.add_new_work_orders_am_pm = response.Items[0].add_new_work_orders_am_pm;
      this.add_new_work_orders_work_order_description = response.Items[0].add_new_work_orders_work_order_description;
      this.add_new_work_orders_upload_pictures = response.Items[0].add_new_work_orders_upload_pictures;
      this.add_new_work_orders_upload_documents_pdf_only = response.Items[0].add_new_work_orders_upload_documents_pdf_only;
      this.add_new_work_orders_create_new_work_order = response.Items[0].add_new_work_orders_create_new_work_order;
      this.all_work_orders_all_work_orders = response.Items[0].all_work_orders_all_work_orders;
      this.all_work_orders_print_work_order = response.Items[0].all_work_orders_print_work_order;
      this.my_pending_work_orders_my_pending_work_orders = response.Items[0].my_pending_work_orders_my_pending_work_orders;
      this.my_pending_work_orders_refresh_list = response.Items[0].my_pending_work_orders_refresh_list;
      this.my_pending_work_orders_add_work_order = response.Items[0].my_pending_work_orders_add_work_order;
      this.work_order_details_my_pending_work_orders = response.Items[0].work_order_details_my_pending_work_orders;
      this.work_order_details_print = response.Items[0].work_order_details_print;
      this.work_order_details_edit_work_order = response.Items[0].work_order_details_edit_work_order;
      this.work_order_details_remarks_and_approval_log = response.Items[0].work_order_details_remarks_and_approval_log;
      this.work_order_details_created_date = response.Items[0].work_order_details_created_date;
      this.work_order_details_created_time = response.Items[0].work_order_details_created_time;
      this.work_order_details_created_by = response.Items[0].work_order_details_created_by;
      this.work_order_details_action = response.Items[0].work_order_details_action;
      this.work_order_details_remarks = response.Items[0].work_order_details_remarks;
      this.work_order_details_next_action = response.Items[0].work_order_details_next_action;

      this.dashboard_property_developer = response.Items[0].dashboard_property_developer;
      this.users_status = response.Items[0].users_status;
      this.work_order_details_days_open = response.Items[0].work_order_details_days_open;
      this.work_order_details_date_closed = response.Items[0].work_order_details_date_closed;
      this.work_order_details_update_work_order = response.Items[0].work_order_details_update_work_order;
      this.work_order_details_work_order_details = response.Items[0].work_order_details_work_order_details;
      this.work_order_details_resident_name = response.Items[0].work_order_details_resident_name;
      this.work_order_details_resident_phone = response.Items[0].work_order_details_resident_phone;
      this.work_order_details_time_closed = response.Items[0].work_order_details_time_closed;
      this.work_order_details_closed_by = response.Items[0].work_order_details_closed_by;
      this.work_order_details_add_remark = response.Items[0].work_order_details_add_remark;

      this.add_new_work_orders_property_developer = response.Items[0].properties_property_developer;

      this.generals_search = response.Items[0].generals_search;
      this.generals_clear_search = response.Items[0].generals_clear_search;
      this.generals_view_details = response.Items[0].generals_view_details;
      this.generals_uploaded_images = response.Items[0].generals_uploaded_images;
      this.generals_edit = response.Items[0].generals_edit;
      this.generals_delete = response.Items[0].generals_delete;
      this.generals_creation_date = response.Items[0].generals_creation_date;
      this.generals_actions = response.Items[0].generals_actions;
      this.generals_uploaded_files = response.Items[0].generals_uploaded_files;
      this.generals_download = response.Items[0].generals_download;

    }).catch(error => {
      this.loader.hide();
      console.log(error);
    });
  }
  retriveLanguages() {
    var status = 'Completed';
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    srchFiltersNames.push('status');
    srchFiltersValues.push(status);

    this.projectService.scanContainsAnd(this.apiNamelanguages, this.pathlanguages, srchFiltersNames, srchFiltersValues).then(response => {
      this.loader.hide();
      this.languages = response.Items;
    }).catch(error => {
      console.log(error);
    });
  }
  
  ngOnDestroy() {
    $('#view_pending_work_order').modal('hide');
    $('#edit_work_order').modal('hide');
    $('#delete_ticket').modal('hide');
    $('#add_ticket').modal('hide');
  }
}




