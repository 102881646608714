import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { API } from 'aws-amplify';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-mp-super-admin',
  templateUrl: './mp-super-admin.component.html',
  styleUrls: ['./mp-super-admin.component.css']
})
export class MpSuperAdminComponent implements OnInit {
  //////////////Alert variable///////
  showSuccessMsg: boolean = false;
  showErrorMsg: boolean = false;
  scan: boolean = false;
  scanResults = '';
  typeofunitload: Boolean = false;
  addEmployeeValidation = false;
  editFlag: boolean = false;

  //////////total list/////////////
  totalList
  searchT: any = [];
  public viewEmp: any = [];
  ///////////get variables///////
  AllList: any;
  public AllPd = [];
  public rows = [];
  public AllPdList = [];
  saveT: any = [];
  userLevel;
  ///////////////delete variable////////
  delete_results;
  /////////////add variables///////
  public ChangesSavingResponse;

  public userLevels = [];
  editf;
  unsortedrows2 = [];
  //////////////API/////////////


  apiName = 'mpConAdmin'; // replace this with your api name.
  path = '/mpConAdmin'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  getMyInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameDelete = 'mpConAdmin'; // replace this with your api name.
  pathDelete = '/mpConAdmin/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };

  apiNameTou = 'userlevelandtask'; // replace this with your api name.
  apiPathTou = '/userlevelandtask'; // replace this with the path you have configured on your API
  getTouInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  constructor(private loader: LoaderService, private projectService: AppService) { }

  ngOnInit() {
    this.showSuccessMsg = false;
    this.showErrorMsg = false;
    this.saveT.dashboard = true;
    this.getPropertyDevelopers();
    this.getAll();
    this.userLevel = localStorage.getItem('user_level');
  }
  getAll() {
    // this.AllList.splice(0, this.AllList.length);
    this.scan = true;
    this.scanResults = "Getting Permissions, Please Wait."
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.rows.splice(0, this.rows.length);
      this.AllList = response.data;
      this.rows.push(...response.data);
      this.totalList = Object.keys(this.rows).length;
      this.loader.hide();
      this.scan = false;
      console.log('\'all-residents\' component-> All Residents Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'all-residents\' component-> Error in Retreiving All Residents from server!');
      console.log(error);
    });
  }
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead" && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  ///////////////on view////////

  onView(f) {
    this.viewEmp = f;
  }

  /////////////search variables//////////
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.AllList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});

    this.getAll();
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.AllList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearchDropDown(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.AllList, name, 'All'))
    this.totalList = Object.keys(this.rows).length;
  }
  //////////////ADD///////////
  onAdd(f) {
    if (this.editFlag) {
      this.onSave(f, f.form.value.id);
    } else {
      const randomnumber = Math.floor(Math.random() * 999999999);
      this.onSave(f, randomnumber.toString());
    }
  }
  onChangePD(property_developer) {
    this.userLevels.splice(0, this.userLevels.length);
    if (property_developer) {
      console.log(property_developer);
      const srchFiltersValues = [];
      const srchFiltersNames = [];

      if (property_developer != undefined) {
        srchFiltersNames.push('property_developer');
        srchFiltersValues.push(property_developer);
      }
      this.projectService.scanContainsAnd(this.apiNameTou, this.apiPathTou, srchFiltersNames, srchFiltersValues).then(response => {
        for(let i=0;i<response.length;i++){
          this.userLevels.push(response[i].user_level);
        }
        console.log(this.userLevels);
        if (this.editFlag) {
          this.saveT = this.editf;
          this.addEmployeeValidation = false;
          $('#add_modal').modal('show');
        }
      });
    }

  }
  onSave(f, id) {
    console.log(f);
    this.addEmployeeValidation = false;
    if (f.invalid == true) {
      this.addEmployeeValidation = true;
    } else {
      this.loader.show();
      const createBody = f.form.value;
      createBody.id = id;
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.loader.hide();
        this.ChangesSavingResponse = "Permission Added Successfully."
        this.showSuccessMsg = true;
        console.log('\'mpconAdmin\' component-> Successfully Updated!');
        console.log(response);
        $('#add_modal').modal('hide');
        this.editFlag = false;
        this.getAll();
      }).catch(error => {
        this.ChangesSavingResponse = "Something wrong happend while saving, Please Try Again."
        this.showErrorMsg = true;
        this.loader.hide();
        console.log('\'mpconAdmin\' component-> Error in Updating!');
        console.log(error.response);
      });
    }
  }
  addReset() {
    this.addEmployeeValidation = false;
    this.saveT = [];
    $('#add_modal').modal('show');
  }
  ///////////////onedit//////////////
  onEdit(f) {
    this.editFlag = true;
    this.onChangePD(f.property_developer);
    this.editf = f;
  }

  ///////////////deletion///////////

  onDelete(c) {
    this.projectService.onDelete(c);
  }

  deleteConfirmation() {
    this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
      this.delete_results = response;
    }).catch(error => {
      this.delete_results = error;
    });
  }

  deleteResult() {
    $('#delete_result').modal('hide');
    this.getAll();
  }
  ///////////////set up module permission/////
  setupModulePermissions(data) {
    this.saveT.dashboard = data[0].dashboard;
    this.saveT.users = data[0].users;

    this.saveT.work_orders = data[0].work_orders;
    this.saveT.work_orders_add = data[0].work_orders_add;
    this.saveT.work_orders_pending = data[0].work_orders_pending;
    this.saveT.work_orders_all = data[0].work_orders_all;

    this.saveT.properties = data[0].properties;
    this.saveT.properties_property = data[0].properties_property;
    this.saveT.properties_documents = data[0].properties_documents;


    this.saveT.residents = data[0].residents;
    this.saveT.residents__all = data[0].residents__all;
    this.saveT.residents_lead = data[0].residents_lead;

    
    this.saveT.crm = data[0].crm;
    this.saveT.crm_add = data[0].crm_add;
    this.saveT.crm_pending = data[0].crm_pending;
    this.saveT.crm_all = data[0].crm_all;
    this.saveT.crm_reports = data[0].crm_reports;

    this.saveT.property_developers = data[0].property_developers;

    this.saveT.contractors = data[0].contractors;
    this.saveT.contractors_all = data[0].contractors_all;
    this.saveT.contractors_my = data[0].contractors_my;

    this.saveT.payments = data[0].payments;
    this.saveT.all_payments = data[0].all_payments;
    this.saveT.eghl_payments = data[0].eghl_payments;
    this.saveT.payments_bukku_accounting = data[0].payments_bukku_accounting;


    this.saveT.payments = data[0].agents;
    this.saveT.bookings = data[0].bookings;
    this.saveT.bookings_venues = data[0].bookings_venues;
    this.saveT.bookings_booking = data[0].bookings_booking;

    this.saveT.announcements = data[0].announcements;

    this.saveT.services = data[0].services;
    this.saveT.category_services = data[0].category_services;
    this.saveT.sub_services = data[0].sub_services;
    this.saveT.services_service = data[0].services_service;

    this.saveT.guard_house = data[0].guard_house;
    this.saveT.visitor_guard_house = data[0].visitor_guard_house;
    this.saveT.cms = data[0].cms;
    this.saveT.menue_cms = data[0].menue_cms;
    this.saveT.sub_menue_cms = data[0].sub_menue_cms;
    this.saveT.content_cms = data[0].content_cms;

    this.saveT.leasing_and_tenancy = data[0].leasing_and_tenancy;
    this.saveT.leasing_and_tenancy_add = data[0].leasing_and_tenancy_add;
    this.saveT.leasing_and_tenancy_pending = data[0].leasing_and_tenancy_pending;
    this.saveT.leasing_and_tenancy_all = data[0].leasing_and_tenancy_all;
    this.saveT.plts_report = data[0].plts_report;

    this.saveT.plts_defects = data[0].plts_defects;
    this.saveT.plts_defects_add = data[0].plts_defects_add;
    this.saveT.plts_defects_pending = data[0].plts_defects_pending;
    this.saveT.plts_defects_all = data[0].plts_defects_all;

    this.saveT.documentation = data[0].documentation;
    this.saveT.settings = data[0].settings;
  }
}
