import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_creation_date = 'Creation Date';
  generals_actions = 'Actions';


  // dashboard_property_developer='Client';
  // dashboard_main_contractor='Main Contractor';
  // dashboard_precinct_project='Precincts/Projects/Condos';
  // dashboard_street='Street';
  // dashboard_house_number='House Number';
  deal = "Deal";
  dashboard_date_from = 'Date From';
  dashboard_date_to = 'Date To'
  dashboard_Number_of_Listed_Clients = 'Number of Listed Clients'
  dashboard_Total_Number_of_Outlets = 'Total Number of Outlets'
  dashboard_Average_Number_Of_Outlets = 'Average Number Of Outlets';
  dashboard_Total_Deals_Value = 'Total Deals Value';
  dashboard_Average_Deal_Value_Client = 'Average Deal Value / Client';
  dashboard_Average_Deal_Value_Outlet = 'Average Deal Value / Outlet';
  dashboard_New_Clients_Accepted = 'New Clients Accepted';
  dashboard_Deals_KIV = 'Deals KIV'
  dashboard_Deals_Lost = 'Deals Lost'
  // dashboard_age_open_work_orders='Age Open Work Orders';
  // dashboard_age_closed_work_orders='Age Closed Work Orders'
  // dashboard_frontdesk_work_orders='Front Desk Work Orders';
  // dashboard_supervisor_work_orders='Supervisor Work Orders';
  dashboard_created_closed_work_orders_Month = 'Deals Closed - Deal Value  (Compared to Previous Month)';
  dashboard_created_closed_work_orders_quarter = 'Deals Closed  - Previous Quarter';
  public userLevel: string;
  showPD = false;
  searchT: any = [];
  show_search_boxes = false;
  constructor() { }

  ngOnInit() {

    this.showPD = true;
    this.show_search_boxes = true;
  }

}
