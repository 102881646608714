import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { API } from 'aws-amplify';
import { LoaderService } from 'src/app/loader/loader.service';
import * as XLSX from 'xlsx';
import { UploadFileService } from 'src/app/services/upload-file.service';

declare const $: any;
type AOA = any[][];


@Component({
  selector: 'app-services-category',
  templateUrl: './services-category.component.html',
  styleUrls: ['./services-category.component.css']
})


export class ServicesCategoryComponent implements OnInit {

  public rows = [];
  public srch = [];
  pictures = [];


  selectedPics: FileList;
  eventPics;
  havePictures: boolean = false;
  viewPictures = [];

  public addLT: any = {};
  public uptLT: any = {};
  addFormValidation: Boolean = false;
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  public MSG;
  totalList: any;

  public categoryList = [];
  public userLevel;

  uploadPicsFolder = '/General/Settings/Services/Category';


  searchT: any = [];
  scan: boolean = false;
  scanResults = '';
  unsortedrows = [];

  deleteID;
  delete_results;
  apiNameDelete = 'servicesCategory'; // replace this with your api name.
  pathDelete = '/servicesCategory/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };
  logoPic;
  apiName = 'servicesCategory'; // replace this with your api name.
  path = '/servicesCategory'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  public rowslanguages = [];

  apiNamelanguages = 'languages'; // replace this with your api name.
  pathlanguages = '/languages'; // replace this with the path you have configured on your API
  getMyInitlanguages = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };

  services_upload_picture='Upload Pitcture';
  services_category='Category';

  title = 'Services';
  generals_search = 'Search';
  generals_clear_search = 'Clear Search';
  generals_view_details = 'View Details';
  generals_uploaded_images = 'Uploaded Images'
  generals_edit = 'Edit'
  generals_delete = 'Delete';
  generals_uploaded_files = 'Uploaded Files';
  generals_actions = 'Actions';
  generals_creation_date = 'Creation Date';

  constructor(private leaveService: AppService, private loader: LoaderService, private uploadService: UploadFileService
  ) {
    this.userLevel = localStorage.getItem('user_level');

  }


  ngOnInit() {
    this.getAllCategories();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    var language = localStorage.getItem('language');
    if(language!='undefined' && language!='English'){
      console.log(language);
      this.searchlanguage(language);
    }else{
      //console.log("null5");
    }
  }

  addReset() {
    this.addFormValidation = false;
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');
  }


  onEdit(item) {
    this.uptLT = item;
    this.addFormValidation = false;
    if(this.uptLT.pictures){
    this.viewPictures.splice(0, this.viewPictures.length);
    this.viewPictures.push(this.uptLT.pictures);
    this.logoPic = this.uptLT.pictures;
  }

    $('#Edit_modal').modal('show');

  }

  updateRecordAndPic(f) {
    this.loader.show();
    if (this.havePictures) {
      this.uploadPicsAndFiles().then(Response => {
        console.log(Response);
        this.onUpdate(f);
        this.havePictures = false;
      }).catch(error => {
        console.log(error);
        this.havePictures = false;
      });
    } else {
      this.onUpdate(f);
    }
  }

  onUpdate(f) {
    if (f.invalid) {
      this.addFormValidation = true;
    } else {
      const createBody = {
        id: f.form.value.id,
        category: f.form.value.category,
        pictures: this.logoPic,
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        var id = f.form.value.id;
        var arr = this.rows.find(function (item, i) {
          return item.id === id
        });

        arr.id = f.form.value.id;
        arr.category = f.form.value.category;
        arr.pictures = this.logoPic;


        var index = this.rows.findIndex(function (item, i) {
          return item.id === id
        });
        if (index > -1) {
          this.rows.splice(index, 1);
        }

        this.rows.unshift(arr);
        this.srch.unshift(arr);
        this.rows = this.rows;
        $('#Edit_modal').modal('hide');
        console.log('\'category\' component-> Updated Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.MSG = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'category\' component-> Error in Updating!');
        console.log(error.response);
      });
    }
  }



  uploadAllPictures(event) {
    this.selectedPics = event.target.files;
    this.eventPics = event;
    this.havePictures = true;
  }


  addCatWithPics(f) {
    this.loader.show();
    if (this.havePictures) {
      this.uploadPicsAndFiles().then(Response => {
        console.log(Response);
        this.addFormCategory(f);
        this.havePictures = false;
      }).catch(error => {
        console.log(error);
        this.havePictures = false;
      });
    } else {
      this.addFormCategory(f);
    }
  }

  uploadPicsAndFiles() {
    return new Promise<string>((resolve, reject) => {

      if (this.havePictures) {
        this.uploadPics().then(Response => {
          console.log(Response);
          resolve("Add  Now!")
          this.eventPics.srcElement.value = null;
          resolve(Response);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      } else {
        resolve("Add  Now!")
      }
    });
  }

  uploadPics() {
    return new Promise<string>((resolve, reject) => {
      for (let i = 0; i < this.selectedPics.length; i++) {
        const file = this.selectedPics.item(i);
        this.uploadService.uploadfile(file, this.uploadPicsFolder).then(Response => {
          console.log("PIC Uploaded Successfully " + JSON.stringify(Response));
          this.logoPic = Response.Location;
          this.pictures.push(Response.Location);
          resolve("All pics uploaded");
        }).catch(error => {
          console.log("Error in uploading pic" + error);
          reject(error);
        });
      }
    });
  }



  addFormCategory(f) {
    if (f.invalid) {
      this.addFormValidation = true;
    } else {
      const randomnumber = Math.floor(Math.random() * 999999999);

      const createBody = {
        id: randomnumber.toString(),
        category: f.form.get('category').value,
        pictures: this.pictures.join(";"),
      };
      this.putMyInit.body = createBody;
      API.put(this.apiName, this.path, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.MSG = 'Added Successfully!';
        this.viewPictures.splice(0, this.viewPictures.length);
        this.viewPictures.push(this.pictures.join(";"));

        this.rows.unshift(f.form.value);
        // this.srch.unshift(f.form.value);
        this.rows = this.rows;
        $('#add_modal').modal('hide');
        console.log('\'category\' component-> New category added Successfully!');
        console.log(response);
        this.loader.hide();
      }).catch(error => {
        this.showErrorMsg = true;
        this.MSG = 'Failed, Please Retry or Contact App Support';
        this.loader.hide();
        console.log('\'category\' component-> Error in creating new category!');
        console.log(error.response);
      });
    }

  }


  searchRecord(f) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];

    const category = f.form.get('category').value;

    if (category != undefined) {
      srchFiltersNames.push('category');
      srchFiltersValues.push(category);
    }

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += 'contains ' + '(#' + srchFiltersNames[i] + ' , ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);

    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);

    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      this.getAllCategories();
    } else {
      this.unsortedrows.splice(0, this.unsortedrows.length);
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);
      this.getMyInit.body.FilterExpression = FilterExpression;
      this.getMyInit.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInit.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanRows();
    }
  }

  

  scanRows() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    this.getRows(this.apiName, this.path, this.getMyInit).then(response => {
      this.unsortedrows.push(...response.Items);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.category;
        const name2 = t2.category;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);

      this.totalList = Object.keys(this.rows).length;
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInit.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanRows();
      } else {
        this.categoryList.push(...this.rows);
        this.scanResults = 'Completed';
        this.scan = false;
        if (this.totalList == 0) {
          this.scanResults = 'Completed. No Results Found for this Search Please Try Different Search parameters!';
        }
        this.srch.push(...this.rows);
        delete this.getMyInit.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
      if (this.totalList != 0) {
        this.scanResults = 'Incomplete, Records List Reached to its maximum limit, Please Narrow Down your Search by Adding More Filters ';
      } else {
        this.scanResults = 'Sorry Some Error Occured While Scanning, Please Try again ';
      }
    });
  }

  getRows(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'category\' component->  Rows Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.loader.hide();
        console.log('\'category\' component-> Error in Retreiving Rows from server!');
        console.log(error.response);
        reject(error.response);
      });

    });
  }

  searchCategory(val) {
    // console.log(val);
    // console.log(this.srch);
    // console.log(this.rows);
    if (val === '') {
      this.rows.splice(0, this.rows.length);
      this.rows.push(...this.srch);
      this.totalList = Object.keys(this.rows).length;

    } else {
      const filters = { category: [val] },
        results = this.srch,
        Filtered = this.multiFilter(results, filters);
      this.rows.splice(0, this.rows.length);
      this.rows.push(...Filtered);
      this.totalList = Object.keys(this.rows).length;

      //   console.log(Filtered);
    }
  }

  clearSearch() {
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
      this.rows.splice(0, this.rows.length);
      this.srch.splice(0, this.srch.length);

      this.rows.push(...this.categoryList);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);

  }

  multiFilter(array, filters) {
    return array.filter(o =>
      Object.keys(filters).every(k =>
        [].concat(filters[k]).some(v => o[k].toLowerCase().includes(v.toLowerCase()))));
  }

  getAllCategories() {
    this.unsortedrows.splice(0, this.unsortedrows.length);

    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.scan = false;
      this.unsortedrows.push(...response);
      const sorted = this.unsortedrows.sort((t1, t2) => {
        const name1 = t1.category;
        const name2 = t2.category;
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
      this.rows.splice(0, this.rows.length);
      this.rows.push(...sorted);
      this.totalList = Object.keys(this.rows).length;
      this.srch.push(...this.rows);


      console.log('\'project\' component-> All categories Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All categories from server!');
      console.log(error.response);
    });
  }


  searchlanguage(language) {
    const srchFiltersValues = [];
    const srchFiltersNames = [];


    srchFiltersNames.push('language');
    srchFiltersValues.push(language);

    let FilterExpression = '';
    for (let i = 0; i < srchFiltersNames.length; i++) {
      FilterExpression += '(#' + srchFiltersNames[i] + ' = ' + ':' + srchFiltersNames[i] + ')';
      if (i != srchFiltersNames.length - 1) {
        FilterExpression += ' and ';
      }
    }
    console.log(FilterExpression);


    const ExpressionAttributeNames = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeNames['#' + srchFiltersNames[i]] = srchFiltersNames[i];
    }
    console.log(ExpressionAttributeNames);


    const ExpressionAttributeValues = {};
    for (let i = 0; i < srchFiltersNames.length; i++) {
      ExpressionAttributeValues[':' + srchFiltersNames[i]] = srchFiltersValues[i];
    }
    console.log(ExpressionAttributeValues);

    if (srchFiltersNames.length == 0) {
      // this.ticketScan = true;
      // this.scanResults = 'Please Enter Atleast One Search Field to get Work Orders';
    } else {
      this.getMyInitlanguages.body.FilterExpression = FilterExpression;
      this.getMyInitlanguages.body.ExpressionAttributeNames = ExpressionAttributeNames;
      this.getMyInitlanguages.body.ExpressionAttributeValues = ExpressionAttributeValues;
      this.scanLanguage();
    }

  }

  scanLanguage() {
    var unsortedrows = [];

    this.getLanguage(this.apiNamelanguages, this.pathlanguages, this.getMyInitlanguages).then(response => {
      console.log(response.Items);

      unsortedrows.push(...response.Items);
      const sorted = unsortedrows.sort((t1, t2) => {
        const name1 = t1.id;
        const name2 = t2.id;
        if (name1 < name2) { return 1; }
        if (name1 > name2) { return -1; }
        return 0;
      });
      this.rowslanguages.splice(0, this.rowslanguages.length);
      this.rowslanguages.push(...sorted);
      if (typeof response.LastEvaluatedKey != 'undefined') {
        this.getMyInitlanguages.body['ExclusiveStartKey'] = response.LastEvaluatedKey;
        this.scanLanguage();
      } else {
        // console.log(this.rows);
        this.services_category = this.rowslanguages[0].services_category;      
        this.services_upload_picture = this.rowslanguages[0].services_upload_picture;      
        
        
        this.generals_search = this.rowslanguages[0].generals_search;
        this.generals_clear_search = this.rowslanguages[0].generals_clear_search;
        this.generals_view_details = this.rowslanguages[0].generals_view_details;
        this.generals_uploaded_images = this.rowslanguages[0].generals_uploaded_images;
        this.generals_edit = this.rowslanguages[0].generals_edit;
        this.generals_delete = this.rowslanguages[0].generals_delete;
        this.generals_uploaded_files = this.rowslanguages[0].generals_uploaded_files;
        this.generals_actions = this.rowslanguages[0].generals_actions;
        this.generals_creation_date = this.rowslanguages[0].generals_creation_date;
  
        delete this.getMyInitlanguages.body['ExclusiveStartKey'];
        return;
      }
    }).catch(error => {
      console.log(error);
    });
  }

  getLanguage(apiName, apiPath, getMyInt): any {

    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        console.log('\'login\' component->  languages Retrieved Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        console.log('\'login\' component-> Error in Retreiving languages from server!');
        console.log(error);
        reject(error.response);
      });

    });
  }

  onDelete(c) {
    console.log(c.id);
    this.deleteID=c.id;
    $('#delete').modal('show');
  }

  deleteConfirmation() {
    $('#delete').modal('hide');
    this.loader.show();
    console.log("delete confirmed "+this.deleteID);
    const createBody = {
      "key":{
          "id":this.deleteID
  }
  }
    this.myInitDelete.body = createBody;
    this.delete(this.apiNameDelete,this.pathDelete,this.myInitDelete).then(response => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Deleted Successfully."
     // this.getUsers();
      console.log('\'\' component-> Deleted Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      $('#delete_result').modal('show');
      this.delete_results="Error in Deletion, Please Contact App Support."
      console.log('\'\' component-> Error in Deleting!');
      console.log(error);
    });
  }

  deleteResult(){
    $('#delete_result').modal('hide');
    this.getAllCategories();
  }

  delete(apiName, apiPath, getMyInt): any {
    return new Promise<string>((resolve, reject) => {
      API.post(apiName, apiPath, getMyInt).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  }
}
