import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { API } from 'aws-amplify';

declare const $: any;
type AOA = any[][];
@Component({
  selector: 'app-tempate-upload-workflows',
  templateUrl: './tempate-upload-workflows.component.html',
  styleUrls: ['./tempate-upload-workflows.component.css']
})
export class TempateUploadWorkflowsComponent implements OnInit {
  uploadFile = false;
  Results = '';
  excelData: AOA = [[1, 2], [3, 4]];
  showSuccessMsg = false;
  showErrorMsg = false;
  PropertyAdded = '';


  apiName = 'pltstask'; // replace this with your api name.
  apiPath = '/pltstask'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };

  
  constructor() { }

  ngOnInit() {
  }

  DownloadTemplate() {
    window.open("https://workordersfiles920.s3.amazonaws.com/General/TemplateUploads/WorkflowsTemplate.xlsx");
  }

  onFileUpload(evt: any) {
    this.showErrorMsg=false;
    this.uploadFile = false;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      this.showErrorMsg = true;
      this.Results = "Sorry Cannot use multiple files. Please Contact Customer Support";
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.excelData);

      if(this.excelData.length>=2){
      if (this.excelData[1].length == 7) {
        this.uploadFile = true;
        this.Results = "File Uploaded Successfully! Please Press Submit To Upload.";
       // this.asynccalltoupload(this.excelData);
      }else{
        this.showErrorMsg = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support";
      }
    } else {
        this.showErrorMsg = true;
        this.Results = "Sorry Wrong Format of the file. Please Contact Customer Support";
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  async asynccalltoupload(excelData) {
    for (var i = 2; i < excelData.length; i++) {
      await new Promise<void>(resolve => {
        this.addPropertiesFromFile(excelData[i][0], excelData[i][1], excelData[i][2],excelData[i][3],excelData[i][4],excelData[i][5],excelData[i][6], i).then(response => {
          console.log('This is iteration ' + i);
          resolve();
        }).catch(error => {
          console.log(error);
        });
      });
    }
    this.Results = "Completed. Please Go to UserLevel Module to view your Uploaded Actions.";
  }

  addPropertiesFromFile(id, property_developer,workflow_for, order,from_user_level,task,to_user_level,i): any {
    const createPropertyBody = {
      id: id,
      property_developer: property_developer,
      workflow_for: workflow_for,
      order: order,
      from_user_level: from_user_level,
      task: task,
      to_user_level:to_user_level
    };

    this.putMyInit.body = createPropertyBody;
    console.log(createPropertyBody);
    return new Promise<string>((resolve, reject) => {
      API.put(this.apiName, this.apiPath, this.putMyInit).then(response => {
        this.showSuccessMsg = true;
        this.PropertyAdded = i + ": " + id + 'Added Successfully!';
        console.log('\'project\' component-> Created Successfully!');
        console.log(response);
        resolve(response);
      }).catch(error => {
        this.showErrorMsg = true;
        this.Results = id + ' Creation Failed, Please Retry!';
        console.log('\'project\' component-> Error in creating!');
        console.log(error.response);
        reject(error.response);
      });
    });

  }

  submit(){
    this.Results='Under Progress, Please Wait.'
    this.asynccalltoupload(this.excelData);
  }

}
