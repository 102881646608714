import { Component, OnInit } from '@angular/core';
import { API } from 'aws-amplify';
import { AppService } from 'src/app/app.service';
import { LoaderService } from 'src/app/loader/loader.service';

@Component({
  selector: 'app-user-level',
  templateUrl: './user-level.component.html',
  styleUrls: ['./user-level.component.css']
})
export class UserLevelComponent implements OnInit {
  ///////////total list variable/////
  totalList: any;
  searchT: any = [];
  /////////////alert variable////////
  showErrorMsg: Boolean = false;
  showSuccessMsg: Boolean = false;
  addTypeOfUnitValidation: Boolean = false;
  public addMsg;
  scan: boolean = false;
  scanResults = '';
  editFlag: boolean = false;
  UpdatedMsg;
  //////////search variables/////////////
  public addLT: any = {};
  public uptLT: any = {};
  public rows = [];
  public AllPdList = [];
  public AllPd = [];
  precinctsList = [];
  /////////////////API///////////////
  apiNamePD = 'propertydevelopers';
  apiPathPD = '/propertydevelopers';
  apiName = 'userlevelandtask'; // replace this with your api name.
  path = '/userlevelandtask'; // replace this with the path you have configured on your API
  putMyInit = {
    body: {}, // replace this with attributes you need
    headers: {} // OPTIONAL
  };
  getMyInit = {
    headers: {},
    body: {
      FilterExpression: '',
      ExpressionAttributeNames: {
      },
      ExpressionAttributeValues: {
      }
    }
  };
  delete_results;

  apiNameDelete = 'userlevelandtask'; // replace this with your api name.
  pathDelete = '/userlevelandtask/delete'; // replace this with the path you have configured on your API
  myInitDelete = {
    body: {}, // replace this with attributes you need
  };

  getPDInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param'
    }
  };
  constructor(private loader: LoaderService, private projectService: AppService) { }

  ngOnInit() {
    this.getAll();
    this.getPropertyDevelopers();
    $('.floating').on('focus blur', function (e) {
      $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }
  ///////////////get functions////////////
  getPropertyDevelopers() {
    var p = [];

    API.get(this.apiNamePD, this.apiPathPD, this.getPDInit).then(response => {
      this.AllPdList.push(...response.data)
      response.data.forEach(function (arrayItem) {
        var x = arrayItem.pd_name;
        p.push(x);
      });
      const results = p.filter(element => {
        return element !== undefined && element !== "" && element !== "New Lead";
      });
      this.AllPd.splice(0, this.AllPd.length);
      this.AllPd.push(...results);
      console.log('\'Contractor\' component-> All pd Retrieved Successfully!');
      console.log(this.AllPd);
    }).catch(error => {
      this.loader.hide();
      console.log('\'Contractors\' component-> Error in Retreiving All pd from server!');
      console.log(error.response);
    });
  }
  getAll() {
    this.scan = true;
    this.scanResults = 'In Progress...';
    API.get(this.apiName, this.path, this.getMyInit).then(response => {
      this.precinctsList = response;
      this.scan = false;
      this.rows.splice(0, this.rows.length);
      this.rows.push(...response);
      this.totalList = Object.keys(this.rows).length;
      console.log('\'project\' component-> All  precincts Retrieved Successfully!');
      console.log(response);
    }).catch(error => {
      this.loader.hide();
      console.log('\'project\' component-> Error in Retreiving All  precincts from server!');
      console.log(error.response);
    });
  }
  //////////////search functions//////////
  clearSearch() {
    this.rows.splice(0, this.rows.length);
    this.searchT = Object.keys(this.searchT).reduce(
      (accumulator, current) => {
        accumulator[current] = null;
        return accumulator
      }, {});
    this.getAll();
  }
  searchRecordLocally(f) {
    let Filtered = this.projectService.searchRecordLocally(f, this.precinctsList);
    this.rows.splice(0, this.rows.length);
    this.rows.push(...Filtered);
    console.log(Filtered);
    this.totalList = Object.keys(this.rows).length;
  }
  instantSearch(val, name) {
    this.rows.splice(0, this.rows.length);
    this.rows.push(...this.projectService.instantSearch(val, this.precinctsList, name, ''))
    this.totalList = Object.keys(this.rows).length;
  }


  ////////////add function///////////
  onAdd(f) {
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      const randomnumber = Math.floor(Math.random() * 999999999);
      this.addform(f, randomnumber)
    }
  }
  addform(f, id) {
    console.log(f.form.value);
    this.loader.show();
    f.form.value.id = id.toString(),
      this.putMyInit.body = f.form.value;
    Object.keys(this.putMyInit.body).forEach(key => this.putMyInit.body[key] === undefined ? delete this.putMyInit.body[key] : {});
    API.put(this.apiName, this.path, this.putMyInit).then(response => {
      this.showSuccessMsg = true;
      this.addMsg = 'User Level Added Successfully!';
      if (this.editFlag == false) {
        this.rows.unshift(f.form.value);
        this.rows = this.rows;
      } else {
        this.addMsg = "Updated Successfully."
        $('#Edit_modal').modal('hide');
      }
      $('#add_modal').modal('hide');
      this.getAll();
      console.log('\'type of unit\' component-> User Level added Successfully!');
      console.log(response);
      this.loader.hide();
    }).catch(error => {
      this.showErrorMsg = true;
      this.addMsg = 'Failed, Please Retry or Contact App Support';
      this.loader.hide();
      console.log('\'type of unit\' component-> Error in creating new User Level!');
      console.log(error.response);
    });

  }
  addReset() {
    let randomnumber = Math.floor(Math.random() * 500);
    this.addLT = { 'id': randomnumber };
    $('#add_modal').modal('show');
  }
  ////////////Edit function//////////
  onEdit(item) {
    this.uptLT = item;
    $('#Edit_modal').modal('show');
  }
  onUpdate(f) {
    if (f.invalid) {
      this.addTypeOfUnitValidation = true;
    } else {
      this.addform(f, f.form.value.id);
      this.editFlag = true;

    }
  }

    ///////////////deletion///////////

    onDelete(c) {
      this.projectService.onDelete(c);
    }
  
    deleteConfirmation() {
      this.projectService.deleteConfirmation(this.apiNameDelete, this.pathDelete).then(response => {
        this.delete_results = response;
      }).catch(error => {
        this.delete_results = error;
      });
    }
  
    deleteResult() {
      $('#delete_result').modal('hide');
      this.getAll();
    }

}
